import { colors } from '../../theme';

export const dataOverview = [
  {
    "id": 1,
    "label": "Emolumentos",
    "qtd": 50,
    "color": colors.fadedGrey
  },
  {
    "id": 2,
    "label": "Registros",
    "qtd": 100,
    "color": colors.orange
  },
  {
    "id": 3,
    "label": "Inscrições",
    "qtd": 60,
    "color": colors.darkYellow
  },
  {
    "id": 4,
    "label": "Resenhas",
    "qtd": 20,
    "color": colors.red
  },
];

export const dataNextMaturities = {
  count: '',
  pages: '',
  registrationRequests: [

  ],
};

export const dataOpenBillets = {
  count: '',
  pages: '',
  registrationRequests: [
  ],
};
