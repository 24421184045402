import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Select as AntSelect } from 'antd';

import { Container } from './styles';

const Select = ({ name, width, disabled, options, ...props }) => (
  <Container
    size="large"
    name={name}
    id={name}
    disabled={disabled}
    width={width}
    suffixIcon={<FiChevronDown size={24} />}
    {...props}
  >
    {options?.map((option, index) => (
      <AntSelect.Option key={index} value={option.value}>
        {option.label}
      </AntSelect.Option>
    ))}
  </Container>
);

Select.defaultProps = {
  width: 320,
  disabled: false,
};

export default Select;
