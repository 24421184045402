import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  border-radius: ${pxToRem(6)};
  padding: ${pxToRem(6)} ${pxToRem(20)} ${pxToRem(6)} ${pxToRem(24)};

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.1);

  ${media.greaterThan('1600px')`
    border-radius: ${pxToRem(7)};
    padding: ${pxToRem(7)} ${pxToRem(28)};
  `}

  ${media.greaterThan('1900px')`
  border-radius: ${pxToRem(8)};
    padding: ${pxToRem(8)} ${pxToRem(34)};
  `}
`;

export const DebtsIcon = styled.div`
  img {
    width: ${pxToRem(51)};
    height: ${pxToRem(51)};

    ${media.greaterThan('1600px')`
      width: ${pxToRem(60)};
      height: ${pxToRem(60)};
  `}

    ${media.greaterThan('1900px')`
      width: ${pxToRem(70)};
      height: ${pxToRem(70)};
  `}
  }
`;

export const DebtsInfo = styled.div`
  margin-left: ${pxToRem(16)};

  display: flex;
  align-items: left;
  flex-direction: column;

  color: ${colors.white};

  h1 {
    ${fonts.size5};
    color: rgba(255, 255, 255, 0.75);
    font-family: 'SFRounded-Regular';
  }

  span {
    ${fonts.size6};
  }

  ${media.greaterThan('1600px')`
    margin-left: ${pxToRem(18)};

    h1 {
      ${fonts.size6};
    }

    span {
      ${fonts.size7};
    }
  `}

  ${media.greaterThan('1900px')`
    margin-left: ${pxToRem(22)};

    h1 {
      ${fonts.size7}
    }

    span {
      ${fonts.size8};
    }
  `}
`;

export const DebtsDetails = styled.a`
  margin-left: ${pxToRem(14)};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  opacity: 0.5;
  color: ${colors.white};
  transition: opacity 0.15s ease;

  &:hover {
    opacity: 0.7;
  }

  img {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  p {
    margin-top: ${pxToRem(2)};

    ${fonts.size1};
    text-align: center;
    color: ${colors.white};
  }

  ${media.greaterThan('1600px')`
    margin-left: ${pxToRem(16)};

    img {
      width: ${pxToRem(18)};
      height: ${pxToRem(18)};
    }

    p {
      ${fonts.size2}
    }
  `}

  ${media.greaterThan('1900px')`
    margin-left: ${pxToRem(20)};

    img {
      width: ${pxToRem(22)};
      height: ${pxToRem(22)};
    }

    p {
      ${fonts.size3}
    }
  `}
`;
