import React from 'react';
import PropTypes from 'prop-types';
// import { FiDownload } from 'react-icons/fi';
import { BsFilter } from 'react-icons/bs';
import { GiBroom } from 'react-icons/gi';

import { PageTitle } from '../.';

import { colors } from '../../theme';
import { Container, Button } from './styles';

const TableHeader = ({
  title,
  count,
  onSearch,
  filtered,
  clearFilter,
  onFilterButtonClick,
  extraButtons,
}) => (
  <Container>
    <div className="title-actions-wrapper">
      <PageTitle>{title}</PageTitle>

      <div className="actions-wrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();

            onSearch();
          }}
        >
          <label>Busca: </label>
          <input
            onChange={(e) => onSearch(e.target.value)}
            placeholder="Digite..."
          />
        </form>

        {/* <Button color={colors.blue} fontLight>
          <FiDownload size={14} />
          &nbsp; PDF
        </Button> */}

        {extraButtons}

        <Button color="rgba(178, 178, 178, 0.47)" onClick={onFilterButtonClick} className="ButtonDrawler">
          <BsFilter size={18} />
          &nbsp;Filtro
        </Button>

        {filtered && (
          <Button color={colors.blue} onClick={clearFilter} width="32px" >
            <GiBroom size={18} color={colors.white} />
          </Button>
        )}
      </div>
    </div>

    <p>
      {count} {count <= 1 ? 'registro' : 'registros'}
    </p>
  </Container>
);

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onFilterButtonClick: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  extraButtons: PropTypes.node,
};

TableHeader.defaultProps = {
  onSearch: () => {},
};

export default TableHeader;
