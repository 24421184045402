import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { api } from '../../services/api';

import {
  Table,
  FormItem,
  Select,
  PreLoader,
  Breadcrumb,
  TableHeader,
} from '../../components';

import { colors } from '../../theme';
import { sort } from '../../functions';
import profileHorseDefault from '../../theme/assets/images/silhueta.svg';

import { Container, Image, Animal, Drawer, Form, DrawerButton } from './styles';

const AnimalRelationship = () => {
  const initialParameters = useMemo(() => ({
    sexo: '',
    obito: '0',
    propriedade: 2,
    pagina: 1,
    tamanho_pag: 50,
    ativo_studbook: '1',
  }), []);

  const [page, setPage] = useState(1);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState(false);
  const [preLoader, setPreLoader] = useState(true);
  const [activeSort, setActiveSort] = useState('');
  const [filterData, setFilterData] = useState({
    sex: '',
    status: '1',
    situation: '0',
    property: '2',
  });
  const [allAnimals, setAllAnimals] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [listingTotal, setListingTotal] = useState(0);
  const [params, setParams] = useState(initialParameters);
  const [searchedAnimals, setSearchedAnimals] = useState([]);
  const [listingOfAnimals, setListingOfAnimals] = useState([]);

  const pageTitle = useMemo(() => 'Relação de Animais', []);

  //Open and close drawer
  const switchDrawer = useCallback(() => 
    setDrawerVisibility(!drawerVisibility), 
    [drawerVisibility]
  );

  //Function to call in api.
  const fetchAnimalRelationship = useCallback(async (params, page) => {
    setLoading(true);

    console.log('params', { ...params, pagina: page })

    try {
      const { data } = await api.get('animal/relacaoanimais', {
        params: { ...params, pagina: page },
      });

      console.log(data)

      if (data.success === true) {
        setListingTotal(data.total);

        return data.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setPreLoader(false);
    }
  }, []);

  //Executes the function of calling the api as soon as the screen is rendered or when the params state is changed.
  useEffect(() => {
    setPreLoader(true);

    const initialTableData = async () => {
      const fetchedData = await fetchAnimalRelationship(params, 1);

      setAllAnimals(fetchedData);
    };

    initialTableData();
  }, [fetchAnimalRelationship, params]);

  useEffect(() => {
    const listenSearch = async () => {
      if (searchValue === '') {
        setListingOfAnimals(allAnimals);
      } else {
        setListingOfAnimals(searchedAnimals);
      }
    };

    listenSearch();
  }, [searchValue, allAnimals, searchedAnimals]);

  const handleSearch = useCallback((value) => {
    setSearchValue(value);

    const searchedItems = allAnimals.filter((animal) => {
      const name = animal?.nome?.toLowerCase();
      const coat = animal?.pelagem?.toLowerCase();
      const valueToSearch = value?.toLowerCase();

      return (
        name.indexOf(valueToSearch) !== -1 || coat.indexOf(valueToSearch) !== -1
      );
    });

    setSearchedAnimals(searchedItems);
  }, [allAnimals]);

  const handleSort = useCallback((objectKey, method) => {
    const [activeSort, sortedData] = sort(listingOfAnimals, objectKey, method);

    setActiveSort(activeSort);
    setListingOfAnimals(sortedData);
  }, [listingOfAnimals]);

  const handleSubmitFilter = useCallback(() => {
    setPage(1);
    setListingOfAnimals([]);


    const filteredParams = {
      pagina: page,
      tamanho_pag: 50,
      sexo: filterData?.sex ? filterData.sex : '',
      obito: filterData?.situation ? filterData.situation : '',
      propriedade: filterData?.property ? filterData.property : '',
      ativo_studbook: filterData?.status ? filterData.status : '',
    };

    setParams(filteredParams);

    setFiltered(true);

    switchDrawer();
  }, [page, filterData, switchDrawer]);

  const clearFilter = useCallback(() => {
    setPage(1);
    setFiltered(false);
    setListingOfAnimals([]);
    setParams(initialParameters);
  }, [initialParameters]);

  const fetchMoreData = useCallback(async () => {
    const fetchedData = await fetchAnimalRelationship(params, page + 1);

    setPage(page + 1);

    setListingOfAnimals([...listingOfAnimals, ...fetchedData]);
    setAllAnimals([...allAnimals, ...fetchedData]);
  }, [fetchAnimalRelationship, params, page, listingOfAnimals, allAnimals]);

  const tableColumns = useMemo(() => [
    { key: 'img_animal', title: '', size: 0.7 },
    { key: 'nome', title: 'NOME / REGISTRO', sort: true, size: 5.5 },
    { key: 'sexo', title: 'SEXO', sort: true, size: 1.4 },
    { key: 'pelagem', title: 'PELAGEM', sort: true, size: 2.4 },
    { key: 'sangue', title: 'SANGUE', sort: true, size: 2 },
    { key: 'data_nascimento', title: 'NASCIMENTO', sort: true, size: 3.5 },
    { key: 'obito', title: 'SITUAÇÃO', sort: true, size: 2 },
    { key: 'ativo_studbook', title: 'STATUS', sort: true, size: 1.2 },
  ], [])

  const tableData = useMemo(() => listingOfAnimals?.map((rowOfAnimal) => ({
    img_animal: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        <Image>
          {rowOfAnimal?.img_animal ? (
            <img src={rowOfAnimal.img_animal} alt="horse" />
          ) : (
            <img src={profileHorseDefault} alt="horse" />
          )}
        </Image>
      </Link>
    ),
    nome: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        <Animal>
          <p className="name">{rowOfAnimal.nome}</p>
          <p className="registry">{rowOfAnimal.registro}</p>
        </Animal>
      </Link>
    ),
    sexo: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        {rowOfAnimal.sexo === 'M' ? 'Macho' : 'Fêmea'}
      </Link>
    ),

    pelagem: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        {rowOfAnimal.pelagem}
      </Link>
    ),
    sangue: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        {rowOfAnimal.sangue}
      </Link>
    ),
    data_nascimento: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        {rowOfAnimal.data_nascimento}
      </Link>
    ),
    obito: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        {rowOfAnimal.obito === 0 ? 'Vivo' : 'Em óbito'}
      </Link>
    ),
    ativo_studbook: (
      <Link to={`/perfil-do-animal/${rowOfAnimal?.id_animal}`}>
        {rowOfAnimal.ativo_studbook === 0 ? 'Inativo' : 'Ativo'}
      </Link>
    ),
  })), [listingOfAnimals]);

  const selectPropertyOptions = useMemo(() => [
    // { value: '0', label: 'Todos os animais' },
    { value: '1', label: 'Proprietário' },
    { value: '2', label: 'Criador' },
  ], []);

  const selectSexOptions = useMemo(() => [
    { value: '', label: 'Ambos' },
    { value: 'femea', label: 'Fêmea' },
    { value: 'macho', label: 'Macho' },
  ], []);

  const selectStatusOptions = useMemo(() => [
    { value: '', label: 'Ambos' },
    { value: '1', label: 'Ativo' },
    { value: '0', label: 'Suspenso' },
  ], []);

  const selectSituationOptions = useMemo(() => [
    { value: '', label: 'Ambos' },
    { value: '0', label: 'Vivos' },
    { value: '1', label: 'Em óbito' },
  ], []);

  const handleSubmit = () => {
    console.log('Filter values: ', filterData);

    switchDrawer();
  };

  return (
    <Container>
      <Breadcrumb
        pages={[
          {
            title: pageTitle,
          },
        ]}
      />

      <Helmet>
        <title>{pageTitle} - Central do Quartista</title>
      </Helmet>

      <TableHeader
        title={pageTitle}
        filtered={filtered}
        count={listingTotal}
        onSearch={handleSearch}
        clearFilter={clearFilter}
        onFilterButtonClick={switchDrawer}
      />

      {preLoader ? (
        <PreLoader light />
      ) : (
        <Table
          light
          loading={loading}
          count={listingTotal}
          columns={tableColumns}
          dataSource={tableData}
          handleSort={handleSort}
          activeSort={activeSort}
          dataLength={allAnimals}
          fetchMoreData={fetchMoreData}
        />
      )}

      <Drawer
        title="Filtro"
        placement="right"
        onClose={switchDrawer}
        destroyOnClose
        visible={drawerVisibility}
      >
        <Form onFinish={handleSubmitFilter}>
        <FormItem label="Propriedade" name="property">
            <Select
              placeholder="Selecione"
              name="property"
              width="100%"
              options={selectPropertyOptions}
              value={filterData.property}
              onChange={(value) => setFilterData({ ...filterData, property: value })}
            />
          </FormItem>

          <FormItem label="Sexo" name="sex" className="form-item2">
            <Select
              placeholder="Selecione"
              name="sex"
              width="100%"
              options={selectSexOptions}
              value={filterData.sex}
              onChange={(value) => setFilterData({ ...filterData, sex: value })}
            />
          </FormItem>

          <FormItem label="Status" name="status" className="form-item3">
            <Select
              placeholder="Selecione"
              name="status"
              width="100%"
              options={selectStatusOptions}
              value={filterData.status}
              onChange={(value) =>
                setFilterData({ ...filterData, status: value })
              }
            />
          </FormItem>

          <FormItem label="Situação" name="situation" className="form-item3">
            <Select
              placeholder="Selecione"
              name="situation"
              width="100%"
              options={selectSituationOptions}
              value={filterData.situation}
              onChange={(value) =>
                setFilterData({ ...filterData, situation: value })
              }
            />
          </FormItem>

          <DrawerButton type="submit" color={colors.lightGreen} fontLight>
            Aplicar
          </DrawerButton>
        </Form>
      </Drawer>
    </Container>
  );
};

export default AnimalRelationship;
