import React, { useState, useCallback, useEffect } from 'react';
import Lottie from 'react-lottie';
import moment from 'moment';
import swal from 'sweetalert';
import { Helmet } from 'react-helmet';
import { Form, List, message } from 'antd';
import { FiCheck, FiArrowRight } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { cpf } from 'cpf-cnpj-validator';

import successAnimated from '../../theme/assets/animations/success.json';
import alertAnimated from '../../theme/assets/animations/alert.json';
import errorAnimated from '../../theme/assets/animations/error.json';

import {
  Modal,
  Input,
  Button,
  Select,
  MailSvg,
  FormItem,
  Checkbox,
  PageTitle,
  Breadcrumb,
  DatePicker,
  MaskedInput,
  AutoComplete,
  FormValidator,
  SearchButtonAnimals,
  // Radio,
} from '../../components';

import { colors } from '../../theme';
import { parseDateIso, handleCpfOrCnpj } from '../../functions';
import { api } from '../../services';
import { useAuth } from '../../context/Auth';

import {
  Steps,
  Drawer,
  ListItem,
  Container,
  AnimalInfo,
  StepWrapper,
  ModalFooter,
  ModalButton,
  ModalContent,
  MareTypeButton,
  MareTypeWrapper,
  CloseModalButton,
  MareOwnerDocumentTypeContainer,
  CoverageDataContainer,
  CoverageDataRowGroup
} from './styles';

const CoverageCommunication = () => {
  // Constants
  const pageTitle = 'Comunicado de cobertura';
  const minCharsToSearchAnimal = 4;

  // Hooks
  const location = useLocation();
  const [form] = Form.useForm();
  const {
    userData: { token },
  } = useAuth();

  // States
  const [currentStep, setCurrentStep] = useState(
    location.state?.stallion ? 1 : 0
  );
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState([false, false, false]);
  const [sendEmail, setSendEmail] = useState(false);
  const [notFoundStallionMessage, setNotFoundStallionMessage] = useState('');
  const [notFoundMareMessage, setNotFoundMareMessage] = useState('');
  const [emptyAutoCompleteField, setEmptyAutoCompleteField] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [frozenSemenImporters, setFrozenSemenImporters] = useState(location.state?.frozenSemenImporters || []);
  const [frozenSemenImporter, setFrozenSemenImporter] = useState({
    label: '',
    data: {},
    value: null, //int
  });
  const [stallion, setStallion] = useState(location.state?.stallion || {});
  const [stallionOptions, setStallionOptions] = useState([]);
  const [searchStallionLoading, setSearchStallionLoading] = useState(false);
  const [mare, setMare] = useState({});
  const [mareOptions, setMareOptions] = useState([]);
  const [searchMareLoading, setSearchMareLoading] = useState(false);
  const [mareType, setMareType] = useState('registered');
  const [commonMareType, setCommonMareType] = useState('');
  const [myAnimals, setMyAnimals] = useState([]);
  const [isFetchingStallions, setIsFetchingStallions] = useState(false);
  const [isFetchingMares, setIsFetchingMares] = useState(false);

  const [coats, setCoats] = useState([]);
  const [coatType, setCoatType] = useState('');
  const [mareOwnerCpf, setMareOwnerCpf] = useState('');
  const [ownerCommonMare, setOwnerCommonMare] = useState({});
  const [ownerCommonMareDocumentType, setOwnerCommonMareDocumentType] = useState(''); // CPF || CNPJ
  const [isFetchingOwnerByDocument, setIsFetchingOwnerByDocument] = useState(''); // CPF || CNPJ
  const [preRegisterMareNumber, setPreRegisterMareNumber] = useState('');
  const [coverageType, setCoverageType] = useState('');
  const [coverageDatesString, setCoverageDatesString] = useState(['', '', '']);
  const [coverageDates, setCoverageDates] = useState([null, null, null]);
  const [invalidDateMessage, setInvalidDateMessage] = useState(null);

  const [sendCertificate, setSendCertificate] = useState(false);
  const [modalCertificateOpen, setModalCertificateOpen] = useState(false);
  const [modalValidationOpen, setModalValidationOpen] = useState(false);
  const [coverageCommunicationStatus, setCoverageCommunicationStatus] = useState({
    text: '',
    title: '',
    type: '', //attention | error
    can_ignore_validation: 0, //ignore some validation when 1
  });

  const [coverageCommunicated, setCoverageCommunicated] = useState({});

  const switchDrawer = useCallback(() => setDrawerVisibility(!drawerVisibility), [setDrawerVisibility, drawerVisibility]);

  const switchModal = useCallback(() => {
    setModalVisibility(!modalVisibility);

    if (sendEmail === true) {
      setSendEmail(false);
    }
  }, 
  [
    modalVisibility,
    sendEmail 
  ]);

  const switchModalCertificate = useCallback(() => {
    setModalCertificateOpen(!modalCertificateOpen);
  }, [modalCertificateOpen]);

  const switchModalValidation = useCallback(() => {
    setModalCertificateOpen(!modalValidationOpen);
  }, [modalValidationOpen]);

  const handleCancelCertificate = useCallback(() => {
    setModalCertificateOpen(false);
    setModalVisibility(true);
  }, []);

  useEffect(() => {
    const fetchCoats = async () => {
      try {
        const { data } = await api.get('/animal/pelagem');

        if (data.success) {
          const formattedCoats = data.data.map((coat) => ({
            value: coat.id_pelagem,
            label: coat.ds_pelagem,
          }));

          setCoats(formattedCoats);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCoats();
  }, []);

  /**
   * Fetch animals in API
   * @param {string} value value to search
   * @param {string} gender animal gender: 'M' for male, 'F' for female
   * @param {number} thirds animals owner: 0 for my animals, 1 for thirds
   */
  const fetchAnimals = useCallback(async (value, gender, thirds) => {
    if (gender !== 'M' && gender !== 'F') {
      throw new Error('Gender must be equal "M" or "F"');
    }

    if (thirds && thirds !== 0 && thirds !== 1) {
      throw new Error('Thirds must be equal 0 or 1');
    }

    try {
      const { data } = await api.get('/animal/consultaanimal', {
        params: {
          terceiros: thirds || 0,
          sexo: gender,
          nome_registro: value,
        },
      });

      if (data.success) {
        return data.data;
      }
    } catch (error) {
      console.error(error.message);
    }

    return null;
  }, []);

  useEffect(() => {
    const allowThirdStep = () => {
      const cpfNumber =
        mareOwnerCpf && handleCpfOrCnpj.removeSeparators(mareOwnerCpf);

      let allowed = false;

      if (mareType === 'common') {
        if (
          mare.name &&
          coatType &&
          mareOwnerCpf.match(/[0-9]/g)?.length === 11 &&
          cpf.isValid(cpfNumber)
        ) {
          if (commonMareType !== 'in-process') {
            allowed = true;
          } else if (
            commonMareType === 'in-process' &&
            preRegisterMareNumber.match(/[0-9]/g)?.length === 7
          ) {
            allowed = true;
          } else {
            allowed = false;
          }
        } else {
          allowed = false;
        }

        if (allowed) setCurrentStep(2);
        else setCurrentStep(1);
      }
    };

    allowThirdStep();
  }, [
    mareType,
    mare,
    coatType,
    mareOwnerCpf,
    commonMareType,
    preRegisterMareNumber,
  ]);

  const fetchAllStallions = useCallback(async () => {
    if (myAnimals.filter((animal) => animal.gender === 'M').length > 0) {
      return switchDrawer();
    } else {
      setIsFetchingStallions(true);

      const fetchedStallions = await fetchAnimals('', 'M');

      if (fetchedStallions) {
        setMyAnimals(
          fetchedStallions.map((stallion) => ({ ...stallion, gender: 'M' }))
        );
        switchDrawer();
      }

      setIsFetchingStallions(false);
    }
  }, 
  [
    fetchAnimals,
    myAnimals,
    switchDrawer
  ]);

  const fetchAllMares = useCallback(async () => {
    if (myAnimals.filter((animal) => animal.gender === 'F').length > 0) {
      return switchDrawer();
    } else {
      setIsFetchingMares(true);

      const fetchedMares = await fetchAnimals('', 'F');

      if (fetchedMares) {
        setMyAnimals(fetchedMares.map((mare) => ({ ...mare, gender: 'F' })));
        switchDrawer();
      }

      setIsFetchingMares(false);
    }
  }, 
  [
    fetchAnimals, 
    myAnimals, 
    switchDrawer
  ]);

  const getFrozenSemenImporters = useCallback(
    async id_animal => {
      try {
        const { data } = await api.get('semencongelado/importadores', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            id_animal,
          },
        });

        if (data.success) {

          const importers = data.data.map((importer, index) => ({
            label: importer.nome_pessoa,
            data: importer,
            value: index,
          }));

          setFrozenSemenImporters(importers);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, setFrozenSemenImporters],
  );

  const onSelectSemenImporter = useCallback(
    value => {
      setFrozenSemenImporter(() => (
        frozenSemenImporters.filter(importer => importer.value === value)[0]
      ));
    },[frozenSemenImporters]
  )

  const selectAnimal = useCallback((animal, gender) => {
    const {
      id_animal,
      registro_abqm,
      nome_animal,
      nome_proprietario,
      cpf_cnpj,
      id_proprietario,
      registro_aqha,
      sangue
    } = animal;

    if (gender !== 'M' && gender !== 'F') {
      throw new Error('Gender must be equal "M" or "F"');
    } else {
      if (gender === 'M') {
        setStallion({
          id: id_animal,
          name: nome_animal,
          abqmRegister: registro_abqm,
          registro_aqha,
          blood: sangue,
          owner: {
            id: id_proprietario,
            name: nome_proprietario,
            cpfOrCpnj: cpf_cnpj,
          },
        });

        setNotFoundMareMessage('');
        setNotFoundStallionMessage('');
        setCurrentStep(currentStep < 1 ? 1 : currentStep);
        setStallionOptions([]);
        getFrozenSemenImporters(id_animal);
        form.setFieldsValue({
          stallion: nome_animal,
        });
      }

      if (gender === 'F') {
        setMare({
          id: id_animal,
          name: nome_animal,
          abqmRegister: registro_abqm,
          blood: sangue,
          owner: {
            id: id_proprietario,
            name: nome_proprietario,
            cpfOrCpnj: cpf_cnpj,
          },
        });

        setCurrentStep(currentStep < 2 ? 2 : currentStep);
        setMareOptions([]);
        form.setFieldsValue({
          mare: nome_animal,
        });
      }
    }
  }, 
  [
    currentStep,
    form,
    getFrozenSemenImporters
  ]);

  const onSelectAnimalFromTable = (animal) => {
    if (animal && animal.gender) {
      selectAnimal(animal, animal.gender);
      switchDrawer();
    }
  };

  const onSearchStallion = useCallback(async (value) => {
    if (!value) {
      setCurrentStep(0);
      setStallion({});
    }

    if (
      value.length >= minCharsToSearchAnimal &&
      stallionOptions.length === 0
    ) {
      setSearchStallionLoading(true);

      const stallions = await fetchAnimals(value, 'M', 1);

      if (stallions?.length > 0) {
        const options = stallions.map((animal) => {
          let valueToSearch = animal.nome_animal;

          if (value.match(/[a-z]\d[0-9]*/i)) {
            valueToSearch = animal.registro_abqm;
          }

          return { value: valueToSearch, animal };
        });

        setStallionOptions(options);
        setNotFoundStallionMessage('');
      } else {
        setNotFoundStallionMessage('Animal não encontrado');
      }

      setSearchStallionLoading(false);
    }
  }, 
  [
    setCurrentStep,
    setStallion,
    fetchAnimals,
    stallionOptions
  ]);

  const onSelectStallion = useCallback((value, option) => {
    if (option.animal) {
      selectAnimal(option.animal, 'M');
    }
  }, 
  [selectAnimal]);

  const onChangeAnimal = (value, animal) => {
    if (animal !== 'stallion' && animal !== 'mare') {
      throw new Error('Animal must be equal "stallion" or "mare" ');
    } else {
      if (value.length >= minCharsToSearchAnimal) {
        setEmptyAutoCompleteField(false);
      } else {
        setEmptyAutoCompleteField(true);
        animal === 'stallion' ? setStallionOptions([]) : setMareOptions([]);
        animal === 'stallion' ? setStallion({}) : setMare({});
      }
    }
  };

  const onSearchMare = useCallback(async (value) => {
    if (!value) {
      setCurrentStep(1);
      setMare({});
    }

    if (value.length >= minCharsToSearchAnimal && mareOptions.length === 0) {
      setSearchMareLoading(true);

      const mares = await fetchAnimals(value, 'F', 1);

      if (mares?.length > 0) {
        const options = mares.map((animal) => {
          let valueToSearch = animal.nome_animal;

          if (value.match(/[pm]\d[0-9]*/i)) {
            valueToSearch = animal.registro_abqm;
          }

          return { value: valueToSearch, animal };
        });

        setMareOptions(options);
        setNotFoundMareMessage('');
      } else {
        setNotFoundMareMessage('Animal não encontrado');
      }

      setSearchMareLoading(false);
    }
  }, 
  [
    fetchAnimals,
    mareOptions
  ]);

  const onSelectMare = useCallback(async (value, option) => {
    if (option.animal) {
      selectAnimal(option.animal, 'F');
    }
  },
  [selectAnimal]);

  const handleMareType = useCallback((type) => {
    if (type !== 'registered' && type !== 'common') {
      throw new Error('handleMareType must be equal "registered" or "common"');
    }

    setOwnerCommonMareDocumentType('CPF');
    setCommonMareType('');

    setMareType(type);
    setMare({});
    form.setFieldsValue({
      mare: '',
    });
  },
  [form]);

  const handleCommonMareType = useCallback((valueChecked, type) => {
    if (type !== 'psi' && type !== 'in-process') {
      throw new Error(
        'handleCommonMareType must be equal "psi" or "in-process"'
      );
    }

    if(commonMareType === type) {
      setCommonMareType('');
      return;
    }

    setCommonMareType(type);
  }, 
  [commonMareType]);

  const handleCommonMareOwnerDocumentType = (valueChecked, type) => {
    if (type !== 'CPF' && type !== 'CNPJ') {
      throw new Error(
        'handleCommonMareOwnerDocumentType must be equal "psi" or "in-process"'
      );
    }

    setOwnerCommonMareDocumentType(type);
  };

  /**
   * Change isCalendarOpen array with position selected equal a true
   * @param {number} index of component selected
   */
  const handleCalendarOpen = useCallback((index) => {
    const calendarToOpen = isCalendarOpen.map((isOpen, mapIndex) => {
      if (index < 0) return false;
      else return index === mapIndex ? !isOpen : isOpen;
    });

    setIsCalendarOpen(calendarToOpen);
  },
  [
    setIsCalendarOpen,
    isCalendarOpen
  ]);

  const validateDate = useCallback((dateString, index) => {
    const day = Number(dateString.split('/')[0]);
    const month = Number(dateString.split('/')[1]);
    const year = Number(dateString.split('/')[2]);

    const isoDate = parseDateIso(dateString);
    const currentDate = new Date();

    let invalidDateMessage = null;

    if (dateString.match(/[0-9]/g).length === 8) {
      if (!(isoDate instanceof Date && !isNaN(isoDate))) {
        invalidDateMessage = 'Digite uma data válida';
      }

      if (isoDate > currentDate) {
        invalidDateMessage = 'A data não pode ser maior que a data atual';
      }

      if (index > 0) {
        const previousCoverageDay = coverageDates[index - 1]?.getDate();
        const previousCoverageMonth = coverageDates[index - 1]?.getMonth() + 1;
        const previousCoverageYear = coverageDates[index - 1]?.getFullYear();

        if (!coverageDates[index - 1] || !coverageDates[0]) {
          invalidDateMessage = 'Digite uma data para a cobertura anterior';
        } else {
          if (
            (day <= previousCoverageDay && month <= previousCoverageMonth) ||
            month < previousCoverageMonth
          ) {
            invalidDateMessage =
              'A data não pode ser menor ou igual que a data da cobertura anterior';
          }

          if (
            (previousCoverageMonth < 7 && month >= 7) ||
            (previousCoverageMonth >= 7 && month < 7) ||
            previousCoverageYear !== year
          ) {
            invalidDateMessage =
              'Todas as datas devem estar dentro do mesmo semestre';
          }
        }
      }
    }

    return invalidDateMessage;
  },
  [coverageDates]);

  const disabledDate = useCallback((currentDate, index) => {
    return (
      (coverageDates[index - 1] &&
        currentDate < moment(coverageDates[index - 1]).endOf('day')) ||
      currentDate > moment().endOf('day')
    );
  }, 
  [coverageDates]);

  /**
   * Change coverageDatesString with date selected on array position and validate based on business rules
   * @param {number} index index of component selected
   * @param {string} value value of component selected
   * @param {object} date moment date format
   * @param {string} dateString date selected on picker
   */
  const handleDateChange = useCallback((index, value, date, dateString) => {
    let currentDate;

    if (value) {
      currentDate = value;
    }

    if (date && dateString) {
      currentDate = dateString;
    }

    if (currentDate?.match(/[0-9]/g)?.length === 8) {
      const validationFailsMessage = validateDate(currentDate, index);

      if (validationFailsMessage) {
        swal('Data inválida', validationFailsMessage, 'error');
        setInvalidDateMessage(validationFailsMessage);
      } else {
        const isoDate = parseDateIso(currentDate);

        const dateToInsert = coverageDatesString.map((item, mapIndex) =>
          index === mapIndex ? currentDate : item
        );

        const isoDateToInsert = coverageDates.map((item, mapIndex) =>
          index === mapIndex ? isoDate : item
        );

        setCoverageDates(isoDateToInsert);
        setCoverageDatesString(dateToInsert);
        setInvalidDateMessage(null);
      }
    }
  }, 
  [
    validateDate, 
    coverageDates, 
    coverageDatesString, 
    setCoverageDates, 
    setCoverageDatesString, 
    setInvalidDateMessage
  ]);

  const handleResetDate = useCallback((elementIndex) => {
    const resetedCoverageDate = coverageDates.map((date, index) => {
      if (index >= elementIndex) return date = null;

      return date;
    });

    const resetedCoverageDateString = coverageDatesString.map((date, index) => {
      if (index >= elementIndex) return date = '';

      return date;
    });

    setCoverageDates(resetedCoverageDate);
    setCoverageDatesString(resetedCoverageDateString);
  }, [coverageDates, coverageDatesString])

  const validateDateField = useCallback((getFieldValue, item) => ({
    validator(rule, value) {
      const index = item - 1;

      if (value) {
        const currentDate =
          getFieldValue(`coverage-date${item}`) || coverageDatesString[index];
        const validationFailsMessage = validateDate(currentDate, index);

        if (validationFailsMessage) {
          return Promise.reject('Digite uma data válida');
        } else {
          return Promise.resolve();
        }
      } else if (item === 1 && coverageDatesString[0].length < 10) {
        return Promise.reject('Digite uma data');
      } else {
        return Promise.resolve();
      }
    },
  }),
  [coverageDatesString, validateDate]);

  const validateCpf = () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject('Informe o número de CPF do proprietário');
      } else {
        const cpfNumber = handleCpfOrCnpj.removeSeparators(value);

        if (value.match(/[0-9]/g).length === 11) {
          if (cpf.isValid(cpfNumber)) {
            return Promise.resolve();
          } else {
            return Promise.reject('Digite um número de CPF válido');
          }
        }
      }
    },
  });

  const handleGetOwnerByDocument = useCallback(async () => {

    const documentNumber = mareOwnerCpf.replace(/[^a-z0-9]/gi, '');

    if ((ownerCommonMareDocumentType === 'CPF' && documentNumber.length !== 11) 
      || (ownerCommonMareDocumentType === 'CNPJ' && documentNumber.length !== 14)
    ) {
      swal(
        `${ownerCommonMareDocumentType} inválido`, 
        `O ${ownerCommonMareDocumentType} digitado não é válido, tente novamente.`, 
        'warning'
      );
      setIsFetchingOwnerByDocument(false);
      return;
    }

    setIsFetchingOwnerByDocument(true);

    try {
      const { data } = await api.get('pessoa/consultapessoa', {
        headers: { Authorization: `Bearer ${token}`},
        params: {
          nome_cpf: documentNumber
        }
      });

      if (data.success) {
        if (data.data.length > 0) {
          setOwnerCommonMare(data.data[0]);
          setIsFetchingOwnerByDocument(false);
        }
        else{
          swal(
            'Pessoa não encontrada', 
            'Não foi possível encontrar a pessoa pesquisada, tente novamente.', 
            'warning'
          );
        }
      }

      else {
        swal(
          'Ocorreu um erro',
          `Ocorreu um erro ao buscar usuário pelo ${ownerCommonMareDocumentType}, entre em contato com o suporte da ABQM.`,
          'error'
        );
      }
    } catch (error) {
      swal(
        'Ocorreu um erro',
        `Ocorreu um erro ao buscar usuário pelo ${ownerCommonMareDocumentType}, entre em contato com o suporte da ABQM.`,
        'error'
      );
    } finally {
      setIsFetchingOwnerByDocument(false);
    }
  }, [ownerCommonMareDocumentType, mareOwnerCpf, token])

  const handleGoBackValidation = useCallback(() => {
    setModalValidationOpen(false);
    setCoverageCommunicationStatus({
      text: '',
      title: '',
      type: '', //attention | error
      can_ignore_validation: 0, //ignore some validation when 1
    });
  }, []);

  const validateCoverage = useCallback(() => {
    if (
      mareType === 'registered' &&
      (mare.blood.trim() === 'Puro de Origem' ||
        mare.blood.trim() === 'Puro por Cruza') &&
      coverageType === 1
    )
      throw new Error(
        'Cobertura A Campo somente para éguas comuns, Mestiço 1/2, Mestiço 3/4 ou Mestiço 7/8.',
      );

    if (!stallion.registro_aqha && coverageType === 3)
      throw new Error(
        'Cobertura Sêmen Importado apenas para garanhões importados.',
      );

    if ((Object.keys(frozenSemenImporter.data).length === 0) && (coverageType === 3))
      throw new Error(
        'Comunicação do tipo Sêmen Importado precisam de um importador, selecione um importador e tente novamente.',
      );
  }, 
  [
    mare, 
    stallion, 
    coverageType, 
    frozenSemenImporter, 
    mareType
  ]);

  const submitCoverageCommunication = useCallback(async () => {    

    setModalValidationOpen(false);

    if (invalidDateMessage) {
      return swal('Data inválida', invalidDateMessage, 'error');
    }

    const dateFormat = 'YYYY-MM-DD';

    const formattedCoverageDates = coverageDates.map((date) =>
      date ? moment(date).format(dateFormat) : null
    );

    let dataToSubmit = {
      id_animal_garanhao: stallion.id,
      id_proprietario_garanhao:
            coverageType === 3
              ? frozenSemenImporter.data.id_pessoa
              : stallion.owner.id,
      dt_cobertura1: formattedCoverageDates[0],
      dt_cobertura2: formattedCoverageDates[1],
      dt_cobertura3: formattedCoverageDates[2],
      id_tipo_cobertura: coverageType,
      ignorar_validacao_cobertura: !!coverageCommunicationStatus.can_ignore_validation,
    };

    if (mareType === 'registered') {
      dataToSubmit = {
        ...dataToSubmit,
        id_animal_egua: mare.id,
        id_proprietario_egua: mare.owner.id,
        id_raca: 1,
      };
    } else if (mareType === 'common') {
      dataToSubmit = {
        ...dataToSubmit,
        id_animal_egua: null,
        nome_animal_egua: mare.name,
        id_pelagem_egua: coatType,
        cpf_cnpj: handleCpfOrCnpj.removeSeparators(mareOwnerCpf),
        id_proprietario_egua: ownerCommonMare.id_pessoa,
        id_raca: 3,
      };

      if (commonMareType === 'psi') {
        dataToSubmit = {
          ...dataToSubmit,
          id_raca: 2,
        };
      } else if (commonMareType === 'in-process') {
        dataToSubmit = {
          ...dataToSubmit,
          pre_registro_mae: preRegisterMareNumber,
          id_raca: null,
        };
      }
    }

    setSubmitLoading(true);

    try {
      const { data } = await api.post('/cobertura/comunicacao', dataToSubmit);

      if (data.success) {
        setCoverageCommunicationStatus(prev => ({
          ...prev,
          can_ignore_validation: 0,
          status: true,
        }));

        setCoverageCommunicated(data.data.cobertura);
        setModalCertificateOpen(data.data.certificado === '1' ? true : false);
        setModalVisibility(data.data.certificado === '1' ? false : true)
      }
      else{
        setCoverageCommunicationStatus(prev => ({
          title: 'Atenção!',
          text: `${data.mensagem}`,
          can_ignore_validation: data.can_ignore_validation === true ? 1 : 0,
          type: data.can_ignore_validation === true ? 'attention' : 'error'
        }));

        setModalValidationOpen(true);
      }
    } catch (error) {
      setCoverageCommunicationStatus({
        title: 'Falha!',
        text: `${
            error.response?.data?.mensagem 
          ||'Erro ao comunicar a cobertura. Se o erro persistir contate-nos!'
        }`,
        can_ignore_validation: 0,
        type: 'error'
      });

      setModalValidationOpen(true);
    } finally {
      setSubmitLoading(false);
    }
  }, 
  [
    stallion, 
    coverageDates, 
    coverageType,
    coverageCommunicationStatus, 
    invalidDateMessage, 
    mareType,
    mare,
    mareOwnerCpf,
    coatType,
    commonMareType,
    preRegisterMareNumber,
    setSubmitLoading,
    setCoverageCommunicationStatus,
    setModalValidationOpen,
    setModalVisibility,
    frozenSemenImporter,
    ownerCommonMare,
  ]);
  
  const handleSubmit = useCallback((values) => {

    try {
      validateCoverage();
      submitCoverageCommunication();
    } catch (error) {
      swal(
        'Atenção!',
        error.message,
        'warning'
      );
    }
  }, [submitCoverageCommunication, validateCoverage]);


  useEffect(() => {
    if (sendCertificate) {
      setModalCertificateOpen(false);

      const handleSendCertificate = async () => {
        try {
          const { data } = await api.post(
            'cobertura/emitecertificadocobertura',
            {
              pre_registro: coverageCommunicated.num_preregistro,
              autorizar_emissao: true,
            }
          );

          if (data.success) {
            message.success(data.mensagem);
          }
        } catch (error) {
          if (error.response?.data?.success === false) {
            swal('Erro!', error.response?.data?.mensagem, 'error');
          }
        } finally {
          setModalVisibility(true);
        }
      };
      handleSendCertificate();
    }
  }, [sendCertificate, coverageCommunicated]);

  const handleSendEmail = useCallback(async (values) => {
    const { address } = values;

    if (address) {
      if (!address.match(/@/g)) {
        return message.error('Digite um e-mail válido!');
      }

      const emails = address.replace(/\s/g, '').split(',');

      setSendingEmail(true);

      try {
        await Promise.all(
          emails.map(async (email) => {
            const { data } = await api.post('/cobertura/enviaremail', {
              email,
              pre_registro: coverageCommunicated.num_preregistro,
            });

            if (data.success) {
              message.success(`E-mail enviado para ${email} com sucesso!`);
            } else {
              message.error('Houve um erro ao enviar o e-mail');
            }
          })
        );
      } catch (error) {
        console.error(error.response);

        message.error('Houve um erro ao enviar o e-mail');
      } finally {
        setSendingEmail(false);
        setSendEmail(false);
      }
    }
  },
  [coverageCommunicated]);

  const FirstStep = useCallback(() => (
    <StepWrapper>
      <FormItem name="stallion" label="Identifique o Garanhão">
        <div className="separetor">
          <FormValidator
            name="stallion"
            message="Digite o registro ou nome do garanhão"
            initialValue={stallion.name}
            required={!stallion.name}
          >
            <AutoComplete
              options={stallionOptions}
              value={stallion.name}
              defaultValue={stallion.name}
              name="stallion"
              placeholder="Digite o registro ou nome"
              loading={searchStallionLoading}
              onSearch={onSearchStallion}
              onSelect={onSelectStallion}
              onChange={(value) => onChangeAnimal(value, 'stallion')}
              notFoundContent={notFoundStallionMessage}
              open={!emptyAutoCompleteField}
            />
          </FormValidator>

          <p>ou</p>

          <SearchButtonAnimals
            title="Buscar em meus animais"
            description="Meus animais"
            onClick={fetchAllStallions}
            loading={isFetchingStallions}
          />

          {currentStep >= 1 && Object.keys(stallion).length > 0 && (
            <AnimalInfo>
              <div>
                <p>Nº do Registro</p>
                <span>
                  <strong>{stallion.abqmRegister}</strong>
                </span>
              </div>

              <div>
                <p>Proprietário</p>
                <span>
                  <strong>{stallion.owner?.name}</strong>
                </span>
              </div>
            </AnimalInfo>
          )}
        </div>
      </FormItem>
    </StepWrapper>
  ), 
  [
    currentStep,
    emptyAutoCompleteField,
    fetchAllStallions,
    isFetchingStallions,
    notFoundStallionMessage,
    onSearchStallion,
    onSelectStallion,
    searchStallionLoading,
    stallion,
    stallionOptions
  ]);

  const SecondStep = useCallback(() => (
    <StepWrapper disabled={currentStep < 1}>
      <MareTypeWrapper>
        <MareTypeButton
          type="button"
          active={mareType === 'registered'}
          right
          onClick={() => handleMareType('registered')}
          disabled={currentStep < 1}
        >
          Égua registrada
        </MareTypeButton>

        <MareTypeButton
          type="button"
          active={mareType === 'common'}
          onClick={() => handleMareType('common')}
          disabled={currentStep < 1}
        >
          Égua comum
        </MareTypeButton>

        {mareType === 'common' && (
          <>
            <div id="common-mare-type-wrapper">
              <FormValidator name="common-mare-type" required={false}>
                <Checkbox
                  checkSize="small"
                  onChange={(valueChecked) =>
                    handleCommonMareType(valueChecked, 'psi')
                  }
                  checked={commonMareType === 'psi'}
                >
                  PSI
                </Checkbox>
                <Checkbox
                  checkSize="small"
                  onChange={(valueChecked) =>
                    handleCommonMareType(valueChecked, 'in-process')
                  }
                  checked={commonMareType === 'in-process'}
                >
                  Em processo de registro
                </Checkbox>
              </FormValidator>
            </div>

            <div id="common-mare-type-wrapper">
              <FormValidator name="common-mare-owner-document-type" required={false}>
                <Checkbox
                  checkSize="small"
                  onChange={(valueChecked) =>
                    handleCommonMareOwnerDocumentType(valueChecked, 'CPF')
                  }
                  checked={ownerCommonMareDocumentType === 'CPF'}
                >
                  CPF
                </Checkbox>
                <Checkbox
                  checkSize="small"
                  onChange={(valueChecked) =>
                    handleCommonMareOwnerDocumentType(valueChecked, 'CNPJ')
                  }
                  checked={ownerCommonMareDocumentType === 'CNPJ'}
                >
                  CNPJ
                </Checkbox>
              </FormValidator>
            </div>
          </>
        )}
      </MareTypeWrapper>

      <div id="mare-identification">
        <FormItem
          disabled={currentStep < 1}
          name="mare"
          className="mare-name"
          label="Identifique a Égua"
        >
          <div className="separetor">
            <FormValidator
              name="mare"
              message={
                mareType === 'registered'
                  ? 'Digite o registro ou nome da égua'
                  : 'Digite o nome da égua'
              }
            >
              {mareType === 'registered' ? (
                <AutoComplete
                  disabled={currentStep < 1}
                  value={mare.name}
                  name="mare"
                  options={mareOptions}
                  placeholder="Digite o registro ou nome"
                  loading={searchMareLoading}
                  onSearch={onSearchMare}
                  onSelect={onSelectMare}
                  onChange={(value) => onChangeAnimal(value, 'mare')}
                  notFoundContent={notFoundMareMessage}
                  open={!emptyAutoCompleteField}
                />
              ) : (
                <Input
                  name="mare"
                  placeholder="Digite o nome"
                  value={mare.name}
                  onChange={(value) => setMare({ name: value })}
                  width={200}
                />
              )}
            </FormValidator>

            {mareType === 'registered' && (
              <>
                <p>ou</p>
                <SearchButtonAnimals
                  title="Buscar em meus animais"
                  disabled={currentStep < 1}
                  onClick={fetchAllMares}
                  loading={isFetchingMares}
                  description="Meus animais"
                />

                {currentStep >= 2 && Object.keys(mare).length > 0 && (
                  <AnimalInfo>
                    <div>
                      <p>Nº do Registro</p>
                      <span>
                        <strong>{mare.abqmRegister}</strong>
                      </span>
                    </div>

                    <div>
                      <p>Proprietário</p>
                      <span>
                        <strong>{mare?.owner?.name}</strong>
                      </span>
                    </div>
                  </AnimalInfo>
                )}
              </>
            )}
          </div>
        </FormItem>

        {mareType === 'common' && (
          <>
            <FormItem
              disabled={currentStep < 1}
              className="common-mare-input-group"
              name="coat"
              label="Pelagem"
            >
              <FormValidator name="coat" message="Selecione a pelagem">
                <Select
                  placeholder="Selecione o Tipo"
                  value={coatType}
                  onChange={(value) => setCoatType(value)}
                  name="coat"
                  disabled={currentStep < 1}
                  width={174}
                  options={coats}
                />
              </FormValidator>
            </FormItem>

            {commonMareType === 'in-process' && (
              <FormItem
                disabled={currentStep < 1}
                className="common-mare-input-group in-process-div"
                name="number-pre-register"
                label="Nº do pré-registro"
              >
                <FormValidator
                  name="number-pre-register"
                  message="Digite o número"
                >
                  <MaskedInput
                    value={preRegisterMareNumber}
                    onChange={(value) => setPreRegisterMareNumber(value)}
                    mask="1111111"
                    placeholder="0000000"
                    name="number-pre-register"
                    disabled={currentStep < 1}
                    width={113}
                  />
                </FormValidator>
              </FormItem>
            )}

            <MareOwnerDocumentTypeContainer>
              <FormItem
                disabled={currentStep < 1}
                className="common-mare-input-group common-mare-cpf"
                name="mare-owner-cpf"
                label="Proprietário"
              >
                <FormValidator name="mare-owner-cpf" rules={[validateCpf]}>
                  <MaskedInput
                    name="mare-owner-cpf"
                    mask={
                      ownerCommonMareDocumentType === 'CPF' 
                      ? "111.111.111-11" 
                      : "11.111.111/1111-11"
                    }
                    value={mareOwnerCpf}
                    onChange={(value) => setMareOwnerCpf(value)}
                    placeholder={`Digite o ${ownerCommonMareDocumentType}`}
                    disabled={currentStep < 1}
                    width={200}
                  />
                </FormValidator>
              </FormItem>

              <SearchButtonAnimals
                title="Buscar proprietário"
                description=""
                onClick={handleGetOwnerByDocument}
                loading={isFetchingOwnerByDocument}
              />

              {ownerCommonMare.nome_pessoa && (
                <AnimalInfo>
                  <div>
                    <p>Proprietário</p>
                    <span>
                      <strong>{ownerCommonMare.nome_pessoa}</strong>
                    </span>
                  </div>
                </AnimalInfo>
              )}
              
            </MareOwnerDocumentTypeContainer>
            
          </>
        )}
      </div>
    </StepWrapper>
  ), 
  [
    currentStep,
    coatType,
    coats,
    commonMareType,
    handleGetOwnerByDocument,
    emptyAutoCompleteField,
    fetchAllMares,
    handleMareType,
    isFetchingMares,
    mare,
    mareOptions,
    mareOwnerCpf,
    mareType,
    notFoundMareMessage,
    onSearchMare,
    onSelectMare,
    preRegisterMareNumber,
    searchMareLoading,
    ownerCommonMareDocumentType,
    ownerCommonMare,
    isFetchingOwnerByDocument,
    handleCommonMareType
  ]);

  const ThirdStep = useCallback(() => (
    <StepWrapper disabled={currentStep < 2}>
      <div>
        <FormItem
          disabled={currentStep < 2}
          name="coverage-type"
          label="Tipo de Cobertura"
        >
          <FormValidator
            name="coverage-type"
            message="Selecione o tipo de cobertura"
          >
            <Select
              placeholder="Selecione o Tipo"
              name="coverage-type"
              value={coverageType}
              onChange={(value) => setCoverageType(value)}
              disabled={currentStep < 2}
              options={[
                { value: 0, label: 'Monta Controlada' },
                { value: 1, label: 'Monta a Campo' },
                { value: 2, label: 'Inseminação Artificial' },
                { value: 3, label: 'Sêmen Importado' }
              ]}
            />
          </FormValidator>
        </FormItem>
        
        {coverageType === 3 && (
          <FormItem
            disabled={currentStep < 2}
            name="frozen-semen-importer"
            label="Importador"
          >
            <FormValidator
              name="frozen-semen-importer"
              message="Selecione um Importador!"
            >
              <Select
                placeholder="Selecione o Importador"
                name="frozen-semen-importer"
                value={frozenSemenImporter.value}
                onChange={onSelectSemenImporter}
                disabled={currentStep < 2}
                options={frozenSemenImporters}
              />
            </FormValidator>
          </FormItem>
        )}
      </div>

      <div className="coverage-date">
        {[1, 2, 3].map((item, index) => (
          <FormItem
            disabled={
              currentStep < 2 || (index >= 1 && !coverageDates[index - 1])
            }
            key={item}
            name={`coverage-date${item}`}
            label={`Data da Cobertura ${item}`}
          >
            <DatePicker
              name={`coverage-date${item}`}
              disabled={
                currentStep < 2 || (index >= 1 && !coverageDates[index - 1])
              }
              onChange={(date, dateString) =>
                handleDateChange(index, null, date, dateString)
              }
              onInputChange={(e) => handleDateChange(index, e)}
              open={isCalendarOpen[index]}
              onOpenChange={() => handleCalendarOpen(index)}
              value={coverageDatesString[index]}
              rules={
                !coverageDates[index] && [
                  ({ getFieldValue }) => validateDateField(getFieldValue, item),
                ]
              }
              disabledDate={(currentDate) => disabledDate(currentDate, index)}
              onBottom
              cleanButton={index > 0}
              handleCleanButton={() => handleResetDate(index)}
            />
          </FormItem>
        ))}
      </div>
    </StepWrapper>
  ), 
  [
    currentStep,
    coverageType, 
    handleResetDate, 
    disabledDate, 
    handleCalendarOpen, 
    validateDateField, 
    coverageDates, 
    coverageDatesString,
    isCalendarOpen,
    handleDateChange,
    frozenSemenImporter,
    frozenSemenImporters,
    onSelectSemenImporter
  ]);

  return (
    <Container>
      <Breadcrumb
        pages={[
          {
            title: 'Coberturas comunicadas',
            path: '/listagem-de-coberturas',
          },
          {
            title: pageTitle,
          },
        ]}
      />

      <Helmet>
        <title>{pageTitle} - Central do Quartista</title>
      </Helmet>

      <PageTitle>{pageTitle}</PageTitle>

      <Form name="coverage-communication" onFinish={handleSubmit} form={form}>
        <Steps size="small" direction="vertical" current={currentStep}>
          <Steps.Step
            className="first-step"
            icon={currentStep >= 1 && <FiCheck size={14} />}
            description={FirstStep()}
          />
          <Steps.Step
            className="second-step"
            icon={currentStep >= 2 && <FiCheck size={14} />}
            description={SecondStep()}
          />
          <Steps.Step
            className="third-step"
            icon={currentStep >= 3 && <FiCheck size={14} />}
            description={ThirdStep()}
          />
        </Steps>

        <Button
          className="button-submit"
          type="submit"
          disabled={currentStep < 2}
          fontLight
          color={colors.lightGreen}
          // onClick={handleSubmit}
          width={131}
          height={40}
          loading={submitLoading}
        >
          Confirmar&nbsp; <FiArrowRight size={16} />
        </Button>
      </Form>

      <Drawer
        title="Meus Animais"
        placement="right"
        onClose={switchDrawer}
        destroyOnClose
        visible={drawerVisibility}
        width="25%"
      >
        <List
          size="small"
          dataSource={myAnimals}
          renderItem={(animal) => (
            <ListItem onClick={() => onSelectAnimalFromTable(animal)}>
              {animal.nome_animal}
            </ListItem>
          )}
        />
      </Drawer>
      
      <Modal
        isVisible={modalValidationOpen}
        toggleVisibility={switchModalValidation}
        closable={false}
        centered
        scroll={false}
        height={300}
        width={498}
        footer={
          <>
            <ModalFooter>

              {coverageCommunicationStatus.type === 'attention' && (
                <ModalButton
                  type="button"
                  fontLight
                  width={80}
                  color={colors.lightGreen}
                  onClick={handleSubmit}
                >
                  Continuar
                </ModalButton>
              )}
              

              <ModalButton
                type="button"
                width={coverageCommunicationStatus.type === 'attention' ? 80 : 160}
                fontLight
                color={colors.red}
                onClick={handleGoBackValidation}
              >
                Voltar
              </ModalButton>
            </ModalFooter>
          </>
        }
      >
        <ModalContent>
          <>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: coverageCommunicationStatus.type === 'attention' ? alertAnimated : errorAnimated,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={110}
              height={100}
            />
            <h1 className="h1-certificate">{coverageCommunicationStatus.title}</h1>
            <p>{coverageCommunicationStatus.text}</p>
          </>
        </ModalContent>
      </Modal>

      <Modal
        isVisible={modalCertificateOpen}
        toggleVisibility={switchModalCertificate}
        closable={false}
        centered
        scroll={false}
        height={300}
        width={478}
        footer={
          <ModalFooter>
            <ModalButton
              type="button"
              fontLight
              width={80}
              color={colors.lightGreen}
              onClick={() => setSendCertificate(true)}
            >
              Sim
            </ModalButton>

            <ModalButton
              type="button"
              width={80}
              fontLight
              color={colors.red}
              onClick={handleCancelCertificate}
            >
              Não
            </ModalButton>
          </ModalFooter>
        }
      >
        <ModalContent>
          <>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: successAnimated,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={120}
              height={105}
            />
            <h1 className="h1-certificate">Certificado de cobertura</h1>
            <p>Deseja liberar agora?</p>
          </>
        </ModalContent>
      </Modal>

      <Modal
        isVisible={modalVisibility}
        toggleVisibility={switchModal}
        closable={false}
        centered
        scroll={false}
        offset={sendEmail}
        height={sendEmail ? 373 : 690}
        width={ sendEmail ? 478 : 600 }
        footer={
          !sendEmail && (
            <ModalFooter>
              <Link 
                to={{ 
                  pathname: '/novo-comunicado', 
                  state: { stallion, frozenSemenImporters } 
                }}
              >
                <ModalButton type="button" fontLight color={colors.blue}>
                  Nova comunicação
                </ModalButton>
              </Link>

              <ModalButton
                type="button"
                fontLight
                color={colors.blue}
                onClick={() => setSendEmail(true)}
              >
                Enviar por E-mail
              </ModalButton>

              <Link to="/listagem-de-coberturas">
                <ModalButton type="button" fontLight color={colors.lightGreen}>
                  Concluir
                </ModalButton>
              </Link>
            </ModalFooter>
          )
        }
      >
        <ModalContent>
          {sendEmail ? (
            <>
              <CloseModalButton onClick={() => setSendEmail(false)}>
                &times;
              </CloseModalButton>

              <MailSvg className="mail-icon" />

              <p className="send-email-label">
                Para adicionar mais de um e-mail, digite os endereços separados
                por vírgula.
              </p>

              <Form
                name="send-email"
                onFinish={handleSendEmail}
                className="send-email-form"
              >
                <FormValidator
                  name="address"
                  message="Digite pelo menos um endereço de e-mail"
                >
                  <Input
                    type="text"
                    name="email-address"
                    size="large"
                    placeholder="Digite um ou mais e-mail"
                    autoComplete="off"
                  />
                </FormValidator>

                <Button type="submit" fontLight color={colors.lightGreen}>
                  Enviar
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: successAnimated,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width={120}
                height={105}
              />
              <h2>Sucesso!</h2>
              <h1>
                Pré-Registro:&nbsp; 
                {coverageCommunicated?.num_preregistro ? 
                  coverageCommunicated?.num_preregistro : 
                  '----'}
              </h1>
              <h3>Dados da Cobertura</h3>

              <CoverageDataContainer>

                <p>
                  <strong>Protocolo:&nbsp;</strong> 
                  {coverageCommunicated?.protocolo ? 
                    coverageCommunicated?.protocolo : 
                    '----'}
                </p>
                  
                <p>
                  <strong>Nome Proprietario Garanhão:&nbsp;</strong>
                  {coverageCommunicated?.nome_proprietario_garanhao ? 
                    coverageCommunicated?.nome_proprietario_garanhao : 
                    ' ----'}
                </p>

                <p>
                  <strong>Proprietario Garanhão - CPF/CNPJ:&nbsp;</strong> 
                  {coverageCommunicated?.proprietario_garanhao_cpf_cnpj ? 
                    coverageCommunicated?.proprietario_garanhao_cpf_cnpj : 
                    ' ----'}
                </p>

                <p>
                  <strong>Nome do Garanhão:&nbsp;</strong>
                  {coverageCommunicated?.nome_garanhao ? 
                    coverageCommunicated?.nome_garanhao : 
                    ' ----'}
                </p>

                
                <CoverageDataRowGroup>

                  <p>
                    <strong>N° de Registro do Garanhão:&nbsp;</strong>
                    {coverageCommunicated?.registro_abqm_garanhao ? 
                    coverageCommunicated?.registro_abqm_garanhao : 
                    ' ----'}
                  </p>

                  <p>
                    <strong>Pelagem Garanhão:&nbsp;</strong>
                    {coverageCommunicated?.pelagem_garanhao ? 
                    coverageCommunicated.pelagem_garanhao.toUpperCase() : 
                    ' ----'}
                  </p>
                
                </CoverageDataRowGroup>

                <p className="proprietario-egua">
                  <strong>Nome Proprietario Égua:&nbsp;</strong>
                  {coverageCommunicated?.nome_proprietario_egua ? 
                    coverageCommunicated?.nome_proprietario_egua : 
                    ' ----'}
                </p>

                <p>
                  <strong>Proprietario Égua - CPF/CNPJ:&nbsp;</strong> 
                  {coverageCommunicated?.proprietario_egua_cpf_cnpj ? 
                    coverageCommunicated?.proprietario_egua_cpf_cnpj : 
                    ' ----'}
                </p>

                <p>
                  <strong>Nome da Égua:&nbsp;</strong>
                  {coverageCommunicated?.nome_egua ? 
                    coverageCommunicated?.nome_egua : 
                    ' ----'}
                </p>

                <CoverageDataRowGroup>
                  <p>
                    <strong>N° de Registro da Égua:&nbsp;</strong>
                    {coverageCommunicated?.registro_abqm_egua ? 
                    coverageCommunicated?.registro_abqm_egua : 
                    ' ----'}
                  </p>

                  <p>
                    <strong>Pelagem Égua:&nbsp;</strong>
                    {coverageCommunicated?.pelagem_egua ? 
                    coverageCommunicated.pelagem_egua.toUpperCase() : 
                    ' ----'}
                  </p>
                </CoverageDataRowGroup>
              
                <p>
                  <strong>Data da Cobertura:&nbsp;</strong> 
                  {coverageCommunicated?.datas_cobertura ? 
                    coverageCommunicated?.datas_cobertura : 
                    '----'}
                </p>
              </CoverageDataContainer>

              <h3>O que você deseja fazer?</h3>

            </>
          )}
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default CoverageCommunication;
