/**
 * Convert brazilian format date in ISO 8601 date format
 * @param {string} dateString date in format DD/MM/YYYY
 */
export default function (dateString) {
  const day = Number(dateString.split('/')[0]);
  const month = Number(dateString.split('/')[1]);
  const year = Number(dateString.split('/')[2]);

  return new Date(`${year}/${month}/${day}`);
}
