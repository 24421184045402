import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../../functions';
import { colors, fonts } from '../../../theme';

import { ReactComponent as GenealogyLineConectorSvg } from '../../../theme/assets/images/genealogy-line-conector.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 0 ${pxToRem(65)} 0 ${pxToRem(97)};
`;

export const OwnerInfo = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  .name-wrapper {
    p {
      margin-bottom: ${pxToRem(7)};

      ${fonts.size2};
      color: rgba(255, 255, 255, 0.75);
    }

    strong {
      ${fonts.size3};
      color: rgba(255, 255, 255, 0.86);
      font-family: 'SFProText-Bold', sans-serif;
    }

    &.left {
      position: relative;

      padding-right: ${pxToRem(38)};
      border-right: solid 1px rgba(255, 255, 255, 0.15);
    }

    &.right {
      padding-left: ${pxToRem(38)};
    }
  }
`;

export const Genealogy = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8%;

  a {
    transition: all 0.3s ease;

    :hover {
      filter: brightness(1.1);
    }
  }

  #left {
    .parent-wrapper {
      display: flex;

      + .parent-wrapper {
        margin-top: ${pxToRem(50)};
      }
    }

    #father,
    #mother {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: ${pxToRem(245)};
      height: ${pxToRem(40)};
      padding: ${pxToRem(13)} ${pxToRem(20)} ${pxToRem(13)} ${pxToRem(16)};
      border-radius: ${pxToRem(6)} ${pxToRem(20)};

      ${fonts.size2};
      color: ${colors.white};

      svg {
        position: absolute;
        right: 0;
        top: 0;

        height: 100%;
        width: 100%;
      }

      strong {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: ${pxToRem(140)};

        font-family: 'SFProText-Bold', sans-serif;
      }

      strong,
      p {
        z-index: 1;
      }
    }
  }

  #right {
    .grandfather,
    .grandmother {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: ${pxToRem(217)};
      height: ${pxToRem(30)};
      padding: ${pxToRem(10)} ${pxToRem(18)} ${pxToRem(9)} ${pxToRem(16)};
      border-radius: ${pxToRem(6)} ${pxToRem(20)};

      font-size: 11px;
      color: ${colors.white};

      svg {
        position: absolute;
        right: 0;
        top: 0;

        height: 100%;
        width: 100%;
      }

      p {
        z-index: 1;
      }
    }

    .grandmother {
      margin-top: ${pxToRem(11)};
    }

    #maternal {
      margin-top: ${pxToRem(19)};
    }
  }

  ${media.greaterThan('1600px')`
    #left {
      #father,
      #mother { 
        width: ${pxToRem(286)};
        height: ${pxToRem(46)};
        padding: ${pxToRem(13)} ${pxToRem(24)};

        ${fonts.size3};
        
        strong {
          max-width: ${pxToRem(163)};
        }
      }
    }

    #right {
      .grandfather,
      .grandmother {
        width: ${pxToRem(254)};
        height: ${pxToRem(35)};
        padding: ${pxToRem(10)} ${pxToRem(26)} ${pxToRem(8)} ${pxToRem(24)};
      }
    }
  `}
`;

export const GenealogyLineConector = styled(GenealogyLineConectorSvg)`
  ${media.greaterThan('1600px')`
    width: ${pxToRem(210)} !important;
    height: ${pxToRem(50)} !important;  
  `}
`;
