import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { Form as AntForm } from 'antd';
import { AiOutlineLoading } from 'react-icons/ai';

import { colors } from '../../../theme';
import { pxToRem } from '../../../functions';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const Container = styled.section`
  height: 100%;
  overflow: hidden;

  h1 {
    font-size: 1.5rem;
    font-family: 'SFProText-Bold', sans-serif;
  }

  table {
    position: relative;

    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
    border-collapse: collapse;

    ::after {
      position: absolute;
      bottom: -12px;

      content: '';
      width: 100%;
      height: 1px;

      background: #ccc;
    }
  }

  thead tr {
    height: 50px;
  }

  tbody {
    margin-right: ${pxToRem(8)};
  }

  th,
  td,
  p {
    font-size: ${pxToRem(14)};
    font-family: 'SFProText-Regular', sans-serif;
  }

  td {
    height: auto;

    > a {
      width: auto;
      margin-right: ${pxToRem(20)};
    }
  }

  svg {
    color: #008f39;
  }

  #body-wrapper {
    height: calc(100vh - 24.625rem);
    padding: 0 ${pxToRem(8)} 0 ${pxToRem(16)};

    ${media.greaterThan('1900px')`
      height: calc(100vh - 30.625rem);
    `}

    ${media.lessThan('1024px')`
      height: 76vh;
    `}
  }

  ${media.lessThan('1024px')`
    height: 100%;
    overflow: auto;

    table {
      width: 100%;

      > tr {
        padding: 0 ${pxToRem(16)};
        }

      }
    }

    th,
    td,
    p {
      font-size: ${pxToRem(12)};
    }

    #body-wrapper {
      margin: 0;
      padding: 0;
      width: 100%;

    }
  `}
`;

export const Type = styled.td`
  display: flex;
  flex-direction: row;

  > div.type-wrapper {
    padding: ${pxToRem(8)} 0;

    display: flex;
    flex-direction: column;
  }

  p {
    padding-left: 8px;

    & + p {
      margin-top: ${pxToRem(4)};
    }
  }
`;

export const Text = styled.p`
  font-size: 11px;
  text-align: left;
  line-height: 1.18;
  white-space: nowrap;
  max-width: 50px;

  font-family: 'SFProText-Semibold', sans-serif;

  ${media.greaterThan('huge')`
    font-size: 12px;
  `}
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .form-item1 {
    margin-top: 30px;

    ${media.greaterThan('1600px')`
      label {
        margin-bottom: 0;
      }
    `}
  }

  .header-modal {
    margin-top: ${pxToRem(20)};

    div {
      > label {
        width: ${pxToRem(100)};
      }
    }
  }

  .text-area {
    resize: none;
  }
`;

export const CircleLoading = styled(AiOutlineLoading)`
  margin-top: 120px;

  align-self: center;

  font-size: 30px;
  color: ${colors.lightGreen};
  animation: ${rotation} 1s infinite;
`;
