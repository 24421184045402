import styled, { css } from 'styled-components';

import { colors, fonts } from '../../theme';
import { pxToRem } from '../../functions';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  /**
  * JUST CHROME!
  * if this component is inside mainframe, it's necessary to set negative margin in styles 
  */
  /* ${() =>
    navigator.userAgent.match(/chrome/i) &&
    css`
      margin: 0 ${pxToRem(-20)} ${pxToRem(-20)};
    `} */

  background-color: ${colors.white};

  .content-wrapper {
    margin-left: 20.75%;

    h1,
    h2 {
      color: ${colors.lightBlack};
    }

    h1 {
      max-width: ${pxToRem(732)};
      margin-bottom: ${pxToRem(4)};

      ${fonts.size11};
    }

    h2 {
      margin-bottom: ${pxToRem(24)};

      ${fonts.size7};
    }
  }

  img {
    right: 0;
    bottom: 0;
    position: absolute;

    width: ${({ mainframe }) => (mainframe ? '23%' : '28%')};
  }
`;
