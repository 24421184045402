import React from 'react';
import PropTypes from 'prop-types';

import { Container, RadioItem } from './styles';

const Radio = ({
  value,
  onChange,
  name,
  options,
  vertical,
  size,
  ...props
}) => (
  <Container
    value={value}
    name={name}
    id={name}
    vertical={vertical}
    size={size}
    {...props}
  >
    {options?.map((option, index) => (
      <RadioItem
        key={index}
        value={option.value}
        vertical={vertical}
        size={size}
        onChange={(e) => onChange(e.target.value)}
      >
        {option.label}
      </RadioItem>
    ))}
  </Container>
);

Radio.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  vertical: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
};

Radio.defaultProps = {
  size: 'middle',
};

export default Radio;
