import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { Form as AntForm } from 'antd';
import InputMask from 'react-input-mask';
import { AiOutlineLoading } from 'react-icons/ai';

import { pxToRem } from '../../../../functions';
import { colors, fonts } from '../../../../theme';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const ModalContent = styled.div`
  height: ${({ specialStyle }) => (specialStyle ? '100%' : 'auto')};
  padding: ${({ specialStyle }) =>
    specialStyle ? '0 0 0 39px' : '20px 20px 0'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ specialStyle }) =>
    specialStyle ? 'flex-start' : 'center'};

  > * {
    color: ${colors.eightGrey};
  }

  label {
    margin-top: ${pxToRem(20)};
  }

  h1,
  h3 {
    font-family: 'SFRounded-Regular', sans-serif;
  }

  h1 {
    margin-top: ${pxToRem(7)};

    ${fonts.size7}
    line-height: 1.21;
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
    margin-top: ${pxToRem(16)};

    ${fonts.size7}
    line-height: 1.21;

    font-family: 'SFRounded-Bold', sans-serif;
  }

  h3 {
    margin-bottom: 0;
    margin-top: ${pxToRem(4)};

    ${fonts.size4};
    line-height: 1.19;
  }

  div[aria-label='animation'] {
    margin-top: ${pxToRem(-14)} !important;
  }
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .requester-span {
    margin-top: ${pxToRem(20)};
  }

  .form-item1 {
    margin-top: ${pxToRem(30)};

    ${media.greaterThan('1600px')`
      label {
        margin-bottom: 0;
      }
    `}

    ${media.lessThan('1024px')`
      margin-top: ${pxToRem(10)};

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > label + label {
          margin-top: ${pxToRem(10)};
        }
      }
    `}
  }

  .form-item2 {
    .ant-form-item-required {
      color: ${colors.eightGrey};
    }
  }

  ${media.greaterThan('1900px')`  
    *,
    label {
      ${fonts.size5}
    }

    label {
      margin-bottom: ${pxToRem(6)};
    }

    .horizontal {
      label {
        margin-bottom: 0;
      }
    }

    .ant-switch {
      width: ${pxToRem(60)};
      height: ${pxToRem(30)};

      :after {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
      }
    }
  `}
`;

export const MaskInput = styled(InputMask)`
  height: 40px;
  border: 1px solid #d9d9d9;
  padding: 0 ${pxToRem(8)};
  width: ${({ width }) => (width ? pxToRem(width) : pxToRem(320))};
  border-radius: 4px !important;

  color: rgba(0, 0, 0, 0.65);
  font-family: 'SFProText-Regular', sans-serif;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  :not(:disabled) {
    :hover,
    :active,
    :focus {
      border-color: ${colors.blue};
    }
  }

  :disabled::placeholder {
    color: rgba(0, 0, 0, 0.1);
  }
`;

export const CircleLoading = styled(AiOutlineLoading)`
  align-self: center;

  font-size: 20px;
  color: ${colors.lightGreen};
  animation: ${rotation} 1s infinite;
`;
