import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../.';

import { fonts, colors } from '../../theme';
import { pxToRem } from '../../functions';

export const Container = styled.div`
  border-radius: ${pxToRem(6)};

  grid-area: m5;

  background: rgba(255, 255, 255, 0.1);

  ${media.greaterThan('1600px')`
    border-radius: ${pxToRem(7)};
  `}

  ${media.greaterThan('1900px')`
    border-radius: ${pxToRem(8)};
  `}
`;

export const Called = styled.ul`
  height: 90%;
  overflow-y: hidden;

  li {
    margin: 0 ${pxToRem(16)};
    padding: ${pxToRem(12)} 0;
    border-bottom: ${pxToRem(0.5)} solid rgba(255, 255, 255, 0.1);

    cursor: pointer;
    transition: opacity 0.15s ease;

    :last-child {
      border-bottom: 0;
    }

    &:hover {
      opacity: 0.7;
    }

    ${fonts.size2};
    color: rgba(255, 255, 255, 0.5);

    .white {
      color: rgba(255, 255, 255, 0.75);
    }

    .openAndClosed {
      ${fonts.size1}

      > p + p {
        margin-left: ${pxToRem(6)};
      }
    }

    > div {
      margin-top: ${pxToRem(4)};

      display: flex;
      align-items: center;

      :first-child {
        margin-top: 0;
      }

      > span {
        margin: 0 ${pxToRem(6)};
      }
    }

    > p {
      margin-top: ${pxToRem(4)};

      ${fonts.size2};
    }
  }

  ${media.greaterThan('1600px')`
    li {
      margin: 0 ${pxToRem(18)};
      padding: ${pxToRem(14)} 0;

      ${fonts.size3};

      .openAndClosed {
        ${fonts.size2}

        > p + p {
        margin-left: ${pxToRem(7)};
        }
      }

      > div {
        margin-top: ${pxToRem(5)};

        > span {
          margin: 0 ${pxToRem(7)};
        }
      }

      > p {
        margin-top: ${pxToRem(5)};

        ${fonts.size3};
      }
    }
  `}

  ${media.greaterThan('1900px')`
    li {
      margin: 0 ${pxToRem(22)};
      padding: ${pxToRem(16)} 0;

      ${fonts.size4};

      .openAndClosed {
        ${fonts.size3}

        > p + p {
        margin-left: ${pxToRem(8)};
        }
      }

      > div {
        margin-top: ${pxToRem(6)};

        > span {
          margin: 0 ${pxToRem(8)};
        }
      }

      > p {
        margin-top: ${pxToRem(6)};

        ${fonts.size4};
      }
    }
  `}
`;

export const CustomModal = styled(Modal)`
  .ant-modal-footer {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  }
`;

export const ModalContent = styled.div`
  height: 100%;
  padding: ${pxToRem(16)} ${pxToRem(16)} 0;

  header {
    ul li {
      display: grid;
      grid-gap: ${pxToRem(16)};
      grid-template-columns: ${pxToRem(75)} 1fr;

      color: ${colors.lightBlack};

      + li {
        margin-top: ${pxToRem(8)};
      }
    }
  }

  main {
    margin-top: ${pxToRem(24)};

    * {
      color: ${colors.lightBlack};
    }

    ul {
      margin: 0 ${pxToRem(-16)};
      padding-left: ${pxToRem(16)};

      li {
        margin-right: ${pxToRem(20)};

        .contact-date {
          display: flex;
          align-items: center;
          justify-content: space-between;

          border-radius: 4px;
          padding: ${pxToRem(8)};

          font-family: 'SFRounded-Bold', sans-serif;
          background-color: ${colors.lightGrey};

          h4 {
            margin: 0;
          }
        }

        .description {
          * {
            font-family: 'SFProText-Regular', sans-serif;
          }

          margin: ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(40)};
        }
      }
    }
  }
`;

export const SkeletonContainer = styled.div`
  header {
    width: ${pxToRem(350)};
  }

  .main-skeleton {
    margin: 0;

    padding: 0 ${pxToRem(16)};
  }

  .ant-skeleton-paragraph li,
  .ant-skeleton-button {
    border-radius: 4px;
  }
`;
