import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';

export const Container = styled.div`
  display: grid;  
  grid-template-columns: ${pxToRem(243)} 1fr;  

  height: 100vh;

  ${media.greaterThan('huge')`
    grid-template-columns: ${pxToRem(288)} 1fr;
  `}

  ${media.greaterThan('1600px')`
    grid-template-columns: ${pxToRem(284)} 1fr;
  `}

  ${media.greaterThan('1900px')`
    grid-template-columns: ${pxToRem(337)} 1fr;
  `}
`;
