import styled from 'styled-components';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';
import backgroundButtonMask from '../../theme/assets/images/backgroundMaskBtnDashboard.svg';

export const Container = styled.button`
  position: relative;

  border: 0;
  min-width: ${pxToRem(116)};
  min-height: ${pxToRem(32)};
  border-radius: ${pxToRem(16)};

  ${fonts.size2};
  transition: 0.4s;
  font-weight: bold;
  color: ${colors.white};
  background: linear-gradient(91deg, #bc4654 1%, #d13044 100%);

  &::before {
    position: absolute;
    left: 0;

    content: '';
    width: 100%;
    height: 100%;
    border-radius: ${pxToRem(16)};
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);

    z-index: 1;

    opacity: 0;
    background: #d13044;
    transition: opacity 0.4s;
  }

  &:hover::before {
    opacity: 1;
  }

  & div {
    padding: ${pxToRem(4)};

    display: flex;
    align-items: center;

    background: url(${backgroundButtonMask}) no-repeat left;

    & span {
      border-radius: 50%;
      width: ${pxToRem(24)};
      height: ${pxToRem(24)};
      margin-right: ${pxToRem(5)};
      border: 1px solid rgba(255, 255, 255, 0.23);

      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      background: ${colors.buttonDashboardWhite};

      color: ${colors.darkYellow};
    }

    & p {
      z-index: 2;
    }
  }
`;
