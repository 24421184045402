import React from 'react';
import PropTypes from 'prop-types';

import Sider from './Sider';
import Content from './Content';

import { Container } from './styles';

const Layout = ({ children }) => {
  return (
    <Container>
      <Sider />
      <Content children={children} />
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
