import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { api } from '../../services/api';
import { Table, Loader, PageTitle } from '../../components';

import { Header, Container } from './styles';

const AnimalChip = () => {
  const [loading, setLoading] = useState(true);
  const [paginasChip, setPaginasChip] = useState([]);

  const fetchPaginasChip = useCallback(async () => {
    try {
      const { data } = await api.get('animal/paginasChip', {});

      if (data.success) {
        setPaginasChip(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPaginasChip();
  }, [fetchPaginasChip]);

  const tableColumns = [{ key: 'link', title: '', size: 7 }];

  const tableData = paginasChip.map((document) => ({
    link: (
      <td>
        {document.link && (
          <a href={document.link} target="_blank" rel="noopener noreferrer">
            {document.lista}
          </a>
        )}
      </td>
    ),
  }));

  return (
    <>
      <Helmet>
        <title>Listagem de Chip - Central do Quartista</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <>
            <Header>
              <PageTitle fontLight>Listagem de Chip</PageTitle>
            </Header>
            <Table dataSource={tableData} columns={tableColumns} />
          </>
        </Container>
      )}
    </>
  );
};
export default AnimalChip;
