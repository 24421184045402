import React, { useEffect, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import { Loader, PageTitle, StyledCustomScrollBar } from '../../../components';

import { Container } from './styles';

export default function DNAControl({ preRegister, token }) {
  const [loading, setLoading] = useState(true);
  const [DNAControl, setDNAControl] = useState([]);

  const fetchDNAControl = useCallback(async () => {
    try {
      const { data } = await api.get('pedidoregistro/controledna', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_preregistro: preRegister,
        },
      });

      if (data.success) {
        setDNAControl(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [preRegister, token]);

  useEffect(() => {
    fetchDNAControl();
  }, [fetchDNAControl]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {!isMobile && <PageTitle>Controle de DNA</PageTitle>}

          {DNAControl.length <= 0 ?
            <p className="bold text-not-found">
              Nenhum registro encontrado!
            </p>
          :
            <div className="table-info">
              <StyledCustomScrollBar color={colors.black}>
                {DNAControl.map((control) => (
                  <table key={control.protocolo}>
                    <tr>
                      <td>Descriçao:</td>
                      <td>{control?.descricao}</td>
                    </tr>

                    <tr>
                      <td>Protocolo:</td>
                      <td>{control?.protocolo}</td>
                    </tr>

                    <tr>
                      <td>Animal:</td>
                      <td>{control?.animal}</td>
                    </tr>

                    <tr>
                      <td>Envio:</td>
                      <td>{control?.envio}</td>
                    </tr>

                    <tr>
                      <td>Retorno:</td>
                      <td>{control?.retorno}</td>
                    </tr>

                    <tr>
                      <td>Qualificação: </td>
                      <td>{control?.qualificacao}</td>
                    </tr>

                    <tr>
                      <td>Observação: </td>
                      <td>{control.observacao ? control.observacao : '--'}</td>
                    </tr>

                    <tr>
                      <td>Laboratório: </td>
                      <td>{control?.laboratorio}</td>
                    </tr>
                  </table>
                ))}
              </StyledCustomScrollBar>
            </div>
          }
        </Container>
      )}
    </>
  );
}
