import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ConfigProvider } from 'antd';
import { isMobile } from 'react-device-detect';
import ptBR from 'antd/es/locale-provider/pt_BR';

import Routes from './routes';

import { Global } from './theme';
import { Layout } from './components';
import { login, logout } from './services/auth';
import { useAuth } from './context/Auth';

moment.locale('pt-br');

function App() {
  const [loading, setLoading] = useState(false); //eslint-disable-line

  const { setUserData } = useAuth();

  useEffect(() => {
    if (!isMobile) {
      const handleAuthentication = async () => {
        setLoading(false);

        const res = await login();

        if (res) {
          setUserData(res);
          setLoading(false);
        } else {
          logout();
        }
      };

      handleAuthentication();
    }
  }, [setUserData]);

  return (
    <ConfigProvider locale={ptBR}>
      <Global />
      {isMobile ? (
        <Routes />
      ) : (
        <Layout>
          <Routes />
        </Layout>
      )}
    </ConfigProvider>
  );
}

export default App;
