import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert';
import { Input as AntInput } from 'antd';
import { isMobile } from 'react-device-detect';
import { FiEdit3, FiExternalLink } from 'react-icons/fi';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import { Loader } from '../../../components';
import { parseDateIso } from '../../../functions';

import {
  Modal,
  Input,
  Radio,
  Select,
  Button,
  Checkbox,
  FormItem,
  PageTitle,
  DatePicker,
  StyledCustomScrollBar,
} from '../../../components';

import { EmbryoTransferModal, ChangeRequesterModal } from '../components';

import {
  Form,
  Container,
  ModalContent,
  CircleLoading,
  TdAffiliation,
} from './styles';

export default function RecordDetails({
  token,
  apiCall,
  isCanceled,
  preRegister,
  isRegistred,
  isInspector,
  initialLoading,
  detailsRequest,
  detailsPreRegistro,
}) {
  const { TextArea } = AntInput;
  //Parâmetros obrigatórios para atualização de dados.
  const baseToUpdate = useMemo(() => ({
    num_preregistro: preRegister,
    opcao_1: detailsRequest?.opcao_1,
    opcao_2: detailsRequest?.opcao_2,
    opcao_3: detailsRequest?.opcao_3,
    produto_pe: detailsRequest?.produto_pe,
    observacoes: detailsRequest?.obs_inspetor1,
    data_inspecao: detailsRequest?.dt_inspecao,
    data_nascimento: detailsRequest?.dt_nascimento?.toString().substring(0, 10),
    data_cobertura_1: detailsRequest?.datas_coberturas?.split('-')[0] ? detailsRequest?.datas_coberturas?.split('-')[0].trim() : null,
    data_cobertura_2: detailsRequest?.datas_coberturas?.split('-')[1] ? detailsRequest?.datas_coberturas?.split('-')[1].trim() : null,
    data_cobertura_3: detailsRequest?.datas_coberturas?.split('-')[2] ? detailsRequest?.datas_coberturas?.split('-')[2].trim() : null,
    caracteristicas_indesejaveis:
      detailsRequest?.prognata !== 0 ||
      detailsRequest?.criptorquido !== 0 ||
      detailsRequest?.excesso_de_manchas !== 0
        ? '1'
        : '0',
    caracteristicas: [
      { caracteristica: detailsRequest?.prognata === 1 ? 1 : null },
      { caracteristica: detailsRequest?.criptorquido === 1 ? 2 : null },
      { caracteristica: detailsRequest?.excesso_de_manchas === 1 ? 3 : null },
    ],
    uf: detailsRequest?.sigla_estado,
    id_cidade: detailsRequest?.id_cidade_nascimento,
    id_receptora: detailsRequest?.id_receptora,
    sequencia: detailsRequest?.sequencia,
    transferencia_embriao: detailsRequest?.transferencia_embriao === 'SIM' ? '1' : '0',
    afixo: detailsRequest.sufixo, /// o sufixo na verdade retorna o valor do afixo(erro de semantica...)
    tipo_afixo: detailsRequest.cd_prefixo_sufixo === '' ? '' : (detailsRequest.cd_prefixo_sufixo.toLowerCase() === 's' ? '1' : '2')
  }), [preRegister, detailsRequest]);

  const [places, setPlaces] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [optionsCities, setOptionsCities] = useState([]);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [invalidDateMessage, setInvalidDateMessage] = useState(null); //eslint-disable-line

  const [caracteristics, setCaracteristics] = useState([
    {
      type: 'prognata', 
      isChecked: false
    },
    {
      type: 'criptorquidismo', 
      isChecked: false
    },
    {
      type: 'excesso_de_manchas', 
      isChecked: false
    },
  ]);

  const [undesirableCaracteristic, setUndesirableCaracteristic] = useState('0')

  const [coverageDates, setCoverageDates] = useState([
    null,
    null,
    null,
  ]);
  const [isCalendarOpen, setIsCalendarOpen] = useState([false, false, false]);

  const [coverageDatesString, setCoverageDatesString] = useState([
    null,
    null,
    null,
  ]);

  const [modalIsOpen, setModalIsOpen] = useState({
    sex: false,
    name: false,
    requester: false,
    placeBirth: false,
    footProduct: false,
    coverageDate: false,
    inspectionDate: false,
    embryoTransfer: false,
    inspectorObservations: false,
    undesirableCharacteristics: false,
  });

  const validateDate = (dateString, index) => {
    const day = Number(dateString.split('/')[0]);
    const month = Number(dateString.split('/')[1]);
    const year = Number(dateString.split('/')[2]);

    const isoDate = parseDateIso(dateString);
    const currentDate = new Date();

    let invalidDateMessage = null;

    function validateSemester(previousDate, currentDate) {
      const {prevMonth, prevYear} = previousDate;
      const {currentMonth, currentYear} = currentDate;

      if (
        (prevMonth < 7 && currentMonth >= 7) ||
        (prevMonth >= 7 && currentMonth < 7) ||
        (prevYear !== currentYear)
      ) {
        invalidDateMessage =
          'Todas as datas devem estar dentro do mesmo semestre';
      }
    }

    if (dateString.match(/[0-9]/g).length === 8) {

      if (!(isoDate instanceof Date && !isNaN(isoDate)))
        invalidDateMessage = 'Insira uma data válida';

      if (isoDate > currentDate)
        invalidDateMessage = 'A data não pode ser maior que a data atual';

      //primeiro datetimepicker
      if (index === 0) {

        const previousDate = {
            prevDay: Number(baseToUpdate.data_cobertura_1.split('/')[0]),
            prevMonth: Number(baseToUpdate.data_cobertura_1.split('/')[1]),
            prevYear: Number(baseToUpdate.data_cobertura_1.split('/')[2]),
        };

        const currentDate = {
          currentDay: day,
          currentMonth: month,
          currentYear: year,
        };

        validateSemester(previousDate, currentDate)
      }

      // segundo e terceiro datetimepicker
      else {
        if (!coverageDates[index - 1] || !coverageDates[0]) 
          invalidDateMessage = 'Insira uma data para a cobertura anterior';

        else {

          const previousDate = {
            prevDay: Number(coverageDatesString[index - 1]?.split('/')[0]),
            prevMonth: Number(coverageDatesString[index - 1]?.split('/')[1]),
            prevYear: Number(coverageDatesString[index - 1]?.split('/')[2]),
          };

          const currentDate = {
            currentDay: day,
            currentMonth: month,
            currentYear: year,
          };

          
          if (
            ((currentDate.currentDay <= previousDate.prevDay) && 
            (currentDate.currentMonth <= previousDate.prevMonth)
            ) 
          || (currentDate.currentMonth < previousDate.prevMonth)
          ) {
            invalidDateMessage =
              'A data não pode ser menor ou igual que a data da cobertura anterior';
          }

          validateSemester(previousDate, currentDate);
        }
      }
    }

    return invalidDateMessage;
  };


  const validateDateInspection = (dateString) => {
    const day = Number(dateString.split('/')[0]);
    const month = Number(dateString.split('/')[1]);
    const year = Number(dateString.split('/')[2]);

    const dayNasc = Number(baseToUpdate.data_nascimento.split('/')[0]);
    const monthNasc = Number(baseToUpdate.data_nascimento.split('/')[1]);
    const yearNasc = Number(baseToUpdate.data_nascimento.split('/')[2]);

    let invalidDateMessage = null;

    if(year > yearNasc)
      return invalidDateMessage;

    if (month > monthNasc && year === yearNasc) 
      return invalidDateMessage;

    if(year < yearNasc)
      return invalidDateMessage = 'A data de inspeção tem que ser menor que a data de nascimento'

    if(day < dayNasc && month <= monthNasc){
      invalidDateMessage = 'A data de inspeção tem que ser menor que a data de nascimento'
    }

    return invalidDateMessage;
  };


  const handleChangeDateInspection = (index, value, date, dateString) => {
    let currentDate;

    if (value) {
      currentDate = value;
    }

    if (date && dateString) {
      currentDate = dateString;
    }

    if (currentDate?.match(/[0-9]/g)?.length === 8) {
      const validationFailsMessage = validateDateInspection(currentDate);

      if (validationFailsMessage) {
        swal('Data inválida', validationFailsMessage, 'error');
        setInvalidDateMessage(validationFailsMessage);
      } else {
        setFilterData({ ...filterData, data_inspecao: currentDate })
        setInvalidDateMessage(null);
      }
    }
  };


  const handleDateChange = (index, value, date, dateString) => {
    let currentDate;

    if (value) {
      currentDate = value;
    }

    if (date && dateString) {
      currentDate = dateString;
    }

    if (currentDate?.match(/[0-9]/g)?.length === 8) {
      const validationFailsMessage = validateDate(currentDate, index);

      if (validationFailsMessage) {
        swal('Data inválida', validationFailsMessage, 'error');
        setInvalidDateMessage(validationFailsMessage);
      } else {
        const isoDate = parseDateIso(currentDate);

        const dateToInsert = coverageDatesString.map((item, mapIndex) =>
          index === mapIndex ? currentDate : item
        );

        const isoDateToInsert = coverageDates.map((item, mapIndex) =>
          index === mapIndex ? isoDate : item
        );

        setCoverageDates(isoDateToInsert);
        setCoverageDatesString(dateToInsert);
        setInvalidDateMessage(null);
      }
    }
  };

  const handleCleanButton = useCallback((index) => {

    let newCoverageDates = [...coverageDates];
    newCoverageDates[index] = null;
    
    setCoverageDates(newCoverageDates);

    let newCoverageDatesString = [...coverageDatesString];
    newCoverageDatesString[index] = null;
    setCoverageDatesString(newCoverageDatesString);

  }, [setCoverageDates, setCoverageDatesString, coverageDates, coverageDatesString])

  const clearData = useCallback(() => {
    setFilterData({});
    setCoverageDates([null, null, null]);
  }, []);

  const handleSubmit = useCallback(
    async (type) => {
      const updateData = {
        ...baseToUpdate,
        ...filterData,
      };

      setLoading(true);

      try {
        console.log(updateData);
        const { data } = await api.put('pedidoregistro/atualizar', updateData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.success) {
          swal('Sucesso!', 'Alteração realizada.', 'success');
          apiCall();
          clearData();
          setModalIsOpen((prev) => ({ ...prev, [type]: false }));
        }
      } catch (error) {
        console.log(error);

        if (error.response.data.success === false) {
          return swal('Erro!', error.response.data.mensagem, 'error');
        }

        swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
      } finally {
        setLoading(false);
      }
    },
    [token, baseToUpdate, filterData, apiCall, clearData]
  );

  const handleChangeCoverageDate = useCallback(
    async (type) => {
      const updateData = {
        ...baseToUpdate,
        data_cobertura_1: coverageDatesString[0],
        data_cobertura_2: coverageDatesString[1],
        data_cobertura_3: coverageDatesString[2],
      };

      setLoading(true);

      try {
        const { data } = await api.put('pedidoregistro/atualizar', updateData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.success) {
          swal({
            title: 'Sucesso!',
            text: 'Data da cobertura atualizada!',
            icon: 'success',
          });
          apiCall();
          clearData();
          setModalIsOpen((prev) => ({ ...prev, [type]: false }));
        }
      } catch (error) {
        console.log(error);

        if (error.response.data.success === false) {
          return swal('Erro!', error.response.data.mensagem, 'error');
        }

        swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
      } finally {
        setLoading(false);
      }
    },
    [token, baseToUpdate, coverageDatesString, apiCall, clearData]
  );

  const handleOpenModalCoverage = useCallback(() => {
    setModalIsOpen({ ...modalIsOpen, coverageDate: true });

    setCoverageDates([
      baseToUpdate?.data_cobertura_1 ? parseDateIso(baseToUpdate.data_cobertura_1) : null,
      baseToUpdate?.data_cobertura_2 ? parseDateIso(baseToUpdate.data_cobertura_2) : null,
      baseToUpdate?.data_cobertura_3 ? parseDateIso(baseToUpdate.data_cobertura_3) : null,
    ]);

    setCoverageDatesString([
      baseToUpdate.data_cobertura_1,
      baseToUpdate.data_cobertura_2,
      baseToUpdate.data_cobertura_3,
    ]);
  }, [modalIsOpen, baseToUpdate])

  /**
   * Change isCalendarOpen array with position selected equal a true
   * @param {number} index of component selected
   */
  const handleCalendarOpen = (index) => {
    const calendarToOpen = isCalendarOpen.map((isOpen, mapIndex) => {
      if (index < 0) return false;
      else return index === mapIndex ? !isOpen : isOpen;
    });

    setIsCalendarOpen(calendarToOpen);
  };

  const validateDateField = (getFieldValue, item) => ({
    validator(rule, value) {
      const index = item - 1;

      if (value) {
        const currentDate =
          getFieldValue(`coverage-date${item}`) || coverageDatesString[index];
        const validationFailsMessage = validateDate(currentDate, index);

        if (validationFailsMessage) {
          return Promise.reject('Insira uma data válida');
        } else {
          return Promise.resolve();
        }
      } else if (item === 1 && coverageDatesString[0].length < 10) {
        return Promise.reject('Insira uma data');
      } else {
        return Promise.resolve();
      }
    },
  });

  const disabledDate = (currentDate, index) => {
    // primeiro datepicker
    if(index === 0) {
      return currentDate > moment().endOf('day');
    }

    //segundo e terceiro datepicker
    else {
      return currentDate < moment(coverageDates[index - 1]).endOf('day');
    }
  };

  //Chamada na API para busca de UF
  useEffect(() => {
    axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((response) => {
        const ufInitials = response.data.map((uf) => uf.sigla);

        setPlaces(ufInitials);
      });
  }, []);

  // Chamada na API para busca de cidades com base na UF
  const fetchCities = useCallback(async () => {
    try {
      const { data } = await api.get('cidades/get', {
        params: {
          sigla: filterData.uf,
        },
      });

      setCities(data);
    } catch (error) {
      console.log(error);
    }
  }, [filterData.uf]);

  useEffect(() => {
    if (filterData.uf) {
      fetchCities();
    }
  }, [filterData.uf, fetchCities]);

  // Setando as cidades em um state no formato que o select está preparado para receber
  useEffect(() => {
    if (cities) {
      const selectCitiesOptions = cities.map((city) => ({
        value: city?.id_cidade,
        label: city?.ds_cidade,
      }));

      setOptionsCities(selectCitiesOptions);
    }
  }, [cities]);
  
  useEffect(() => {
    if (baseToUpdate) 
      setFilterData(baseToUpdate);
  }, [modalIsOpen, clearData, baseToUpdate]);

  useEffect(() => {
    setCaracteristics([
      {
        type: 'prognata', 
        isChecked: baseToUpdate.caracteristicas[0].caracteristica !== null ? true : false
      },
      {
        type: 'criptorquidismo', 
        isChecked: baseToUpdate.caracteristicas[1].caracteristica !== null ? true : false
      },
      {
        type: 'excesso_de_manchas', 
        isChecked: baseToUpdate.caracteristicas[2].caracteristica !== null ? true : false
      },
    ]);

    setUndesirableCaracteristic(baseToUpdate.caracteristicas_indesejaveis);
    
  }, [baseToUpdate]);

  const toggleCheckbox = useCallback(
    (number) => {
      const checked = filterData.caracteristicas?.find(
        (item) => item.caracteristica === (number + 1)
      );

      //remove
      if (checked) {

        setFilterData({
          ...filterData,
          caracteristicas: filterData.caracteristicas?.filter(
            (item, index) => {

              if(number === index) 
                item.caracteristica = null;
    
              return item;
            }
          ),
        });

        setCaracteristics(prev => prev.map((item, index) => {
          if(number === index)
            item.isChecked = false;

          return item;
        }));
      } 

      //add
      else {

        setFilterData({
          ...filterData,
          caracteristicas: filterData.caracteristicas?.filter(
            (item, index) => {

              if(index === number ) {
                item.caracteristica = number + 1;
                return item;
              }
              
              return item;
            }
          ),
        });

        setCaracteristics(prev => prev.map((item, index) => {
          if(number === index)
            item.isChecked = true;

          return item;
        }));
      }
    },
    [filterData]
  );

  const toggleUndesirableOptions = useCallback(
    (value) => {
      const optionValue = value;

      if (optionValue === '1') {
        setFilterData({
          ...filterData,
          caracteristicas_indesejaveis: optionValue,
        });
        setUndesirableCaracteristic(optionValue)
      } else {
        setFilterData({
          ...filterData,
          caracteristicas_indesejaveis: optionValue,
          caracteristicas: [
            { caracteristica: null },
            { caracteristica: null },
            { caracteristica: null },
          ],
        });
        setUndesirableCaracteristic(optionValue);
        setCaracteristics(prev => prev.map(item => {
            item.isChecked = false;
            return item;
        }))
      }
    },
    [filterData, setCaracteristics]
  );

  const radioAffixOptions = [
    { value: '2', label: 'Prefixo' },
    { value: '1', label: 'Sufixo' },
  ];

  const radioUndesirableOptions = [
    { value: '1', label: 'Sim' },
    { value: '0', label: 'Não' },
  ];

  const selectPlacesOptions = places.map((place, index) => ({
    value: index,
    label: place,
  }));

  return (
    <Container>
      {!isMobile && <PageTitle>Detalhes do registro</PageTitle>}

      {initialLoading ? (
        <Loader />
      ) : (
        detailsRequest && (
          <table>
            <StyledCustomScrollBar color={colors.black}>
              <tbody>
                <tr>
                  <td>Pré-registro:</td>
                  <td>{preRegister}</td>
                </tr>

                <tr>
                  <td>Status:</td>
                  <td>
                    {isCanceled
                      ? 'Cancelado'
                      : detailsRequest?.status_lancamento}
                  </td>
                </tr>

                <tr>
                  <td>Solicitante:</td>
                  <td>
                    {!isRegistred && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({ ...modalIsOpen, requester: true })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}
                    {detailsRequest?.nome_solicitante
                      ? detailsRequest?.nome_solicitante
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Possui cartão de assinante:</td>
                  <td>
                    {detailsRequest?.possui_assinatura === 2 ? 'Não' : 'Sim'}
                  </td>
                </tr>

                <tr>
                  <td>Inspetor:</td>
                  <td>
                    {detailsRequest?.nome_inspetor
                      ? detailsRequest?.nome_inspetor
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Proprietário:</td>
                  <td>
                    {detailsRequest?.proprietario
                      ? detailsRequest?.proprietario
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Criador:</td>
                  <td>
                    {detailsRequest?.criador ? detailsRequest?.criador : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Grau de sangue: </td>
                  <td>
                    {detailsRequest?.dsc_grau_sangue
                      ? detailsRequest?.dsc_grau_sangue
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Pelagem do Produto: </td>
                  <td>
                    {detailsRequest?.dsc_pelagem_produto
                      ? detailsRequest?.dsc_pelagem_produto
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Sexo do Produto: </td>
                  <td>
                    {!isRegistred && isInspector && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({ ...modalIsOpen, sex: true })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}
                    {detailsRequest?.sexo_animal
                      ? detailsRequest?.sexo_animal
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Opções de nome: </td>
                  <td>
                    {!isRegistred && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({ ...modalIsOpen, name: true })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}

                    {detailsRequest?.opcoes_nome
                      ? detailsRequest?.opcoes_nome
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Data do Lançamento: </td>
                  <td>
                    {detailsRequest?.dt_lancamento
                      ? detailsRequest?.dt_lancamento
                      : '--/--/--'}
                  </td>
                </tr>

                <tr>
                  <td>Data da Cobertura: </td>
                  <td>
                    {!isRegistred && (
                      <button
                        type="button"
                        onClick={() => handleOpenModalCoverage()}
                      >
                        <FiEdit3 />
                      </button>
                    )}
                    {detailsRequest?.datas_coberturas
                      ? detailsRequest?.datas_coberturas
                      : '--/--/--'}
                  </td>
                </tr>

                <tr>
                  <td>Data de Nascimento: </td>
                  <td>
                    {detailsRequest?.dt_nascimento
                      ? detailsRequest?.dt_nascimento.substr(0, 10)
                      : '--/--/--'}
                  </td>
                </tr>

                <tr>
                  <td>Data de Inspeção: </td>
                  <td>
                    {!isRegistred && isInspector && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({
                            ...modalIsOpen,
                            inspectionDate: true,
                          })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}

                    {detailsRequest?.dt_inspecao
                      ? detailsRequest?.dt_inspecao
                      : '--/--/--'}
                  </td>
                </tr>

                <tr>
                  <td>Chip:</td>
                  <td>
                    {detailsRequest?.linkchip && (
                      <a href={detailsRequest?.linkchip || '#'}>
                        <FiExternalLink />
                      </a>
                    )}

                    {detailsRequest?.nro_chip ? detailsRequest?.nro_chip : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Filiação: </td>
                  <TdAffiliation>
                    {detailsRequest?.ID_PAI && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="external-link"
                        href={`${process.env.REACT_APP_SERVICOS_ONLINE_PHP}/animais/detalhes/${detailsRequest.ID_PAI}`}
                      >
                        <FiExternalLink />
                      </a>
                    )}

                    <p>
                      {detailsRequest?.NOME_PAI}
                      {detailsRequest?.REGISTRO_PAI != null
                        ? detailsRequest.REGISTRO_PAI
                        : ''}
                    </p>
                    <span className="link-divisor">&nbsp;x&nbsp;</span>
                    {detailsRequest?.ID_MAE && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="external-link"
                        href={`${process.env.REACT_APP_SERVICOS_ONLINE_PHP}/animais/detalhes/${detailsRequest.ID_MAE}`}
                      >
                        <FiExternalLink />
                      </a>
                    )}

                    <p>
                      {detailsRequest?.NOME_MAE}
                      {detailsRequest?.REGISTRO_MAE != null
                        ? detailsRequest.REGISTRO_MAE
                        : ''}
                    </p>
                  </TdAffiliation>
                </tr>

                <tr>
                  <td>Pelagem da Égua Comum: </td>
                  <td>
                    {detailsRequest?.PELAGEM_MAE
                      ? detailsRequest?.PELAGEM_MAE
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Transferência de Embrião: </td>
                  <td>
                    {!isRegistred && isInspector && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({
                            ...modalIsOpen,
                            embryoTransfer: true,
                          })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}

                    {detailsRequest?.transferencia_embriao
                      ? detailsRequest?.transferencia_embriao
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Sequência: </td>
                  <td>
                    {detailsRequest?.sequencia
                      ? detailsRequest?.sequencia
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Observação inspetor: </td>
                  <td>
                    {!isRegistred && isInspector && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({
                            ...modalIsOpen,
                            inspectorObservations: true,
                          })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}

                    {detailsRequest?.obs_inspetor1
                      ? detailsRequest?.obs_inspetor1
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Produto ao Pé: </td>
                  <td>
                    {!isRegistred && isInspector && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({
                            ...modalIsOpen,
                            footProduct: true,
                          })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}

                    {detailsRequest?.produto_pe
                      ? detailsRequest?.produto_pe
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Características Indesejáveis: </td>
                  <td>
                    {!isRegistred && isInspector && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({
                            ...modalIsOpen,
                            undesirableCharacteristics: true,
                          })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}

                    {
                      <>
                        {detailsRequest?.prognata !== 0 && (
                          <span>Prognata</span>
                        )}
                        {detailsRequest?.criptorquido !== 0 && (
                          <span>Criptorquido</span>
                        )}
                        {detailsRequest?.excesso_de_manchas !== 0 && (
                          <span>Excesso de manchas</span>
                        )}
                      </>
                    }
                  </td>
                </tr>

                <tr>
                  <td>Localidade de nascimento: </td>
                  <td>
                    {!isRegistred && (
                      <button
                        type="button"
                        onClick={() =>
                          setModalIsOpen({ ...modalIsOpen, placeBirth: true })
                        }
                      >
                        <FiEdit3 />
                      </button>
                    )}

                    {detailsRequest?.ds_cidade && detailsRequest?.sigla_estado
                      ? `${detailsRequest?.ds_cidade}, ${detailsRequest?.sigla_estado} `
                      : detailsRequest?.sigla_estado}
                  </td>
                </tr>

                <tr>
                  <td>Tipo da Cobertura: </td>
                  <td>
                    {detailsRequest?.tipo_cobertura
                      ? detailsRequest?.tipo_cobertura
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Registrado em: </td>
                  <td>
                    {detailsPreRegistro?.dt_inclusao
                      ? detailsPreRegistro.dt_inclusao
                      : '--/--/--'}
                  </td>
                </tr>

                <tr>
                  <td>Pedido de Registro fora do prazo: </td>
                  <td>
                    {detailsRequest?.pre_fora_prazo
                      ? detailsRequest?.pre_fora_prazo
                      : '--'}
                  </td>
                </tr>

                <tr>
                  <td>Cobertura fora do prazo: </td>
                  <td>
                    {detailsRequest?.cobertura_fora_prazo
                      ? detailsRequest?.cobertura_fora_prazo
                      : '--'}
                  </td>
                </tr>

                {/* <tr>
                  <td>Situação: </td>
                  <td>
                    {detailsFinanceiro.SITUACAO_PEDIDO_REGISTRO
                      ? detailsFinanceiro.SITUACAO_PEDIDO_REGISTRO
                      : '--'}
                  </td>
                </tr> */}

                <tr>
                  <td>Status do endereço: </td>
                  <td>
                    {detailsRequest?.dsc_status_endereco
                      ? detailsRequest?.dsc_status_endereco
                      : '--'}
                  </td>
                </tr>
              </tbody>
            </StyledCustomScrollBar>
          </table>
        )
      )}

      <ChangeRequesterModal
        token={token}
        apiCall={apiCall}
        isOpen={modalIsOpen.requester}
        setModalIsOpen={setModalIsOpen}
        detailsRequest={detailsRequest}
      />

      <Modal
        centered
        closable
        height={241}
        scroll={false}
        circleLoading={loading}
        title="Sexo do produto"
        isVisible={modalIsOpen.sex}
        width={window.innerWidth >= 1600 ? 530 : 424}
        toggleVisibility={() => setModalIsOpen({ ...modalIsOpen, sex: false })}
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() => setModalIsOpen({ ...modalIsOpen, sex: false })}
            >
              Cancelar
            </Button>

            <Button
              fontLight
              color={colors.lightGreen}
              onClick={() => handleSubmit('sex')}
              disabled={!filterData.sexo}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <ModalContent specialStyle>
            <Radio
              name="sex"
              size={'large'}
              vertical
              options={[
                { value: 'M', label: 'Macho' },
                { value: 'F', label: 'Fêmea' },
              ]}
              value={filterData.sexo}
              onChange={(value) =>
                setFilterData({ ...filterData, sexo: value })
              }
            />
          </ModalContent>
        )}
      </Modal>

      <Modal
        centered
        scroll={false}
        circleLoading={loading}
        title="Nome do produto"
        isVisible={modalIsOpen.name}
        height={window.innerWidth <= 420 ? 420 : 241}
        width={window.innerWidth >= 1600 ? 845 : 722}
        toggleVisibility={() => setModalIsOpen({ ...modalIsOpen, name: false })}
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() => setModalIsOpen({ ...modalIsOpen, name: false })}
            >
              Cancelar
            </Button>

            <Button
              fontLight
              color={colors.lightGreen}
              onClick={() => handleSubmit('name')}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <Form>
            <div className="group-name-inputs">
              <FormItem label="1ªopção" name="first-option-name">
                <Input
                  name="first-option-name"
                  width={window.innerWidth >= 1600 ? 250 : 213}
                  height={40}
                  value={filterData.opcao_1}
                  onChange={(value) =>
                    setFilterData({ ...filterData, opcao_1: value })
                  }
                />
              </FormItem>

              <FormItem label="2ªopção" name="second-option-name">
                <Input
                  width={window.innerWidth >= 1600 ? 250 : 213}
                  height={40}
                  name="second-option-name"
                  value={filterData.opcao_2}
                  onChange={(value) =>
                    setFilterData({ ...filterData, opcao_2: value })
                  }
                />
              </FormItem>

              <FormItem label="3ªopção" name="third-option-name">
                <Input
                  width={window.innerWidth >= 1600 ? 250 : 213}
                  height={40}
                  name="third-option-name"
                  value={filterData.opcao_3}
                  onChange={(value) =>
                    setFilterData({ ...filterData, opcao_3: value })
                  }
                />
              </FormItem>
            </div>

            <div className="affix-group">
              <FormItem label="Afixo" name="affix-text">
                <Input
                  width={123}
                  height={40}
                  maxLength={4}
                  name="affix-text"
                  value={filterData.afixo}
                  onChange={(value) =>
                    setFilterData({ ...filterData, afixo: value })
                  }
                />
              </FormItem>

              <FormItem name="affix" className="affix-radio">
                <Radio
                  name="affix"
                  size={'large'}
                  value={filterData.tipo_afixo}
                  options={radioAffixOptions}
                  onChange={(value) =>
                    setFilterData({ ...filterData, tipo_afixo: value })
                  }
                />
              </FormItem>
            </div>
          </Form>
        )}
      </Modal>

      <Modal
        centered
        width={900}
        circleLoading={loading}
        height={window.innerWidth <= 420 ? 360 : 241}
        scroll={false}
        title="Data da cobertura"
        isVisible={modalIsOpen.coverageDate}
        toggleVisibility={() =>
          setModalIsOpen({ ...modalIsOpen, coverageDate: false })
        }
        description={
          !loading &&
          'As datas de cobertura necessariamente devem estar dentro do mesmo semestre.'
        }
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() =>
                setModalIsOpen({ ...modalIsOpen, coverageDate: false })
              }
            >
              Cancelar
            </Button>

            <Button
              fontLight
              color={colors.lightGreen}
              onClick={() => handleChangeCoverageDate('coverageDate')}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <Form>
            <FormItem className="form-item1">
              <div className="coverage-date">
                {[1, 2, 3].map((item, index) => (
                    <FormItem
                      disabled={index >= 1 && !coverageDates[index - 1]}
                      key={item}
                      name={`coverage-date${item}`}
                      label={`Data da cobertura ${item}`}
                    >
                      <DatePicker
                        name={`coverage-date${item}`}
                        disabled={index >= 1 && !coverageDates[index - 1]}
                        onChange={(date, dateString) => 
                          handleDateChange(index, null, date, dateString)
                        }
                        onInputChange={(e) => handleDateChange(index, e)}
                        open={isCalendarOpen[index]}
                        onOpenChange={() => handleCalendarOpen(index)}
                        value={coverageDatesString[index]}
                        rules={
                          !coverageDates[index] && [
                            ({ getFieldValue }) =>
                              validateDateField(getFieldValue, item),
                          ]
                        }
                        disabledDate={(currentDate) =>
                          disabledDate(currentDate, index)
                        }
                        onBottom
                        {...index > 0 && {cleanButton: true}}
                        handleCleanButton={() => handleCleanButton(index)}
                      />
                    </FormItem>
                  ))}
              </div>
            </FormItem>
          </Form>
        )}
      </Modal>

      <Modal
        centered
        height={241}
        scroll={false}
        title="Data da inspeção"
        circleLoading={loading}
        isVisible={modalIsOpen.inspectionDate}
        width={window.innerWidth >= 1600 ? 530 : 424}
        toggleVisibility={() =>
          setModalIsOpen({ ...modalIsOpen, inspectionDate: false })
        }
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() =>
                setModalIsOpen({ ...modalIsOpen, inspectionDate: false })
              }
            >
              Cancelar
            </Button>

            <Button
              fontLight
              color={colors.lightGreen}
              onClick={() => handleSubmit('inspectionDate')}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <Form>
            <FormItem className="form-item1" label="Data atual:" horizontal>
              {detailsRequest?.dt_inspecao}
            </FormItem>

            <FormItem
              className="inspection-date"
              label="Nova data"
              name="inpection-date"
            >
              <DatePicker
                name="inpection-date"
                onChange={(date, dateString) => 
                  handleChangeDateInspection(null, null, date, dateString)
                }
                onInputChange={(e) => handleChangeDateInspection(null, e)}
                open={openCalendar}
                onOpenChange={() => setOpenCalendar(!openCalendar)}
                value={filterData.data_inspecao}
                onBottom
              />
            </FormItem>
          </Form>
        )}
      </Modal>

      <Modal
        centered
        height={254}
        scroll={false}
        circleLoading={loading}
        title="Observações do inspetor"
        width={window.innerWidth >= 1600 ? 585 : 500}
        isVisible={modalIsOpen.inspectorObservations}
        toggleVisibility={() =>
          setModalIsOpen({ ...modalIsOpen, inspectorObservations: false })
        }
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() =>
                setModalIsOpen({ ...modalIsOpen, inspectorObservations: false })
              }
            >
              Cancelar
            </Button>

            <Button
              fontLight
              color={colors.lightGreen}
              disabled={!filterData.observacoes}
              onClick={() => handleSubmit('inspectorObservations')}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <Form>
            <FormItem
              label="Deixe aqui suas observações"
              name="inspector-observations"
              className="form-item2"
            >
              <TextArea
                rows={4}
                width={457}
                className="text-area"
                name="inspector-observations"
                value={filterData.observacoes}
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    observacoes: e.target.value,
                  })
                }
              />
            </FormItem>
          </Form>
        )}
      </Modal>

      <Modal
        centered
        closable
        height={241}
        scroll={false}
        title="Produto ao pé"
        circleLoading={loading}
        isVisible={modalIsOpen.footProduct}
        width={window.innerWidth >= 1600 ? 530 : 424}
        toggleVisibility={() =>
          setModalIsOpen({ ...modalIsOpen, footProduct: false })
        }
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() =>
                setModalIsOpen({ ...modalIsOpen, footProduct: false })
              }
            >
              Cancelar
            </Button>

            <Button
              fontLight
              color={colors.lightGreen}
              onClick={() => handleSubmit('footProduct')}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <ModalContent specialStyle>
            <Radio
              name="foot-product"
              size={'large'}
              vertical
              options={[
                { value: 'SIM', label: 'Sim' },
                { value: 'NÃO', label: 'Não' },
              ]}
              value={filterData.produto_pe}
              onChange={(value) =>
                setFilterData({ ...filterData, produto_pe: value })
              }
            />
          </ModalContent>
        )}
      </Modal>

      <EmbryoTransferModal
        token={token}
        apiCall={apiCall}
        preRegister={preRegister}
        modalIsOpen={modalIsOpen}
        detailsRequest={detailsRequest}
        setModalIsOpen={setModalIsOpen}
        baseToUpdate={baseToUpdate}
      />

      <Modal
        centered
        circleLoading={loading}
        height={window.innerWidth <= 420 ? 200 : 241}
        title="Características indesejáveis"
        scroll={false}
        width={window.innerWidth >= 1600 ? 610 : 520}
        isVisible={modalIsOpen.undesirableCharacteristics}
        toggleVisibility={() =>
          setModalIsOpen({ ...modalIsOpen, undesirableCharacteristics: false })
        }
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() =>
                setModalIsOpen({
                  ...modalIsOpen,
                  undesirableCharacteristics: false,
                })
              }
            >
              Cancelar
            </Button>

            <Button
              fontLight
              color={colors.lightGreen}
              disabled={
                undesirableCaracteristic === '1' 
                ? caracteristics.filter(item => item.isChecked === true).length === 0 
                : false
              }
              onClick={() => handleSubmit('undesirableCharacteristics')}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <Form>
            <FormItem name="undesirable-characteristics" className="form-item1">
              <Radio
                size="large"
                name="undesirable-characteristics"
                options={radioUndesirableOptions}
                value={undesirableCaracteristic}
                onChange={(value) => toggleUndesirableOptions(value)}
              />
            </FormItem>

            <FormItem
              horizontal
              className="form-item3"
              name="checkbox-undesirable-characteristics"
              disabled={undesirableCaracteristic !== '1'}
            >
              <Checkbox
                size={40}
                checkColor={colors.buttonGray}
                checked={caracteristics[0].isChecked}
                onChange={() => toggleCheckbox(0)}
                disabled={undesirableCaracteristic !== '1'}
              >
                Prognata
              </Checkbox>

              <Checkbox
                size={40}
                checkColor={colors.buttonGray}
                checked={caracteristics[1].isChecked}
                disabled={undesirableCaracteristic !== '1'}
                onChange={() => toggleCheckbox(1)}
              >
                Criptorquido
              </Checkbox>

              <Checkbox
                size={40}
                checkColor={colors.buttonGray}
                checked={caracteristics[2].isChecked}
                disabled={undesirableCaracteristic !== '1'}
                onChange={() => toggleCheckbox(2)}
              >
                Excesso de manchas
              </Checkbox>
            </FormItem>
          </Form>
        )}
      </Modal>

      <Modal
        centered
        height={265}
        scroll={false}
        circleLoading={loading}
        title="Localidade do nascimento"
        isVisible={modalIsOpen.placeBirth}
        width={window.innerWidth >= 1600 ? 610 : 520}
        toggleVisibility={() =>
          setModalIsOpen({ ...modalIsOpen, placeBirth: false })
        }
        footer={
          <>
            <Button
              fontLight
              color={colors.red}
              onClick={() =>
                setModalIsOpen({ ...modalIsOpen, placeBirth: false })
              }
            >
              Cancelar
            </Button>

            <Button
              fontLight
              disabled={!filterData.uf || !filterData.id_cidade}
              color={colors.lightGreen}
              onClick={() => handleSubmit('placeBirth')}
            >
              Salvar
            </Button>
          </>
        }
      >
        {loading ? (
          <CircleLoading />
        ) : (
          <Form>
            <div className="place-birth">
              <FormItem
                className="form-item1"
                name="place-birth-uf"
                label="Estado"
              >
                <Select
                  width={window.innerWidth <= 420 ? 150 : 280}
                  name="place-birth-uf"
                  placeholder="Selecione"
                  options={selectPlacesOptions}
                  value={filterData.uf}
                  onChange={(value, label) =>
                    setFilterData({ ...filterData, uf: label.children, id_cidade: null })
                  }
                />
              </FormItem>
            </div>

            <div className="place-birth">
              <FormItem
                className="form-item1"
                name="place-birth-city"
                label="Cidade"
              >
                <Select
                  width={window.innerWidth <= 420 ? 300 : 400}
                  name="place-birth-city"
                  placeholder="Selecione"
                  options={optionsCities}
                  value={filterData.id_cidade}
                  disabled={!filterData.uf}
                  onChange={(value) =>
                    setFilterData({ ...filterData, id_cidade: value })
                  }
                />
              </FormItem>
            </div>
          </Form>
        )}
      </Modal>
    </Container>
  );
}
