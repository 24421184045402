import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../../functions';

export const Container = styled.section`
  height: 92%;

  h1 {
    font-size: 1.5rem;
    font-family: 'SFProText-Bold', sans-serif;
  }

  table {
    position: relative;

    height: auto;
    min-width: 100%;
    margin: 1.5rem 0;
    
    ::after {
      position: absolute;
      bottom: -12px; 
      
      content: "";
      width: 100%;
      height: 1px;

      background: #ccc;
    }

    :last-child::after {
      background: transparent;
    }

    thead tr {
      height: 50px;
    }

    tr {
      padding-left: ${pxToRem(8)};
    }
  }

  th,
  td,
  p {
    font-size: ${pxToRem(14)};
    font-family: 'SFProText-Regular', sans-serif;
  }

  .receiver-table {
    margin: 0.5rem 0 1.5rem;
    border-collapse: collapse;
    
    tr {
      padding: 0 ${pxToRem(8)};
      /* margin-right: ${pxToRem(20)}; */

      display: grid;
      grid-template-columns: 0.4fr 2fr;

      > td {
        margin-right: 10px;
      }

      :nth-child(odd) {
        background-color: rgba(40, 49, 49, 0.06);
      }

      :nth-child(even) {
        background-color: rgba(40, 49, 49, 0.1);
      }

      :hover {
        background-color: rgba(40, 49, 49, 0.15);
      }
    }

    h2 {
      margin-top: 1rem;

      font-size: 24px;
      font-family: 'SFProText-Regular', sans-serif;
    }

    td,
    th {
      padding: 8px 0;

      text-align: left;
    }
  }

  .table-info {
    height: 90%;

    ${media.lessThan('1280px')`
      height: 83%;

      :last-child {
        padding-bottom: 8px;
      }
    `}

    ${media.lessThan('1024px')`
      :last-child {
        padding-bottom: 0;
      }
    `}
  }

  ${media.lessThan('1024')`
    height: 100%;

    table {
      width: 100%;
    }
  `}
`;
