import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Checkbox = ({
  children,
  onChange,
  light,
  checkSize,
  checkColor,
  ...props
}) => (
  <Container
    onChange={(e) => onChange(e.target.checked)}
    light={light}
    checkSize={checkSize}
    checkColor={checkColor}
    {...props}
  >
    {children}
  </Container>
);

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  light: PropTypes.bool,
  checkSize: PropTypes.oneOf(['large', 'small']),
  checkColor: PropTypes.string,
};

Checkbox.defaultProps = {
  onChange: (value) => console.log(value),
  checkSize: 'small',
};

export default Checkbox;
