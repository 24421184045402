import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { message } from 'antd';
import Cropper from 'react-easy-crop';
import ReactHtmlParser from 'react-html-parser';
// import crypto from 'crypto';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import Loader from '../../../components/Loader';
import { cropImage as getCroppedImg } from '../../../functions';
import defaultProfilePicture from '../../../theme/assets/images/silhueta.svg';

import { Button, Slider } from '../../../components';

import { Container, ImageContainer, ImageCrop, CloseButton } from './styles';

const Photo = ({ data, id }) => {
  const photo = data.foto;

  const [profileImage, setProfileImage] = useState(defaultProfilePicture);
  const [photoSituation, setPhotoSituation] = useState('approved'); // review, approved, disapproved
  const [photoPreview, setPhotoPreview] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropConfirmed, setCropConfirmed] = useState(false);
  const [croppedImageConfirm, setCroppedImageConfirm] = useState();
  const [loading, setLoading] = useState(false);
  const [cropperConfig, setCropperConfig] = useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspectRatio: 1 / 1,
    croppedAreaPixels: null,
    isCropping: false,
    shape: 'round',
    showGrid: true,
  });

  const photoSituationMessage = {
    review: 'A foto foi enviada para <strong>ANÁLISE</strong>',
    approved: 'A foto enviada foi <strong>APROVADA</strong>',
    disapproved: 'A foto enviada foi <strong>REPROVADA</strong>',
  };

  const imagePreview = useMemo(() => {
    return photoPreview ? URL.createObjectURL(photoPreview) : null;
  }, [photoPreview]);

  const handleCropChange = (crop) => {
    setCropperConfig({ ...cropperConfig, crop });
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) =>
    setCropperConfig({ ...cropperConfig, croppedAreaPixels });

  const handleZoomChange = (zoom) =>
    setCropperConfig({ ...cropperConfig, zoom });

  const convertToBase64 = (croppedImage) => {
    const img = new Image();
    img.src = croppedImage;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);

      const base64 = canvas
        .toDataURL('image/png')
        .replace(/^data:image.+;base64,/, '');
      setCroppedImageConfirm(base64);
    };
  };

  const handleCropConfirm = async () => {
    try {
      setCropperConfig({ ...cropperConfig, isCropping: true });

      const croppedImage = await getCroppedImg(
        imagePreview,
        cropperConfig.croppedAreaPixels
      );

      setCroppedImage(croppedImage);
      setPhotoSituation('review');
      setCropConfirmed(true);

      convertToBase64(croppedImage);
    } catch (error) {
      console.error(error.message);
    } finally {
      setCropperConfig({ ...cropperConfig, isCropping: false });
    }
  };

  const handleCancel = () => {
    setPhotoPreview(null);
    setCroppedImage(null);
    setCropConfirmed(false);
    setCroppedImageConfirm('');
    setPhotoSituation(profileImage === photo ? 'approved' : 'default');
  };

  const handleSubmit = useCallback(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    async function updateImage() {
      try {
        const { data } = await api.post(
          'animal/enviarfoto',
          { id_animal: id, foto: String(croppedImageConfirm) },
          config
        );

        if (data.success) {
          setPhotoPreview(null);
          setCroppedImage(null);
          setCropConfirmed(false);
          setCroppedImageConfirm('');
          setPhotoSituation(profileImage === photo ? 'approved' : 'default');

          message.success('A imagem foi enviada para análise.');
        }
      } catch (error) {
        setPhotoPreview(null);
        setCroppedImage(null);
        setCropConfirmed(false);
        setCroppedImageConfirm('');
        setPhotoSituation(profileImage === photo ? 'approved' : 'default');

        message.error(
          error.response 
          ? error.response.data.mensagem 
          : 'Erro ao enviar foto. Se o erro persistir contate-nos!'
        );
      } finally {
        setLoading(false);
      }
    }

    updateImage();
  }, [croppedImageConfirm, id, setPhotoSituation, photo, profileImage]);

  return (
    <>
      {loading ? (
        <Loader light sizeScreen="middle" message="Enviando nova foto!" />
      ) : (
        <Container>
          <ImageContainer photoSituation={photoSituation}>
            <div className="image-wrapper">
              <img
                src={croppedImage ? croppedImage : profileImage}
                alt="Foto"
              />
            </div>

            {cropConfirmed ? (
              <div id="crop-confirmed-buttons">
                <Button
                  width={104}
                  height={32}
                  fontLight
                  color="rgba(178, 178, 178, 0.5)"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>

                <Button
                  width={104}
                  height={32}
                  fontLight
                  color={colors.lightGreen}
                  onClick={handleSubmit}
                >
                  Enviar foto
                </Button>
              </div>
            ) : (
              <p>{ReactHtmlParser(photoSituationMessage[photoSituation])}</p>
            )}
          </ImageContainer>

          <ImageCrop>
            {imagePreview ? (
              <>
                <div className="cropper-wrapper">
                  <CloseButton
                    title="Cancelar"
                    onClick={() => setPhotoPreview(null)}
                  >
                    &times;
                  </CloseButton>

                  <Cropper
                    image={imagePreview}
                    crop={cropperConfig.crop}
                    zoom={cropperConfig.zoom}
                    aspect={cropperConfig.aspectRatio}
                    onCropChange={handleCropChange}
                    onCropComplete={handleCropComplete}
                    onZoomChange={handleZoomChange}
                    cropShape={cropperConfig.shape}
                    showGrid={cropperConfig.showGrid}
                    cropSize={{ width: 240, height: 240 }}
                  />
                </div>

                <div className="cropper-controls">
                  <div className="slider-wrapper">
                    <label>Zoom</label>
                    <Slider
                      value={cropperConfig.zoom}
                      min={1}
                      max={3}
                      step={0.01}
                      onChange={(value) =>
                        setCropperConfig({ ...cropperConfig, zoom: value })
                      }
                    />
                  </div>

                  <Button
                    style={{ padding: '0 24px' }}
                    onClick={handleCropConfirm}
                    width={94}
                    height={23}
                    fontLight
                    color={colors.lightGreen}
                    loading={cropperConfig.isCropping}
                  >
                    Recortar
                  </Button>
                </div>
              </>
            ) : (
              <label className="upload-button" htmlFor="upload-photo">
                Nova foto
                <input
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setPhotoPreview(e.target.files[0])}
                />
              </label>
            )}
          </ImageCrop>
        </Container>
      )}
    </>
  );
};

export default Photo;
