import React, { useState, useCallback, useEffect } from 'react';
import swal from 'sweetalert';

import { colors } from '../../../../theme';
import { api } from '../../../../services/api';
import {
  Button,
  Radio,
  Select,
  FormItem,
  AutoComplete,
  FormValidator,
} from '../../../../components';

import { Modal, Form, CircleLoading } from './styles';
import { errorHandler } from '../../../../functions';

export default function EmbryoTransferModal({
  token,
  apiCall,
  modalIsOpen,
  preRegister,
  setModalIsOpen,
  baseToUpdate,
  detailsRequest
}) {
  const minCharsToSearchAnimal = 4;
  const initialRegisterData = {
    animal: {
      id_animal: baseToUpdate.id_receptora,
      nome_animal: detailsRequest?.receptora === 'comum' ? '' : (() => {
        let nameArray = [];
        nameArray = detailsRequest?.receptora?.split(' ');
        nameArray = nameArray !== undefined && nameArray.length > 0 ? nameArray.slice(0, nameArray.length-1) : [];
  
        let completeName = '';
        nameArray.map(string => {
          if(string !== '')
            completeName += ' ' + string
          return string;
        });
  
        return completeName;
      })(),
    },
    sequence: baseToUpdate.sequencia,
    receiver: detailsRequest?.receptora?.toLowerCase() === 'comum' ? 'comum' : 'registrada',
    requestNumber: preRegister,
  };

  const [loading, setLoading] = useState(false);
  const [isEmbryoTransfer, setIsEmbryoTransfer] = useState(baseToUpdate.transferencia_embriao);
  const [saveButtonIsDisabled, setSaveButtonIsDisabled] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [
    currentEmbryoTransferRegister,
    setCurrentEmbryoTransferRegister,
  ] = useState(initialRegisterData);
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [
    autoCompleteNotFoundMessage,
    setAutoCompleteNotFoundMessage,
  ] = useState('');
  const [emptyAutoCompleteField, setEmptyAutoCompleteField] = useState(false);

  const fetchAnimals = useCallback(async (value = '', thirds = 0) => {
    try {
      const { data } = await api.get('/animal/consultaanimal', {
        params: {
          terceiros: thirds,
          sexo: 'F',
          nome_registro: value,
        },
      });

      if (data.success) {
        return data.data;
      }
    } catch (error) {
      console.error(error.message);
    }

    return null;
  }, []);

  const handleCancel = useCallback(() => {
    setModalIsOpen({ ...modalIsOpen, embryoTransfer: false });

    setCurrentEmbryoTransferRegister(initialRegisterData);
    setIsEmbryoTransfer(baseToUpdate.transferencia_embriao);
  }, [initialRegisterData, modalIsOpen, setModalIsOpen, setIsEmbryoTransfer, baseToUpdate]);

  const handleSubmit = useCallback(
    async (type) => {
      const {
        animal,
        receiver,
        sequence,
      } = currentEmbryoTransferRegister;

      const updateData = {
        ...baseToUpdate,
        sequencia: sequence,
        id_receptora: receiver === 'registrada' ? animal?.id_animal : 0,
        transferencia_embriao: Number(isEmbryoTransfer)
      };

      setLoading(true);

      try {

        // console.log(updateData);

        const { data } = await api.put('pedidoregistro/atualizar', updateData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.success) {
          swal({
            title: 'Sucesso!',
            text: 'Cobertura atualizada!',
            icon: 'success',
          });
          apiCall();
          setModalIsOpen((prev) => ({ ...prev, [type]: false }));
        }
      } catch (error) {
        console.log(error);

        errorHandler(error);
        handleCancel();
      } finally {
        setLoading(false);
      }
    },
    [
      token, 
      baseToUpdate, 
      apiCall, 
      handleCancel,
      setModalIsOpen,
      currentEmbryoTransferRegister,
      isEmbryoTransfer
    ]
  );

  const onSearchRegisteredReceiver = async (value) => {
    if (!value) {
      return;
    }

    if (
      value.length >= minCharsToSearchAnimal &&
      autoCompleteOptions.length === 0
    ) {
      setAutoCompleteLoading(true);

      const registeredReceiver = await fetchAnimals(value, 1);

      if (registeredReceiver?.length > 0) {
        const options = registeredReceiver.map((animal) => {
          let valueToSearch = animal.nome_animal;

          if (value.match(/[a-z]\d[0-9]*/i)) {
            valueToSearch = animal.registro_abqm;
          }

          return { value: valueToSearch, animal };
        });

        setAutoCompleteOptions(options);
        setAutoCompleteNotFoundMessage('');
      } else {
        setAutoCompleteNotFoundMessage('Animal não encontrado');
      }

      setAutoCompleteLoading(false);
    }
  };

  const onSelectRegisteredReceiver = (_, option) => {
    const { animal } = option;

    if (animal) {
      setCurrentEmbryoTransferRegister({
        ...currentEmbryoTransferRegister,
        animal,
      });
      setAutoCompleteNotFoundMessage('');
      setAutoCompleteOptions([]);
      setEmptyAutoCompleteField(true);
    }
  };

  const onChangeRegisteredReceiver = (value) => {
    if (value.length >= minCharsToSearchAnimal) {
      setEmptyAutoCompleteField(false);
    } else {
      setCurrentEmbryoTransferRegister({
        ...currentEmbryoTransferRegister,
        animal: null,
      });
      setAutoCompleteOptions([]);
      setEmptyAutoCompleteField(true);
    }
  };

  const toggleEmbryoType = useCallback(
    (value) => {
      setIsEmbryoTransfer(value);

      setCurrentEmbryoTransferRegister({
        animal: {
          id_animal: null,
          nome_animal: ''
        },
        sequence: null,
        receiver: value === '1' ? 'comum' : '',
        requestNumber: preRegister,
      });
    },
    [preRegister]
  );

  const radioEmbryoOptions = [
    { value: '1', label: 'Sim' },
    { value: '0', label: 'Não' },
  ];

  const radioEmbryoOptionsType = [
    { value: 'registrada', label: 'Égua registrada' },
    { value: 'comum', label: 'Égua comum' },
  ];

  const selectSequenceOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  // enable/disable save button
  useEffect(() => {

    if(isEmbryoTransfer === '1')
    {
      if(currentEmbryoTransferRegister.receiver === 'comum') {
        if(currentEmbryoTransferRegister.sequence && currentEmbryoTransferRegister.receiver !== '') {
          setSaveButtonIsDisabled(false);
          return;
        }
      }

      // receptora
      else {
        if(currentEmbryoTransferRegister.sequence 
          && currentEmbryoTransferRegister.animal) {
          setSaveButtonIsDisabled(false);
          return;
        }
      }
    }

    if(isEmbryoTransfer === '0') {
      setSaveButtonIsDisabled(false);
      return;
    }

    setSaveButtonIsDisabled(true);
  }, [isEmbryoTransfer, currentEmbryoTransferRegister])

  return (
    <Modal
      centered
      scroll={false}
      circleLoading={loading}
      title="Transferência de embrião"
      isVisible={modalIsOpen.embryoTransfer}
      height={window.innerWidth <= 420 ? 300 : 265}
      width={window.innerWidth >= 1600 ? 610 : 520}
      toggleVisibility={() =>
        setModalIsOpen({ ...modalIsOpen, embryoTransfer: false })
      }
      footer={
        <>
          <Button fontLight color={colors.red} onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            fontLight
            color={colors.lightGreen}
            disabled={saveButtonIsDisabled}
            onClick={() => handleSubmit('embryoTransfer')}
          >
            Salvar
          </Button>
        </>
      }
    >
      {loading ? (
        <CircleLoading />
      ) : (
        <Form>
          <div className="embrio-transfer-radio-group">
            <FormItem horizontal name="embryo-transfer" className="form-item1">
              <Radio
                size="large"
                name="embryo-transfer"
                value={isEmbryoTransfer}
                options={radioEmbryoOptions}
                onChange={(value) => toggleEmbryoType(value)}
              />
            </FormItem>

            <FormItem
              name="embryo-transfer-type"
              className="embryo-transfer-type"
            >
              <Radio
                disabled={isEmbryoTransfer !== '1'}
                size="large"
                name="embryo-transfer-type"
                options={radioEmbryoOptionsType}
                value={currentEmbryoTransferRegister.receiver}
                onChange={(value) =>
                  setCurrentEmbryoTransferRegister({
                    ...currentEmbryoTransferRegister,
                    receiver: value,
                  })
                }
              />
            </FormItem>
          </div>

          <div className="receiving-mare">
            {currentEmbryoTransferRegister.receiver === 'registrada' && (
              <FormValidator
                name="receiving-mare"
                className="form-item1"
                message="Insira a égua registrada"
                label="Nome da égua receptora"
              >
                <AutoComplete
                  placeholder="Insira o nome ou registro"
                  height={40}
                  options={autoCompleteOptions}
                  defaultValue={
                    currentEmbryoTransferRegister?.animal?.nome_animal
                      ? currentEmbryoTransferRegister.animal.nome_animal
                      : ''
                  }
                  loading={autoCompleteLoading}
                  onSearch={onSearchRegisteredReceiver}
                  onSelect={onSelectRegisteredReceiver}
                  onChange={onChangeRegisteredReceiver}
                  open={!emptyAutoCompleteField}
                  notFoundContent={autoCompleteNotFoundMessage}
                  name="receiving-mare"
                  value={currentEmbryoTransferRegister?.animal?.nome_animal
                    ? currentEmbryoTransferRegister.animal.nome_animal
                    : ''}
                  width={window.innerWidth >= 1600 ? 330 : 280}
                  title={
                    currentEmbryoTransferRegister.receiver === 'comum' &&
                    'Selecione registrada para inserir os dados'
                  }
                  disabled={
                    currentEmbryoTransferRegister.receiver !== 'registrada' || isEmbryoTransfer === '0'
                  }
                />
              </FormValidator>
            )}

            <FormItem
              className="form-item1"
              name="sequence-receiving-mare"
              label="Sequência:"
            >
              <Select
                width={165}
                placeholder="Selecione"
                name="sequence-receiving-mare"
                value={currentEmbryoTransferRegister.sequence}
                onChange={(value) =>
                  setCurrentEmbryoTransferRegister({
                    ...currentEmbryoTransferRegister,
                    sequence: value,
                  })
                }
                disabled={isEmbryoTransfer !== '1'}
                options={selectSequenceOptions}
              />
            </FormItem>
          </div>
        </Form>
      )}
    </Modal>
  );
}
