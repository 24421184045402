import React from 'react';
import PropTypes from 'prop-types';
import { FiRepeat } from 'react-icons/fi';

import { TextHeader } from '../.';

import { Container, Content } from './styles';

function CardError({ large, title, message, code, onUpdate, ...props }) {
  return (
    <Container large={large} {...props}>
      {title && <TextHeader title={title} />}

      <Content>
        <div>
           {/* <h4>{message}</h4>
           <h5>Código de erro: {code}</h5>   */}
        </div>

        <button onClick={onUpdate}>
          <FiRepeat /> <br />
          Atualizar
        </button>
      </Content>
    </Container>
  );
}

CardError.propTypes = {
  large: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func.isRequired,
};

CardError.defaultProps = {
  message: 'Não foi possível carregar estes dados',
  code: 400,
};

export default CardError;
