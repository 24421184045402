/**
 * This function check if the css property backdrop-filter is supported according to the browser
 */
export default () => {
  const browser = navigator.userAgent;

  if (
    browser.match(/firefox/i) ||
    browser.match(/trident/i) ||
    browser.match(/opr/i)
  ) {
    return false;
  }

  return true;
};
