import styled from 'styled-components';
import { Slider } from 'antd';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const Container = styled(Slider)`
  .ant-slider-handle {
    width: ${pxToRem(9)};
    height: ${pxToRem(9)};

    border: solid 1px #707070;
    transform: translate(-50%, 2.5px) !important;
  }

  .ant-slider-rail.ant-slider-rail,
  .ant-slider-track.ant-slider-track,
  .ant-slider-step.ant-slider-step {
    height: ${pxToRem(3)};
    transform: translateY(0.5px);
  }

  .ant-slider-track.ant-slider-track {
    background-color: ${colors.white};
  }
`;
