import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import coin from '../../theme/assets/images/icons/coin.svg';
import debtsDetailsIcon from '../../theme/assets/images/icons/files-transit.svg';
import { convertCurrency } from '../../functions';

import { Container, DebtsIcon, DebtsInfo, DebtsDetails } from './style';

export default function Debts({ title, value }) {
  const checkValueType = useCallback((item) => {
    if (!isNaN(item)) {
      return <span>{convertCurrency(item)}</span>;
    } else {
      return <p>Indisponível</p>;
    }
  }, []);

  return (
    <Container>
      <DebtsIcon>
        <img src={coin} alt="icon" />
      </DebtsIcon>

      <DebtsInfo>
        <h1>{title}</h1>

        {checkValueType(value)}
      </DebtsInfo>

      <Link to="/pagamentos">
        <DebtsDetails href="#">
          <img src={debtsDetailsIcon} alt="Debts Details Icon" />

          <p>ver detalhes</p>
        </DebtsDetails>
      </Link>
    </Container>
  );
}
