import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { fadeIn } from 'react-animations';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

const bounceFadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: 40px 160px 1fr;

  height: 100%;
  padding: ${pxToRem(20)} ${pxToRem(40)};

  background: rgba(255, 255, 255, 0.86);

  ${media.greaterThan('1900px')`
    grid-template-rows: 50px 225px 1fr;
  `}
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  #header-buttons {
    display: inherit;

    button + button {
      margin-left: ${pxToRem(16)};
    }
  }
`;

export const DetailsInfo = styled.section`
  display: flex;
  align-items: center;

  width: 100%;
  margin-top: 1%;

  #image-wrapper {
    position: relative;

    width: ${pxToRem(153)};
    height: ${pxToRem(153)};

    img,
    .image-border {
      position: absolute;
      top: calc(50% - (${pxToRem(120)} / 2));
      right: calc(50% - (${pxToRem(120)} / 2));

      border-radius: 50%;
      width: ${pxToRem(120)};
      height: ${pxToRem(120)};
      border: solid 5px rgba(255, 255, 255, 0.2);

      object-fit: cover;
    }
  }

  #info-wrapper {
    margin-left: 1%;

    #info-top {
      display: flex;
      align-items: center;

      .characters {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: ${pxToRem(34)};
        padding: 0 ${pxToRem(16)};

        text-align: center;
        color: ${colors.dark};

        p {
          ${fonts.size2};
        }

        strong {
          ${fonts.size3};
          font-family: 'SFProText-Bold', sans-serif;
        }

        + .characters::before {
          position: absolute;
          left: 0;
          top: calc(50% - (${pxToRem(20)} / 2));

          height: ${pxToRem(20)};
          width: ${pxToRem(1)};

          content: '';
          background-color: rgba(255, 255, 255, 0.15);
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    #register-number {
      ${fonts.size4};
      color: ${colors.dark};
    }
  }

  ${media.greaterThan('1900px')`
    #image-wrapper {
      width: ${pxToRem(215)};
      height: ${pxToRem(215)};

      img,
      .image-border{
        top: calc(50% - (${pxToRem(168)} / 2));
        right: calc(50% - (${pxToRem(168)} / 2));
        
        width: ${pxToRem(168)};
        height: ${pxToRem(168)};
      }
    }

    #info-wrapper {
      margin-left: 2%;
    } 
  `}

  ${media.lessThan('1366px')`
    #image-wrapper {
      margin-left: ${pxToRem(-17)};
    }
  `}
`;

export const Main = styled.section`
  display: grid;
  grid-gap: ${pxToRem(32)};
  grid-template-columns: 2fr 9fr;
`;

export const Nav = styled.nav`
  margin-top: 5%;

  ul li {
    padding: ${pxToRem(12)};
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.15);

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: ${colors.dark};

      svg {
        display: none;
        margin-right: ${pxToRem(-4)};
        color: ${colors.dark};
      }

      &:hover,
      &.active {
        color: ${colors.eightGrey};
      }

      &.active svg {
        display: unset;
      }
    }
  }

  ${media.greaterThan('1900px')`
    margin-top: 8%;
  `}
`;

export const Content = styled.div`
  padding: ${pxToRem(16)};
  margin-top: ${pxToRem(-16)};
  border-radius: ${pxToRem(6)};

  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.3);

  > * {
    animation: 0.15s ${bounceFadeInAnimation};
  }
`;
