import styled from 'styled-components';
import { shade } from 'polished';

import { colors } from '../../theme';
import { pxToRem } from '../../functions';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: ${pxToRem(20)} ${pxToRem(40)};

  background: rgba(255, 255, 255, 0.86);
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  form {
    margin-top: ${pxToRem(21)};

    display: flex;
    align-items: center;
    justify-content: center;

    fieldset {
      position: relative;

      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        position: absolute;
        top: ${pxToRem(-14)};

        font-size: 14px;
        letter-spacing: -0.4;
        color: ${colors.eightGrey};
        font-family: 'SFProText-Regular', sans-serif;
      }

      input {
        padding: 0px 1rem;
        width: ${pxToRem(178)};
        height: ${pxToRem(40)};
        margin-top: ${pxToRem(4)};
        border-radius: ${pxToRem(4)};
        background-color: ${colors.white};
        border: 1px solid rgba(178, 178, 178, 0.5);

        ::placeholder {
          color: rgba(0, 0, 0, 0.25);
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    > button {
      border: none;
      width: ${pxToRem(131)};
      height: ${pxToRem(40)};
      border-radius: 1.25rem;
      margin: 0 ${pxToRem(73)} 0 2rem;

      font-size: 0.75rem;
      color: ${colors.white};
      background-color: ${colors.lightGreen};
      font-family: 'SFProText-Semibold', 'sans-serif';
      transition: background 0.4s;

      :hover {
        background: ${shade(0.2, colors.lightGreen)};
      }
    }

    > h2 {
      font-size: 24px;
      color: ${colors.eightGrey};
      font-family: 'SFProText-Regular', sans-serif;

      strong {
        font-family: 'SFProText-Bold', sans-serif;
      }
    }
  }
`;

export const Content = styled.div`
  height: 85%;

  table {
    width: 100%;
    height: 95%;
    margin-top: 1rem;
    border-collapse: collapse;

    tr {
      padding: 0 ${pxToRem(16)};
      margin-right: ${pxToRem(20)};

      display: grid;
      grid-template-columns: 1.2fr 2fr;

      :nth-child(odd) {
        background-color: rgba(40, 49, 49, 0.06);
      }

      :hover {
        background-color: rgba(40, 49, 49, 0.15);
      }
    }

    h2 {
      margin-top: 1rem;

      font-size: 24px;
      font-family: 'SFProText-Regular', sans-serif;
    }

    td,
    th {
      padding: 8px 0;

      text-align: left;
    }
  }
`;
