import styled, { keyframes } from 'styled-components';
import { Form as AntForm } from 'antd';
import media from 'styled-media-query';
import { AiOutlineLoading } from 'react-icons/ai';

import { pxToRem } from '../../../functions';
import { colors, fonts } from '../../../theme';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const Container = styled.section`
  height: 100%;
  overflow: hidden;

  h1 {
    font-size: 1.5rem;
    font-family: 'SFProText-Bold', sans-serif;
  }

  table {
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
    border-collapse: collapse;

    tbody {
      padding-bottom: ${pxToRem(40)};

      display: grid;
      grid-template-columns: 1fr;

      ${media.lessThan('1024px')`
        padding-bottom: ${pxToRem(74)};
      `}
    }

    tr {
      padding: 0 ${pxToRem(30)} 0 ${pxToRem(8)};

      display: grid;
      grid-template-columns: 1.2fr 2fr;

      :nth-child(odd) {
        background-color: rgba(40, 49, 49, 0.06);
      }

      :nth-child(even) {
        background-color: rgba(40, 49, 49, 0.1);
      }

      :hover {
        background-color: rgba(40, 49, 49, 0.15);
      }

      td {
        position: relative;

        a {
          color: rgba(0, 0, 0, 0.65);
        }

        a,
        button {
          left: -20px;
          position: absolute;

          border: none;

          display: flex;
          align-items: center;
          justify-content: center;

          transition: color 0.4s;
          background: transparent;

          ${media.lessThan('1024px')`
            left: auto;
            right: -24px;

            width: 20px;
            height: 20px;
            border-radius: ${pxToRem(3)};

            background: ${colors.darkGreen};

            svg {
              color: #fff;
            }
          `}
        }

        span {
          /* padding-right: ${pxToRem(6)}; */

          + span::before {
            width: 15px;
            /* left: ${pxToRem(-6)}; */

            content: '; ';
            position: relative;
          }

          ${media.greaterThan('1024px')`
            margin-right: ${pxToRem(16)};

            + span::before {
              content: "-";
              position: relative;
              left: ${pxToRem(-8)};
            } 
          `}
        }

        a,
        svg {
          :hover {
            color: ${colors.blue};

            ${media.lessThan('1024px')`
              color: ${colors.white};
            `}
          }
        }
      }

      :nth-child(odd) {
        background-color: rgba(40, 49, 49, 0.06);
      }

      :hover {
        background-color: rgba(40, 49, 49, 0.15);
      }
    }

    h2 {
      margin-top: 1rem;

      font-size: 24px;
      font-family: 'SFProText-Regular', sans-serif;
    }

    td,
    th {
      padding: 8px 0;

      text-align: left;
    }
  }
`;

export const ModalContent = styled.div`
  height: ${({ specialStyle }) => (specialStyle ? '100%' : 'auto')};
  padding: ${({ specialStyle }) =>
    specialStyle ? '0 0 0 39px' : '20px 20px 0'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ specialStyle }) =>
    specialStyle ? 'flex-start' : 'center'};

  > * {
    color: ${colors.eightGrey};
  }

  label {
    margin-top: ${pxToRem(20)};
  }

  h1,
  h3 {
    font-family: 'SFRounded-Regular', sans-serif;
  }

  h1 {
    margin-top: ${pxToRem(7)};

    ${fonts.size7}
    line-height: 1.21;
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
    margin-top: ${pxToRem(16)};

    ${fonts.size7}
    line-height: 1.21;

    font-family: 'SFRounded-Bold', sans-serif;
  }

  h3 {
    margin-bottom: 0;
    margin-top: ${pxToRem(4)};

    ${fonts.size4};
    line-height: 1.19;
  }

  div[aria-label='animation'] {
    margin-top: ${pxToRem(-14)} !important;
  }
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .form-item1 {
    margin-top: ${pxToRem(30)};

    ${media.greaterThan('1600px')`
      label {
        margin-bottom: 0;
      }
    `}

    ${media.lessThan('1024px')`
      margin-top: ${pxToRem(10)};

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > label + label {
          margin-top: ${pxToRem(10)};
        }
      }
    `}
  }

  .form-item2 {
    margin-top: ${pxToRem(20)};
  }

  .form-item3 {
    margin-top: ${pxToRem(50)};

    ${media.lessThan('1024px')`
      margin-top: ${pxToRem(20)};

      > label {
        margin-right: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
          padding-right: 0;
        }
      }
    `}
  }

  .group-name-inputs {
    width: 100%;
    margin-top: ${pxToRem(30)};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${media.lessThan('1024px')`
      flex-direction: column;
      align-items: flex-start;

      > div + div {
        margin-top: ${pxToRem(8)};
      }
    `}
  }

  .affix-group {
    width: 100%;
    margin-top: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > div + div {
      margin-left: ${pxToRem(16)};
    }

    ${media.lessThan('1024px')`
      align-items: flex-end;

      > div {
          margin-top: ${pxToRem(16)};

        > div {
          display: flex;
          align-items: center;
          flex-direction: row;
          
        }
      }
    `}
  }

  .affix-radio {
    align-self: flex-end;
  }

  .coverage-date {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.lessThan('1024px')`
      > div {
        margin-top: ${pxToRem(8)};
      }
    `}
  }

  .inspection-date {
    margin-top: ${pxToRem(30)};

    ${media.greaterThan('1600px')`
      label {
        margin-bottom: 0;
      }
    `}
  }

  .embrio-transfer-radio-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .embryo-transfer-type {
    margin-top: ${pxToRem(30)};

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > div {
      margin-left: 75px;

      flex-direction: column;

      > label + label {
        margin-top: ${pxToRem(10)};
      }
    }

    ${media.lessThan('1024px')`
      margin-top: ${pxToRem(10)};
    `}
  }

  .receiving-mare {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    ${media.lessThan('1024px')`
      flex-direction: column;
      align-items: flex-start;
    `}
  }

  .place-birth {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .text-area {
    resize: none;
  }

  ${media.greaterThan('1900px')`  
    *,
    label {
      ${fonts.size5}
    }

    label {
      margin-bottom: ${pxToRem(6)};
    }

    .horizontal {
      label {
        margin-bottom: 0;
      }
    }

    .ant-switch {
      width: ${pxToRem(60)};
      height: ${pxToRem(30)};

      :after {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
      }
    }
  `}
`;

export const CircleLoading = styled(AiOutlineLoading)`
  align-self: center;

  font-size: 20px;
  color: ${colors.lightGreen};
  animation: ${rotation} 1s infinite;
`;

export const TdAffiliation = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -20px;

  & .external-link {
    position: static;
    padding: 0;
    margin-right: 4px;
  }

  & .link-divisor {
    margin-right: 0;
    padding: 0;
    text-align: center;
  }
`;
