import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { Form } from 'antd';

import {
  Modal,
  Loader,
  Button,
  FormItem,
  Checkbox,
  DatePicker,
  ExclamationSvg,
} from '../../../components';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import successAnimated from '../../../theme/assets/animations/success.json';

import { Container, ModalContent, ModalFooter } from './styles';

const Death = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [deathDate, setDeathDate] = useState('');
  const [dateString, setDateString] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [dateToSubmit, setDateToSubmit] = useState('');
  const [animalIsDead, setAnimalIsDead] = useState('');
  const [deathProtocol, setDeathProtocol] = useState('');
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [confirmButtonIsEnable, setConfirmButtonIsEnable] = useState(false);

  const switchCalendar = () => setCalendarIsOpen(!calendarIsOpen);

  const switchModal = () => setModalVisibility(!modalVisibility);

  const handleConfirm = async () => {
    try {
      const { data } = await api.post('animal/statusobito', {
        id_animal: id,
        comunicar_obito: 1,
        comunicar_obito_data: dateToSubmit,
      });

      if (data.success) {
        setConfirmed(true);
        setDeathDate(data.data.ano);
        setDeathProtocol(data.data.num_protocolo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    async function fetchStatusObito() {
      try {
        const { data } = await api.get('animal/perfil', {
          params: {
            id_animal: id,
          },
        });

        if (data.success) {
          if (data.data[0].status_obito === 1) {
            setAnimalIsDead(true);
            setDeathDate(data.data[0].data_obito);
            setDeathProtocol(data.data[0].protocolo_obito);
          } else {
            setAnimalIsDead(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    fetchStatusObito();
  }, [id]);

  useEffect(() => {
    const listenDateChange = () => {
      if (dateString?.match(/[0-9]/g)?.length === 8) {
        setConfirmButtonIsEnable(true);
      } else {
        setConfirmButtonIsEnable(false);
      }
    };

    listenDateChange();
  }, [dateString]);

  const handleDate = (value, date, dateString) => {
    let dateToInsert = '';

    if (value) {
      dateToInsert = value;
    }

    if (date && dateString) {
      dateToInsert = dateString;
    }

    setDateString(dateToInsert);
  };

  const handleSubmit = () => {
    const day = dateString.split('/')[0];
    const month = dateString.split('/')[1];
    const year = dateString.split('/')[2];

    setDateToSubmit(`${year}-${month}-${day}`);
    switchModal();
  };

  if (loading) {
    return <Loader light />;
  } else {
    return (
      <Container>
        {animalIsDead ? (
          <h1 id="dead-animal">
            {deathDate && <span>Óbito comunicado em {deathDate}</span>}
            <br />
            {deathProtocol && <strong>Protocolo {deathProtocol}</strong>}
          </h1>
        ) : (
          <>
            <h1>
              Comunicação de <strong>ÓBITO</strong> do Animal
            </h1>

            <p>
              Deseja comunicar o óbito deste Animal? <br />
              Para realizar essa ação insira a data do óbito.
            </p>

            <Form
              className="date-picker-button-wrapper"
              onFinish={handleSubmit}
            >
              <FormItem name="death-date">
                <DatePicker
                  light
                  required={!confirmButtonIsEnable}
                  name="death-date"
                  onChange={(date, dateString) =>
                    handleDate(null, date, dateString)
                  }
                  onInputChange={(event) => handleDate(event)}
                  open={calendarIsOpen}
                  onOpenChange={switchCalendar}
                  value={dateString}
                />
              </FormItem>

              <Button
                type="submit"
                width={112}
                height={32}
                color={colors.lightGreen}
                disabled={!confirmButtonIsEnable}
                fontLight
                bgDark
              >
                Comunicar
              </Button>
            </Form>
          </>
        )}

        <Modal
          scroll={false}
          isVisible={modalVisibility}
          toggleVisibility={switchModal}
          width={478}
          height={300}
          centered
          footer={
            <ModalFooter>
              {confirmed ? (
                <Button
                  fontLight
                  color={colors.lightGreen}
                  onClick={() => {
                    setAnimalIsDead(true);
                    switchModal();
                  }}
                >
                  Concluir
                </Button>
              ) : (
                <>
                  <Button onClick={switchModal} color={colors.buttonGray}>
                    Cancelar
                  </Button>
                  <Button
                    fontLight
                    color={colors.lightGreen}
                    disabled={!checked}
                    onClick={handleConfirm}
                  >
                    Confirmar
                  </Button>
                </>
              )}
            </ModalFooter>
          }
        >
          <ModalContent>
            {confirmed ? (
              <>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: successAnimated,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width={120}
                  height={105}
                />

                <h1>Sucesso!</h1>

                {deathProtocol && <h2>Protocolo {deathProtocol}</h2>}

                {deathDate && <h3>Óbito comunicado em: {deathDate}</h3>}
              </>
            ) : (
              <>
                <ExclamationSvg />

                <h1>
                  Você tem certeza que deseja comunicar o óbito deste animal?
                </h1>

                <Checkbox
                  checkColor={colors.buttonGray}
                  onChange={(checkedValue) => setChecked(checkedValue)}
                  checked={checked}
                >
                  Sim, tenho certeza!
                </Checkbox>
              </>
            )}
          </ModalContent>
        </Modal>
      </Container>
    );
  }
};

export default Death;
