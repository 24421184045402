import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Route,
  Switch,
  NavLink,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import { api } from '../../services/api';

import { colors } from '../../theme';

import Information from './Information';
import Photo from './Photo';
import StudBook from './StudBook';
import Sports from './Sports';
import Castration from './Castration';
import Death from './Death';

import { Breadcrumb, PageTitle, Button, PreLoader } from '../../components';

import { ReactComponent as ImageOutline } from '../../theme/assets/images/effect-image-outline.svg';
import defaultProfilePicture from '../../theme/assets/images/silhueta.svg';

import { Container, Header, AnimalInfo, Main, Nav, Content } from './styles';

const AnimalProfile = () => {
  const history = useHistory();
  const { id } = useParams();
  const urlPublicPage = `https://servicosonline.abqm.com.br/animais/detalhes/${id}`;

  const [dataAnimal, setDataAnimal] = useState({});
  const [preLoading, setPreLoading] = useState(false);

  const navItems = [
    { path: 'informacoes', title: 'Informações' },
    { path: 'foto', title: 'Foto' },
    { path: 'stud-book', title: 'Stud Book' },
    { path: 'competicoes', title: 'Competições' },
    { path: 'castracao', title: 'Castração' },
    { path: 'obito', title: 'Óbito' },
  ];

  useEffect(() => {
    setPreLoading(true);

    const fetchAnimalProfile = async () => {
      try {
        const { data } = await api.get('animal/perfil', {
          params: {
            id_animal: id,
          },
        });

        if (data.success) {
          setDataAnimal(data.data[0]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setPreLoading(false);
      }
    };

    id !== 'undefined' ? fetchAnimalProfile() : history.push('/error');
  }, [id, history]);

  if (preLoading) {
    return <PreLoader profileAnimal />;
  } else {
    return (
      <>
        <Helmet>
          <title>Perfil do Animal - Central do Quartista</title>
        </Helmet>

        <Breadcrumb
          pages={[
            { path: '/relacao-de-animais', title: 'Relação de Animais' },
            { title: 'Perfil do Animal' },
          ]}
        />

        <Redirect
          strict
          from="/perfil-do-animal/"
          to={`/perfil-do-animal/${id}/informacoes`}
        />

        <Container>
          <Header>
            <PageTitle fontLight>Perfil do Animal</PageTitle>

            <div id="header-buttons">
              <Button
                fontLight
                width={90}
                height={32}
                color="rgba(178, 178, 178, 0.5)"
                onClick={() => history.push('/relacao-de-animais')}
              >
                Voltar
              </Button>

              <Button fontLight width={130} height={32} color={colors.blue}>
                <a
                  target="_blank"
                  href={urlPublicPage}
                  rel="noopener noreferrer"
                >
                  Página pública
                </a>
              </Button>
            </div>
          </Header>

          <AnimalInfo>
            <div id="image-wrapper">
              <ImageOutline />

              {dataAnimal?.foto ? (
                <img src={dataAnimal.foto} alt="Foto" />
              ) : (
                <img src={defaultProfilePicture} alt="Foto" />
              )}

              <div className="image-border" />
            </div>

            <div id="info-wrapper">
              <div id="info-top">
                <h1 title={dataAnimal?.nome_animal}>
                  {dataAnimal?.nome_animal}
                </h1>

                <div className="characters">
                  <p>Sexo</p>
                  <strong>{dataAnimal?.sexo}</strong>
                </div>

                <div className="characters">
                  <p>Pelagem</p>
                  <strong>{dataAnimal?.pelagem}</strong>
                </div>

                <div className="characters">
                  <p>Sangue</p>
                  <strong>{dataAnimal?.sangue}</strong>
                </div>

                <div className="characters">
                  <p>Nascimento</p>
                  <strong>
                    {dataAnimal?.data_nascimento &&
                      dataAnimal.data_nascimento.substr(0, 10)}
                  </strong>
                </div>

                <div className="characters">
                  <p>Situação</p>
                  <strong>{dataAnimal?.situacao}</strong>
                </div>
              </div>

              <h3 id="register-number">{dataAnimal?.registro_animal}</h3>
            </div>
          </AnimalInfo>

          <Main>
            <Nav>
              <ul>
                {navItems.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.path}>
                      {item.title} <AiFillCaretRight size={10} />
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Nav>

            <Content>
              <Switch>
                <Route
                  path={`/perfil-do-animal/${id}/informacoes`}
                  exact
                  render={() => <Information data={dataAnimal} />}
                />
                <Route
                  path={`/perfil-do-animal/${id}/foto`}
                  exact
                  render={() => <Photo id={id} data={dataAnimal} />}
                />
                <Route
                  path={`/perfil-do-animal/${id}/stud-book`}
                  exact
                  render={() => <StudBook id={id} />}
                />
                <Route
                  path={`/perfil-do-animal/${id}/competicoes`}
                  exact
                  render={() => <Sports id={id} />}
                />
                <Route
                  path={`/perfil-do-animal/${id}/castracao`}
                  exact
                  render={() => <Castration id={id} />}
                />
                <Route
                  path={`/perfil-do-animal/${id}/obito`}
                  exact
                  render={() => <Death id={id} />}
                />
              </Switch>
            </Content>
          </Main>
        </Container>
      </>
    );
  }
};

export default AnimalProfile;
