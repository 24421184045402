import styled from 'styled-components';
import media from 'styled-media-query';

import { colors, fonts } from '../../../theme';
import { pxToRem } from '../../../functions';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 4% 1fr;

  /* Changing grid min width and height to prevent overflow. Default is "auto" */
  min-width: 0;
  min-height: 0;

  padding: ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)} 0;

  main {
    height: 100%;
    overflow: ${({ overflowHidden }) => (overflowHidden ? '' : 'hidden')};
    border-radius: 0 0 ${pxToRem(6)} ${pxToRem(6)};

    backdrop-filter: blur(28.8px);
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const TopBar = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: ${pxToRem(6)} ${pxToRem(6)} 0 0;
  padding: 0 ${pxToRem(40)};

  backdrop-filter: blur(13.7px);
  background-color: rgba(0, 0, 0, 0.1);
`;

export const Breadcrumb = styled.div`
  ul,
  li,
  a,
  p {
    display: flex;
    align-items: center;

    color: ${colors.white};
  }

  .home-icon {
    ${fonts.size3};
  }

  .page,
  .chevrons-icon {
    ${fonts.size1};

    opacity: 0.5;

    &:last-child {
      opacity: 1;
    }
  }

  .chevrons-icon {
    margin: 0 ${pxToRem(10)};
  }

  ${media.greaterThan('1600px')`
    .home-icon {
      ${fonts.size4};
    }

    .page,
    .chevrons-icon {
      ${fonts.size2};
    }
  `}

  ${media.greaterThan('1900px')`
    .home-icon {
      ${fonts.size5};
    }

    .page,
    .chevrons-icon {
      ${fonts.size3};
    }
  `}
`;

export const RightIcons = styled.div`
  display: flex;

  .star-icon,
  .bell-icon {
    margin-left: ${pxToRem(8)};

    ${fonts.size3}
  }

  .star-icon {
    color: ${colors.darkYellow};
  }

  .bell-icon {
    color: ${colors.white};
  }

  ${media.greaterThan('1600px')`
    .star-icon,
    .bell-icon {
      ${fonts.size4}
    }
  `}

  ${media.greaterThan('1900px')`
    .star-icon,
    .bell-icon {
      ${fonts.size5}
    }
  `}
`;
