import styled from 'styled-components';

import { pxToRem } from '../../../functions';
import { fonts, colors } from '../../../theme';

const imageBorderColors = {
  review: {
    outside: 'rgba(255, 203, 0, 0.19)',
    inside: 'rgba(255, 203, 0, 0.7)',
  },
  approved: {
    outside: 'rgba(135, 197, 0, 0.2)',
    inside: 'rgba(135, 197, 0, 0.7)',
  },
  disapproved: {
    outside: 'rgba(208, 79, 95, 0.2)',
    inside: 'rgba(208, 79, 95, 0.7)',
  },
};

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 100%;
  height: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .image-wrapper {
    width: ${pxToRem(241)};
    height: ${pxToRem(241)};

    border: solid 11px
      ${({ photoSituation }) => imageBorderColors[photoSituation].outside};

    &,
    img {
      border-radius: 50%;
    }

    img {
      width: 100%;
      object-fit: cover;
      min-height: ${pxToRem(219)};

      border: solid 5px
        ${({ photoSituation }) => imageBorderColors[photoSituation].inside};
    }
  }

  p {
    margin-top: ${pxToRem(20)};

    ${fonts.size2};
    color: rgba(255, 255, 255, 0.75);
  }

  &::after {
    position: absolute;
    top: 10%;
    right: 0;

    width: ${pxToRem(1)};
    height: 85%;

    content: '';
    background-color: rgba(255, 255, 255, 0.15);
  }

  #crop-confirmed-buttons {
    display: flex;
    align-items: center;

    margin-top: 2.235%;

    button {
      padding: unset;

      & + button {
        margin-left: ${pxToRem(16)};
      }
    }
  }
`;

export const ImageCrop = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .upload-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${pxToRem(130)};
    height: ${pxToRem(32)};
    border-radius: ${pxToRem(17)};

    ${fonts.size2};
    cursor: pointer;
    letter-spacing: -0.4px;
    color: ${colors.white};
    transition: all 0.3s ease;
    background-color: ${colors.blue};
    font-family: 'SFProText-Semibold', sans-serif;

    :hover {
      filter: brightness(1.05);
    }

    :active {
      filter: brightness(1.1);
    }

    input[type='file'] {
      display: none;
    }
  }

  .cropper-wrapper {
    position: relative;

    width: ${pxToRem(315)};
    height: ${pxToRem(252)};
  }

  .cropper-controls {
    display: flex;
    align-items: flex-end;

    margin-top: ${pxToRem(16)};

    width: ${pxToRem(315)};

    .slider-wrapper {
      flex: 4;

      margin-right: ${pxToRem(21)};

      label {
        ${fonts.size2};
        color: rgba(255, 255, 255, 0.75);
      }

      .ant-slider {
        margin-left: 0;
      }
    }

    button {
      flex: 1;

      margin-bottom: ${pxToRem(10)};
    }
  }
`;

export const CloseButton = styled.button`
  z-index: 1;
  position: absolute;
  top: ${pxToRem(-3)};
  right: ${pxToRem(5)};

  border: none;
  ${fonts.size7};
  cursor: pointer;

  filter: opacity(0.5);
  color: ${colors.white};
  transition: all 0.15s ease;
  background-color: transparent;

  :hover {
    filter: opacity(1);
  }
`;
