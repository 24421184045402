import React, { useState, useCallback, useEffect } from 'react';
import Lottie from 'react-lottie';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import successAnimated from '../../../theme/assets/animations/success.json';
import {
  Modal,
  Button,
  Loader,
  Checkbox,
  ExclamationSvg,
} from '../../../components';

import { Container, ModalContent, ModalFooter } from './styles';

const Castration = ({ id }) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [castrationDate, setCastrationDate] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);
  const [castrationProtocol, setCastrationProtocol] = useState('');
  const [animalIsCastrated, setAnimalIsCastrated] = useState(false);
  const [animalData, setAnimalData] = useState({});

  useEffect(() => {
    setLoading(true);

    const fetchCastratedStatus = async () => {
      try {
        const { data } = await api.get('animal/perfil', {
          params: {
            id_animal: id,
          },
        });

        if (data.success) {

          setAnimalData(data.data[0]);

          if (data.data[0].status_castrado === 1) {
            setAnimalIsCastrated(true);
            setCastrationDate(data.data[0].data_castracao);
            setCastrationProtocol(data.data[0].protocolo_castracao);
          } else {
            setAnimalIsCastrated(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCastratedStatus();
  }, [id]);

  const switchModal = useCallback(() => {
    setModalVisibility(!modalVisibility);
    setConfirmed(false);
    setChecked(false);
  }, [modalVisibility]);

  const handleConfirm = () => {
    setConfirmed(true);
  };

  const setCastratedAnimal = useCallback(async () => {
    try {
      const { data } = await api.post('animal/statuscastrado', {
        id_animal: id,
        comunicar_castracao: 1,
      });

      setAnimalIsCastrated(true);
      setCastrationDate(data.data.data_castracao);
      setCastrationProtocol(data.data.num_protocolo);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    if (confirmed && checked) {
      setCastratedAnimal();
    }
  }, [checked, confirmed, setCastratedAnimal]);

  const inProcessStep = useCallback(() => (
    animalData?.sexo === 'Macho' ? (
    <>
      <h1>
        Comunicação de <strong>CASTRAÇÃO</strong> do Animal
      </h1>

      <p>
        Deseja comunicar a castração deste Animal? <br />
        Esta ação não pode ser desfeita.
      </p>

      <Button
        width={112}
        height={32}
        color={colors.lightGreen}
        fontLight
        onClick={switchModal}
      >
        Comunicar
      </Button>
    </>
    ) : (
      <>
      <h1>
        Animais do sexo <strong>Fêmea</strong> não podem ser castrados!
      </h1>
    </>
    )
  ), [animalData, switchModal])

  if (loading) {
    return <Loader light />;
  } else {
    return (
      <Container>
        {animalIsCastrated ? (
          <h1 id="castrated-animal">
            {castrationDate && <span>Animal castrado em {castrationDate}</span>}{' '}
            <br />
            {castrationProtocol && (
              <strong>Protocolo {castrationProtocol}</strong>
            )}
          </h1>
        ) : (
          <>
            {inProcessStep()}
          </>
        )}

        <Modal
          scroll={false}
          isVisible={modalVisibility}
          toggleVisibility={switchModal}
          width={478}
          height={300}
          centered
          footer={
            <ModalFooter>
              {confirmed ? (
                <Button
                  fontLight
                  color={colors.lightGreen}
                  onClick={() => {
                    setAnimalIsCastrated(true);
                    switchModal();
                  }}
                >
                  Concluir
                </Button>
              ) : (
                <>
                  <Button onClick={switchModal} color={colors.buttonGray}>
                    Cancelar
                  </Button>
                  <Button
                    fontLight
                    color={colors.lightGreen}
                    disabled={!checked}
                    onClick={handleConfirm}
                  >
                    Confirmar
                  </Button>
                </>
              )}
            </ModalFooter>
          }
        >
          <ModalContent>
            {confirmed ? (
              <>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: successAnimated,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width={120}
                  height={105}
                />

                <h1>Sucesso!</h1>

                {castrationProtocol && <h2>Protocolo {castrationProtocol}</h2>}

                {castrationDate && <h3>Ano: {castrationDate}</h3>}
              </>
            ) : (
              <>
                <ExclamationSvg />

                <h1>
                  Você tem certeza que deseja comunicar a castração deste
                  animal?
                </h1>

                <Checkbox
                  checkColor={colors.buttonGray}
                  onChange={(checkedValue) => setChecked(checkedValue)}
                  checked={checked}
                >
                  Sim, tenho certeza!
                </Checkbox>
              </>
            )}
          </ModalContent>
        </Modal>
      </Container>
    );
  }
};

export default Castration;
