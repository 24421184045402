import React from 'react';

import { Container, Circle, MailIcon } from './styles';

const Mail = ({ className }) => (
  <Container
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="89"
    viewBox="0 0 89 89"
    className={className}
  >
    <g id="prefix__Ícone" transform="translate(-1054 -208)">
      <g
        id="prefix__Elipse_74"
        fill="none"
        stroke="rgba(59,145,177,0.2)"
        strokeWidth="4"
        data-name="Elipse 74"
        transform="translate(1054 208)"
      >
        <Circle cx="44.5" cy="44.5" r="44.5" stroke="none" />
        <Circle cx="44.5" cy="44.5" r="42.5" />
      </g>
      <g id="prefix__mail" transform="translate(1078 234)">
        <MailIcon
          id="prefix__Caminho_226"
          d="M5.646 4h29.172a3.657 3.657 0 0 1 3.646 3.646v21.879a3.657 3.657 0 0 1-3.646 3.646H5.646A3.657 3.657 0 0 1 2 29.525V7.646A3.657 3.657 0 0 1 5.646 4z"
          className="prefix__cls-2"
          data-name="Caminho 226"
        />
        <MailIcon
          id="prefix__Caminho_227"
          d="M38.465 6L20.232 18.763 2 6"
          className="prefix__cls-2"
          data-name="Caminho 227"
          transform="translate(0 1.646)"
        />
      </g>
    </g>
  </Container>
);

export default Mail;
