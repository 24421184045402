import styled, { css } from 'styled-components';
import { DatePicker as AntDatePicker } from 'antd';

import SearchButton from '../SearchButton';

export const Container = styled.div`
  display: flex;
  align-items: center;

  div {
    align-items: inherit;
  }

  input {
    transform: translateX(-2px);
  }
`;

export const StyledDatePicker = styled(AntDatePicker)`
  padding: 0;
  margin: 0;

  .ant-picker-suffix {
    transform: translateY(2px);
  }

  .ant-picker-input {
    display: none;
  }
`;

export const CalendarButton = styled(SearchButton)`
  margin-top: 0 !important;
  align-self: center !important;

  ${({ light }) =>
    light &&
    css`
      color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75);
    `}
`;


export const CleanButton =  styled(SearchButton)`
  margin-top: 0 !important;
  align-self: center !important;

  svg {
    color: #e96161;
  }
`;