import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { FiChevronsRight } from 'react-icons/fi';

import { useBreadcrumb } from '../../../context/Breadcrumb';
import { useIgnoreMainframe } from '../../../context/IgnoreMainframe';

import { Container, TopBar, Breadcrumb } from './styles';

const Content = ({ children }) => {
  const { breadcrumb } = useBreadcrumb();
  const { ignoreMainframe } = useIgnoreMainframe();

  return (
    <Container overflowHidden={ignoreMainframe}>
      <TopBar>
        <Breadcrumb>
          <ul>
            <li>
              <Link to="/">
                <FaHome className="home-icon" />
              </Link>
            </li>
            {breadcrumb?.map((item, index, array) => (
              <li key={index} className="page">
                <FiChevronsRight className="chevrons-icon" />

                {array.length === index + 1 ? (
                  <p>{item.title}</p>
                ) : (
                  <Link to={item.path}>{item.title}</Link>
                )}
              </li>
            ))}
          </ul>
        </Breadcrumb>

        {/* <RightIcons>
          <FaStar className="star-icon" />

          <FaBell className="bell-icon" />
        </RightIcons> */}
      </TopBar>

      <main>{children}</main>
    </Container>
  );
};

export default Content;
