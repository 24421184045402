import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

export default (isoDate, type) => {
  let formatted;
  const parsed = parseISO(isoDate);

  switch (type) {
    case 'newsletter':
      formatted = {
        day: format(parsed, 'dd', { locale: pt }),
        month: format(parsed, 'MMM', { locale: pt }),
      };
      break;
    default:
      formatted = format(parsed, "dd 'de' MMMM 'de' yyyy", { locale: pt });
  }

  return formatted;
};
