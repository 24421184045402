/**
 * convert normal text to slug
 * @param {string} value text to transform
 */
export default (value) => {
  if (!value || typeof value !== 'string') {
    throw new Error('Need string value to convert');
  }

  return value
    .normalize('NFD')
    .replace(/[!@#$%^&*()_\-ªº+=[\]{};':"\\|,.<>/?]/g, '') // remove special characters
    .replace(/\s/g, '-') // remove space
    .replace(/[\u0300-\u036f]/g, '') // remove accentuations
    .toLowerCase();
};
