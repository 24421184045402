import styled from 'styled-components';
import media from 'styled-media-query';

import { colors, fonts } from '../../theme';

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

export const Label = styled.label`
  position: absolute;

  ${fonts.size8}
  font-weight: 100;
  color: ${colors.white};
  font-family: 'SFRounded-Thin', sans-serif;

  ${media.greaterThan('1600px')`
    ${fonts.size9};
  `}

  ${media.greaterThan('1900px')`
    ${fonts.size11};
  `}
`;
