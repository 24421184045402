import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { Steps as AntSteps, Drawer as AntDrawer, List } from 'antd';
import { fadeInUp, fadeIn } from 'react-animations';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';
import { Button } from '../../components';

const bounceFadeInAnimation = keyframes`${fadeIn}`;
const bounceFadeInUpAnimation = keyframes`${fadeInUp}`;

export const Container = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(40)};

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.86);

  form {
    margin-top: ${pxToRem(57)};

    .button-submit {
      position: absolute;
      bottom: ${pxToRem(21)};
      right: ${pxToRem(40)};
    }
  }

  ${media.lessThan('1300px')`
    padding: ${pxToRem(10)};
  `}
`;

export const Steps = styled(AntSteps)`
  .first-step .ant-steps-item-content {
    transform: translateY(-24px);
  }

  .second-step .ant-steps-item-content {
    transform: translateY(-36px);
    margin-bottom: ${pxToRem(45)};
  }

  .third-step .ant-steps-item-content {
    transform: translateY(-65px);
  }

  .ant-steps-item-icon {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;

    font-family: 'SFProText-Regular', sans-serif;

    cursor: default;
  }

  .ant-steps-item-finish .ant-steps-item-icon,
  .ant-steps-item-active .ant-steps-item-icon {
    background: ${colors.lightGreen};
    border-color: ${colors.lightGreen};
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: rgba(255, 255, 255, 0.86);
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${colors.white};
  }

  .ant-steps-item-tail::after {
    width: 2.2px !important;
  }

  .ant-steps-item-finish .ant-steps-item-tail::after {
    background-color: ${colors.lightGreen};
  }

  .ant-steps-item-active .ant-steps-item-tail::after,
  .ant-steps-item-wait .ant-steps-item-tail::after {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .ant-steps-item-description {
    padding-bottom: ${pxToRem(20)};
  }

  ${media.lessThan('1300px')`
   .second-step .ant-steps-item-content {
      transform: translateY(-45px);      
    }

    .third-step .ant-steps-item-content {
      transform: translateY(-80px);
    }
  `}
`;

export const StepWrapper = styled.div`
  margin-left: 10px;

  min-height: ${pxToRem(120)};

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector:active,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
    border-color: ${({ disabled }) => !disabled && colors.blue};
  }

  .mare-name {
    margin-right: 16px;
    margin-bottom: 32px;
  }

  .separetor {
    display: flex;
    align-items: center;

    > p {
      margin-left: ${pxToRem(16)};

      color: rgba(0, 0, 0, 0.25);
    }
  }

  > div {
    display: flex;    
    align-items: flex-start;
    flex-wrap: wrap;

    :not(.coverage-date) {
      div + div:not(.ant-form-item-explain) {
        margin-left: ${pxToRem(40)};
        justify-content: flex-end;  
      }

      + div {
        margin-top: ${pxToRem(32)};
      }
    }
  }

  .coverage-date {
    > div + div {
      margin-left: ${pxToRem(40)};
    }
  }

  #mare-identification {
    .common-mare-input-group {
      margin-left: 0;
      margin-right: 20px;
    }
  }


  ${media.lessThan('1300px')`
    margin-left: 0;

    > .coverage-type + .coverage-type {
      margin-top: ${pxToRem(25)};
    }    
  `}

  ${media.greaterThan('huge')`
    min-height: ${pxToRem(140)};
  `}

  ${media.greaterThan('1600px')`
    min-height: ${pxToRem(160)};
  `}

  ${media.greaterThan('1900px')`
    min-height: ${pxToRem(180)};
  `}
`;

export const AnimalInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${pxToRem(40)};

  height: ${pxToRem(40)};

  color: ${colors.eightGrey};
  animation: 0.3s ${bounceFadeInUpAnimation};

  span {
    ${fonts.size4};
    line-height: 1.14;
    letter-spacing: -0.4px;
  }

  strong {
    line-height: 1.19;
    letter-spacing: -0.8px;
    font-family: 'SFProText-Bold', sans-serif;
  }

  div + div {
    margin-left: ${pxToRem(24)};
  }
`;

export const MareTypeWrapper = styled.div`
  #common-mare-type-wrapper {
    display: flex;
    align-items: center;

    > div {
      display: flex;

      label {
        margin-top: 0;
      }
    }

    margin-left: ${pxToRem(40)};

    border: 1px solid #d1d1d1;
    padding: 1px 10px;
    border-radius: 30px;
    background-color: #e8e8e8;
  }
`;

export const MareOwnerDocumentTypeContainer = styled.div`
  margin-left: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  button {
    margin-left: -12px;
  }
`;

export const MareTypeButton = styled.button`
  line-height: 0.9;
  letter-spacing: -0.4px;
  width: ${pxToRem(130)};
  height: ${pxToRem(32)};
  padding: ${pxToRem(8)} ${pxToRem(14)};
  border-radius: ${({ right }) => (right ? '4px 0 0 4px' : '0 4px 4px 0')};

  border: 1px solid ${({ active }) => (active ? colors.blue : colors.white)};
  color: ${({ active }) => (active ? colors.white : colors.grey)};
  background-color: ${({ active }) => (active ? colors.blue : colors.white)};

  transition: all 0.3s ease;

  :disabled {
    filter: opacity(0.5);
    cursor: not-allowed;
  }

  :not([disabled]) {
    :hover {
      border: 1px solid ${colors.blue};
      color: ${({ active }) => (active ? colors.white : colors.blue)};
    }
  }
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-list-items li {
    padding: ${pxToRem(8)} ${pxToRem(24)} !important;
  }
`;

export const ModalContent = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: ${pxToRem(10)} 0;

  > * {
    color: ${colors.eightGrey};
  }

  h2 {
    line-height: 1.21;
    margin-bottom: ${pxToRem(16)};

    font-size: ${pxToRem(24)};
    font-family: 'SFRounded-Regular', sans-serif;
  }

  h1 {
    margin-bottom: ${pxToRem(4)};

    line-height: 1.21;
    font-size: ${pxToRem(24)};
    font-family: 'SFRounded-Bold', sans-serif;
  }

  h3 {
    & + div {
      margin-top: 1.4rem;
    }
  }

  p {
    line-height: 1.19;
    text-align: center;
    font-size: ${pxToRem(16)};
    font-family: 'SFRounded-Regular', sans-serif;
  }

  div[aria-label='animation'] {
    transform: translateX(2px);
  }

  .mail-icon {
    margin-top: ${pxToRem(14)};
    margin-bottom: ${pxToRem(8)};
  }

  .send-email-label,
  .send-email-form {
    animation: 0.05s ${bounceFadeInAnimation};
  }

  .send-email-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    margin-top: ${pxToRem(12)};

    button {
      margin-top: ${pxToRem(26)};
    }
  }

  .h1-certificate {
    margin: ${pxToRem(20)} 0;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  > a:last-child {
    margin-left: ${pxToRem(16)};
  }

  > a + button {
    margin-left: ${pxToRem(16)};
  }
`;

export const ModalButton = styled(Button)`
  ${fonts.size2};
  font-family: 'SFProText-Semibold', sans-serif;
  padding: ${pxToRem(8)} ${pxToRem(16)};
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: ${pxToRem(12)};
  right: ${pxToRem(12)};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};

  cursor: pointer;
  font-weight: bold;
  color: ${colors.danger};
  background-color: transparent;
  border: 1px solid ${colors.danger};

  :hover {
    filter: brightness(1.1);
  }
`;

export const ListItem = styled(List.Item)`
  position: relative;
  transition: all 0.15s ease;

  && {
    border-bottom: 0;

    ::before {
      position: absolute;
      bottom: 0;
      right: 5%;

      width: 90%;
      height: 1px;
      margin: 0 auto;
      text-align: center;

      content: '';
      background-color: #f5f5f5;
    }
  }

  :hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

export const CoverageDataContainer = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0.5rem 0.5rem 0.5rem;
  word-wrap: normal;
  margin-top: 0 !important;

  p {
    margin: 0.1rem 0 0.7rem 0.5rem;
    text-align: start;
  }
  
  .proprietario-egua {
    margin-top: 1.2rem;
  }
`;

export const CoverageDataRowGroup =  styled.div`
  display: flex;
  flex-direction: row;

  p {
    & + p {
      margin-left: 1.5rem;
    }
  }
`;
