import React, { useEffect, useState, useCallback } from 'react';
import { Route, Switch, useParams, useLocation } from 'react-router-dom';
import swal from 'sweetalert';

import { api } from '../../services/api';
import { setToken as setTokenService } from '../../services/auth';
import { errorHandler } from '../../functions';

import {
  BlockModal,
  CancellationModal,
} from '../EditingRegistrationOrders/components';

import Receiver from '../../pages/EditingRegistrationOrders/Receiver';
import DNAControl from '../../pages/EditingRegistrationOrders/DNAControl';
import Pendencies from '../../pages/EditingRegistrationOrders/Pendencies';
import RecordDetails from '../../pages/EditingRegistrationOrders/RecordDetails';
import ActivityRecord from '../../pages/EditingRegistrationOrders/ActivityRecord';
import PropertyHistory from '../../pages/EditingRegistrationOrders/PropertyHistory';
import ProcessDocument from '../../pages/EditingRegistrationOrders/ProcessDocument';

import { colors } from '../../theme';

import {
  Container,
  Content,
  MobileButton,
  ButtonsWrapper,
  MobileButtons,
} from './styles';

const Mobile = () => {
  const { id } = useParams();
  const location = useLocation();

  const [page, setPage] = useState('');
  const [token, setToken] = useState('');
  const [idPerson, setIdPerson] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isRegistred, setIsRegistred] = useState(false);
  const [isRequester, setIsRequester] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isInspector, setIsInspector] = useState(false);
  const [recordDetails, setRecordDetails] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [modalBlockOpen, setModalBlockOpen] = useState(false);
  const [requestIsBlocked, setRequestIsBlocked] = useState(false);
  const [modalCancellationOpen, setModalCancellationOpen] = useState(false);
  const [processoPre, setProcessoPre] = useState([]);

  const fetchRecordDetails = useCallback(async () => {
    try {
      setInitialLoading(true);

      const { data } = await api.get('pedidoregistro/detalhespedidoregistro', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_preregistro: id,
        },
      });

      if (data.success) {
        setRecordDetails(data.data[0]);
      }
    } catch (error) {
      console.log("fetchRecordDetails", error);
      errorHandler(error);
    } finally {
      setInitialLoading(false);
    }
  }, [id, token]);

  const fetchProcessoPre = useCallback(async () => {
    try {
      const { data } = await api.get(
        'pedidoregistro/GeralRelatorioProcessoPre',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            num_pedido_registro: id,
          },
        }
      );

      if (data.success) {
        setProcessoPre(data.data[0]);
      }
    } catch (error) {
      console.log("fetchProcessoPre", error);

      errorHandler(error);
    }
  }, [id, token]);

  const fetchUserData = useCallback(async () => {
    try {
      const { data } = await api.get('pessoa/dadospessoa', {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (data.success) {
        setIdPerson(data.data[0].id_pessoa);
      }
    } catch (error) {
      console.log("fetchUserData", error);
      
      errorHandler(error);
    }
  }, [token]);

  const toggleBlockModal = useCallback(() => {
    setModalBlockOpen(!modalBlockOpen);
  }, [modalBlockOpen]);

  const toggleModalCancellation = useCallback(() => {
    setModalCancellationOpen(!modalCancellationOpen);
  }, [modalCancellationOpen]);

  const toggleModal = useCallback(() => {
    setModalIsOpen(!modalIsOpen);
  }, [modalIsOpen]);

  useEffect(() => {
    if (recordDetails?.status_lancamento === 'Cancelado') {
      setIsCanceled(true);
    }
  }, [recordDetails]);

  useEffect(() => {
    if (recordDetails?.status_atividade === 1) {
      setRequestIsBlocked(true);
    }
  }, [recordDetails]);

  useEffect(() => {
    const completeURL = location.pathname;
    if (completeURL) {
      const string = completeURL.split('/');
      setPage(string[3]);
      setToken(string[4]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (token) {
      setTokenService(token);
    }
  }, [token])

  useEffect(() => {
    if(token) {
      fetchUserData();
      fetchRecordDetails();
      fetchProcessoPre();
    }
    
  }, [fetchRecordDetails, fetchUserData, fetchProcessoPre, token]);

  useEffect(() => {
    if (processoPre?.registro_abqm) {
      setIsRegistred(true);
    }
  }, [processoPre]);

  useEffect(() => {

    setIsInspector(idPerson === recordDetails?.id_inspetor);
    setIsRequester(idPerson === recordDetails?.id_solicitante);

    if(
      Object.keys(recordDetails).length > 0 && 
      idPerson && 
      idPerson !== recordDetails?.id_inspetor && 
      idPerson !== recordDetails?.id_solicitante){
      swal(
        'Atenção', 
        `Para ter acesso as informações desse Pedido de Registro é necessário ser o Solictante ou Inspetor do mesmo!`, 
        'warning');
    }
  }, [idPerson, recordDetails]);

  return (
    <>
      {idPerson && (isInspector || isRequester) ? (
        <>
          <Container>
            <Content>
              <Switch>
                <Route
                  exact
                  path={`/mobile/${id}/detalhe-do-registro/${token}`}
                  render={() => (
                    <RecordDetails
                      token={token}
                      preRegister={id}
                      isCanceled={isCanceled}
                      isRegistred={isRegistred}
                      isInspector={isInspector}
                      apiCall={fetchRecordDetails}
                      detailsRequest={recordDetails}
                      initialLoading={initialLoading}
                    />
                  )}
                />

                <Route
                  exact
                  path={`/mobile/${id}/documentos/${token}`}
                  render={() => (
                    <ProcessDocument
                      token={token}
                      preRegister={id}
                      modalIsOpen={modalIsOpen}
                      toggleModal={toggleModal}
                    />
                  )}
                />

                <Route
                  exact
                  path={`/mobile/${id}/controle-de-dna/${token}`}
                  render={() => <DNAControl preRegister={id} token={token} />}
                />

                <Route
                  exact
                  path={`/mobile/${id}/historico-de-propriedade/${token}`}
                  render={() => <PropertyHistory preRegister={id} token={token} />}
                />

                <Route
                  exact
                  path={`/mobile/${id}/pendencias/${token}`}
                  render={() => <Pendencies preRegister={id} token={token} />}
                />

                <Route
                  exact
                  path={`/mobile/${id}/receptora/${token}`}
                  render={() => <Receiver preRegister={id} token={token} />}
                />

                <Route
                  exact
                  path={`/mobile/${id}/registro-de-atividades/${token}`}
                  render={() => <ActivityRecord preRegister={id} token={token} />}
                />
              </Switch>
            </Content>

            {page === 'detalhe-do-registro' && (
              <ButtonsWrapper>
                {isInspector && !isCanceled && !isRegistred && (
                  <MobileButtons
                    backgroundColor={colors.blue}
                    onClick={toggleBlockModal}
                  >
                    {requestIsBlocked ? 'Desbloquear' : 'Bloquear'}
                  </MobileButtons>
                )}

                {!isRegistred && !isCanceled &&(
                  <MobileButtons
                    backgroundColor={colors.red}
                    onClick={toggleModalCancellation}
                  >
                    Cancelar
                  </MobileButtons>
                )}
              </ButtonsWrapper>
            )}

            {page === 'documentos' && isInspector === true && !isRegistred &&(
              <MobileButton onClick={toggleModal}>Novo parecer</MobileButton>
            )}

            <CancellationModal
              preRegister={id}
              setIsCanceled={setIsCanceled}
              detailsRequest={recordDetails}
              modalIsOpen={modalCancellationOpen}
              toggleModal={toggleModalCancellation}
            />

            <BlockModal
              preRegister={id}
              modalIsOpen={modalBlockOpen}
              isBlocked={requestIsBlocked}
              detailsRequest={recordDetails}
              toggleModal={toggleBlockModal}
              toggleBlock={setRequestIsBlocked}
            />
          </Container>
        </>
      ) : (
        <Container />
      )}
    </>
  );
};

export default Mobile;
