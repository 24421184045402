import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  position: relative;

  padding: ${pxToRem(12)} ${pxToRem(16)} ${pxToRem(6)};
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1) inset;

  color: ${colors.white};

  p {
    ${fonts.size2};
  }

  .more-icon {
    position: absolute;
    right: ${pxToRem(8)};
    top: 20%;

    ${fonts.size6};

    cursor: pointer;
  }

  ${media.greaterThan('1600px')`
    padding: ${pxToRem(14)} ${pxToRem(18)} ${pxToRem(7)};

    p {
      ${fonts.size3};
    }

    .more-icon {
      right: ${pxToRem(10)};

      ${fonts.size7};
    }
  `}

  ${media.greaterThan('1900px')`
    padding: ${pxToRem(16)} ${pxToRem(22)} ${pxToRem(8)};

    p {
      ${fonts.size4};
    }

    .more-icon {
      right: ${pxToRem(12)};

      ${fonts.size8};
    }
  `}
`;
