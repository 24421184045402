import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useBreadcrumb } from '../../context/Breadcrumb';

function Breadcrumb({ pages }) {
  const { setBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb(pages);
  }, [setBreadcrumb, pages]);

  return <div />;
}

Breadcrumb.propTypes = {
  pages: PropTypes.array,
};

Breadcrumb.defaultProps = {
  pages: [],
};

export default Breadcrumb;
