import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
// import { FiMoreVertical } from 'react-icons/fi';
import { Helmet } from 'react-helmet';

import {
  Table,
  Radio,
  FormItem,
  PreLoader,
  Breadcrumb,
  TableHeader,
} from '../../components';
import { colors } from '../../theme';
import { sort } from '../../functions';
import { api } from '../../services/api';

import {
  Name,
  Form,
  Number,
  Animal,
  Drawer,
  Product,
  Registry,
  Container,
  DrawerButton,
  // ActionButton,
} from './styles';

function RegistrationRequest() {
  const initialParams = {
    situacao: '',
    tamanho_pag: 50,
  };

  const [loading, setLoading] = useState(true);
  const [preLoader, setPreLoader] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [activeSort, setActiveSort] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [listingTotal, setListingTotal] = useState(0);
  const [searchedRecords, setSearchedRecords] = useState([]);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [registrationRequests, setRegistrationRequests] = useState([]);

  const [page, setPage] = useState(1);
  const [filtered, setFiltered] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [allRegistrationRequests, setAllRegistrationRequests] = useState([]);

  const pageTitle = 'Pedidos de Registro';

  const switchDrawer = () => setDrawerVisibility(!drawerVisibility);

  const fetchRegistrationRequest = useCallback(async (params, page) => {
    setLoading(true);

    try {
      const { data } = await api.get('studbook/situacaopedidosregistro', {
        params: { ...params, pagina: page },
      });

      if (data.success) {
        setListingTotal(data.total);
        return data.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setPreLoader(false);
    }
  }, []);

  useEffect(() => {
    setPreLoader(true);
    const initialTableData = async () => {
      const fetchedData = await fetchRegistrationRequest(params, 1);

      setRegistrationRequests(fetchedData);
      setAllRegistrationRequests(fetchedData);
    };

    initialTableData();
  }, [fetchRegistrationRequest, params]);

  const handleSort = (objectKey, method) => {
    const [activeSort, sortedData] = sort(
      registrationRequests,
      objectKey,
      method
    );

    setActiveSort(activeSort);
    setRegistrationRequests(sortedData);
  };

  const fetchMoreData = async () => {
    const fetchedData = await fetchRegistrationRequest(params, page + 1);

    setPage(page + 1);

    setRegistrationRequests([...registrationRequests, ...fetchedData]);
    setAllRegistrationRequests([...allRegistrationRequests, ...fetchedData]);
  };

  const handleSubmitFilter = () => {
    setPage(1);
    setRegistrationRequests([]);

    const filteredParams = {
      pagina: page,
      tamanho_pag: 50,
      situacao: filterData?.situation ? filterData?.situation : '',
    };

    setFiltered(true);
    setParams(filteredParams);
    switchDrawer();
  };

  useEffect(() => {
    const listenSearch = async () => {
      if (searchValue === '') {
        setRegistrationRequests(allRegistrationRequests);
      } else {
        setRegistrationRequests(searchedRecords);
      }
    };

    listenSearch();
  }, [searchValue, allRegistrationRequests, searchedRecords]);

  const handleSearch = (value) => {
    setSearchValue(value);
    
    const searchedItems = allRegistrationRequests.filter((record) => {
      const father = record?.pai?.toLowerCase();
      const mother = record?.mae?.toLowerCase();
      const product = record?.produto?.toLowerCase();
      const preRegister = record?.num_preregistro?.toLowerCase();
      const valueToSearch = value?.toLowerCase();

      return (
        father.indexOf(valueToSearch) !== -1    ||
        mother.indexOf(valueToSearch) !== -1    ||
        product.indexOf(valueToSearch) !== -1   ||
        preRegister.indexOf(valueToSearch) !== -1
      );
    });

    setSearchedRecords(searchedItems);
  };

  const clearFilter = () => {
    setPage(1);
    setFiltered(false);
    setParams(initialParams);
    setRegistrationRequests([]);
  };

  const tableColumns = [
    { key: 'numero', title: 'NÚMERO', size: 1 },
    { key: 'produto', title: 'PRODUTO', sort: true, size: 3 },
    { key: 'pai', title: 'PAI', sort: true, size: 3 },
    { key: 'mae', title: 'MÃE', sort: true, size: 3 },
    { key: 'cobertura', title: 'COBERTURA', sort: true, size: 1.3 },
    { key: 'entrada', title: 'ENTRADA', sort: true, size: 1.3 },
    { key: 'envio', title: 'ENVIO', size: 1.3 },
    // { key: 'actions', title: '', size: 0.5 },
  ];

  const tableData = registrationRequests.map((registrationRequest) => ({
    number: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        <Number>{registrationRequest.num_preregistro}</Number>
      </Link>
    ),

    product: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        <Product>
          <div className="product-wrapper">
            <Animal>
              <Name>
                {registrationRequest.produto
                  ? registrationRequest.produto
                  : '--'}
              </Name>
            </Animal>
          </div>
        </Product>
      </Link>
    ),

    father: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        <td>
          <Name>{registrationRequest?.pai}</Name>
          <Registry>{registrationRequest?.registro_pai}</Registry>
        </td>
      </Link>
    ),

    mother: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        <td>
          <Name>{registrationRequest?.mae}</Name>
          <Registry>{registrationRequest?.registro_mae}</Registry>
        </td>
      </Link>
    ),
    coverage: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.cobertura}
      </Link>
    ),

    entry: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.entrada}
      </Link>
    ),

    envio: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.envio ? registrationRequest.envio : '--'}
      </Link>
    ),

    // actions: (
    //  <ActionButton>
    //    <button>
    //      <FiMoreVertical />
    //    </button>
    //  </ActionButton>
    // ),
  }));

  const radioSituationOptions = [
    { value: '', label: 'Todos' },
    { value: '1', label: 'Registrados' },
    { value: '0', label: 'Não registrados' },
  ];

  return (
    <Container>
      <Breadcrumb
        pages={[
          {
            title: pageTitle,
            path: '/pedido-de-registro',
          },
        ]}
      />

      <Helmet>
        <title>{pageTitle} - Central do Quartista</title>
      </Helmet>

      <TableHeader
        title={pageTitle}
        filtered={filtered}
        count={listingTotal}
        onSearch={handleSearch}
        clearFilter={clearFilter}
        dataLength={registrationRequests}
        onFilterButtonClick={switchDrawer}
      />

      {preLoader ? (
        <PreLoader light />
      ) : (
        <Table
          light
          loading={loading}
          count={listingTotal}
          dataSource={tableData}
          columns={tableColumns}
          handleSort={handleSort}
          activeSort={activeSort}
          fetchMoreData={fetchMoreData}
          dataLength={allRegistrationRequests}
        />
      )}

      <Drawer
        title="Filtro"
        placement="right"
        onClose={switchDrawer}
        destroyOnClose
        visible={drawerVisibility}
      >
        <Form onFinish={handleSubmitFilter}>
          <FormItem label="Situação" name="situation">
            <Radio
              name="situation"
              size={window.innerWidth >= 1900 ? 'large' : 'middle'}
              options={radioSituationOptions}
              value={filterData.situation}
              vertical
              onChange={(value) =>
                setFilterData({ ...filterData, situation: value })
              }
            />
          </FormItem>

          <DrawerButton type="submit" color={colors.lightGreen} fontLight>
            Aplicar
          </DrawerButton>
        </Form>
      </Drawer>
    </Container>
  );
}

export default RegistrationRequest;
