import React, { useState, useCallback } from 'react';
import swal from 'sweetalert';

import { colors } from '../../../../theme';
import { api } from '../../../../services/api';
import { errorHandler } from '../../../../functions';
import {
  Modal,
  Button,
  Checkbox,
  ExclamationSvg,
} from '../../../../components';

import { CircleLoading, ModalContent } from './styles';

export default function ConfirmChangeRequesterModal({
  token,
  apiCall,
  switchModal,
  newRequester,
  detailsRequest,
  confirmationModal,
}) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleConfirm = useCallback(async () => {
    const dataToUpdate = {
      id_solicitante: detailsRequest.id_solicitante,
      id_novo_solicitante: newRequester.id_pessoa,
      num_preregistro: detailsRequest.num_preregistro,
      id_lancamento: detailsRequest.id_lancamento,
      nome_solicitante: detailsRequest.nome_solicitante,
      nome_novo_solicitante: newRequester.nome_pessoa,
      nome_produto: detailsRequest.nome_produto,
      nome_pai: detailsRequest.NOME_PAI,
      nome_mae: detailsRequest.NOME_MAE,
    };

    setLoading(true);

    try {
      const { data } = await api.put(
        'PedidoRegistro/AtualizarSolicitante',
        dataToUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        swal('Sucesso!', 'Alteração de solicitante realizada.', 'success');
        apiCall();
      }
      else {
        errorHandler(data);
      }
    } catch (error) {
      console.log("handleConfirm", error);

      errorHandler(error);
    } finally {
      setLoading(false);
      switchModal();
    }
  }, [token, apiCall, switchModal, newRequester, detailsRequest]);

  return (
    <Modal
      centered
      height={360}
      width={525}
      scroll={false}
      circleLoading={loading}
      isVisible={confirmationModal}
      title={'Alterar solicitante'}
      toggleVisibility={switchModal}
      footer={
        <>
          <Button color={colors.red} fontLight onClick={() => switchModal()}>
            Cancelar
          </Button>
          <Button
            fontLight
            color={colors.lightGreen}
            disabled={!checked}
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </>
      }
    >
      {loading ? (
        <CircleLoading />
      ) : (
        <ModalContent>
          <ExclamationSvg />

          <h1>
            Você tem certeza que deseja alterar o solicitante desse pedido para
            <br />
            <strong>{newRequester.nome_pessoa}</strong>?
          </h1>

          <Checkbox
            checked={checked}
            checkColor={colors.buttonGray}
            onChange={(checkedValue) => setChecked(checkedValue)}
          >
            Sim, tenho certeza!
          </Checkbox>
        </ModalContent>
      )}
    </Modal>
  );
}
