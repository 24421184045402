import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  border-radius: ${pxToRem(6)};

  grid-area: m4;

  background: rgba(255, 255, 255, 0.1);

  ${media.greaterThan('1600px')`
    border-radius: ${pxToRem(7)};
  `}

  ${media.greaterThan('1900px')`
    border-radius: ${pxToRem(8)};
  `}
`;

export const Content = styled.div`
  height: calc(100% - ${pxToRem(33)});

  padding: ${pxToRem(16)};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  p {
    margin-top: ${pxToRem(7)};

    text-align: center;

    ${fonts.size1};
    color: ${colors.white};
  }

  ${media.greaterThan('1600px')`
    height: calc(100% - ${pxToRem(38)});
    
    padding: ${pxToRem(18)};

    p {
      ${fonts.size2};
    }
  `}

  ${media.greaterThan('1900px')`
    height: calc(100% - ${pxToRem(44)});

    padding: ${pxToRem(22)};

    p {
      ${fonts.size3};
    }
  `}
`;

export const ChartWrapper = styled.div`
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.1);
`;
