import React from 'react';

import CircularProgressbar from '../CircularProgressbar';

import { colors } from '../../theme';
import { Container, PendenciesGraphic, PendenciesInfo } from './styles';

export default function Pendencies({ title, description, value, graphValue }) {
  if (isNaN(graphValue)) {
    graphValue = 0;
  }

  return (
    <Container>
      <PendenciesGraphic>
        <CircularProgressbar value={graphValue} color={colors.white} />
      </PendenciesGraphic>

      <PendenciesInfo>
        <h1>{title}</h1>
        <p>{description}</p>
        <span>{value}</span>
      </PendenciesInfo>
    </Container>
  );
}
