import styled from 'styled-components';

import { Button as OriginalButton } from '../.';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  margin-bottom: ${pxToRem(20)};

  color: ${colors.dark};

  > .title-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions-wrapper {
      display: flex;

      form {
        label {
          ${fonts.size2};
          margin-right: ${pxToRem(8)};
        }

        input {
          width: ${pxToRem(152)};
          height: ${pxToRem(32)};
          padding: 0 ${pxToRem(16)};

          border-radius: ${pxToRem(17)};
          background-color: rgba(255, 255, 255, 0.4);
          border: 1px solid rgba(178, 178, 178, 0.5);

          ::-webkit-input-placeholder {
            font-size: 0.75rem;
          }

          ::-moz-placeholder {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  > p {
    line-height: 1.29;
    font-family: 'SFRounded-Thin', sans-serif;
  }
`;

export const Button = styled(OriginalButton)`
  height: ${pxToRem(32)};
  width: ${({ width }) => (width ? width : '90px')};

  padding: 0;
  margin-left: ${pxToRem(16)};

  color: ${({ fontLight }) => !fontLight && 'rgba(40, 49, 49, 0.75)'};

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
