import styled, { css } from 'styled-components';

import { Modal } from 'antd';

import { pxToRem } from '../../functions';

export const Container = styled(Modal)`
  width: ${({ width }) => (width ? pxToRem(width) : pxToRem(520))};

  h2 {
    color: #a6a7a9;
    font-size: ${pxToRem(14)};
    font-family: 'SFRounded-Medium';
  }

  transform: translateY(${({ offset }) => offset && pxToRem(36)});

  .ant-modal-content {
    overflow: hidden;
    background-color: #f7f7f7;
    border-radius: ${pxToRem(8)};

  
    
  .ant-modal-header {
    margin: 0 ${pxToRem(12)};
    padding: 16px 0 8px;
    background-color: #f7f7f7;
  }

  .ant-modal-title {
    margin: 0 ${pxToRem(8)};

    color: #626364;
    font-size: ${pxToRem(25)};
    font-family: 'SFRounded-Medium', sans-serif;
  }

  .ant-modal-body {
    ${({ circleLoading }) =>
      circleLoading &&
      css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}

    padding: 0;
    margin: 0 ${pxToRem(20)};
    padding-top: ${pxToRem(6)};

    /* 73: footer height */
    height: ${({ height }) => (height ? pxToRem(height - 73) : pxToRem(300))};

    background-color: #f7f7f7;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    border: none;
    padding: ${pxToRem(16)};

    background-color: #f7f7f7;
  }
`;
