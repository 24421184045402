import styled from 'styled-components';
import { Form as AntForm, Drawer as AntDrawer } from 'antd';

import { Button } from '../../../components';

import { colors, fonts } from '../../../theme';
import media from 'styled-media-query';

import { pxToRem } from '../../../functions';

export const ButtonTab = styled(Button)`
  height: ${pxToRem(32)};
  width: '100px';

  padding: 0 20px;
  margin-left: ${pxToRem(16)};
  color: rgba(40,49,49,0.75);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Container = styled.section`
  width: 100%;
  height: 100%;

  padding: ${pxToRem(20)} ${pxToRem(40)};

  background-color: rgba(255, 255, 255, 0.86);

  a{
    :first-child{
      padding-left: 10px;
    }
  }

th{
  :first-child{
    p{
      margin-right: 35px;
    }
  }
}
`;

export const Product = styled.td`
  display: flex;
  flex-direction: row;

  div.product-wrapper {
    display: flex;
  }

  p {
    padding-left: 8px;
  }

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;

    object-fit: cover;

    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
`;

export const Animal = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.p`
  font-size: 11px;
  text-align: left;
  line-height: 1.18;
  white-space: nowrap;

  font-family: 'SFProText-Semibold', sans-serif;

  ${media.greaterThan('huge')`
    font-size: 12px;
  `}
`;

export const Registry = styled.p`
  margin-top: 2px;

  font-size: 9px;
  text-align: left;
  line-height: 1.22;
`;


export const RegistryCentered = styled.p`
  margin-top: 2px;

  font-size: 9px;
  text-align: center;
  line-height: 1.22;
  transform: translateX(-20%);
`;

export const Number = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButton = styled.td`
  align-items: center !important;
  flex-direction: row !important;
  justify-content: flex-end !important;

  padding-right: 5px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    border: 0;

    background: unset;

    svg {
      width: 24px;
      height: 24px;

      color: ${colors.GrayishGreen};
    }
  }
`;

export const OptionsButton = styled.button`
  text-align: center;

  background: none;
  border: 0;
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-content-wrapper {
    width: 25% !important;
  }

  .ant-drawer-body {
    padding: ${pxToRem(22)} ${pxToRem(26)};
    overflow: hidden;
  }

  ${media.greaterThan('1900px')`
    .ant-drawer-title {
      ${fonts.size6};
    }
  `}
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .form-item2 {
    margin-top: 10.92%;
  }

  .form-item3 {
    margin-top: 13.65%;
  }

  .form-item4 {
    margin-top: 12.96%;
  }

  .form-item5 {
    margin-top: 5.46%;
  }

  .form-item6 {
    margin-top: 15.01%;
  }

  ${media.greaterThan('1900px')`  
    *,
    label {
      ${fonts.size5}
    }

    label {
      margin-bottom: ${pxToRem(6)};
    }

    .horizontal {
      label {
        margin-bottom: 0;
      }
    }

    .ant-switch {
      width: ${pxToRem(60)};
      height: ${pxToRem(30)};

      :after {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
      }
    }
  `}
`;

export const PeriodWrapper = styled.div`
  display: flex;
  align-items: center !important;

  height: ${pxToRem(40)};

  .ant-select {
    margin-right: ${pxToRem(27)};
  }
`;

export const DrawerButton = styled(Button)`
  align-self: center;
  width: ${pxToRem(131)};
  margin-top: 10.57%;
`;
