import styled from 'styled-components';
import ReactIframe from 'react-iframe';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const Iframe = styled(ReactIframe).attrs({
  frameBorder: 0,
})`
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.86);

  /* &,
  * {
    ::-webkit-scrollbar {
      width: ${pxToRem(4)};
      margin-right: -20px;

      -webkit-appearance: none;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${pxToRem(5)};

      background-color: ${({ light }) => (light ? colors.dark : colors.white)};
    }

    .invisible-scrollbar::-webkit-scrollbar {
      display: none;
    }
  } */

  body,
  body * {
    background: transparent !important;
    background-color: transparent !important;
  }
`;
