import styled from 'styled-components';
import media from 'styled-media-query';
import { Menu } from 'antd';

import { colors, fonts } from '../../theme';
import { pxToRem, isBackdropFilterSupported } from '../../functions';

export const StyledMenu = styled(Menu)`
  backdrop-filter: blur(13.7px);
  background: ${isBackdropFilterSupported()
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(212, 212, 212, 0.9)'};
`;

export const StyledMenuItem = styled(Menu.Item)`
  a,
  a:hover {
    color: ${isBackdropFilterSupported() ? colors.white : colors.black};
  }

  :hover {
    background: ${isBackdropFilterSupported()
      ? 'rgba(255, 255, 255, 0.3)'
      : colors.white};
  }

  ${media.greaterThan('1600px')`
    line-height: ${pxToRem(25)} !important;
    height: ${pxToRem(37)} !important;

    a {
      ${fonts.size4};
    }
  `}

  ${media.greaterThan('1900px')`
    line-height: ${pxToRem(30)} !important;
    height: ${pxToRem(44)} !important;

    a {
      ${fonts.size5};
    }
  `}
`;
