import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Skeleton } from 'antd';

import { TextHeader, StyledCustomScrollBar, Button } from '../';
import { colors } from '../../theme';
import { useAuth } from '../../context/Auth';

import { api } from '../../services';

import {
  Container,
  Called,
  CustomModal,
  ModalContent,
  SkeletonContainer,
} from './styles';

const textHeaderMenu = [
  { path: '#!', title: 'Novo chamado' },
  { path: '#!', title: 'Listar todos os chamados' },
];

const ServiceAndCalled = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentCalled, setCurrentCalled] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userData } = useAuth();

  const switchModal = () => setIsVisible(!isVisible);

  const openCalledDetails = async (id) => {
    setLoading(false);

    switchModal();

    try {
      const { data } = await api.get('/dashboard/chamadosdetalhes', {
        params: {
          id_chamado: id,
        },
      });

      if (data.success) { 
        setCurrentCalled(data.data);
      }
    } catch (error) {
      console.error(error.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <TextHeader
          title="Atendimento e chamado"
          hasMenu
          menuItems={textHeaderMenu}
        />

        <Called>
          <StyledCustomScrollBar>
            {data.length > 0 &&
              data.map((data) => (
                <li onClick={() => openCalledDetails(data.chamado)}>
                  <div>
                    <p>Chamado:&nbsp;</p>
                    <strong className="white"># {data.chamado}</strong>
                    <span className="white">|</span> <p>{data.status}</p>
                  </div>

                  <p className="white">Assunto: {data.assunto}</p>

                  <div className="openAndClosed">
                    <p>Abertura: {data.abertura.substring(0, 10)}</p>

                    {data.fechamento ? (
                      <p>Encerrado: {data.fechamento ? data.fechamento.substring(0, 10) : ''}</p>
                    ) : (
                      data.interacao && (
                        <p>Interação: {data.interacao.substring(0, 10)}</p>
                      )
                    )}
                  </div>
                </li>
              ))}
          </StyledCustomScrollBar>
        </Called>
      </Container>

      <CustomModal
        width={900}
        height={500}
        isVisible={isVisible}
        toggleVisibility={switchModal}
        centered
        footer={
          <Button onClick={switchModal} color={colors.lightGreen} fontLight>
            OK
          </Button>
        }
      >
        <ModalContent>
          {loading ? (
            <SkeletonContainer>
              <header>
                <Skeleton
                  active
                  title={false}
                  paragraph={{ rows: 3, width: '100%' }}
                />
              </header>

              <Skeleton.Button
                active
                size="large"
                style={{ width: 868, margin: '32px 0 16px' }}
              />

              <main className="main-skeleton">
                <Skeleton active title={false} paragraph={{ rows: 4 }} />
              </main>
            </SkeletonContainer>
          ) : (
            <>
              <header>
                <ul>
                  <li>
                    Solicitante: <strong>{userData?.user?.nome_pessoa}</strong>
                  </li>
                  <li>
                    Área:{' '}
                    <strong>
                      {currentCalled.slice(-1)[0]?.nome_area_atendimento}
                    </strong>
                  </li>
                  <li>
                    Assunto:{' '}
                    <strong>{currentCalled.slice(-1)[0]?.nome_motivo}</strong>
                  </li>
                </ul>
              </header>

              <main>
                <ul>
                  {currentCalled.map((called) => (
                    <li key={called?.id_chamado_detalhe}>
                      <span className="contact-date">
                        <h4>{called?.s_nome_usuario}</h4>
                        <h4>{called.data_final && called.data_final.replace(' ', ' | ')}</h4>
                      </span>

                      <div className="description">
                        {ReactHtmlParser(called?.ds_descricao_solicitacao)}
                      </div>
                    </li>
                  ))}
                </ul>
              </main>
            </>
          )}
        </ModalContent>
      </CustomModal>
    </>
  );
};

export default ServiceAndCalled;
