import React, { useState, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { StyledCustomScrollBar } from '../../../components';

import { api } from '../../../services/api';
import { convertCurrency } from '../../../functions';
import { PageTitle, Loader } from '../../../components';

import { Container } from './styles';

export default function Receiver({ preRegister, token }) {
  const [receiver, setReceiver] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReceiver = useCallback(async () => {
    try {
      const { data } = await api.get('pedidoregistro/receptora', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_preregistro: preRegister,
        },
      });

      if (data.success) {
        setReceiver(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [preRegister, token]);

  useEffect(() => {
    fetchReceiver();
  }, [fetchReceiver]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {!isMobile && <PageTitle>Receptora</PageTitle>}

          <table className="receiver-table">
            <tr>
              <td>Doadora: </td>
              <td>{receiver[0]?.doadora}</td>
            </tr>

            <tr>
              <td>Temporada: </td>
              <td>{`${receiver[0]?.dt_inicio_temporada} - ${receiver[0]?.dt_fim_temporada}`}</td>
            </tr>
          </table>

          <div className="table-info">
            <StyledCustomScrollBar>
              {receiver.map((item, index) => (
                <table key={index} className="receiver-table">
                  <tr>
                    <td>Receptora:</td>
                    <td>{item.nome_receptora ? item.nome_receptora : '--'}</td>
                  </tr>

                  <tr>
                    <td>Animal:</td>
                    <td>{item.nome_animal ? item.nome_animal : '--'}</td>
                  </tr>

                  <tr>
                    <td>Registro:</td>
                    <td>
                      {item.num_preregistro ? item.num_preregistro : '--'}
                    </td>
                  </tr>

                  <tr>
                    <td>Sequência:</td>
                    <td>{item.SEQUENCIA != null ? item.SEQUENCIA : '--'}</td>
                  </tr>

                  <tr>
                    <td>Valor:</td>
                    <td>{item.vlr_te ? convertCurrency(item.vlr_te) : 'R$ 0,00'}</td>
                  </tr>
                </table>
              ))}
            </StyledCustomScrollBar>
          </div>
        </Container>
      )}
    </>
  );
}
