import styled, { css } from 'styled-components';
import MaskedInput from 'antd-mask-input';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const Container = styled(MaskedInput)`
  margin-right: -2px;

  height: ${({ size }) => {
    if (size === 'small') return pxToRem(24);
    else if (size === 'middle') return pxToRem(32);
    else if (size === 'large') return 'auto';
  }};
  width: ${({ width }) => (width ? pxToRem(width) : pxToRem(320))};
  border-radius: 4px !important;

  transition: border-color 0.3s ease;

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 11px;
    `};

  :hover,
  :active,
  :focus {
    border-color: ${colors.blue};
  }

  :disabled::placeholder {
    color: rgba(0, 0, 0, 0.1);
  }

  ${({ invalid }) =>
    invalid &&
    css`
      &,
      :hover,
      :active,
      :focus {
        border-color: ${colors.red};
      }
    `};
`;
