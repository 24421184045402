import React, { useState, useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { api } from '../../../services/api';
import { PageTitle, Table, Loader } from '../../../components';

import { Container } from './styles';

export default function PropertyHistory({ preRegister, token }) {
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState([]);

  const fetchProperty = useCallback(async () => {
    try {
      const { data } = await api.get('pedidoregistro/historicopropriedade', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_preregistro: preRegister,
        },
      });

      if (data.success) {
        setProperty(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [preRegister, token]);

  useEffect(() => {
    fetchProperty();
  }, [fetchProperty]);

  const stallionColumn = [
    { key: 'garanhao', title: 'Propriedade do Garanhão', size: 1 },
  ];

  const stallion = [
    { title: 'Nascimento:', name: property?.proprietario_garanhao_nascimento },
    { title: 'Cobertura:', name: property?.proprietario_garanhao_cobertura },
    { title: 'Atual', name: property?.proprietario_garanhao_atual },
  ];

  const breedingColumn = [
    { key: 'reprodutora', title: 'Propriedade da Reprodutora', size: 1 },
  ];

  const breeding = [
    { title: 'Nascimento:', name: property?.proprietario_egua_nascimento },
    { title: 'Cobertura:', name: property?.proprietario_egua_cobertura },
    { title: 'Atual', name: property?.proprietario_egua_atual },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {!isMobile && <PageTitle>Histórico de Propriedade</PageTitle>}

          <Table light columns={stallionColumn} dataSource={stallion} />
          <Table light columns={breedingColumn} dataSource={breeding} />
        </Container>
      )}
    </>
  );
}
