import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

import {
  Table,
  TableHeader,
} from '../../../components';
import { sort } from '../../../functions';

import {
  Name,
  Registry,
  ButtonTab,
} from './styles';

function RealizedInspections({
  loading, 
  filtered,
  totalRealizeds,
  allInspectCarried,
  realizedInpections,
  setRealizedInpections,
  fetchMoreDataRealizeds,
  switchDrawer,
  pageTitle,
  handleSearch,
  clearFilter,
  handleSwitchScreen
}) {

  
  //realizeds
  const [activeSortRealizeds, setActiveSortRealizeds] = useState('');
  
  const handleSort = (objectKey, method) => {
    const [activeSort, sortedData] = sort(realizedInpections, objectKey, method);

    setActiveSortRealizeds(activeSort);
    setRealizedInpections(sortedData);
  };

  const RealizedTableColumns = [
    { key: 'pedido', title: 'PEDIDO', sort: false, size: 1 },
    { key: 'nome_animal', title: 'PRODUTO', sort: true, size: 2 },
    { key: 'pai', title: 'PAI', sort: true, size: 2 },
    { key: 'mae', title: 'MÃE', sort: true, size: 2 },
    { key: 'dt_cobertura', title: 'COBERTURA', sort: true, size: 1 },
    { key: 'dt_inclusao', title: 'INCLUSÃO', sort: true, size: 1 },
    { key: 'dt_inspecao', title: 'INSPEÇÃO', sort: true, size: 1 },
    { key: 'dt_nascimento', title: 'NASCIMENTO', sort: true, size: 1 },
    { key: 'registro', title: 'REGISTRO', sort: true, size: 1 },
  ];

  const RealizedTableData = realizedInpections.map((registrationRequest) => ({
    pedido: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.num_preregistro}
      </Link>
    ),

    produto: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.nome_animal}
      </Link>
    ),

    pai: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        <td>
          <Name>{registrationRequest?.pai}</Name>
          <Registry>{registrationRequest?.registro_pai}</Registry>
        </td>
      </Link>
    ),

    mae: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        <td>
          <Name>{registrationRequest?.mae}</Name>
          <Registry>{registrationRequest?.registro_mae}</Registry>
        </td>
      </Link>
    ),
    cobertura: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.dt_cobertura.substr(0, 10)}
      </Link>
    ),

    inclusao: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.dt_inclusao.substr(0, 10)}
      </Link>
    ),

    inspecao: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.dt_inspecao.substr(0, 10)}
      </Link>
    ),

    nascimento: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.dt_nascimento.substr(0, 10)
          ? registrationRequest.dt_nascimento.substr(0, 10)
          : '--'}
      </Link>
    ),

    registro: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.registro
          ? registrationRequest.registro.substr(0, 10)
          : '--'}
      </Link>
    ),
  }));

  const SwitchTabButtons = useCallback(() => (
    <ButtonTab 
      color="rgba(178, 178, 178, 0.47)" 
      onClick={handleSwitchScreen}
    >
      <>
        Com erros
        &nbsp;
        <FiChevronRight size={18}/>
      </>
    </ButtonTab>
  ), [handleSwitchScreen]);

  return (
    <>
      <TableHeader
        title={pageTitle}
        filtered={filtered}
        count={totalRealizeds}
        onSearch={handleSearch}
        clearFilter={clearFilter}
        dataLength={realizedInpections}
        onFilterButtonClick={switchDrawer}
        extraButtons={SwitchTabButtons()}
      />

      <Table
        light
        loading={loading}
        count={totalRealizeds}
        dataSource={RealizedTableData}
        columns={RealizedTableColumns}
        handleSort={handleSort}
        activeSort={activeSortRealizeds}
        fetchMoreData={fetchMoreDataRealizeds}
        dataLength={allInspectCarried}
      />
    </>
)}

export default RealizedInspections;
