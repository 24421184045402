import React from 'react';
import PropTypes from 'prop-types';

import { colorsChart } from '../../theme';

import { TextHeader, DonutChart } from '../../components';

import {
  Graph,
  Container,
  Characteristics,
  CharacteristicsText,
  GraphAndCharacteristics,
} from './styles';

const MyAnimals = ({ data, doubleChart }) => {
  const allCategories = data.slice(1);
  const dataFormated = allCategories.map((item, index) => {
    return { id: index, ...item, color: colorsChart[index] };
  });

  return (
    <Container>
      <TextHeader title="Meus animais" />
      {data[0].qtd > 0 ? (
        <GraphAndCharacteristics>
          <Graph doubleChart={doubleChart}>
            <DonutChart data={dataFormated} />
          </Graph>
          <Characteristics doubleChart={doubleChart}>
            {dataFormated.map((item) => (
              <li key={item.id}>
                <CharacteristicsText color={item.color}>
                  {item.titulo}
                </CharacteristicsText>
                <span>{item.qtd}</span>
              </li>
            ))}
          </Characteristics>
        </GraphAndCharacteristics>
      ) : (
        <h4 className="no-animals">Você não possui animais</h4>
      )}
    </Container>
  );
};

MyAnimals.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      qtd: PropTypes.number,
      titulo: PropTypes.string,
    })
  ).isRequired,
  doubleChart: PropTypes.bool,
};

export default MyAnimals;
