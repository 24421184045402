import React from 'react';

import starIcon from '../../theme/assets/images/icons/star.svg';

import { Container } from './styles';

export default function JoinUsButton() {
  return (
    <Container>
      <div>
        <span>
          <img src={starIcon} alt="StarIcon" />
        </span>

        <p>Associe-se!</p>
      </div>
    </Container>
  );
}
