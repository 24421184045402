import React from 'react';
import { FiFileText } from 'react-icons/fi';

import { Container } from './styles';

export default function MobileList({ documents }) {
  return (
    <Container>
      {documents.map((document, index) => (
        <table key={index}>
          <tr>
            <td>Descrição:</td>
            <td>{`${document?.tipo_lancamento}`}</td>
          </tr>

          <tr>
            <td>Genitores:</td>
            <td>{`${document?.nome_garanhao} X ${document?.nome_egua}`}</td>
          </tr>

          <tr>
            <td>Solicitante:</td>
            <td>{document.solicitante ? document.solicitante : '--'}</td>
          </tr>

          <tr>
            <td>Inicio da Cobertura:</td>
            <td>{document.dt_cobertura_inicio}</td>
          </tr>

          <tr>
            <td>Fim da cobertura:</td>
            <td>{document.dt_cobertura_fim}</td>
          </tr>

          <tr>
            <td>Documento válido:</td>
            <td>{document.documento_valido ? 'Sim' : 'Não'}</td>
          </tr>

          <tr>
            <td>Protocolo:</td>
            <td>{document?.protocolo + '/' + document?.AnoProtocolo}</td>
          </tr>

          <tr>
            <td>Entrada:</td>
            <td>{document.dt_entrada}</td>
          </tr>

          <tr>
            <td>Pagamento:</td>
            <td>{document?.dt_pagamento}</td>
          </tr>

          <tr>
            <td>Status:</td>
            <td>{document.status}</td>
          </tr>

          <tr>
            <td>Valor:</td>
            <td>{`R$ ${document.valor}`}</td>
          </tr>

          <tr className="link">
            {/* <td className="link"> */}
              {document.link && (
                <a href={document.link} target="_blank" rel="noopener noreferrer">
                  <FiFileText size={20}/>
                </a>
              )}
            {/* </td> */}
          </tr>

        </table>
      ))}
    </Container>
  );
}
