import styled from 'styled-components';

import { colors } from '../../theme';
import { pxToRem } from '../../functions';

export const Container = styled.section`
  width: 100vw;
  height: 100vh;
  min-height: 100vh;

  background: rgba(255, 255, 255, 1);
`;

export const Content = styled.main`
  height: 100%;
  padding: ${pxToRem(2)} ${pxToRem(10)} 0;
`;

export const ButtonsWrapper = styled.div`
  bottom: 0;
  position: fixed;

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MobileButton = styled.button`
  bottom: 0;
  position: fixed;

  width: 100%;
  border: none;
  height: ${pxToRem(60)};

  color: ${colors.white};
  font-size: ${pxToRem(18)};
  font-family: 'SFProText-Semibold', sans-serif;
  background: ${({ backgroundColor }) => backgroundColor || colors.lightGreen};
`;

export const MobileButtons = styled.button`
  width: 100%;
  border: none;
  height: ${pxToRem(60)};

  color: ${colors.white};
  font-size: ${pxToRem(18)};
  font-family: 'SFProText-Semibold', sans-serif;
  background: ${({ backgroundColor }) => backgroundColor || colors.lightGreen};
`;
