import styled from 'styled-components';
import { Select } from 'antd';

import { pxToRem } from '../../functions';

export const Container = styled(Select)`
  height: auto;

  .ant-select-selector {
    width: ${({ width }) => (width ? pxToRem(width) : pxToRem(320))} !important;
    border-radius: 4px !important;

    input {
      color: rgba(0, 0, 0, 0.65);
      font-family: 'SFProText-Regular', sans-serif;
    }
  }

  .ant-select-arrow svg {
    position: absolute;
    top: -6px;
    right: -2px;
    ${({ disabled }) => disabled && 'opacity: 0.3'};

    transition: transform 0.3s;
    transform-style: preserve-3d;
  }

  &.ant-select-open {
    .ant-select-arrow svg {
      transform: rotate(180deg);
    }
  }
`;
