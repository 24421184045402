import React, { useState, useCallback } from 'react';
import swal from 'sweetalert';

import {
  Radio,
  Button,
  FormItem,
  Checkbox,
  ExclamationSvg,
} from '../../../../components';

import { colors } from '../../../../theme';
import { api } from '../../../../services/api';

import { Container, Form, ModalContent, CircleLoading } from './styles';

export default function CancellationModal({
  modalIsOpen,
  toggleModal,
  preRegister,
  setIsCanceled,
  detailsRequest,
}) {
  const initialParams = {
    NOME_PAI: detailsRequest?.NOME_PAI,
    NOME_MAE: detailsRequest?.NOME_MAE,
    nome_produto: detailsRequest?.nome_produto,
    id_solicitante: detailsRequest?.id_solicitante,
    nome_solicitante: detailsRequest?.nome_solicitante,
  };

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');

  const switchModal = useCallback(() => {
    toggleModal();
    setChecked(false);
    setConfirmed(false);
    setCancellationReason('');
  }, [toggleModal]);

  const handleConfirm = () => {
    setConfirmed(true);
  };

  const radioOptions = [
    { value: 3, label: 'Óbito do animal' },
    { value: 6, label: 'Duplicidade' },
    { value: 9, label: 'Criador sem interesse' },
  ];

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const cancellationData = {
      ...initialParams,
      num_preregistro: preRegister,
      id_motivo_estorno: cancellationReason,
    };

    try {
      const { data } = await api.delete('pedidoregistro/cancelar', {
        data: cancellationData,
      });

      if (data.success) {
        swal('Sucesso', 'Pedido de registro cancelado!', 'success');
        switchModal();
        setIsCanceled(true);
      }
    } catch (error) {
      console.log(error);
      swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
    } finally {
      setLoading(false);
    }
  }, [
    preRegister,
    switchModal,
    initialParams,
    setIsCanceled,
    cancellationReason,
  ]);

  return (
    <Container
      centered
      scroll={true}
      isVisible={modalIsOpen}
      height={confirmed ? 180 : 320}
      title={confirmed && 'Cancelamento'}
      width={window.innerWidth >= 1600 ? 600 : 500}
      toggleVisibility={switchModal}
      footer={
        <>
          {confirmed ? (
            <>
              <Button onClick={switchModal} color={colors.red} fontLight>
                Cancelar
              </Button>

              <Button
                fontLight
                onClick={handleSubmit}
                color={colors.lightGreen}
                disabled={cancellationReason === ''}
              >
                Salvar
              </Button>
            </>
          ) : (
            <>
              <Button color={colors.red} fontLight onClick={switchModal}>
                Cancelar
              </Button>
              <Button
                fontLight
                disabled={!checked}
                onClick={handleConfirm}
                color={colors.lightGreen}
              >
                Continuar
              </Button>
            </>
          )}
        </>
      }
    >
      <>
        {confirmed ? (
          <>
            <Form>
              {loading ? (
                <CircleLoading />
              ) : (
                <>
                  <FormItem
                    label="Motivo do cancelamento de pedido de registro"
                    name="reason"
                    className="form-item1"
                  >
                    <Radio
                      name="reason"
                      size={window.innerWidth >= 1600 ? 'large' : 'middle'}
                      options={radioOptions}
                      value={cancellationReason}
                      onChange={(value) => setCancellationReason(value)}
                    />
                  </FormItem>
                </>
              )}
            </Form>
          </>
        ) : (
          <ModalContent>
            <ExclamationSvg />

            <h1>
              Ao cancelar um pedido de registro, não será possível recuperá-lo.
            </h1>

            <Checkbox
              checkColor={colors.buttonGray}
              onChange={(checkedValue) => setChecked(checkedValue)}
              checked={checked}
            >
              Quero cancelar!
            </Checkbox>
          </ModalContent>
        )}
      </>
    </Container>
  );
}
