import styled, { css } from 'styled-components';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  right: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;

  /* Ignore padding when this component is inside CSS Grid */
  margin: ${({ removeMargin }) =>
    removeMargin ? `0 ${pxToRem(-20)} ${pxToRem(-20)} 0` : ''};

  width: 100vw;
  height: 100vh;

  background-color: ${colors.darkWhite};

  ${({ profileAnimal }) =>
    profileAnimal &&
    css`
      width: 100%;
      height: 100%;

      margin: 0;
    `}

  div[aria-label='animation'] {
    transform: scaleX(-1) translateY(-24px);

    path {
      fill: ${colors.lightBlack};
    }
  }

  .loader-text {
    text-align: center;
    transform: translateY(-113px);

    > * {
      color: ${colors.lightBlack};
      font-family: 'SFText-Regular', sans-serif;
    }

    h1 {
      margin: 0;

      ${fonts.size8};
      line-height: normal;
    }

    p {
      margin: 0;
      ${fonts.size4};
    }
  }
`;
