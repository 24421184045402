import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import CurrencyFormat from 'react-currency-format';
import { Row, Col, Button, Badge, Form, Drawer, Select, notification } from 'antd';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { ArrowRightOutlined } from '@ant-design/icons';
import DonutChart from '../../components/DonutChart';
import { dataOverview, dataNextMaturities, dataOpenBillets } from './data';
import { Table } from 'antd';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Payment from 'payment';
import { Animated } from 'react-animated-css';
import {
  getTokenApiPagamentos,
  lancamentosVencidos,
  lancamentosAVencer,
  obterCredito,
  inserirCarrinho,
  resumoCarrinho,
  limparCarrinho,
  pagamentoCartaoCredito,
  totalCarrinho,
  pagamentoBoleto,
  cartao,
  boletosEmAberto,
  obterQtdParcelas,
  existeLiquidaFacil,
  listarCampanhaLiquidaFacil,
  pagamentoCredito,
  usarCredito,
  listarDebitosTerceiro,
  api,
  minimumValueBillet,
  listarParcelasBoleto,
  authApiPagamentos,
} from '../../services/api-pagamentos'

//Global styles
import {
  Container,
  MainTitle,
  MainTitleWrap,
  Block,
  TitleBlock,
  Wrap,
  ButtonApp,
  Clear,
  Divider,
} from '../../components/Global/styles';

import {
  PreLoader,
  Breadcrumb,
  Loader,
  Input,
  StyledCustomScrollBar,
  SearchBox,
  Checkbox,
  Radio,
} from '../../components';

import {
  GraphAndCharacteristics,
  Characteristics,
  CharacteristicsText,
  Graph,
  MetricasGraphic,
  Overview,
  Metricas,
  MetricasBlock,
  TotalRecords,
  TotalOfDebt,
  TableBody,
  SelectedTotal,
  PaymentButton,
  Filters,
  Tabs,
  GlobalStyle,
  PaymentOption,
  PaymentSumary,
} from './styles';
import CircularProgressbar from '../../components/CircularProgressbar';
import { colors } from '../../theme';
import okIcon from '../../theme/assets/images/icons/ok.svg';
import okWIcon from '../../theme/assets/images/icons/okW.svg';
import barCodeIcon from '../../theme/assets/images/icons/bar-code.svg';
import creditCardIcon from '../../theme/assets/images/icons/credit-card.svg';
import { BsFilter, BsArrowLeft, BsTrash } from 'react-icons/bs';
import queryString from 'query-string';
import { setToken } from '../../services/auth';
import data from '../RegistrationRequest/data';
import { NavLink } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';
import { string } from 'prop-types';
import { stringify } from 'query-string';
const { Option } = Select;

export default function Payments() {
  const { token, d } = queryString.parse(window.location.search);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [preLoading, setPreLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedItens, setSelectedItens] = useState([])
  const [totalSelectedValue, setTotalSelectedValue] = useState(0.00)
  const [maturityTab , setMaturityTab] = useState('')
  const [nextMaturityTab , setNextMaturityTab] = useState('')
  const [entryMaturityTab , setEntryMaturityTab] = useState('active')
  const [openBilletsTab , setOpenBilletsTab] = useState('')
  const [openLiquidaFacilTab , setOpenLiquidaFacilTab] = useState('')
  const [openDebitosTerceiro , setOpenDebitosTerceiro] = useState('')
  const [debitosTerceiroData, setDebitosTerceiroData] = useState([])
  const [debitosTerceiro , setDebitosTerceiro] = useState(false)
  const [dataTable , setDataTable] = useState({
    count: '',
    pages: '',
    registrationRequests: [ ]
  });
  const [visible, setVisible] = useState(false);
  const [drawerPayment, setDrawerPayment] = useState(false);
  const [drawerCards, setDrawerCards] = useState(false);
  const [cardState, setCardState] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    issuer: ''
  });
  const [payCardOk, setPayCardOk] = useState(false)
  const [payBilletOk, setPayBilletOk] = useState(false)
  const [payCredOk, setPayCredOk] = useState(false)
  const [useCredOk, setUseCredOk] = useState(false)
  const [generatedBillet, setGeneratedBillet] = useState('')
  const [totalDebits, setTotalDebits] = useState(0.00)
  const [debtsDue, setDebtsDue] = useState(0.00)
  const [overdueDebts, setOverdueDebts] = useState(0.00)
  const [credit, setCredit] = useState(0.00)
  const [usedCredit, setUsedCredit] = useState(false)
  const [cartSummaryData, setCartSummaryData] = useState(null)
  const [cartTotal, setCartTotal] = useState(0.00)
  const [serviceTotal, setServiceTotal] = useState(0.00)
  const [parcel, setParcel] = useState(1)
  const [liquidaFacil, setLiquidaFacil] = useState({
    DataCorte: '',
    PercentualAVista: 0,
    PercentualBoleto: 0,
    PercentualParcelado: 0
  });
  const [numberParcelsSelected, setNumberParcelsSelected] = useState(0)
  const [savedCards, setSavedCards] = useState([])
  /*const [savedCards, setSavedCards] = useState([
    { cvc: '', expiry: '04/2023', name: 'Cartão OK', number: '4556 2862 5053 5361', label: 'Cartão OK',},
    { cvc: '', expiry: '04/2023', name: 'Não Autorizada', number: '6011 1378 8694 5892', label: 'Cartão Não Autorizado',},
    { cvc: '', expiry: '04/2023', name: 'Cartão Expirado', number: '5376 7767 9238 5783', label: 'Cartão Expirado',},
    { cvc: '', expiry: '04/2023', name: 'Cartão Bloqueado', number: '6062 8254 7058 9815', label: 'Cartão Bloqueado',},
    { cvc: '', expiry: '04/2023', name: 'Time Out', number: '4916 5997 3989 0586', label: 'Tempo Esgotado',},
    { cvc: '', expiry: '04/2023', name: 'Cartão Cancelado', number: '5165 2695 2699 6997', label: 'Cartão Cancelado',},
    { cvc: '', expiry: '04/2023', name: 'Problemas com o Cartão', number: '6011 6465 9203 8248', label: 'Problemas com o Cartão',},
    { cvc: '', expiry: '04/2023', name: 'Ana Carolina', number: '5579 6181 1173 2631', label: '•••• •••• •••• 2631',},
    { cvc: '', expiry: '11/2028', name: 'João Carlos', number: '4910 0648 6171 5321', label: '•••• •••• •••• 5321',},
    {cvc: '', expiry: '06/2022', name: 'Pedro Carlos', number: '3754 363839 13558', label: '•••• •••• •••• 13558',}
  ])*/
  const [stateCards, setStateCards] = useState(false)
  const [stateMakePayment, setStateMakePayment] = useState(false)
  const [payAnimation, setPayAnimation] = useState(true)

  const [minValueBillet, setMinValueBillet] = useState('') //min value to generate Billet
  const discountValueBillet = liquidaFacil.PercentualBoleto //discount amount billet for \"Liquida Fácil\"
  const discountValueAVista = liquidaFacil.PercentualAVista //discount amount card a vista for \"Liquida Fácil\"
  const discountValueParcelado = liquidaFacil.PercentualParcelado //discount card parcelado for \"Liquida Fácil\"
  const [receiptAnimation, setReceiptAnimation] = useState(false)
  const [idReceipt, setIdReceipt] = useState(true)
  const [typeMaturity, setTypeMaturity] = useState('all')
  const [payBilletAnimation, setPayBilletAnimation] = useState(false)
  const [diaVctoBoleto, setDiaVctoBoleto] = useState(0)
  const [selectAll, setSelectAll] = useState(false)
  const [downloadBilletAnimation, setDownloadBilletAnimation] = useState(false)
  const [billets, setBillets] = useState([])

  const rowSelection = {

    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItens(selectedRows)

      let arr = [];
      selectedRows.map(v =>   arr.push(v.value))
      setTotalSelectedValue(arr.reduce((a, b) => a + b, 0))

      if((selectedRows.length - 2) == dataTable.count) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }

    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const tableColumns = [
    {
      title: 'Nº DOC',
      dataIndex: 'number',
      sorter: (a, b) => a.number - b.number,
      render: (text) => <small>{text}</small>,
      rowClassName: (c) => c,
      width: 100,
    },
    {
      title: openBilletsTab !== 'active' ? 'DESCRIÇÃO' : '',
      dataIndex: openBilletsTab !== 'active' ? 'description' : '',
      responsive: ['md'],
      render: (text) =>  {
        const template = <strong>{text}</strong>
        const templateCompetidor = (
          <span>
            Competidor: <strong>{text}</strong>
          </span>
        );

        return openBilletsTab === 'active'
          ? false
          : openDebitosTerceiro === 'active'
            ? templateCompetidor
            : template;
      },
    },
    { title:
        'VENCIMENTO',
      dataIndex: 'maturity',
      sorter: (a, b) =>
        new Date(a.maturity).getTime() - new Date(b.maturity).getTime(),
      align: 'right'
    },
    {
      title: 'VALOR (R$)',
      dataIndex: 'value',
      sorter: (a, b) => a.value - b.value,
      render: (text) => (
        <strong>
          <CurrencyFormat
            value={text}
            thousandSeparator={'.'}
            decimalSeparator={','}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'R$ '}
          />
        </strong>
      ),
      width: 110,
      align: 'right',
    },
    {
      title: openBilletsTab === 'active' ? 'URL do Boleto' : '',
      dataIndex: openBilletsTab === 'active' ? 'url' : '',
      align: 'right',
      width: openBilletsTab === 'active' ? 110 : '',
      render: (text) => {
        const templateURL = (
          <Button className="btn-abqm" href={text} target="_blank">
            {' '}Abrir Boleto{' '}
          </Button>
        );

        return openBilletsTab === 'active' ? templateURL : false;
      },
    },
    {
      title: false,
      dataIndex: 'Id_tipo_lancamento',
      align: 'right',
      render: (text) =>  false
    },
  ];

  const selectBox = openBilletsTab === 'active' ? false : {
    type: "checkbox",
    ...rowSelection,
  }

  const footer = () => {
    return  <Checkbox 
                checkColor={colors.buttonGray}
                onChange={ (value) => actions.handleSelectAll(value) }
                checked={selectAll}
              > <strong>SELECIONAR TODOS</strong>
            </Checkbox>
  }

  const errorNotify = (title, desc) => {

    console.log('desc', desc);

    const message = title ? title : 'Ops, algo deu errado!'
    const description = desc ? desc :
      'Não foi possível processar a sua solicitação. Por favor, entre em contato com o suporte.'

    return notification.error({
      message,
      description
    });
  }

  const processPay = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });
  };

  const services = {

    getMinBillet: async () => {
      return await minimumValueBillet()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '')
        })
    },

    getLancamentosVencidos: async () => {
      return await lancamentosVencidos()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '')
        })
    },

    getLancamentosAVencer: async () => {
      return await lancamentosAVencer()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    getBoletosEmAberto: async () => {
      return await boletosEmAberto()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    getCredito: async () => {
      return await obterCredito()
        .then(value => {
          return value.data.Fields[0]
        })
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    sendToCart: async (itens) => {
      return await inserirCarrinho(itens)
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    cartSummary: async () => {
      return await resumoCarrinho()
        .then(value => value.data)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    seeParcels: async (value) => {
      return await obterQtdParcelas(value)
        .then(value => value.data.Fields.QtdParcelas)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    thereIsLiquidaFacil: async () => {
      return await existeLiquidaFacil()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    getCampanhaLiquidaFacil: async () => {
      return await listarCampanhaLiquidaFacil()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    getDebitosTerceiro: async () => {
      return await listarDebitosTerceiro()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    cartTotal: async () => {
      return await totalCarrinho()
        .then(value => {
          if (value) return value.data.Fields.Valor
        })
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    cleanCart: async () => {
      return await limparCarrinho()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    payCc: async (payload) => {
      return await pagamentoCartaoCredito(payload)
        .then(value => value.data)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    payBillet: async (payload) => {
      return  await pagamentoBoleto(payload)
        .then(value => value.data)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    payCredit: async () => {
      return  await pagamentoCredito()
        .then(value => value.data)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    useCredit: async () => {
      return  await usarCredito()
        .then(value => {
          console.log('value', value);
          return value.data
        })
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    cards: async (id) => {
      return await cartao(id)
        .then(value => value.data)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

    parcelBillet: async () => {
      return await listarParcelasBoleto()
        .then(value => value.data.Fields)
        .catch((e) => {
          errorNotify('Ops, algo deu errado.', e.message ? e.message : '');
        });
    },

  }

  const methods = {

    limit: (val, max) => {
      if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
      }
      if (val.length === 2) {
        if (Number(val) === 0) {
          val = '01';

          //this can happen when user paste number
        } else if (val > max) {
          val = max;
        }
      }
      return val;
    },

    cardExpiry: (val) => {
      let month = methods.limit(val.substring(0, 2), '12');
      let year = methods.limit(val.substring(2, 6), '4');

      return month + (year.length ? '/' + year : '');
    },

    getIssuer: (v) => {
      setCardState((state) => ({ ...state, issuer: v.issuer }));
    },

    formatNameUserCard: (val) => {
      var str = val;
      if(str.length > 10) str.substring(0, 10);
      return str;
    },

    clearCard: () => {
      setCardState((state) => ({
        ...state,
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
      }));
    },

    totalDebits: (data) => {
      if (data || data !== undefined) {
        if (data.length > 0) {
          let arr = [];
          let total;

          data.map(v => arr.push(v.value));
          total = arr.reduce((a, b) => a + b, 0);
          setTotalDebits(total);
        }
      }

    },

    //A vencer
    debtsDue: (items) => {
      if (items) {
        let arr = [];
        let total;
        let now = new Date();
        items.map((v) =>
          new Date(v.maturity) > now ? arr.push(v.value) : 0.0
        );
        total = arr.reduce((a, b) => a + b, 0);
        return total;
      }
    },

    //Vencidos
    overdueDebts: (items) => {
      if(items) {
        let arr = [];
        let total;
        let now = new Date();

        items.map(v => {
          let formattedDate = methods.formataStringData(v.maturity)
          // formattedDate = methods.toTimestamp(formattedDate)
          formattedDate = format(new Date(formattedDate), "yyyy/MM/dd");

          return new Date(formattedDate) < now ? arr.push(v.value) : 0.0;
        });
        total = arr.reduce((a, b) => a + b, 0);
        setOverdueDebts(total)
      }
    },

    checkData: () => {
      return this;
    },

    toTimestamp: (strDate) => {
      let datum = Date.parse(strDate);
      return datum / 1000;
    },

    formataStringData: (data) => {
      let dia = data.split('/')[0];
      let mes = data.split('/')[1];
      let ano = data.split('/')[2];
      return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
    },

    reason: (data) => {
      var reason = '';

      switch (data.ReturnCode) {
        case '05':
          reason = 'Motivo: a transação não foi autorizada pela operadora do seu cartão!';
          break;
        case '57':
          reason = 'Motivo: o seu cartão está expirado!';
          break;
        case '78':
          reason = 'Motivo: o seu cartão está bloqueado!';
          break;
        case '99':
          reason = 'Tempo esgotado. Tente novamente mais tarde!';
          break;
        case '77':
          reason = 'Motivo: o seu cartão está cancelado!';
          break;
        case '70':
          reason = 'Problemas com o Cartão de Crédito. Entre em contato com a sua operadora!';
          break;
      }

      return reason;
    },

    getCards: async () => {
      const cards = await services.cards();
      const arr = [];
      if (cards) {
        cards.map((v) => {
          console.log('v.Id', v.Id);
          arr.push({
            id: v.Id,
            cvc: '',
            expiry: v.DataExpiracao,
            name: v.NomeProprietario,
            number: v.Numero,
            label: v.Numero,
          });
        });
      }
      return arr;
    },

    deleteCard: async (id) => {
      return await services.cards(id);
    },

    checkLiquidaFacil: async () => {
      const liquida = await services.thereIsLiquidaFacil();

      if (liquida) {
        setLiquidaFacil((state) => ({
          ...state,
          DataCorte: liquida.DataCorte,
          PercentualAVista: liquida.PercentualAVista,
          PercentualBoleto: liquida.PercentualAVista,
          PercentualParcelado: liquida.PercentualParcelado,
        }));
      }
    },

    clearTableData: () => {
      setDataTable((state) => ({
        ...state,
        count: 0,
        registrationRequests: [],
      }));
    },

    minBillet: async () => {
      let minValue = await services.getMinBillet()
      setMinValueBillet(minValue)
    },

  }

  const actions = {

    viewEntryMaturities: async (value, type = typeMaturity) => {
      setEntryMaturityTab('active');
      setOpenBilletsTab('');
      setOpenLiquidaFacilTab('');
      setOpenDebitosTerceiro('');
      setSelectedItens([])
      setTotalSelectedValue(0.00)

      if(type == 'all') {
        await actions.getEntryMaturity(value);
      } else if(type == 'vencido') {
        await actions.fetchData(value);
      } else if(type == 'avencer') {
        await actions.getAVencer(value);
      }

      await actions.getCredito();
    },

    showDrawer: () => {
      setVisible(true);
    },

    showCards: async () => {
      setStateCards(true)

      if (savedCards.length < 1) {
        const cards = await methods.getCards();

        if (cards)  {
          setStateCards(false);
          setSavedCards(cards);
          setDrawerCards(true);
        }
      } else {
        setStateCards(false);
        setDrawerCards(true);
      }

    },

    closeDrawer: () => {
      setVisible(false);
    },

    makePayment: async () => {
      setStateMakePayment(true)

      let itens = [];
      selectedItens.map(value => itens.push(value.number))

      let filteredData = [];
      for (let i = 0; i < itens.length; ++i) {
        if (itens[i] !== '') {
          filteredData.push(
            itens[i]
          );
        }
      }

      await services.cleanCart();
      await services.sendToCart(filteredData);
      const cartTotal = await services.cartTotal();

      if (cartTotal) {
        const parcel = await services.seeParcels(cartTotal);
        setParcel(parcel)
      }

      if (cartTotal && cartTotal !== 0.00) {
        setDrawerPayment(true);
        setCartTotal(cartTotal);
        setStateMakePayment(false);
        setSavedCards([]);
      } else {
        errorNotify('Não foi possível adicionar seu itens no carrinho.');
      }
    },

    closePayment: async () => {
      const cleanCart = await services.cleanCart();

      if (!cleanCart) {
        errorNotify('Algo deu erro.');
        return false;
      }

      setDrawerPayment(false);
      setPayAnimation(true);
      setReceiptAnimation(false);
      setPayBilletAnimation(false);
      setPayBilletOk(false);
      setGeneratedBillet('');
      setNumberParcelsSelected(0);
      setDiaVctoBoleto(0);
      setParcel(0);

      document.body.classList.remove('open-payment');

      setUseCredOk(false);
      setUsedCredit(false);
      setDownloadBilletAnimation(false);

    },

    closeCards: () => {
      setDrawerCards(false);
    },

    selectCard: (v) => {
      if (v) {
        let selectedCard = v.target.value;
        let result = savedCards.filter((v) => v.number === selectedCard)[0];
        setCardState((state) => ({
          ...state,
          cvc: '',
          expiry: result.expiry,
          focus: '',
          name: result.name,
          number: result.number,
        }));
        actions.closeCards();
      }
    },

    deleteCard: async (v) => {
      if (v) {
        const cards = await methods.deleteCard(v);
        let result = savedCards.filter(f => f.id !== v);
        setSavedCards(result);
      }
    },

    viewOpenBillets: async (value) => {
      methods.clearTableData();
      setLoadingData(true);
      setOpenBilletsTab('active');
      setMaturityTab('');
      setNextMaturityTab('');
      setEntryMaturityTab('');
      setOpenLiquidaFacilTab('');
      setSelectedItens([]);
      setOpenDebitosTerceiro('');
      setTotalSelectedValue(0.00);

      const openBillets = await services.getBoletosEmAberto();

      if (openBillets) {
        let arr = [];

        openBillets.map(v => {
          arr.push({
            key: v.id_fatura,
            number: v.id_fatura,
            maturity: format(new Date(v.dt_vencimento), 'dd/MM/yyyy'),
            value: v.vlr_fatura,
            url: v.url,
            nidUsuarioAtualizacao: v.nidUsuarioAtualizacao,
            dtmDataHoraAtualizacao: v.dtmDataHoraAtualizacao,
            nidUsuarioCriacao: v.nidUsuarioCriacao,
          });
        });

        if(value != null) {
          let filteredData = [];

          for (let i = 0; i < arr.length; ++i) {
            let searchValue = value.toLowerCase();
            let num = arr[i].number.toString().toLowerCase();
            let maturity = arr[i].maturity.toString().toLowerCase();

            if (num.includes(searchValue) || maturity.includes(searchValue)) {
              filteredData.push({
                key: arr[i].key,
                number: arr[i].number,
                maturity: arr[i].maturity,
                value: arr[i].value,
                url: arr[i].url,
                nidUsuarioAtualizacao: arr[i].nidUsuarioAtualizacao,
                dtmDataHoraAtualizacao: arr[i].dtmDataHoraAtualizacao,
                nidUsuarioCriacao: arr[i].nidUsuarioCriacao,
              });
            }
          }
          setDataTable(state => ({
            ...state,
            count: filteredData.length,
            registrationRequests: filteredData
          }));
          setLoadingData(false);
        } else {
          setDataTable(state => ({
            ...state,
            count: arr.length,
            registrationRequests: arr
          }));
          setLoadingData(false);
        }
      }


    },

    viewLiquidaFacil: async (value) => {
      methods.clearTableData();
      setLoadingData(true);
      setOpenLiquidaFacilTab('active')
      setNextMaturityTab('')
      setEntryMaturityTab('');
      setOpenBilletsTab('')
      setMaturityTab('');
      setOpenDebitosTerceiro('');
      setSelectedItens([])
      setTotalSelectedValue(0.00)

      const campanhaLiquida = await services.getCampanhaLiquidaFacil();

      if (campanhaLiquida) {
        let arr = [];

        campanhaLiquida.map((v) => {
          arr.push({
            key: v.Id_lancamento,
            number: v.Id_lancamento,
            description:
              v.Obs_lancamento !== null
                ? v.Obs_lancamento + ' ' + v.Ds_tipo_lancamento
                : v.Ds_tipo_lancamento,
            maturity: v.Dt_vencimento_original,
            value: v.Vlr_lancamento,
            Id_tipo_lancamento: v.Id_tipo_lancamento,
            Status_lancamento: v.Status_lancamento,
            Dt_inclusao: v.Dt_inclusao,
            Num_protocolo: v.Num_protocolo,
            Ano_protocolo: v.Ano_protocolo,
            Dt_lancamento: v.Dt_lancamento,
          })
        })

        if(value != null) {
          let filteredData = [];

          for (let i = 0; i < arr.length; ++i) {
            let searchValue = value.toLowerCase();
            let desc = arr[i].description.toString().toLowerCase();
            let num = arr[i].number.toString().toLowerCase();
            let maturity = arr[i].maturity.toString().toLowerCase();

            if (desc.includes(searchValue) || num.includes(searchValue) || maturity.includes(searchValue)) {
              filteredData.push({
                key: arr[i].key,
                number: arr[i].number,
                description: arr[i].description,
                maturity: arr[i].maturity,
                value: arr[i].value,
                Id_tipo_lancamento: arr[i].Id_tipo_lancamento,
                Status_lancamento: arr[i].Status_lancamento,
                Dt_inclusao: arr[i].Dt_inclusao,
                Num_protocolo: arr[i].Num_protocolo,
                Ano_protocolo: arr[i].Ano_protocolo,
                Dt_lancamento: arr[i].Dt_lancamento
              });
            }
          }
          setDataTable(state => ({
            ...state,
            count: filteredData.length,
            registrationRequests: filteredData
          }));
          setLoadingData(false);
        } else {
          setDataTable(state => ({
            ...state,
            count: arr.length,
            registrationRequests: arr
          }));
          setLoadingData(false);
        }
      }
    },

    fetchData: async (value) => {
      methods.clearTableData();
      setLoadingData(true);

      const overdueLaunches = await services.getLancamentosVencidos();

      if (overdueLaunches) {
        let arr = [];
        overdueLaunches.map((v) => {
          arr.push({
            key: v.Id_lancamento,
            number: v.Id_lancamento,
            description: v.Obs_lancamento + ' ' + v.Ds_tipo_lancamento,
            maturity: v.Dt_vencimento_original,
            value: v.Vlr_lancamento,
            Id_tipo_lancamento: v.Id_tipo_lancamento,
            Status_lancamento: v.Status_lancamento,
            Dt_inclusao: v.Dt_inclusao,
            Num_protocolo: v.Num_protocolo,
            Ano_protocolo: v.Ano_protocolo,
            Dt_lancamento: v.Dt_lancamento,
          })
        })

        if(value != null) {
          let filteredData = [];

          for (let i = 0; i < arr.length; ++i) {
            let searchValue = value.toLowerCase();
            let desc = arr[i].description.toString().toLowerCase();
            let num = arr[i].number.toString().toLowerCase();
            let maturity = arr[i].maturity.toString().toLowerCase();

            if (desc.includes(searchValue) || num.includes(searchValue) || maturity.includes(searchValue)) {
              filteredData.push({
                key: arr[i].key,
                number: arr[i].number,
                description: arr[i].description,
                maturity: arr[i].maturity,
                value: arr[i].value,
                Id_tipo_lancamento: arr[i].Id_tipo_lancamento,
                Status_lancamento: arr[i].Status_lancamento,
                Dt_inclusao: arr[i].Dt_inclusao,
                Num_protocolo: arr[i].Num_protocolo,
                Ano_protocolo: arr[i].Ano_protocolo,
                Dt_lancamento: arr[i].Dt_lancamento

              });
            }
          }
          setDataTable(state => ( {...state, count: filteredData.length, registrationRequests: filteredData }) );
          setLoadingData(false);
        } else {
          setDataTable(state => ( {...state, count: arr.length, registrationRequests: arr }) );
          setLoadingData(false);
        }

        methods.totalDebits(arr)

        //A vencer
        //methods.debtsDue(arr);

        //Vencidos
        //methods.overdueDebts(arr);

        setLoadingData(false);
      } else {
        errorNotify('Ops, algo deu errado.', 'Não foi possível carregar os dados no memento. Tente atualizar a página!');
      }
    },

    getAVencer: async (value) => {
      methods.clearTableData();
      setLoadingData(true);

      const overdueLaunches = await services.getLancamentosAVencer();

      if (overdueLaunches) {
        let arr = [];
        overdueLaunches.map((v) => {
          arr.push({
            key: v.Id_lancamento,
            number: v.Id_lancamento,
            description: v.Obs_lancamento + ' ' + v.Ds_tipo_lancamento,
            maturity: v.Dt_vencimento_original,
            value: v.Vlr_lancamento,
            Id_tipo_lancamento: v.Id_tipo_lancamento,
            Status_lancamento: v.Status_lancamento,
            Dt_inclusao: v.Dt_inclusao,
            Num_protocolo: v.Num_protocolo,
            Ano_protocolo: v.Ano_protocolo,
            Dt_lancamento: v.Dt_lancamento,
          })
        })

        if(value != null) {
          let filteredData = [];

          for (let i = 0; i < arr.length; ++i) {
            let searchValue = value.toLowerCase();
            let desc = arr[i].description.toString().toLowerCase();
            let num = arr[i].number.toString().toLowerCase();
            let maturity = arr[i].maturity.toString().toLowerCase();

            if (desc.includes(searchValue) || num.includes(searchValue) || maturity.includes(searchValue)) {
              filteredData.push({
                key: arr[i].key,
                number: arr[i].number,
                description: arr[i].description,
                maturity: arr[i].maturity,
                value: arr[i].value,
                Id_tipo_lancamento: arr[i].Id_tipo_lancamento,
                Status_lancamento: arr[i].Status_lancamento,
                Dt_inclusao: arr[i].Dt_inclusao,
                Num_protocolo: arr[i].Num_protocolo,
                Ano_protocolo: arr[i].Ano_protocolo,
                Dt_lancamento: arr[i].Dt_lancamento
              });
            }
          }
          setDataTable(state => ({
            ...state,
            count: filteredData.length,
            registrationRequests: filteredData
          }));
          setLoadingData(false);
        } else {
          setDataTable(state => ( {...state, count: arr.length, registrationRequests: arr }) );
          setLoadingData(false);
        }

        methods.totalDebits(arr)

        setLoadingData(false);
      } else {
        errorNotify('Ops, algo deu errado.', 'Não foi possível carregar os dados no memento. Tente atualizar a página!')
      }
    },

    getEntryMaturity: async (value) => {
      methods.clearTableData();
      setLoadingData(true);

      const lancamentosVencidos = await services.getLancamentosVencidos();
      const lancamentosAVencer = await services.getLancamentosAVencer();

      if (lancamentosVencidos || lancamentosAVencer) {

        let arr = [];
        let i = 0;
        await arr.push({
          key: i,
          number: '',
          description: 'Lançamentos Vencidos',
          maturity: '',
          value: 0,
          Id_tipo_lancamento: 'NOME_COMPETIDOR',
          Status_lancamento: '',
          Dt_inclusao: '',
          Num_protocolo: '',
          Ano_protocolo: '',
          Dt_lancamento: ''
        })
        await lancamentosVencidos.map( v => {
          i++
          arr.push({
            key: i,
            number: v.Id_lancamento,
            description: v.Obs_lancamento + ' ' + v.Ds_tipo_lancamento,
            maturity: v.Dt_vencimento_original,
            value: v.Vlr_lancamento,
            Id_tipo_lancamento: v.Id_tipo_lancamento,
            Status_lancamento: v.Status_lancamento,
            Dt_inclusao: v.Dt_inclusao,
            Num_protocolo: v.Num_protocolo,
            Ano_protocolo: v.Ano_protocolo,
            Dt_lancamento: v.Dt_lancamento,
          })
        })
        await arr.push({
          key: arr.length,
          number: '',
          description: 'Lançamentos a Vencer',
          maturity: '',
          value: 0,
          Id_tipo_lancamento: 'NOME_COMPETIDOR',
          Status_lancamento: '',
          Dt_inclusao: '',
          Num_protocolo: '',
          Ano_protocolo: '',
          Dt_lancamento: ''
        })
        i++
        await lancamentosAVencer.map( v => {
          i++
          arr.push({
            key: i,
            number: v.Id_lancamento,
            description: v.Obs_lancamento + ' ' + v.Ds_tipo_lancamento,
            maturity: v.Dt_vencimento_original,
            value: v.Vlr_lancamento,
            Id_tipo_lancamento: v.Id_tipo_lancamento,
            Status_lancamento: v.Status_lancamento,
            Dt_inclusao: v.Dt_inclusao,
            Num_protocolo: v.Num_protocolo,
            Ano_protocolo: v.Ano_protocolo,
            Dt_lancamento: v.Dt_lancamento,
          })
        })

        if(value != null) {
          let filteredData = [];

          for (let i = 0; i < arr.length; ++i) {
            let searchValue = value.toLowerCase();
            let desc = arr[i].description.toString().toLowerCase();
            let num = arr[i].number.toString().toLowerCase();
            let maturity = arr[i].maturity.toString().toLowerCase();

            if (desc.includes(searchValue) || num.includes(searchValue) || maturity.includes(searchValue) || desc.includes('lançamentos vencidos') || desc.includes('lançamentos a vencer')) {
              filteredData.push({
                key: arr[i].key,
                number: arr[i].number,
                description: arr[i].description,
                maturity: arr[i].maturity,
                value: arr[i].value,
                Id_tipo_lancamento: arr[i].Id_tipo_lancamento,
                Status_lancamento: arr[i].Status_lancamento,
                Dt_inclusao: arr[i].Dt_inclusao,
                Num_protocolo: arr[i].Num_protocolo,
                Ano_protocolo: arr[i].Ano_protocolo,
                Dt_lancamento: arr[i].Dt_lancamento
              });
            }
          }
          setDataTable(state => ( {...state, count: filteredData.length, registrationRequests: filteredData }) );
          setLoadingData(false);
        } else {
          setDataTable(state => ( {...state, count: arr.length-2, registrationRequests: arr }) );
          setLoadingData(false);
        }

        methods.totalDebits(arr)

        setLoadingData(false);
      } else {
        errorNotify('Ops, algo deu errado.', 'Não foi possível carregar os dados no memento. Tente atualizar a página!')
      }
    },

    getCredito: async () => {
      services.getCredito().then(value => {
        let data = value;
        if (data) {
          setCredit(data.Creditos);
          setOverdueDebts(data.SaldoVencido);
          setDebtsDue(data.SaldoNaoVencido);
        }
      });
    },

    viewDebitosTerceiro: async (value) => {
      methods.clearTableData();
      setLoadingData(true);
      setOpenDebitosTerceiro('active');
      setOpenLiquidaFacilTab('');
      setNextMaturityTab('');
      setEntryMaturityTab('');
      setOpenBilletsTab('');
      setMaturityTab('');
      setSelectedItens([]);
      setTotalSelectedValue(0.00);

      const debitosTerceiro = await services.getDebitosTerceiro();

      if (debitosTerceiro) {
        let arr = [];
        debitosTerceiro.Lancamentos.map((v, index) => {
          arr.push({
            key: index,
            number: index,
            description: v.Competidor.NomeCompetidor,
            maturity: '',
            value: 0,
            Id_tipo_lancamento: 'NOME_COMPETIDOR',
            Status_lancamento: '',
            Dt_inclusao: '',
            Num_protocolo: '',
            Ano_protocolo: '',
            Dt_lancamento: '',
          })
          v.Lancamentos.map(v => {
            arr.push({
              key: v.Id_lancamento,
              number: v.Id_lancamento,
              description: v.Obs_lancamento + ' ' + v.Ds_tipo_lancamento,
              maturity: v.Dt_vencimento_original,
              value: v.Vlr_lancamento,
              Id_tipo_lancamento: v.Id_tipo_lancamento,
              Status_lancamento: v.Status_lancamento,
              Dt_inclusao: v.Dt_inclusao,
              Num_protocolo: v.Num_protocolo,
              Ano_protocolo: v.Ano_protocolo,
              Dt_lancamento: v.Dt_lancamento,
            })
          })
        })

        setDataTable(state => ({
          ...state,
          count: arr.length,
          registrationRequests: arr
        }));
        setTotalDebits(debitosTerceiro.Total);
        setDebtsDue(methods.debtsDue(arr));
        setOverdueDebts(methods.overdueDebts(arr));
        setLoadingData(false);
      }

    },

    handleInputList: async (e) => {
      let character = e.target.value;
      actions.searchTable(character);
    },

    handleMaturities: async (e) => {
      setTypeMaturity(e);
      await actions.viewEntryMaturities(null, e);
    },

    searchTable: async (v) => {
      if(openLiquidaFacilTab === 'active') {
        await actions.viewLiquidaFacil(v);
      } else if(openDebitosTerceiro === 'active') {
        await actions.viewDebitosTerceiro(v);
      } else if(openBilletsTab === 'active') {
        await actions.viewOpenBillets(v);
      } else if(entryMaturityTab === 'active') {
        await actions.viewEntryMaturities(v);
      }
    },

    getParcelBillet: async () => {
      let parcelBillet = await services.parcelBillet();
      setPayBilletOk(false);

      if(parcelBillet.nnrParcelas == 0 || useCredOk) {
        setParcel(1);
        setDiaVctoBoleto(0);
        setNumberParcelsSelected(1);
        setPayBilletAnimation(false);

        await actions.payment.payByBillet();

      } else {
        setPayBilletAnimation(true);
        setParcel(parcelBillet.nnrParcelas);
        setDiaVctoBoleto(parcelBillet.nnrDiaVctoBoleto);
      }
    },

    handleSelectAll: async (v) => {
      setSelectAll(v);
      document.querySelector(".ant-table-thead tr .ant-table-selection-column .ant-table-selection .ant-checkbox-input").click();
    },

    handleToken: async () => {
      if (token) {
        setToken(token);
        await authApiPagamentos(token, d);
      }
      return true;
    },

    payment: {

      payByCreditCard: async () => {
        var validNumber = Payment.fns.validateCardNumber(cardState.number);
        var validExp = Payment.fns.validateCardExpiry(cardState.expiry);
        var validCvc = Payment.fns.validateCardCVC(cardState.cvc);

        setPayCardOk(true);

        if (!validNumber) {
          notification.error({
            message: 'Número do cartão inválido',
            description:
              'Atenção, o número do cartão informado não é válido!',
          });
          setPayCardOk(false);
          return false;
        }

        if (!validExp) {
          notification.error({
            message: 'Data de validade inválida',
            description:
              'Atenção, a data de vencimento está incorreta!',
          });
          setPayCardOk(false);
          return false;
        }

        if (!validCvc) {
          notification.error({
            message: 'CVC inválido',
            description:
              'Atenção, o código de verificação (CVC) é inválido!',
          });
          setPayCardOk(false);
        }

        if (numberParcelsSelected === 0) {
          notification.error({
            message: 'Selecione as Parcelas',
            description:
              'Por favor, selecione a quantidade de parcelas para este pagamento.',
          });
          setPayCardOk(false);
          return false;
        }

        const payload = {
          AssociadoABQMUserId: Number(getTokenApiPagamentos().userId),
          NumeroDoCartao: cardState.number,
          BandeiraDoCartao: cardState.issuer === 'mastercard' ? 'Master' : cardState.issuer,
          NomeImpressaoNoCartao: cardState.name,
          DataDeExpiracaoDoCartao: cardState.expiry,
          CVV: cardState.cvc.trim(),
          Valor: Number(cartTotal.toFixed(2)),
          NumeroDeParcelas: numberParcelsSelected,
        };

        console.log('payload', payload);

        const payCc = await services.payCc(payload);
        if (!payCc) errorNotify('Ops, algo deu errado com o seu pagamento.', 'Tente novamente mais tarde.');

        setPayCardOk(false);

        if (!payCc.Response.Ok) {
          errorNotify(
            payCc.Response.Message,
            methods.reason(payCc.Fields.PaymentData)
          );
          await actions.closePayment();
          actions.payment.updateDataTable();
          methods.clearCard();
          await actions.fetchData();
          await actions.getCredito();

          setSelectedItens([]);
          setTotalSelectedValue(0.0);
          return false;
        }

        setPayCardOk(false);
        notification.success({
          message: 'Pagamento efetuado com sucesso!',
          description:
            'O seu pagamento foi processado com sucesso. Obrigado!',
        });

        setIdReceipt(payCc.IdRecibo);
        setReceiptAnimation(true);
      },

      payByBillet: async () => {
        setPayBilletOk(true);

        const cartTotal = await services.cartTotal();

        if(openLiquidaFacilTab === 'active') {
          var cartTotalDiscount = cartTotal*(1-discountValueBillet/100);
          var cartValue = cartTotalDiscount;

        } else {
          var cartValue = cartTotal;
        }

        if(numberParcelsSelected > 1) {
          cartValue = cartValue/numberParcelsSelected;
        }

        const payload = {
          AssociadoABQMUserId: Number(getTokenApiPagamentos().userId),
          Valor: Number(cartValue.toFixed(2)),
          DataDeVencimento: "2021-01-23T00:00:00",
          QteParcelas: numberParcelsSelected,
          DiasVencimento: diaVctoBoleto,
        }

        const payBillet = await services.payBillet(payload);
        setBillets(payBillet);

        if (!payBillet) errorNotify('Ops, algo deu errado com o seu pagamento.', 'Tente novamente mais tarde.');

        if (!payBillet[0].Response.Ok) {
          errorNotify(payBillet[0].Response.Message);
          await actions.closePayment();
          actions.payment.updateDataTable();
          await actions.fetchData();
          await actions.getCredito();
          setSelectedItens([]);
          setTotalSelectedValue(0.00);
          return false;
        }

        setPayBilletOk(false);
        notification.success({
          message: 'Boleto gerado com sucesso!',
          description:
            'O seu boleto foi gerado e já pode ser baixado. Obrigado',
        });
        if (payBillet.length === 1) {
          setGeneratedBillet(payBillet[0].Fields.Url);
        } else {
          setGeneratedBillet(true);
        }
      },

      payByCredit: async () => {
        setPayCredOk(true);

        const payCredit = await services.payCredit();
        if (!payCredit) errorNotify('Ops, algo deu errado com o seu pagamento.', 'Tente novamente mais tarde.');

        if (!payCredit.Response.Ok) {
          errorNotify(payCredit.Response.Message);
          await actions.closePayment();
          actions.payment.updateDataTable();
          methods.clearCard();
          await  actions.fetchData();
          await  actions.getCredito();
          setPayCredOk(false);

          setSelectedItens([]);
          setTotalSelectedValue(0.00);
          return false;
        }
        notification.success({
          message: 'Pagamento efetuado com sucesso!',
          description:
            'O seu pagamento foi processado com sucesso. Obrigado!',
        });
        setPayCredOk(false);
        setIdReceipt(payCredit.IdRecibo);
        setPayAnimation(false);
        setReceiptAnimation(true);
      },

      useCredit: async () => {

        setUseCredOk(true);
        const useCredit = await services.useCredit();
        if (!useCredit) errorNotify('Ops, algo deu errado', 'Tente novamente mais tarde.');

        if (!useCredit.Ok) {
          errorNotify(useCredit.Message);
          await actions.closePayment();
          actions.payment.updateDataTable();
          methods.clearCard();
          await  actions.fetchData();
          await  actions.getCredito();
          setUseCredOk(false);

          setSelectedItens([]);
          setTotalSelectedValue(0.00);
          return false;
        }

        const cartSumary = await services.cartSummary();

        notification.success({
          message: 'Seu carrinho foi atualizado.',
          description:
            'Seu carrinho foi atualizado com o valor do seu crédito. Escolha uma forma de pagamento.',
        });

        const newCart = cartSumary.Fields.CarrinhoResumoData[0];
        setCartTotal(newCart.Saldo);
        setServiceTotal(newCart.TotalServico);
        setUseCredOk(false);
        setUsedCredit(true);
      },

      downloadBillet: async (url = '') => {
        if(billets.length > 1 && url === '') {
          setPayBilletAnimation(false);
          setDownloadBilletAnimation(true);

        } else {
          if(url !== '') {
            var win = window.open(url, '_blank');
            win.focus();
          } else {
            var win = window.open(generatedBillet, '_blank');
            win.focus();
            setDrawerPayment(false);
          }
        }

        const cleanCart = await services.cleanCart();

        if (!cleanCart) {
          errorNotify('Algo deu erro.');
          return false;
        }

        setPayAnimation(true);
        setPayBilletAnimation(false);
        setReceiptAnimation(false);
        setPayBilletOk(false);
        setGeneratedBillet('');
        setNumberParcelsSelected(0);
        setDiaVctoBoleto(0);
        setParcel(0);
  
        document.body.classList.remove('open-payment');
  
        setUseCredOk(false);
        setUsedCredit(false);
      },

      downloadReceipt: async (value) => {
        let url = api.baseUrl+api.financeiro+'/downloadRecibo/'+value;
        var win = window.open(url, '_blank');
        win.focus();

        const cleanCart = await services.cleanCart();
        if (!cleanCart) {
          errorNotify('Algo deu erro.');
          return false;
        }
        setDrawerPayment(false);
        setPayAnimation(true);
        setReceiptAnimation(false);
        setPayBilletOk(false);
        setGeneratedBillet('');
        setNumberParcelsSelected(0);
  
        document.body.classList.remove('open-payment');
  
        setUseCredOk(false);
        setUsedCredit(false);
        methods.clearCard();
        actions.closePayment();
        actions.payment.updateDataTable();
        await actions.fetchData();
        await actions.getCredito();

      },
  
      updateDataTable: () => {

        let arr = [];
        selectedItens.map(v => {
          arr.push(v.key)
        });

        let newDataTable = {
          registrationRequests: []
        }

        newDataTable.registrationRequests = dataTable.registrationRequests.filter(item => !arr.includes(item.key))
        setSelectedItens([]);

        setDataTable(newDataTable);

        methods.debtsDue(newDataTable.registrationRequests);
        methods.overdueDebts(newDataTable.registrationRequests);

        setTotalSelectedValue(0.00);

      },

      handleInputFocus: (e) => {
        const { name, value } = e.target;
        setCardState(state => ({...state, focus: name }) );
      },

      handleInputChange: (e) => {
        const { name, value } = e.target;
        setCardState(state => ( {...state, [name]: value }) );

      },

    }
  }

  const optionsParcel = [];
  for (let i = 1; i <= parcel; i++) {

    if(openLiquidaFacilTab === 'active') {
      if(i == 1) {
        var cartValue = cartTotal*(1-discountValueAVista/100);
      } else {
        var cartValue = cartTotal*(1-discountValueParcelado/100);
      }

    } else {
      var cartValue = cartTotal;
    }

    optionsParcel.push(<Option value={i} key={i}>{i} x (
      <CurrencyFormat
        value={cartValue / i}
        thousandSeparator={'.'}
        decimalSeparator={','}
        displayType={'text'}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'R$ '} />
    )</Option>);
  }

  useEffect( () => {

    async function init() {
      await actions.handleToken();
      actions.getEntryMaturity();
      actions.fetchData();
      actions.getCredito();
      methods.checkLiquidaFacil();
      setTypeMaturity('all');
      let slug = window.location;
  
      if (slug.pathname === "/pagamentos/debitos-terceiros") {
        setDebitosTerceiro(true);
      }
      methods.minBillet();
  
    }
    init();

  }, [token])

  if (preLoading) {
    return <PreLoader removeMargin />;
  } else if (loading) {
    return (
      <Container loading={loading}>
        <Loader light />
      </Container>
    );
  } else {
    return (
      <Container className={drawerPayment}>
        <Breadcrumb />
        <Helmet>
          <title>Pagamentos - Central do Quartista</title>
        </Helmet>

        <MainTitleWrap>
          <MainTitle>Pagamentos Online</MainTitle>

          <Filters style={{display: 'none'}}>
            <Form layout="inline" name="searchFormPayments">
              <Form.Item label="busca:"  name="search">
                <Input />
              </Form.Item>
            </Form>

            <ButtonApp>
              <Button
                onClick={() => actions.showDrawer()}
                shape="round"
                type="filter"
              >
                <BsFilter size={16} /> Filtro
              </Button>
            </ButtonApp>
          </Filters>
        </MainTitleWrap>

        <Drawer
          title="Filtros"
          placement="right"
          closable={false}
          onClose={() => actions.closeDrawer()}
          visible={visible}
        >
          <h3>Filtrar por tipo: </h3>
          <Radio
            options={[{label: "Abertos", value: 1}]}
            optionType="button"
          />
        </Drawer>

        <Clear/>

        <StyledCustomScrollBar color={"rgb(0 0 0 / 5%)"}>

        <Row gutter={16}>

          {/*Vião geral*/}
          <Col span={24} className="col-charts">

            <Row gutter={16}>

              {/*Resumo geral*/}
              <Col md={24} lg={9}>
                <Block>
                  <TitleBlock>Resumo Geral</TitleBlock>
                  <Wrap>
                    <Overview>
                      <Row justify="space-around" align="middle">
                        <Col xs={24} md={12} xxl={7}>
                          <GraphAndCharacteristics>
                            <Graph >
                              <DonutChart
                                data={dataOverview}
                                options={ {
                                  padAngle: 5,
                                  sortByValue: false,
                                  innerRadius: 0.5,
                                  label: false }
                                }/>
                            </Graph>
                          </GraphAndCharacteristics>
                        </Col>
                        <Col xs={24} md={12} xxl={5}>
                          <Characteristics>
                            {dataOverview.map((item) => (
                              <li key={item.id}>
                                <CharacteristicsText color={item.color}>
                                  {item.label}
                                </CharacteristicsText>
                              </li>
                            ))}
                          </Characteristics>
                        </Col>
                        <Col md={24} xxl={12}>
                          <TotalOfDebt>
                            <h3>Total de Débitos</h3>

                            <CurrencyFormat
                              value={totalDebits}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              displayType={'text'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={'R$ '} />
                          </TotalOfDebt>

                      </Col>
                      </Row>
                    </Overview>
                  </Wrap>
                </Block>
              </Col>

              {/*A vencer*/}
              <Col md={24} lg={5}>
                <Block>
                  <Wrap>
                    <Metricas colors="darkYellow">
                      <Row justify="space-around" align="middle">
                        <Col md={24} xxl={7}>
                          <MetricasGraphic>
                            <CircularProgressbar value={Math.round(debtsDue/(debtsDue+overdueDebts)*100)} color={colors.darkYellow} strokeWidth="12" />
                          </MetricasGraphic>
                        </Col>
                        <Col md={24} xxl={17}>
                          <MetricasBlock>
                            <h3>A Vencer</h3>
                            <CurrencyFormat
                              value={debtsDue}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              displayType={'text'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={'R$ '} />
                          </MetricasBlock>
                        </Col>
                      </Row>
                    </Metricas>
                  </Wrap>
                </Block>
              </Col>

              {/*Vencido*/}
              <Col md={24} lg={5}>
                <Block>
                  <Wrap>
                    <Metricas colors="orange">
                      <Row justify="space-around" align="middle">
                        <Col md={24} xxl={7}>
                          <MetricasGraphic>
                            <CircularProgressbar value={Math.round(overdueDebts/(debtsDue+overdueDebts)*100)} color={colors.orange} strokeWidth="12" />
                          </MetricasGraphic>
                        </Col>
                        <Col md={24} xxl={17}>
                          <MetricasBlock>
                            <h3>Vencido</h3>
                            <CurrencyFormat
                              value={overdueDebts}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              displayType={'text'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={'R$ '} />
                          </MetricasBlock>
                        </Col>
                      </Row>
                    </Metricas>
                  </Wrap>
                </Block>
              </Col>

              {/*Crédito*/}
              <Col md={24} lg={5}>
                <Block>
                  <Wrap className="credito-block">
                    <Metricas colors="success">
                      <Row justify="space-around" align="middle">
                        <Col md={24} xxl={7}>
                          <MetricasGraphic>
                            <img src={okIcon} alt="ok" />
                          </MetricasGraphic>
                        </Col>
                        <Col md={24} xxl={17}>
                          <MetricasBlock>
                              <h3>Crédito</h3>
                              <CurrencyFormat
                                value={credit}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'R$ '} />
                          </MetricasBlock>
                        </Col>
                      </Row>
                    </Metricas>
                  </Wrap>
                </Block>
              </Col>

            </Row>

          </Col>

          {/*Tabela*/}
          <Col span={24} className="tabelaData">
            <Tabs>

              <Badge count={dataNextMaturities.registrationRequests.length}>
                <Button
                  onClick={() => actions.viewEntryMaturities()}
                  className={entryMaturityTab}
                  type="link"
                >
                  Lançamentos
                </Button>
              </Badge>

              <Badge count={dataOpenBillets.registrationRequests.length}>
                <Button
                  onClick={() => actions.viewOpenBillets()}
                  className={openBilletsTab}
                  type="link"
                  block>
                  Boletos em Aberto
                </Button>
              </Badge>

              {
                (liquidaFacil.PercentualAVista > 0 ||
                  liquidaFacil.PercentualBoleto > 0 ||
                  liquidaFacil.PercentualParcelado > 0
                ) &&
                <Badge>
                  <Button
                    onClick={() => actions.viewLiquidaFacil()}
                    className={openLiquidaFacilTab}
                    type="link"
                    block>
                    Liquida Fácil
                  </Button>
                </Badge>
              }

              { debitosTerceiro &&
                <Badge>
                  <Button
                    onClick={() => actions.viewDebitosTerceiro()}
                    className={openDebitosTerceiro}
                    type="link"
                    block>
                    Débito de Terceiros
                  </Button>
                </Badge>
              }

            </Tabs>


            <Block>
              <TitleBlock>Extrato: <strong>Mês atual</strong>
                <TotalRecords>{dataTable.count === 0 || dataTable.count === 1 ? dataTable.count + ' Registro' : dataTable.count + ' Registros'}</TotalRecords>
              </TitleBlock>

              <Filters>
                <Row>
                  { entryMaturityTab &&
                    <Radio
                      onChange={ (value) => actions.handleMaturities(value) }
                      value={ typeMaturity }
                      options={[
                        { label: "Todos", value: 'all' },
                        { label: "Vencidos", value: 'vencido' },
                        { label: "A Vencer", value: 'avencer' },
                      ]}
                    />
                  }

                  <SearchBox
                    name="search"
                    placeholder={`${openBilletsTab === 'active' ? "filtrar por número" : "filtrar por descrição" }`}
                    onChange={actions.handleInputList}
                  />
                </Row>
              </Filters>
              <br/>
              <Wrap>
                <TableBody>
                  <Table
                    className={`table-payments ${openLiquidaFacilTab || openDebitosTerceiro ? 'liquida-facil-active' : ''}`}
                    size="middle"
                    rowSelection={selectBox}
                    loading={loadingData}
                    columns={tableColumns}
                    dataSource={dataTable.registrationRequests}
                    pagination={{
                      pageSize: openLiquidaFacilTab || openDebitosTerceiro ? 0 : dataTable.registrationRequests.length,
                      showSizeChanger: false,
                      position: ['bottomRight']
                    }}
                    rowClassName={(record, index) => record.Id_tipo_lancamento === 'NOME_COMPETIDOR' ? 'nome-competidor' : '' }
                    footer={openBilletsTab !== 'active' ? footer : null}
                  />
                </TableBody>

                {openBilletsTab !== 'active' &&

                <SelectedTotal>

                  <Row gutter={16} justify="space-around" align="middle">


                    <Col md={24} lg={12}>
                      <PaymentButton>
                        <ButtonApp>
                          <Button
                            onClick={() => actions.makePayment()}
                            disabled={!selectedItens.length > 0}
                            type="primary"
                            shape="round"
                            icon={<ArrowRightOutlined />}
                            size="large"
                            loading={stateMakePayment}
                          >
                            Realizar Pagamento
                          </Button>
                        </ButtonApp>
                      </PaymentButton>
                    </Col>

                    <Col md={24} lg={12} style={{ textAlign: 'right' }}>
                      <span style={{ marginRight: '20px' }}>Total Selecionado</span>
                      <span><CurrencyFormat
                        value={totalSelectedValue}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={'R$ '} /> </span>
                    </Col>

                  </Row>

                </SelectedTotal>
                }

              </Wrap>
            </Block>
          </Col>
        </Row>

        </StyledCustomScrollBar>

        <Drawer
          className="payment-drawer"
          title="Pagamento Online - Checkout"
          width={550}
          placement="right"
          closable={true}
          onClose={actions.closePayment}
          visible={drawerPayment}
          getContainer={true}
          destroyOnClose={true}
        >
          <Drawer
            title="Meus Cartões de Créditos"
            width={320}
            closable={false}
            onClose={actions.closeCards}
            visible={drawerCards}
          >
            {savedCards.map((item, index) => (
              <div key={index} className="btn-select-cards-wrap">
              <button
                onClick={actions.selectCard}
                value={item.number}
                className={item.number === cardState.number ? 'active list-cards' : 'list-cards'}
                >{item.label}</button>
                <button
                  onClick={() => actions.deleteCard(item.id)}
                  className="delete-card"
                ><BsTrash size={20} /></button>
              </div>
            ))}

          </Drawer>

          <PaymentSumary>
            <h2>{(!receiptAnimation) ? 'Total a Pagar: ' : 'Total Pago: '}<CurrencyFormat
              value={cartTotal}
              thousandSeparator={'.'}
              decimalSeparator={','}
              displayType={'text'}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'R$ '} /></h2>

            {openLiquidaFacilTab === 'active' &&
              <>
                <h3><img src={okIcon} alt="ok" /> Cartão a vista - {discountValueAVista}% desc: <CurrencyFormat
                  value={cartTotal * (1-discountValueAVista/100)}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'R$ '} />
                </h3>
                <h3><img src={okIcon} alt="ok" /> Cartão parcelado - {discountValueParcelado}% desc: <CurrencyFormat
                  value={cartTotal * (1-discountValueParcelado/100)}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'R$ '} />
                </h3>
                <h3><img src={okIcon} alt="ok" /> Boleto a vista - {discountValueBillet}% desc: <CurrencyFormat
                  value={cartTotal * (1-discountValueBillet/100)}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'R$ '} />
                </h3>
              </>
            }

            {credit > 0 && !payBilletAnimation && !downloadBilletAnimation &&
            <h3><img src={okIcon} alt="ok" /> Seus Créditos: <CurrencyFormat
              value={credit}
              thousandSeparator={'.'}
              decimalSeparator={','}
              displayType={'text'}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'R$ '} />
              </h3>
            }

            <Button
              onClick={() => actions.payment.useCredit()}
              className="btn-abqm"
              style={{width: '50%', display: credit > 0 ? 'block' : 'none', margin: 'auto'}}
              block={true}
              type="primary"
              size="large"
              loading={useCredOk}
              disabled={payBilletOk || usedCredit || receiptAnimation || payBilletAnimation || generatedBillet || downloadBilletAnimation}
            >
              Utilizar Crédito
            </Button>
          </PaymentSumary>

          <PaymentOption>
            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={400}
              animationOutDuration={400}
              isVisible={payAnimation}
              animateOnMount={false}
              style={ {display: payAnimation && !payBilletAnimation && !downloadBilletAnimation ? 'block' : 'none'} }
            >

              <div>
                <h3>Escolha o meio de pagamento:</h3>

                {usedCredit && credit >= serviceTotal &&
                <Button
                  onClick={() => actions.payment.payByCredit()}
                  className="btn-payment payment-option"
                  block={true}
                  type="primary"
                  size="large"
                  loading={payCredOk}
                  disabled={payBilletOk || generatedBillet}
                >
                  <span className="img-icon"><img src={okWIcon} alt="ok" /></span> Crédito da Conta
                </Button>
                }

                {!usedCredit  && credit > 0 &&
                <Button
                  onClick={() => actions.payment.payByCredit()}
                  className="btn-payment payment-option"
                  block={true}
                  type="primary"
                  size="large"
                  loading={payCredOk}
                  disabled={payBilletOk || generatedBillet}
                >
                  <span className="img-icon"><img src={okWIcon} alt="ok" /></span> Crédito da Conta
                </Button>
                }

                {!usedCredit &&

                <Button
                  className="btn-payment"
                  onClick={() => setPayAnimation(!payAnimation)}
                  block={true}
                  type="primary"
                  size="large"
                  disabled={payBilletOk || generatedBillet}
                >
                  <span className="img-icon"><img src={creditCardIcon} alt="ok" /></span> Cartão de Crédito (em até {parcel}x)

                </Button>
                }

                {!usedCredit && cartTotal >= minValueBillet &&
                  <Button
                    onClick={!generatedBillet ? () => actions.getParcelBillet() : () => actions.payment.downloadBillet()}
                    className={!payBilletOk && generatedBillet ? 'btn-payment download' : 'btn-payment'}
                    loading={payBilletOk}
                    block={true}
                    type="primary"
                    size="large">
                    <span className="img-icon"><img src={barCodeIcon} alt="ok" /></span>
                    {
                      (!payBilletOk && !generatedBillet) ? ' Gerar boleto' :
                        (payBilletOk && !generatedBillet) ? ' Gerando Boleto...' :
                          (!payBilletOk && generatedBillet) ? ' Clique para Baixar o Boleto' : ''
                    }
                  </Button>
                }

                {usedCredit && credit < serviceTotal &&
                  <Button
                    className="btn-payment"
                    onClick={() => setPayAnimation(!payAnimation)}
                    block={true}
                    type="primary"
                    size="large"
                    disabled={payBilletOk || generatedBillet}
                  >
                    <span className="img-icon"><img src={creditCardIcon} alt="ok" /></span> Cartão de Crédito (em
                    até {parcel}x)
                  </Button>
                }

                {usedCredit && credit < serviceTotal && cartTotal >= minValueBillet &&
                <Button
                  onClick={!generatedBillet ? () => actions.getParcelBillet() : () => actions.payment.downloadBillet()}
                  className={!payBilletOk && generatedBillet ? 'btn-payment download' : 'btn-payment'}
                  loading={payBilletOk}
                  block={true}
                  type="primary"
                  size="large">
                  <span className="img-icon"><img src={barCodeIcon} alt="ok" /></span>
                  {
                    (!payBilletOk && !generatedBillet) ? ' Gerar boleto' :
                      (payBilletOk && !generatedBillet) ? ' Gerando Boleto...' :
                        (!payBilletOk && generatedBillet) ? ' Clique para Baixar o Boleto' : ''
                  }
                </Button>
              
                }


              </div>

            </Animated>


            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={400}
              animationOutDuration={400}
              isVisible={!payAnimation && !receiptAnimation && !payBilletAnimation}
              animateOnMount={true}
              style={ {display: !payAnimation && !receiptAnimation && !payBilletAnimation ? 'block' : 'none'} }
            >
              <div>

                <Button
                  className="card-selection"
                  onClick={() => actions.showCards()}
                  shape="round"
                  disabled={payCardOk}
                  loading={stateCards}
                >
                  Selecionar Cartão Salvo
                </Button>

                <h3>Dados do Cartão de Crédito:</h3>

                <div id="PaymentForm" className={payCardOk ? "process" : ''}>

                  <Cards
                    cvc={cardState.cvc}
                    expiry={cardState.expiry}
                    focused={cardState.focus}
                    name={cardState.name}
                    number={cardState.number}
                    locale={{valid: 'Válido até'}}
                    placeholders={{name: 'SEU NOME AQUI'}}
                    callback={methods.getIssuer}
                  />
                  <form className="ant-form form-card">
                    <Row gutter={16} justify="center">
                      <Col span={18} >
                        <div className="ant-form-item-control-input-content">
                          <CurrencyFormat
                            format="#### #### #### ####"
                            className="ant-input cc-num"
                            id="ccNum"
                            type="tel"
                            name="number"
                            placeholder="Número do Cartão"
                            onChange={actions.payment.handleInputChange}
                            onFocus={actions.payment.handleInputFocus}
                            value={cardState.number}
                          />

                        </div>
                      </Col>

                      <Col span={18} >
                          <Form.Item>
                            <input
                              className="ant-input"
                              type="text"
                              name="name"
                              placeholder="Nome do Titular"
                              onChange={actions.payment.handleInputChange}
                              onFocus={actions.payment.handleInputFocus}
                              maxLength="20"
                              value={cardState.name}
                            />
                          </Form.Item>
                      </Col>

                      <Col span={12} >
                        <div className="ant-form-item-control-input-content">
                          <CurrencyFormat
                            className="ant-input cc-exp"
                            type="text"
                            name="expiry"
                            placeholder="Data de Validade"
                            onChange={actions.payment.handleInputChange}
                            onFocus={actions.payment.handleInputFocus}
                            format={methods.cardExpiry}
                            value={cardState.expiry}
                          />
                          <div className="ant-form-item-label">
                            Digite no formato: mm/aaaa
                          </div>
                        </div>
                      </Col>

                      <Col span={6} >
                        <div className="ant-form-item-control-input-content">
                          <CurrencyFormat
                            format="####"
                            className="ant-input cc-cvc"
                            type="tel"
                            name="cvc"
                            placeholder="CVC"
                            onChange={actions.payment.handleInputChange}
                            onFocus={actions.payment.handleInputFocus}
                          />
                        </div>
                      </Col>

                      <Col span={18}>
                        <Form.Item>
                          <Select
                            placeholder="Parcelas"
                            className="select-input"
                            onChange={setNumberParcelsSelected}
                          >
                            {optionsParcel}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </form>


                  <div className="form-card">
                    <Button
                      onClick={() => actions.payment.payByCreditCard()}
                      disabled={cardState.number.replace(/ /g,'').length != 16 ||
                      cardState.name.replace(/ /g,'').length < 3 ||
                      cardState.expiry.replace(/ /g,'').length < 7 ||
                      cardState.cvc.replace(/ /g,'').length < 3
                      }
                      loading={payCardOk}
                      className="process-payment"
                      block={true}
                      type="primary"
                      size="large"
                    >
                      Processar Pagamento
                    </Button>

                    <Divider mBottom={2} />
                    <Button
                      onClick={() => setPayAnimation(!payAnimation)}
                      block={true}
                      type="back"
                      size="large">
                      <span className="img-icon"><BsArrowLeft size={26} /></span> Voltar
                    </Button>

                  </div>


                </div>
              </div>
            </Animated>

            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={400}
              animationOutDuration={400}
              isVisible={payBilletAnimation}
              animateOnMount={true}
              style={ {display: payBilletAnimation ? 'block' : 'none'} }
            >
              <div>
                <h3 style={ {display: generatedBillet ? 'none' : 'block'} }>Selecione a quantidade de parcelas:</h3>
                <div id="PaymentForm" className={payBilletOk ? "process" : ''}>
                  <form className="ant-form form-card">
                    <Row gutter={16} justify="center">
                      <Col span={18} >
                        <Form.Item>
                          <Select
                            placeholder="Parcelas"
                            className="select-input"
                            disabled={generatedBillet}
                            style={ {display: generatedBillet ? 'none' : 'block'} }
                            onChange={setNumberParcelsSelected}> 
                            {optionsParcel}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </form>

                  <div>
                    <Button
                      onClick={!generatedBillet ? () => actions.payment.payByBillet() : () => actions.payment.downloadBillet()}
                      className={!payBilletOk && generatedBillet ? 'btn-payment download' : 'btn-payment'}
                      loading={payBilletOk}
                      block={true}
                      type="primary"
                      size="large">
                      <span className="img-icon"><img src={barCodeIcon} alt="ok" /></span>
                      {
                        (!payBilletOk && !generatedBillet) ? ' Gerar boleto' :
                          (payBilletOk && !generatedBillet) ? ' Gerando Boleto...' :
                            (!payBilletOk && generatedBillet) ? ' Clique para Baixar o Boleto' : ''
                      }
                    </Button>
                  </div>
                </div>
              </div>
            </Animated>

            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={400}
              animationOutDuration={400}
              isVisible={receiptAnimation}
              animateOnMount={true}
              style={ {display: receiptAnimation ? 'block' : 'none'} }
            >
              <div>
                <Button
                  onClick={() => actions.payment.downloadReceipt(idReceipt)}
                  className="btn-payment download"
                  loading={payBilletOk}
                  block={true}
                  type="primary"
                  size="large">
                  <span className="img-icon"><img src={barCodeIcon} alt="ok" /></span>Clique para Baixar o Recibo
                </Button>
              </div>
            </Animated>

            <Animated
              animationIn="fadeInRight"
              animationOut="fadeOutLeft"
              animationInDuration={400}
              animationOutDuration={400}
              isVisible={downloadBilletAnimation}
              animateOnMount={true}
              style={ {display: downloadBilletAnimation ? 'block' : 'none'} }
            >
              <div>
                <h3>Lista de Boletos:</h3>
                  <div>
                    {billets.map((billet) => (
                      <Button
                        onClick={() => actions.payment.downloadBillet(billet.Fields.Url)}
                        className="btn-payment download"
                        loading={payBilletOk}
                        block={true}
                        type="primary"
                        size="small">
                        <span className="img-icon"><img src={barCodeIcon} alt="ok" /></span>
                        <small>Venc.:</small>{format(new Date(billet.Fields.ExpirationDate), "dd/MM/yyyy")}
                        <small>Val.:</small>{(billet.Fields.Amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                      </Button>
                    ))}
                </div>
              </div>
            </Animated>
          </PaymentOption>
        </Drawer>

        <GlobalStyle />
      </Container>
    );
  }
}
