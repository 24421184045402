import styled from 'styled-components';
import { Switch } from 'antd';

import { colors } from '../../theme';

export const Container = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${colors.blue};
  }
`;
