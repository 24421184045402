import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const AutoComplete = ({ name, size, width, withFilter, value, ...props }) => {
  return (
    <Container
      size={size}
      name={name}
      width={width}
      inputValue={value}
      id={name}
      filterOption={
        withFilter &&
        ((inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1)
      }
      {...props}
    />
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AutoComplete.defaultProps = {
  size: 'large',
  withFilter: true,
};

export default AutoComplete;
