import React, { createContext, useContext, useState } from 'react';

const IgnoreMainframeContext = createContext({});

const IgnoreMainframeProvider = ({ children }) => {
  const [ignoreMainframe, setIgnoreMainframe] = useState(false);

  return (
    <IgnoreMainframeContext.Provider
      value={{ ignoreMainframe, setIgnoreMainframe }}
    >
      {children}
    </IgnoreMainframeContext.Provider>
  );
};

function useIgnoreMainframe() {
  const context = useContext(IgnoreMainframeContext);

  if (!context) {
    throw new Error(
      'useIgnoreMainframe must be used within an IgnoreMainframe'
    );
  }

  return context;
}

export { IgnoreMainframeProvider, useIgnoreMainframe };
