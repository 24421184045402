import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import { colors } from '../../theme';

import { Container, LoadingIcon } from './styles';

const Button = ({
  children,
  width,
  height,
  color,
  fontLight,
  disabled,
  loading,
  bgDark,
  ...props
}) => {
  return (
    <Container
      color={color}
      fontLight={fontLight}
      disabled={disabled || loading}
      width={width}
      height={height}
      loading={loading}
      bgDark={bgDark}
      {...props}
    >
      {loading ? (
        <Spin indicator={<LoadingIcon height={height} />} />
      ) : (
        <div>{children}</div>
      )}
    </Container>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontLight: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  bgDark: PropTypes.bool,
};

Button.defaultProps = {
  color: colors.buttonGray,
  fontLight: false,
};

export default Button;
