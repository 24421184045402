import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';

import horseAnimated from '../../theme/assets/animations/horse.json';

import { Container } from './styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: horseAnimated,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loader = ({ light, size, message, sizeScreen }) => {
  const [iconSize, setIconSize] = useState(240);

  useEffect(() => {
    const handleIconSize = () => {
      switch (size) {
        case 'large':
          setIconSize(360);
          break;
        case 'middle':
          setIconSize(240);
          break;
        case 'small':
          setIconSize(120);
          break;
        default:
          setIconSize(240);
          break;
      }
    };

    handleIconSize();
  }, [size]);

  return (
    <Container
      size={size}
      light={light}
      message={message}
      sizeScreen={sizeScreen}
    >
      <Lottie options={defaultOptions} height={iconSize} width={iconSize} />
      <p>{message}</p>
    </Container>
  );
};

Loader.propTypes = {
  light: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  message: PropTypes.string,
};

Loader.defaultProps = {
  size: 'middle',
  message: 'Carregando...',
  sizeScreen: 'middle',
};

export default Loader;
