import styled from 'styled-components';
import media from 'styled-media-query';

import { fonts, colors } from '../../theme';

export const Container = styled.h1`
  ${fonts.size9};
  font-family: 'SFRounded-Thin', sans-serif;
  color: ${({ fontLight }) => (fontLight ? colors.white : colors.dark)};

  ${media.greaterThan('1600px')`
    ${fonts.size10};
  `}

  ${media.greaterThan('1900px')`
    ${fonts.size11};
  `}
`;
