import swal from 'sweetalert';

const errorHandler = (error) => {
    if (error?.response?.data?.success === false) 
        return swal('Algo de errado!', error.response.data.mensagem, 'error');

    if(error?.mensagem) 
        return swal('Algo de errado!', error.mensagem.toString(), 'error');

    return swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
}

export default errorHandler;
