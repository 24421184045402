import axios from 'axios';
import React, {useCallback} from 'react';

export const api = {
  baseUrl: process.env.REACT_APP_API_PAGAMENTOS || 
  'https://intranet.abqm.com.br/pagamentos/api',
  financeiro: '/Financeiro/v1',
  pagamentos: '/pagamentos/v1',
  Pagamentos: '/Pagamentos/v1',
  cartao: '/cartao/v1',
  geraBoleto: 'http://homolog.abqm.com.br/Revista_Reports/Pages/Contrato/GerarBoleto.aspx?id_fatura='
}

export const config = () => {
  return {
    headers: {
      Authorization: `Bearer ${getTokenApiPagamentos().token}`,
    }
  }
}

export const authApiPagamentos = (token, d) => {
  if (token) {
    return axios.post(`${api.baseUrl}/token`, {
      Usuario: token,
      Senha: "123213",
    }).then(v => {
      localStorage.setItem('@CentralDoQuartista:tokenPagamentos', v.data.Token)
      localStorage.setItem('@CentralDoQuartista:userIdPagamentos', v.data.UserId)

      if (d === 't') {
        window.location.href = "/pagamentos/debitos-terceiros";
      } else {
        localStorage.removeItem('@CentralDoQuartista:debitosTerceiros');
      }
    })
  }
}

export const getTokenApiPagamentos = () => {
  return {
    token: localStorage.getItem('@CentralDoQuartista:tokenPagamentos'),
    userId: localStorage.getItem('@CentralDoQuartista:userIdPagamentos'),
    resToken: localStorage.getItem('@CentralDoQuartista:token'),
  }
}

export const removeTokenApiPagamentos = () => {
  localStorage.removeItem('@CentralDoQuartista:tokenPagamentos');
  localStorage.removeItem('@CentralDoQuartista:userIdPagamentos');
};

export const lancamentosAVencer = () => {
  return axios.post(`${api.baseUrl}${api.financeiro}/lancamentosavencer`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId
  }, config() )
}

export const lancamentosVencidos = () => {
  return axios.post(`${api.baseUrl}${api.financeiro}/lancamentosvencidos`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId
  }, config() )
}

export const boletosEmAberto = () => {
  return axios.get(`${api.baseUrl}${api.financeiro}/listarfaturasaberto/${getTokenApiPagamentos().userId}`, config() )
}

export const minimumValueBillet = () => {
  return axios.get(`${api.baseUrl}${api.financeiro}/valorMinimoBoleto/`, config() )
}

export const obterCredito = () => {
  return axios.post(`${api.baseUrl}${api.financeiro}/saldoextratopessoa`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId
  }, config() )
}

export const inserirCarrinho = (IdLancamentos) => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/inserircarrinho`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId,
    IdLancamentos
  }, config() )
}

export const resumoCarrinho = () => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/resumocarrinho`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId,
  }, config() )
}

export const totalCarrinho = () => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/obtervalortotalcarrinho`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId,
  }, config() )
}

export const limparCarrinho = () => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/limparcarrinho`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId,
  }, config() )
}

export const pagamentoCartaoCredito = (payload) => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/pagamentocartaocredito`, payload, config() )
}

export const pagamentoBoleto = (payload) => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/pagamentoboleto`, payload, config() )
}

export const pagamentoCredito = () => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/pagamentoutilizandocredito`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId}, config() )
}

export const usarCredito = () => {
  return axios.get(`${api.baseUrl}${api.Pagamentos}/UtilizaCreditoCarrinho/${getTokenApiPagamentos().userId}`, config() )
}

export const cartao = (idCartao) => {
  const url = idCartao ? `/${api.cartao}/${idCartao}` : `/${api.cartao}/${getTokenApiPagamentos().userId}`
  return idCartao ?
    axios.delete(`${api.baseUrl}${api.cartao} /${url}`, config()) :
    axios.get(`${api.baseUrl}${url}`, config())
}

export const existeLiquidaFacil = () => {
  return axios.get(`${api.baseUrl}${api.financeiro}/ExisteLiquidaFacil/${getTokenApiPagamentos().userId}`, config() )
}

export const listarCampanhaLiquidaFacil = () => {
  return axios.get(`${api.baseUrl}${api.financeiro}/ListarCampanhaLiquidaFacil/${getTokenApiPagamentos().userId}`, config() )
}

export const obterQtdParcelas = (valor) => {
  return axios.post(`${api.baseUrl}${api.Pagamentos}/obterqtdparcelas`, {
    AssociadoABQMUserId: getTokenApiPagamentos().userId, valor
  }, config() )
}

export const listarDebitosTerceiro = () => {
  return axios.get(`${api.baseUrl}${api.financeiro}/listarDebitosTerceiro/${getTokenApiPagamentos().resToken}`, config() )
}

export const listarParcelasBoleto = () => {
  return axios.get(`${api.baseUrl}${api.financeiro}/parcelamentoBoletoChallenger`, config() )
}
