import React, { useState, useCallback } from 'react';
import swal from 'sweetalert';

import { Button, Checkbox, ExclamationSvg } from '../../../../components';

import { colors } from '../../../../theme';
import { api } from '../../../../services/api';

import { Container, ModalContent, CircleLoading } from './styles';

export default function BlockModal({
  isBlocked,
  modalIsOpen,
  toggleBlock,
  toggleModal,
  preRegister,
  detailsRequest,
}) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const switchModal = useCallback(() => {
    toggleModal();
    setChecked(false);
  }, [toggleModal]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await api.put('pedidoregistro/bloquear', {
        id_tp_pendencia: 0,
        id_status: isBlocked ? 2 : 1,
        num_preregistro: preRegister,
        nome_pai: detailsRequest.NOME_PAI,
        nome_mae: detailsRequest.NOME_MAE,
        nome_produto: detailsRequest.nome_produto,
        id_solicitante: detailsRequest.id_solicitante,
        nome_solicitante: detailsRequest.nome_solicitante,
      });

      if (data.success) {
        if (isBlocked) {
          swal('Sucesso', 'Pedido de registro desbloqueado!', 'success');
        } else {
          swal('Sucesso', 'Pedido de registro bloqueado!', 'success');
        }
        toggleBlock(!isBlocked);
        switchModal();
      } else {
        swal('Algo de errado', data.message, 'error');
      }
    } catch (error) {
      console.log(error);
      swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
    } finally {
      setLoading(false);
    }
  }, [preRegister, isBlocked, switchModal, detailsRequest, toggleBlock]);

  return (
    <Container
      centered
      height={320}
      scroll={true}
      isVisible={modalIsOpen}
      width={window.innerWidth >= 1600 ? 600 : 500}
      title="Bloqueio de pedido"
      toggleVisibility={switchModal}
      footer={
        <>
          <Button onClick={switchModal} color={colors.red} fontLight>
            Cancelar
          </Button>

          <Button
            fontLight
            onClick={handleSubmit}
            color={colors.lightGreen}
            disabled={!checked}
          >
            Confirmar
          </Button>
        </>
      }
    >
      {isBlocked ? (
        <ModalContent>
          {loading ? (
            <CircleLoading />
          ) : (
            <>
              <ExclamationSvg />

              <h1>
                Você tem certeza que deseja desbloquear o pedido de registro
                <strong>{preRegister}</strong>?
              </h1>

              <Checkbox
                checkColor={colors.buttonGray}
                onChange={(checkedValue) => setChecked(checkedValue)}
                checked={checked}
              >
                Sim, quero desbloquear!
              </Checkbox>
            </>
          )}
        </ModalContent>
      ) : (
        <ModalContent>
          {loading ? (
            <CircleLoading />
          ) : (
            <>
              <ExclamationSvg/>

              <h1>
                Este pedido de registro será bloqueado e não poderá ser
                registrado enquanto você não desbloquear!
              </h1>

              <Checkbox
                checkColor={colors.buttonGray}
                onChange={(checkedValue) => setChecked(checkedValue)}
                checked={checked}
              >
                Quero bloquear!
              </Checkbox>
            </>
          )}
        </ModalContent>
      )}
    </Container>
  );
}
