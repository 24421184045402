import styled from 'styled-components';
import { Menu } from 'antd';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const StyledMenu = styled(Menu)`
  background: transparent;
  border: 0;
`;

export const StyledMenuItem = styled(Menu.Item)`
  line-height: ${pxToRem(35)} !important;
  height: ${pxToRem(35)} !important;

  margin: 0 !important;

  &,
  &:hover,
  a,
  a:hover {
    color: ${colors.darkGrey};
  }

  :hover {
    background: ${colors.lightGrey};
  }
`;
