import React, { useState, useEffect, useCallback } from 'react';
import swal from 'sweetalert';
import { message } from 'antd';
import { Helmet } from 'react-helmet';
import WrongInspections from './WrongInspections';
import RealizedInspections from './RealizedInspections';

import {
  Radio,
  FormItem,
  PreLoader,
  Breadcrumb,
  MaskedInput,
} from '../../components';
import { colors } from '../../theme';
import { api } from '../../services/api';
import { useAuth } from '../../context/Auth';

import {
  Form,
  Drawer,
  Container,
  DrawerButton,
} from './styles';

function InspectCarriedOut() {

  const pageTitle = 'Inspeções realizadas';

  const { userData } = useAuth();

  const initialParams = {
    tamanho_pag: 50,
    ano: new Date().getFullYear(),
    status_registro: '',
  };

  const [filterData, setFilterData] = useState({
    period: `${initialParams.ano}`,
    situation: initialParams.status_registro
  });

  const radioSituationOptions = [
    { value: '0', label: 'Todos' },
    { value: '1', label: 'Registrados' },
    { value: '', label: 'Não registrados' },
  ];
  
  const [loading, setLoading] = useState(true);
  const [preLoader, setPreLoader] = useState(true);
  const [inspectorId, setInspectorId] = useState(null);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [selectedTab, setSelectedTab] = useState('REALIZADAS'); // REALIZADAS | COM ERROS
  
  //filter
  const [params, setParams] = useState(initialParams);
  const [searchValue, setSearchValue] = useState('');
  const [filtered, setFiltered] = useState(false);
  
  //realizeds
  const [searchedRecordsRealizeds, setSearchedRecordsRealizeds] = useState([]);
  const [pageRealized, setPageRealized] = useState(1);
  const [totalRealizeds, setTotalRealizeds] = useState(0);
  const [realizedInpections, setRealizedInpections] = useState([]);
  const [allInspectCarried, setAllInspectCarried] = useState([]);
  
  //wrong
  const [searchedRecordsWrong, setSearchedRecordsWrong] = useState([]);
  const [pageWrong, setPageWrong] = useState(1);
  const [totalInspectionsWrong, setTotalInspectionsWrong] = useState(0);
  const [wrongInspections, setWrongInspections] = useState([]);
  const [allWrongInspections, setAllWrongInspections] = useState([]);
  
  const switchDrawer = () => setDrawerVisibility(!drawerVisibility);

  const fetchRealizedInspections = useCallback(
    async () => {
      try {
        const { data } = await api.get('inspecoes/realizadas', {
          params: { ...params, id_inspetor: inspectorId, pagina: pageRealized },
        });

        return data;
      } 
      catch (error) {
        swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
      }
    },
    [inspectorId, pageRealized, params]
  );

  const fetchRealizedInspectionsWrong = useCallback(
    async () => {
      try {
        const { data } = await api.get('inspecoes/Erradas', {
          params: { ...params, id_inspetor: inspectorId, pagina: pageWrong },
        });

        return data;
      } 
      catch (error) {
        swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
      }
    },
    [inspectorId, pageWrong, params]
  );

  // get more data when user scroll
  // the screen until the last register
  const fetchMoreDataRealizeds = async () => {
    setLoading(true);

    const { data, total } = await fetchRealizedInspections();

    setPageRealized(pageRealized + 1);
    setTotalRealizeds(total);
    setRealizedInpections([...realizedInpections, ...data]);
    setAllInspectCarried([...allInspectCarried, ...data]);

    setLoading(false);
    setPreLoader(false);
  };

  // get more data when user scroll
  // the screen until the last register
  const fetchMoreDataWrong = useCallback(async () => {
    setLoading(true);

    const { data, total } = await fetchRealizedInspectionsWrong();

    setPageWrong(pageWrong + 1);
    setTotalInspectionsWrong(total);
    setWrongInspections([...wrongInspections, ...data]);
    setAllWrongInspections([...allWrongInspections, ...data]);

    setLoading(false);
    setPreLoader(false);
  }, [pageWrong, wrongInspections, allWrongInspections, fetchRealizedInspectionsWrong]);

  const popupWarningMessage = useCallback((totalWrong, totalRealized) => {
    
    if(selectedTab === 'REALIZADAS' && totalWrong > 0)
      message.info('Existem Inspeções com Erro nessa mesma data!');

    if(selectedTab === 'COM ERROS' && totalRealized > 0)
      message.info('Existem Inspeções Realizadas nessa mesma data!');

  }, [selectedTab]);

  const fetchAllData = useCallback(async () => {

    const [realizedInspections, _wrongInspections] = await Promise.all([
      await fetchRealizedInspections(),
      await fetchRealizedInspectionsWrong()
    ]);

    popupWarningMessage(_wrongInspections.total, realizedInspections.total);

    return [realizedInspections, _wrongInspections];
  }, 
  [
    popupWarningMessage,
    fetchRealizedInspections, 
    fetchRealizedInspectionsWrong
  ]);

  const handleSubmitFilter = () => {
    setPageRealized(1);
    setPageWrong(1);
    setRealizedInpections([]);

    const filteredParams = {
      ...initialParams,
      pagina: 1,
      status_registro: filterData?.situation ? filterData?.situation : '',
      ano: filterData?.period ? filterData?.period : 2020,
      id_inspetor: userData.user.id_pessoa,
    };

    switchDrawer();
    setFiltered(true);
    setParams(filteredParams);
  };

  const handleSearch = (value) => {
    setSearchValue(value);

      if(selectedTab === 'REALIZADAS') {
      
        const filteredValues = allInspectCarried.filter((record) => {
          const nome = record?.nome_animal?.toLowerCase();
          const pai = record?.pai?.toLowerCase();
          const mae = record?.mae?.toLowerCase();
          const cobertura = record?.dt_cobertura?.toLowerCase();
          const inclusao = record?.dt_inclusao?.toLowerCase();
          const inspecao = record?.dt_inspecao?.toLowerCase();
          const nascimento = record?.dt_nascimento?.toLowerCase();
          const num_preregistro = record?.num_preregistro;
  
          const valueToSearch = value?.toLowerCase();
  
          return (
            nome.indexOf(valueToSearch) !== -1 ||
            pai.indexOf(valueToSearch) !== -1 ||
            mae.indexOf(valueToSearch) !== -1 ||
            cobertura.indexOf(valueToSearch) !== -1 ||
            inclusao.indexOf(valueToSearch) !== -1 ||
            inspecao.indexOf(valueToSearch) !== -1 ||
            nascimento.indexOf(valueToSearch) !== -1 ||
            num_preregistro.indexOf(valueToSearch) !== -1
          );
        });
  
        setSearchedRecordsRealizeds(filteredValues);
      }
  
      else {
        const filteredValues = wrongInspections.filter((record) => {
          const criador = record?.criador?.toLowerCase();
          const inclusao = record?.dt_inclusao?.toLowerCase();
          const inspecao = record?.dt_inspecao?.toLowerCase();
          const num_preregistro = record?.num_preregistro;
  
          const valueToSearch = value?.toLowerCase();
  
          return (
            criador.indexOf(valueToSearch) !== -1 ||
            inclusao.indexOf(valueToSearch) !== -1 ||
            inspecao.indexOf(valueToSearch) !== -1 ||
            num_preregistro.indexOf(valueToSearch) !== -1
          );
        });

        setSearchedRecordsWrong(filteredValues);
      }
  };

  const clearFilter = useCallback(() => {
    setPageRealized(1);
    setPageWrong(1);
    setFiltered(false);
    setRealizedInpections([]);
    setWrongInspections([]);
    setParams(initialParams);
    setFilterData({ period: `${initialParams.ano}` })
  }, [initialParams]);

  // set the filter
  useEffect(() => {

    if(selectedTab === 'REALIZADAS') {
      const listenSearch = async () => {
        searchValue === '' 
          ? setRealizedInpections(allInspectCarried)
          : setRealizedInpections(searchedRecordsRealizeds)
      };
  
      listenSearch();
    }
    else {
      const listenSearch = async () => {
        searchValue === '' 
          ? setWrongInspections(allWrongInspections)
          : setWrongInspections(searchedRecordsWrong)
      };
  
      listenSearch();
    }
  }, 
  [
    searchValue, 
    allInspectCarried, 
    searchedRecordsWrong,
    searchedRecordsRealizeds,
    allWrongInspections, 
    selectedTab
  ]);

  useEffect(() => {
    userData?.user?.id_pessoa && setInspectorId(userData.user.id_pessoa);
  }, [userData]);

  // get data on enter screnn
  // or when update filter
  useEffect(() => {
    setPreLoader(true);

    if (inspectorId) {
      const initialTableData = async () => {
        setLoading(true);

        const [realizedInspections, _wrongInspections] = await fetchAllData();

        //realizeds
        setTotalRealizeds(realizedInspections.total);
        setRealizedInpections(realizedInspections.data);
        setAllInspectCarried(realizedInspections.data);

        //with errors
        setTotalInspectionsWrong(_wrongInspections.total);
        setWrongInspections(_wrongInspections.data);
        setAllWrongInspections(_wrongInspections.data);

        setLoading(false);
        setPreLoader(false);
      };

      initialTableData();
    }
  }, [inspectorId, params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSwitchScreen = useCallback(() => {
    setSearchValue('');

    selectedTab === 'REALIZADAS' 
      ? setSelectedTab('COM ERROS') 
      : setSelectedTab('REALIZADAS')
    
  }, [selectedTab])

  return (
    <Container>
      <Breadcrumb
        pages={[
          {
            title: pageTitle,
            path: '/inspecoes-realizadas',
          },
        ]}
      />
      <Helmet>
        <title>{pageTitle} - Central do Quartista</title>
      </Helmet>

      {preLoader ? (
        <PreLoader light />
      ) : (

        selectedTab === 'REALIZADAS' 
        ?(
          <RealizedInspections
            loading={loading} 
            selectedTab={selectedTab}
            filtered={filtered}
            totalRealizeds={totalRealizeds}
            allInspectCarried={allInspectCarried}
            realizedInpections={realizedInpections}
            setRealizedInpections={setRealizedInpections}
            fetchMoreDataRealizeds={fetchMoreDataRealizeds}
            switchDrawer={switchDrawer}
            pageTitle={pageTitle}
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            handleSwitchScreen={handleSwitchScreen}
          />
        )
          
        :(

          <WrongInspections
            inspectorId={inspectorId}
            params={params}
            filtered={filtered}
            totalInspectionsWrong={totalInspectionsWrong}
            wrongInspections={wrongInspections}
            allWrongInspections={allWrongInspections}
            pageWrong={pageWrong}
            loading={loading}
            setSelectedTab={setSelectedTab}
            setSearchValue={setSearchValue}
            setTotalInspectionsWrong={setTotalInspectionsWrong}
            setWrongInspections={setWrongInspections}
            setAllWrongInspections={setAllWrongInspections}
            setPageWrong={setPageWrong}
            setLoading={setLoading}
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            switchDrawer={switchDrawer}
            fetchMoreDataWrong={fetchMoreDataWrong}
            handleSwitchScreen={handleSwitchScreen}         
          />
        )
      )}

      <Drawer
        title="Filtro"
        placement="right"
        onClose={switchDrawer}
        destroyOnClose
        visible={drawerVisibility}
      >
        <Form onFinish={handleSubmitFilter}>
          <FormItem label="Situação" name="situation">
            <Radio
              name="situation"
              size={window.innerWidth >= 1900 ? 'large' : 'middle'}
              options={radioSituationOptions}
              value={filterData.situation}
              vertical
              onChange={(value) =>
                setFilterData({ ...filterData, situation: value })
              }
            />
          </FormItem>

          <FormItem label="Período" name="period" style={{ marginTop: 10 }}>
            <MaskedInput
              mask="1111"
              name="period"
              width={window.innerWidth / 10.58}
              placeholder="Ano"
              value={filterData.period}
              onChange={(value) =>
                setFilterData({ ...filterData, period: value })
              }
              style={{ marginRight: 27 }}
            />
          </FormItem>

          <DrawerButton type="submit" color={colors.lightGreen} fontLight>
            Aplicar
          </DrawerButton>
        </Form>
      </Drawer>
    </Container>
  );
}

export default InspectCarriedOut;
