import React, { useState, useEffect, useCallback } from 'react';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import { Checkbox, Button, Loader } from '../../../components';

import { Container } from './styles';

const Sports = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [animalIsBlocked, setAnimalIsBlocked] = useState(false);

  useEffect(() => {
    setLoading(true);

    async function fetchStatusStudBook() {
      try {
        const { data } = await api.get('animal/perfil', {
          params: {
            id_animal: id,
          },
        });

        if (data.success) {
          setAnimalIsBlocked(data.data[0].status_sports === 0 ? true : false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchStatusStudBook();
  }, [id]);

  const handleStatus = useCallback(async () => {
    setAnimalIsBlocked(!animalIsBlocked);
    setChecked(false);

    try {
      await api.post('animal/statusanimalesporteupdate', {
        id_animal: id,
        block_sports: animalIsBlocked ? 0 : 1,
      });
    } catch (error) {
      console.log(error);
    }
  }, [animalIsBlocked, id]);

  if (loading) {
    return <Loader light />;
  } else {
    return (
      <Container>
        {animalIsBlocked ? (
          <>
            <h1>
              Este animal está <strong>SUSPENSO</strong> para competições
              esportivas
            </h1>

            <p>
              Deseja reativar este Animal para competições esportivas? <br />
              Esta ação reativará o animal para competições futuras.
            </p>
          </>
        ) : (
          <>
            <h1>
              Este animal está <strong>ATIVO</strong> para competições
              esportivas
            </h1>

            <p>
              Deseja suspender este Animal para competições esportivas? <br />
              Esta ação suspenderá o animal para competições futuras.
            </p>
          </>
        )}

        <div className="checkbox-button-wrapper">
          <Checkbox
            light
            checked={checked}
            onChange={(valueChecked) => setChecked(valueChecked)}
          >
            {animalIsBlocked
              ? 'Sim, reativar este animal.'
              : 'Sim, suspender este animal.'}
          </Checkbox>

          <Button
            width={112}
            height={32}
            color={colors.lightGreen}
            disabled={!checked}
            fontLight
            bgDark
            onClick={handleStatus}
          >
            {animalIsBlocked ? 'Reativar' : 'Suspender'}
          </Button>
        </div>
      </Container>
    );
  }
};
export default Sports;
