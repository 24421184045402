import React from 'react';
import PropTypes from 'prop-types';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { FiCalendar, FiTrash } from 'react-icons/fi';
import moment from 'moment';

import { FormValidator, MaskedInput } from '../.';

import { Container, StyledDatePicker, CalendarButton, CleanButton } from './styles';
import './styles.css';

const DatePicker = ({
  name,
  disabled,
  onChange,
  onInputChange,
  open,
  onOpenChange,
  value,
  valuePropName,
  required,
  onTop,
  onBottom,
  rules,
  light,
  cleanButton,
  handleCleanButton,
  ...props
}) => {
  let offset = [0, 0];

  if (onTop) offset = [0, 44];
  if (onBottom) offset = [0, 4];

  return (
    <Container>
      <StyledDatePicker
        name={name}
        id={name}
        disabled={disabled}
        locale={locale}
        format="DD/MM/YYYY"
        dropdownAlign={{ offset }}
        dropdownClassName="calendar"
        onChange={onChange}
        open={open}
        onOpenChange={onOpenChange}
        value={!!value && moment(value, "DD/MM/YYYY")}
        {...props}
      />

      <FormValidator
        name={name}
        valuePropName={value}
        message="Insira uma data"
        required={required}
        rules={rules}
      >
        <MaskedInput
          mask="11/11/1111"
          placeholder="Insira uma data"
          size="large"
          name={name}
          width={191}
          disabled={disabled}
          value={value}
          onChange={(value) => onInputChange(value)}
          autoComplete="off"
        />
      </FormValidator>

      <CalendarButton
        disabled={disabled}
        title="Selecione uma data"
        icon={<FiCalendar size={17} />}
        onClick={onOpenChange}
        light={light}
      />

      {cleanButton && (
        <CleanButton
          disabled={disabled}
          title="Limpar data"
          icon={<FiTrash size={17}/>}
          onClick={() => {

            handleCleanButton()
          }}
        />
      )}
      
    </Container>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  value: PropTypes.string,
  valuePropName: PropTypes.string,
  required: PropTypes.bool,
  onTop: PropTypes.bool,
  onBottom: PropTypes.bool,
  rules: PropTypes.array,
  cleanButton: PropTypes.bool,
  handleCleanButton: PropTypes.func,
};

DatePicker.defaultProps = {
  disabled: false,
  onChange: () => {},
  onInputChange: () => {},
  open: false,
  onOpenChange: () => {},
  required: false,
  cleanButton: false,
  handleCleanButton: () => {}
};

export default DatePicker;
