import styled from 'styled-components';
import media from 'styled-media-query';
import { Form as AntForm, Drawer as AntDrawer } from 'antd';

import { Button } from '../../components';

import { colors, fonts } from '../../theme';
import { pxToRem } from '../../functions';

export const Container = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(40)};

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.86);
`;

export const Image = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 33%;
    border-radius: 50%;
    width: ${pxToRem(25)};
    height: ${pxToRem(25)};
  }
`;

export const Animal = styled.td`
  p.name {
    font-size: 11px;
    text-align: left;
    line-height: 1.18;
    white-space: nowrap;

    font-family: 'SFProText-Semibold', sans-serif;
  }

  p.registry {
    margin-top: ${pxToRem(2)};

    font-size: 9px;
    text-align: left;
    line-height: 1.22;
  }

  ${media.greaterThan('huge')`
    p.name {
      font-size: 12px;
    }
  `}
`;

// export const OutOfTime = styled.td`
//   text-align: end;
//   padding-right: 5px;

//   font-family: ${({ hasValue }) =>
//       hasValue ? 'SFProText-Bold' : 'SFProText-Regular'},
//     sans-serif;

//   color: ${({ hasValue }) =>
//     hasValue ? colors.danger : colors.GrayishGreen} !important;
// `;

export const ActionButton = styled.td`
  align-items: center !important;
  flex-direction: row !important;
  justify-content: flex-end !important;

  padding-right: 5px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    border: 0;

    background: unset;

    svg {
      width: 24px;
      height: 24px;

      color: ${colors.GrayishGreen};
    }
  }
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-content-wrapper {
    width: 25% !important;
  }

  .ant-drawer-body {
    padding: ${pxToRem(22)} ${pxToRem(26)};
    overflow: hidden;
  }

  ${media.greaterThan('1900px')`
    .ant-drawer-title {
      ${fonts.size6};
    }
  `}
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .form-item2 {
    margin-top: 10.92%;
  }

  .form-item3 {
    margin-top: 13.65%;
  }

  ${media.greaterThan('1900px')`
    *,
    label {
      ${fonts.size5}
    }

    label {
      margin-bottom: ${pxToRem(6)};
    }

    .horizontal {
      label {
        margin-bottom: 0;
      }
    }

    .ant-switch {
      width: ${pxToRem(60)};
      height: ${pxToRem(30)};

      :after {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
      }
    }
  `}
`;

export const DrawerButton = styled(Button)`
  align-self: center;
  width: ${pxToRem(131)};
  margin-top: 10.57%;
`;
