import styled from 'styled-components';
import { Form } from 'antd';

import { colors } from '../../theme';

export const Container = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-explain {
    position: absolute;
    bottom: -24px;

    margin-left: 0;
  }

  .ant-radio-group {
    display: flex;
  }

  &.ant-form-item-has-error {
    .ant-radio-inner {
      border-color: ${colors.red} !important;
    }
  }
`;
