import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { message } from 'antd';
import { Helmet } from 'react-helmet';
import Cropper from 'react-easy-crop';
import ReactHtmlParser from 'react-html-parser';

import { api } from '../../services/api';
import { cropImage as getCroppedImg } from '../../functions';
import { Slider, Button, PageTitle, Breadcrumb } from '../../components';
import { useAuth } from '../../context/Auth';

import defaultMarkPicture from '../../theme/assets/images/default.png'; //Temp
import colors from '../../theme/colors';

import {
  Main,
  Header,
  Content,
  Container,
  ImageCrop,
  CloseButton,
  CircleLoading,
  ImageContainer,
} from './styles';

export default function TrademarkRegistration() {
  const { userData } = useAuth();
  const photo = userData.user?.path_marca_pessoa || null;

  const [loading, setLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [cropConfirmed, setCropConfirmed] = useState(false);
  const [photoSituation, setPhotoSituation] = useState('default');
  const [croppedImageConfirm, setCroppedImageConfirm] = useState();
  const [profileImage, setProfileImage] = useState(defaultMarkPicture);
  const [cropperConfig, setCropperConfig] = useState({
    zoom: 1,
    shape: 'rect',
    showGrid: false,
    isCropping: false,
    aspectRatio: 1 / 1,
    crop: { x: 0, y: 0 },
    croppedAreaPixels: null,
  });

  const photoSituationMessage = {
    default: 'Envie uma foto',
    approved: 'A foto enviada foi <strong>APROVADA</strong>',
    review: 'A foto foi enviada para <strong>ANÁLISE</strong>',
    disapproved: 'A foto enviada foi <strong>REPROVADA</strong>',
  };

  const imagePreview = useMemo(() => {
    return photoPreview ? URL.createObjectURL(photoPreview) : null;
  }, [photoPreview]);

  const handleCropChange = (crop) => {
    setCropperConfig({ ...cropperConfig, crop });
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) =>
    setCropperConfig({ ...cropperConfig, croppedAreaPixels });

  const handleZoomChange = (zoom) =>
    setCropperConfig({ ...cropperConfig, zoom });

  const handleCropConfirm = async () => {
    try {
      setCropperConfig({ ...cropperConfig, isCropping: true });

      const croppedImage = await getCroppedImg(
        imagePreview,
        cropperConfig.croppedAreaPixels
      );

      setCroppedImage(croppedImage);
      setPhotoSituation('review');
      setCropConfirmed(true);

      convertToBase64(croppedImage);
    } catch (error) {
      console.error(error.message);
    } finally {
      setCropperConfig({ ...cropperConfig, isCropping: false });
    }
  };

  const convertToBase64 = (croppedImage) => {
    const img = new Image();
    img.src = croppedImage;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);

      const base64 = canvas
        .toDataURL('image/png')
        .replace(/^data:image.+;base64,/, '');
      setCroppedImageConfirm(base64);
    };
  };

  const handleCancel = () => {
    setPhotoPreview(null);
    setCroppedImage(null);
    setCropConfirmed(false);
    setCroppedImageConfirm('');
    setPhotoSituation(profileImage === photo ? 'approved' : 'default');
  };

  const handleSubmit = useCallback(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    async function updateImage() {
      try {
        const { data } = await api.post(
          'pessoa/enviarmarca',
          { foto: String(croppedImageConfirm) },
          config
        );

        if (data.success) {
          setPhotoPreview(null);
          setCroppedImage(null);
          setCropConfirmed(false);
          setCroppedImageConfirm('');
          setProfileImage(croppedImage);
          setPhotoSituation('review');

          message.success('A imagem foi enviada para análise.');
        }
      } catch (error) {
        setPhotoPreview(null);
        setCroppedImage(null);
        setCropConfirmed(false);
        setCroppedImageConfirm('');
        setPhotoSituation(profileImage === photo ? 'approved' : 'default');

        message.error('Erro ao enviar foto. Se o erro persistir contate-nos!');
      } finally {
        setLoading(false);
      }
    }

    updateImage();
  }, [
    photo,
    croppedImage,
    profileImage,
    setPhotoSituation,
    croppedImageConfirm,
  ]);

  useEffect(() => {
    if (photo) {
      setProfileImage(photo);
      setPhotoSituation('approved');
    } else {
      setProfileImage(defaultMarkPicture);
      setPhotoSituation('default');
    }
  }, [photo]);

  return (
    <>
      <Helmet>
        <title>Cadastro de Marca - Central do Quartista</title>
      </Helmet>

      <Breadcrumb pages={[{ title: 'Cadastro de Marca' }]} />

      <Container photoSituation={photoSituation}>
        <Header>
          <PageTitle>Cadastro de Marca</PageTitle>
        </Header>
        <Main>
          <Content>
            <ImageContainer photoSituation={photoSituation}>
              {loading ? (
                <CircleLoading />
              ) : (
                <>
                  <div className="image-wrapper">
                    <img
                      src={croppedImage ? croppedImage : profileImage}
                      alt="Foto da marca"
                    />
                  </div>

                  {cropConfirmed ? (
                    <div id="crop-confirmed-buttons">
                      <Button
                        width={104}
                        height={32}
                        fontLight
                        color="rgba(178, 178, 178, 0.5)"
                        onClick={handleCancel}
                      >
                        Cancelar
                      </Button>

                      <Button
                        width={104}
                        height={32}
                        fontLight
                        color={colors.lightGreen}
                        onClick={handleSubmit}
                      >
                        Enviar foto
                      </Button>
                    </div>
                  ) : (
                    <p>
                      {ReactHtmlParser(photoSituationMessage[photoSituation])}
                    </p>
                  )}
                </>
              )}
            </ImageContainer>

            <ImageCrop>
              {imagePreview ? (
                <>
                  <div className="cropper-wrapper">
                    <CloseButton
                      title="Cancelar"
                      onClick={() => setPhotoPreview(null)}
                    >
                      &times;
                    </CloseButton>

                    <Cropper
                      image={imagePreview}
                      crop={cropperConfig.crop}
                      zoom={cropperConfig.zoom}
                      onCropChange={handleCropChange}
                      onZoomChange={handleZoomChange}
                      cropShape={cropperConfig.shape}
                      showGrid={cropperConfig.showGrid}
                      aspect={cropperConfig.aspectRatio}
                      onCropComplete={handleCropComplete}
                      cropSize={{ width: 240, height: 240 }}
                    />
                  </div>

                  <div className="cropper-controls">
                    <div className="slider-wrapper">
                      <label>Zoom</label>
                      <Slider
                        value={cropperConfig.zoom}
                        min={1}
                        max={3}
                        step={0.01}
                        onChange={(value) =>
                          setCropperConfig({ ...cropperConfig, zoom: value })
                        }
                      />
                    </div>

                    <Button
                      style={{ padding: '0 24px' }}
                      onClick={handleCropConfirm}
                      width={94}
                      height={23}
                      fontLight
                      color={colors.lightGreen}
                      loading={cropperConfig.isCropping}
                    >
                      Recortar
                    </Button>
                  </div>
                </>
              ) : (
                <label className="upload-button" htmlFor="upload-photo">
                  Nova foto
                  <input
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    accept="image/*"
                    onChange={(e) => setPhotoPreview(e.target.files[0])}
                  />
                </label>
              )}
            </ImageCrop>
          </Content>
        </Main>
      </Container>
    </>
  );
}
