import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  padding: ${pxToRem(6)} ${pxToRem(20)};
  border-radius: ${pxToRem(6)};

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: rgba(255, 255, 255, 0.1);

  ${media.greaterThan('1600px')`    
    border-radius: ${pxToRem(7)};
  `}

  ${media.greaterThan('1900px')`
    padding: ${pxToRem(8)} ${pxToRem(34)};
    border-radius: ${pxToRem(8)};
  `}
`;

export const PendenciesGraphic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 22%;
`;

export const PendenciesInfo = styled.div`
  margin-left: ${pxToRem(12)};

  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  color: ${colors.white};

  h1 {
    ${fonts.size5};
    color: rgba(255, 255, 255, 0.75);
    font-family: 'SFRounded-Regular';
  }

  p {
    ${fonts.size1};
    color: rgba(255, 255, 255, 0.5);
  }

  span {
    ${fonts.size6};
  }

  ${media.greaterThan('1600px')`
    margin-left: ${pxToRem(14)};

    h1 {
      ${fonts.size6};
    }

    p {
      ${fonts.size3};
    }

    span {
      ${fonts.size7};
    }
  `}

  ${media.greaterThan('1900px')`
    margin-left: ${pxToRem(16)};

    h1 {
      ${fonts.size7};
    }

    p {
      ${fonts.size4};
    }

    span {
      ${fonts.size8};
    }
  `}
`;
