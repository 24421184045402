import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const SearchBox = ({
  name,
  label,
  disabled,
  className,
  placeholder,
  value,
  ...props
}) => (
  <Container
    type='text'
    disabled={disabled}
    className={className}
    placeholder={placeholder}
    value={value}
    {...props}
  />
);

SearchBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

SearchBox.defaultProps = {
  disabled: false,
};

export default SearchBox;
