import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const Container = styled.table`
  width: 100%;

  tbody tr,
  thead tr {
    grid-gap: 8px;
    display: grid;

    grid-template-columns: ${({ columnsSize }) => columnsSize};

    td,
    th {
      width: 100%;
    }
  }
`;

export const Head = styled.thead`
  width: 100%;

  tr {
    height: 32px;
  }

  th {
    display: flex;
    align-items: center;

    color: ${({ light }) => (light ? colors.dark : colors.white)};
    text-align: left;
    line-height: 1.22;
    font-size: ${pxToRem(9)};

    ${media.greaterThan('huge')`
      font-size: ${pxToRem(10)};
    `}
  }

  button {
    border: unset;
    background: unset;
  }

  p {
    margin-right: 4px;

    line-height: 1.22;
    color: ${({ light }) => (light ? colors.dark : colors.white)};
  }

  th.actions {
    min-width: 36px;
  }

  th.prazo,
  th.num_preregistro {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  ${media.greaterThan('1900px')`
    th.actions {
      min-width: 48px;
    }

    th.num_preregistro {
      justify-content: center;

      padding-left: 10px;
    }
  `}
`;

export const Body = styled.tbody`
  height: 100%;

  display: block;

  tr {
    &:nth-child(odd) {
      background-color: ${({ light }) =>
        light ? 'rgba(40, 49, 49, 0.06)' : 'rgba(255, 255, 255, 0.056)'};
    }

    :hover {
      background-color: rgba(40, 49, 49, 0.15);
    }
  }

  td {
    height: ${pxToRem(41)};

    display: flex;
    flex-direction: column;
    justify-content: center;

    line-height: 1.18;
    word-wrap: break-word;
    font-size: ${pxToRem(11)};
    color: ${({ light }) =>
      light ? 'rgba(40, 49, 49, 0.75)' : 'rgba(255, 255, 255, 0.75)'};

    ${media.greaterThan('huge')`
      font-size: ${pxToRem(12)};
    `}
  }
`;

export const BodyWrapper = styled.div`
  padding: 0 ${pxToRem(20)};
  margin: 0 ${({ hasScroll }) => (hasScroll ? pxToRem(-24) : pxToRem(-20))} 0
    ${pxToRem(-20)};

  overflow-y: auto;
  height: calc(100vh - ${pxToRem(205)});

  ::-webkit-scrollbar {
    width: ${pxToRem(4)};
    margin-right: -20px;

    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${pxToRem(5)};

    background-color: ${({ light }) => (light ? colors.dark : colors.white)};
  }

  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }

  ${media.greaterThan('1900px')`
    height: calc(100vh - ${pxToRem(225)});
  `}
`;
