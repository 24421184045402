import React, { useState, useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import { Loader, PageTitle, StyledCustomScrollBar } from '../../../components';

import { Container } from './styles';

export default function Pendencies({ preRegister, token }) {
  const [loading, setLoading] = useState(true);
  const [pendencies, setPendencies] = useState([]);
  const [generalProperties, setGeneralProperties] = useState([]);

  const fetchPendencies = useCallback(async () => {
    try {
      const { data } = await api.get('studbook/listapendencias', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_preregistro: preRegister,
        },
      });

      if (data.success) {
        setPendencies(data.pendencias);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [preRegister, token]);

  const fetchGeneralProperty = useCallback(async () => {
    try {
      const { data } = await api.get('PedidoRegistro/GeralPropriedadeGeral', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_pedido_registro: preRegister,
        },
      });

      if (data.success) {
        setGeneralProperties(data.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [preRegister, token]);

  useEffect(() => {
    fetchGeneralProperty();
  }, [fetchGeneralProperty]);

  useEffect(() => {
    fetchPendencies();
  }, [fetchPendencies]);

  const pendenceDescription = ({
    id_tp_pendencia,
    nome_proprietario,
    procedimento,
  }) => {
    if (id_tp_pendencia === 29) {
      return (
        <>
          Dispensar eletronicamente através do site ou do aplicativo ou enviar à
          ABQM Certificado de Cobertura original, com todos os campos
          devidamente preenchidos, emitido e assinado pelo dono do garanhão
          &nbsp;
          {generalProperties.semem_importado
            ? generalProperties.PROPRIETARIO_GARANHAO_COB_SEMEM_IMPORTADO
            : generalProperties.PROPRIETARIO_GARANHAO_COB}
          &nbsp;à época da cobertura.
        </>
      );
    } else if (id_tp_pendencia === 20 || id_tp_pendencia === 31) {
      return (
        <>
          Caso o interessado queira o direito de propriedade e criação do
          produto, enviar à ABQM o&nbsp;
          <a href="javascript:void(0)">
            <b>Termo de Cessão de Direito de Criação do Produto&nbsp;</b>
          </a>
          Caso tenha apenas o interesse na propriedade do produto, enviar a
          &nbsp;
          <a href="javascript:void(0)">
            <b>Guia de Transferência de Produto&nbsp;</b>
          </a>
          Esse documento deve ser enviado à ABQM com todos os campos devidamente
          preenchidos, {nome_proprietario}
        </>
      );
    } else return procedimento;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {!isMobile && <PageTitle>Pendências</PageTitle>}

          <div className="table-info">
            <StyledCustomScrollBar color={colors.black}>
              {pendencies.length === 0 && (
                <p className="bold text-not-found">
                  Processo não possui pendencias!
                </p>
              )}

              {pendencies.map((pendencie, index) => (
                <table key={index}>
                  <tr>
                    <td>Pendência:</td>
                    <td className="bold">
                      {pendencie?.pendencia}
                      {pendencie?.pendencia === 'Falta Certificado de Cobertura' && (
                        <a href="javascript:void(0)">&nbsp;Imprimir Certificado</a>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Procedimento:</td>
                    <td>{pendenceDescription(pendencie)}</td>
                  </tr>
                </table>
              ))}
            </StyledCustomScrollBar>
          </div>
        </Container>
      )}
    </>
  );
}
