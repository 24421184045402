import styled from 'styled-components';

import { pxToRem } from '../../../../functions';

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${pxToRem(46)};

  table {
    position: relative;

    width: 100%;
    margin: 1.5rem 0;
    border-collapse: collapse;

    ::after {
      position: absolute;
      bottom: -12px;

      content: '';
      width: 100%;
      height: 1px;

      background: #ccc;
    }

    :last-child::after {
      background: transparent;
    }

    tr {
      padding: 0 ${pxToRem(8)};

      display: grid;
      grid-template-columns: 1fr 2fr;

      :nth-child(odd) {
        background-color: rgba(40, 49, 49, 0.06);
      }

      :nth-child(even) {
        background-color: rgba(40, 49, 49, 0.1);
      }

      :hover {
        background-color: rgba(40, 49, 49, 0.15);
      }
    }

    td,
    th {
      padding: 8px 0;

      text-align: left;
    }
    .link{
      display: flex;
      flex:1;
      justify-content:center;
      padding: 5px 0px;
    }
  }
`;
