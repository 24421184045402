import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

import { Container, Label } from './styles';

const DonutChart = ({ data, options }) => {
  if (!data) return <></>;

  options = {
    ...options
  }

  const dataFormated = data.map((item) => ({
    id: item.id,
    value: item.qtd,
    color: item.color,
  }));

  const totalValue = dataFormated
    ?.map((item) => item.value)
    .reduce((acc, item) => acc + item, 0);

  return (
    <Container>
      <ResponsivePie
        data={dataFormated}
        sortByValue={!!options.cornerRadius}
        margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
        innerRadius={options.innerRadius ? options.innerRadius : 0.7}
        cornerRadius={options.cornerRadius ? options.cornerRadius : 2}
        padAngle={options.padAngle ? options.padAngle : 2}
        colors={dataFormated?.map((item) => item.color)}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        isInteractive={false}
      />

      <Label>{ options.label === false ? '' : totalValue }</Label>

    </Container>
  );
};

DonutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      qtd: PropTypes.number,
      color: PropTypes.string,
      titulo: PropTypes.string,
    })
  ).isRequired,
};

export default DonutChart;
