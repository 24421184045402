import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.section`
  ${({ loading }) =>
    loading &&
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    `}

  width: 100%;
  height: 100%;

  padding: ${pxToRem(20)} ${pxToRem(40)};

  ${media.greaterThan('1900px')`
    padding: ${pxToRem(28)} ${pxToRem(56)};
  `}

  ${media.greaterThan('1600px')`
    padding: ${pxToRem(23.5)} ${pxToRem(46)};
  `}
`;

export const SalutationAndButtonJoinUs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Salutation = styled.p`
  ${fonts.size7};
  color: ${colors.white};
  font-family: 'SFRounded-Thin', sans-serif;

  ${media.greaterThan('1600px')`
    ${fonts.size8};
  `}

  ${media.greaterThan('1900px')`
    ${fonts.size9};
  `}
`;

export const PendenciesTop = styled.div``;

const gridGapContentCenter = pxToRem(16);

export const ContentCenter = styled.div`
  height: 73%;
  margin-top: ${pxToRem(24)};

  display: grid;
  grid-gap: ${gridGapContentCenter};
  grid-template-rows: repeat(2, calc((100% - ${gridGapContentCenter}) / 2));
  grid-template-columns: 6fr 2fr 3fr;
  grid-template-areas:
    'm1 m1 m5'
    'l1 l1 m5';

  ${(props) =>
    props.doubleChart &&
    css`
      grid-template-columns: 4fr 4fr 3fr;
      grid-template-areas:
        'm1 m2 m5'
        'l1 l1 m5';
    `}

  ${media.greaterThan('1600px')`
    margin-top: ${pxToRem(28)};

    grid-gap: ${pxToRem(18.5)}
  `}

  ${media.greaterThan('1900px')`
    margin-top: ${pxToRem(34)};
    
    grid-gap: ${pxToRem(22)};
  `}
`;
