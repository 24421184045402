import React, { useEffect, useState, useCallback } from 'react';
import swal from 'sweetalert';
import { Helmet } from 'react-helmet';
import { AiFillCaretRight } from 'react-icons/ai';
import {
  Route,
  Switch,
  NavLink,
  Redirect,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { colors } from '../../theme';
import { api } from '../../services/api';
import { useAuth } from '../../context/Auth';
import { Breadcrumb, PageTitle, Button, PreLoader } from '../../components';
import defaultProfilePicture from '../../theme/assets/images/silhueta.svg';
import { ReactComponent as ImageOutline } from '../../theme/assets/images/effect-image-outline.svg';

import Receiver from './Receiver';
import Pendencies from './Pendencies';
import DNAControl from './DNAControl';
import RecordDetails from './RecordDetails';
import ActivityRecord from './ActivityRecord';
import ProcessDocument from './ProcessDocument';
import PropertyHistory from './PropertyHistory';
import { BlockModal, CancellationModal } from './components';

import { Nav, Main, Header, Content, Container, DetailsInfo } from './styles';
import { errorHandler } from '../../functions';

const EditingRegistrationOrders = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { userData } = useAuth();

  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isRegistred, setIsRegistred] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isInspector, setIsInspector] = useState(false);
  const [isRequester, setIsRequester] = useState(false);
  const [recordDetails, setRecordDetails] = useState({});
  const [modalBlockOpen, setModalBlockOpen] = useState(false);
  const [requestIsBlocked, setRequestIsBlocked] = useState(false);
  const [modalCancellationOpen, setModalCancellationOpen] = useState(false);
  const [pendenciaFinanceira, setPendenciaFinanceira] = useState([]);
  const [processoPre, setProcessoPre] = useState([]);

  const fetchRecordDetails = useCallback(async () => {
    try {
      const { data } = await api.get('pedidoregistro/detalhespedidoregistro', {
        params: {
          num_preregistro: id,
        },
      });

      if (data.success) {
        setRecordDetails(data.data[0]);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchPendenciaFinanceira = useCallback(async () => {
    try {
      const { data } = await api.get(
        'pedidoregistro/GeralPendenciaFinanceira',
        {
          params: {
            num_pedido_registro: id,
          },
        }
      );

      if (data.success) {
        setPendenciaFinanceira(data.data[0]);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchProcessoPre = useCallback(async () => {
    try {
      const { data } = await api.get(
        'pedidoregistro/GeralRelatorioProcessoPre',
        {
          params: {
            num_pedido_registro: id,
          },
        }
      );

      if (data.success) {
        setProcessoPre(data.data[0]);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchRecordDetails();
    fetchPendenciaFinanceira();
    fetchProcessoPre();
  }, [fetchRecordDetails, fetchPendenciaFinanceira, fetchProcessoPre]);

  const navItems = [
    { path: 'detalhe-do-registro', title: 'Detalhe do registro' },
    { path: 'documentos', title: 'Documentos do processo' },
    { path: 'controle-de-dna', title: 'Controle de DNA' },
    { path: 'historico-de-propriedade', title: 'Histórico de propriedade' },
    { path: 'pendencias', title: 'Pendências' },
    { path: 'receptora', title: 'Receptora' },
    { path: 'registro-de-atividades', title: 'Registro de atividades' },
  ];

  const toggleModal = useCallback(() => {
    setModalIsOpen(!modalIsOpen);
  }, [modalIsOpen]);

  const toggleBlockModal = useCallback(() => {
    setModalBlockOpen(!modalBlockOpen);
  }, [modalBlockOpen]);

  const toggleModalCancellation = useCallback(() => {
    setModalCancellationOpen(!modalCancellationOpen);
  }, [modalCancellationOpen]);

  useEffect(() => {
    const completeURL = location.pathname;
    if (completeURL) {
      const string = completeURL.split('/');
      setPage(string[3]);
    }
  }, [location.pathname]);

  useEffect(() => {

    if(
      Object.keys(recordDetails).length > 0 && 
      userData?.user?.id_pessoa && 
      userData?.user?.id_pessoa !== recordDetails?.id_inspetor && 
      userData?.user?.id_pessoa !== recordDetails?.id_solicitante){
      swal(
        'Atenção', 
        `Para ter acesso as informações desse Pedido de Registro é 
        necessário ser o Solictante ou Inspetor do mesmo!`, 
        'warning'
      );
    }

    if(recordDetails?.id_inspetor === undefined && userData?.user?.id_pessoa === undefined)
      return;

    if (userData?.user?.id_pessoa === recordDetails?.id_inspetor)
      setIsInspector(true);
    
    if (userData?.user?.id_pessoa === recordDetails?.id_solicitante)
      setIsRequester(true);
    
  }, [userData, recordDetails]);

  useEffect(() => {
    if (recordDetails?.status_atividade === 1) {
      setRequestIsBlocked(true);
    }
  }, [recordDetails]);

  useEffect(() => {
    if (recordDetails?.status_lancamento === 'Cancelado') {
      setIsCanceled(true);
    }
  }, [recordDetails]);

  useEffect(() => {
    if (processoPre?.registro_abqm) {
      setIsRegistred(true);
    }
  }, [processoPre]);

  return (
    
    <>
      <Helmet>
        <title>Detalhes de pedido de registro</title>
      </Helmet>

      <Breadcrumb
        pages={[
          {
            path: '/pedidos-de-registro',
            title: 'Pedidos de registro',
          },
          { title: 'Detalhes de pedido de registro' },
        ]}
      />

      <Redirect
        strict
        from="/detalhe-de-pedido-de-registro/"
        to={`/detalhes-de-pedido-de-registro/${id}/detalhe-do-registro`}
      />

      {loading ? (
        <PreLoader light />
      ) : (
        <>
          {isRequester || isInspector ? (
            <Container>
              <Header>
                <PageTitle>Detalhes de pedido de registro</PageTitle>
    
                <div id="header-buttons">
                  <Button
                    fontLight
                    width={90}
                    height={32}
                    color="rgba(178, 178, 178, 0.5)"
                    onClick={() => history.goBack()}
                  >
                    Voltar
                  </Button>
    
                  {page === 'documentos' && isInspector && !isRegistred && (
                    <Button
                      fontLight
                      width={130}
                      height={32}
                      color={colors.darkGreen}
                      onClick={toggleModal}
                    >
                      Novo parecer
                    </Button>
                  )}
    
                  {isInspector && !isCanceled && !isRegistred && (
                    <Button
                      fontLight
                      width={130}
                      height={32}
                      color={colors.blue}
                      onClick={toggleBlockModal}
                    >
                      {requestIsBlocked ? 'Desbloquear' : 'Bloquear'}
                    </Button>
                  )}
    
                  {!isRegistred && !isCanceled && (
                    <Button
                      fontLight
                      width={130}
                      height={32}
                      color={colors.red}
                      onClick={toggleModalCancellation}
                    >
                      Cancelar
                    </Button>
                  )}
                </div>
              </Header>
    
              <DetailsInfo>
                <div id="image-wrapper">
                  <ImageOutline />
    
                  <img src={defaultProfilePicture} alt="Silhueta" />
    
                  <div className="image-border" />
                </div>
    
                <div id="info-wrapper">
                  <div id="info-top">
                    <div className="characters">
                      <p>Pré-registro</p>
                      <strong>{id}</strong>
                    </div>
    
                    <div className="characters">
                      <p>Sexo</p>
                      <strong>
                        {recordDetails?.sexo_animal
                          ? recordDetails?.sexo_animal
                          : '--'}
                      </strong>
                    </div>
    
                    <div className="characters">
                      <p>Pelagem</p>
                      <strong>
                        {recordDetails?.dsc_pelagem_produto
                          ? recordDetails?.dsc_pelagem_produto
                          : '--'}
                      </strong>
                    </div>
    
                    <div className="characters">
                      <p>Sangue</p>
                      <strong>
                        {recordDetails?.dsc_grau_sangue
                          ? recordDetails?.dsc_grau_sangue
                          : '--'}
                      </strong>
                    </div>
    
                    <div className="characters">
                      <p>Nascimento</p>
                      <strong>
                        {recordDetails?.dt_nascimento
                          ? recordDetails?.dt_nascimento.substr(0, 10)
                          : '--'}
                      </strong>
                    </div>
    
                    <div className="characters">
                      <p>Situação</p>
                      <strong>
                        {pendenciaFinanceira?.SITUACAO_PEDIDO_REGISTRO
                          ? pendenciaFinanceira?.SITUACAO_PEDIDO_REGISTRO
                          : '--'}
                      </strong>
                    </div>
                  </div>
                </div>
              </DetailsInfo>
    
              <Main>
                <Nav>
                  <ul>
                    {navItems.map((item, index) => (
                      <li key={index}>
                        <NavLink to={item.path}>
                          {item.title} <AiFillCaretRight size={10} />
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </Nav>
    
                <Content>
                  <Switch>
                    <Route
                      path={`/detalhes-de-pedido-de-registro/${id}/detalhe-do-registro`}
                      exact
                      render={() => (
                        <RecordDetails
                          preRegister={id}
                          isCanceled={isCanceled}
                          isInspector={isInspector}
                          apiCall={fetchRecordDetails}
                          detailsRequest={recordDetails}
                          detailsFinanceiro={pendenciaFinanceira}
                          detailsPreRegistro={processoPre}
                          isRegistred={isRegistred}
                        />
                      )}
                    />
    
                    <Route
                      path={`/detalhes-de-pedido-de-registro/${id}/documentos`}
                      exact
                      render={() => (
                        <ProcessDocument
                          preRegister={id}
                          userData={userData}
                          modalIsOpen={modalIsOpen}
                          toggleModal={toggleModal}
                        />
                      )}
                    />
    
                    <Route
                      path={`/detalhes-de-pedido-de-registro/${id}/controle-de-dna`}
                      exact
                      render={() => <DNAControl preRegister={id} />}
                    />
    
                    <Route
                      path={`/detalhes-de-pedido-de-registro/${id}/historico-de-propriedade`}
                      exact
                      render={() => <PropertyHistory preRegister={id} />}
                    />
    
                    <Route
                      path={`/detalhes-de-pedido-de-registro/${id}/pendencias`}
                      exact
                      render={() => <Pendencies preRegister={id} />}
                    />
    
                    <Route
                      path={`/detalhes-de-pedido-de-registro/${id}/receptora`}
                      exact
                      render={() => <Receiver preRegister={id} />}
                    />
    
                    <Route
                      path={`/detalhes-de-pedido-de-registro/${id}/registro-de-atividades`}
                      exact
                      render={() => <ActivityRecord preRegister={id} />}
                    />
                  </Switch>
                </Content>
              </Main>
    
              <CancellationModal
                preRegister={id}
                setIsCanceled={setIsCanceled}
                detailsRequest={recordDetails}
                modalIsOpen={modalCancellationOpen}
                toggleModal={toggleModalCancellation}
              />
    
              <BlockModal
                preRegister={id}
                modalIsOpen={modalBlockOpen}
                isBlocked={requestIsBlocked}
                detailsRequest={recordDetails}
                toggleModal={toggleBlockModal}
                toggleBlock={setRequestIsBlocked}
              />
            </Container>
          ) : (
            <Container />
          )}
        </>
      )}
    </>
  );
};

export default EditingRegistrationOrders;
