import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { AiOutlineLoading } from 'react-icons/ai';
import {
  Form as AntForm,
  Drawer as AntDrawer,
  Tooltip as TooltipContainer,
} from 'antd';

import { Button, FormItem as OriginalFormItem } from '../../components';

import { fonts, colors } from '../../theme';
import { pxToRem } from '../../functions';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const Container = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(40)};

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.86);
`;

// Some styles is on components/Table/styles.js

export const Number = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Animal = styled.td`
  p.name {
    font-size: 11px;
    text-align: left;
    line-height: 1.18;
    white-space: nowrap;

    font-family: 'SFProText-Semibold', sans-serif;
  }

  p.registry {
    margin-top: ${pxToRem(2)};

    font-size: 9px;
    text-align: left;
    line-height: 1.22;
  }

  :hover p {
    text-decoration: none;
  }

  ${media.greaterThan('huge')`
    p.name {
      font-size: 12px;
    }
  `}
`;

export const ActionButtonWrapper = styled.td`
  align-items: center !important;
  flex-direction: row !important;
  justify-content: flex-end !important;

  padding-right: 5px;
`;

export const Tooltip = styled(TooltipContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-content-wrapper {
    width: 25% !important;
  }

  .ant-drawer-body {
    padding: ${pxToRem(22)} ${pxToRem(26)};
    overflow: hidden;
  }

  ${media.greaterThan('1900px')`
    .ant-drawer-title {
      ${fonts.size6};
    }
  `}
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .form-item {
    margin-top: 10.92%;
  }

  ${media.greaterThan('1900px')`  
    *,
    label {
      ${fonts.size5}
    }

    label {
      margin-bottom: ${pxToRem(6)};
    }

    .horizontal {
      label {
        margin-bottom: 0;
      }
    }

    .ant-switch {
      width: ${pxToRem(60)};
      height: ${pxToRem(30)};

      :after {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
      }
    }
  `}
`;

export const DrawerButton = styled(Button)`
  align-self: center;
  width: ${pxToRem(131)};
  margin-top: 10.57%;
`;

export const MoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  text-align: center;

  background: unset;
  transition: all 0.15s ease;

  svg {
    width: 24px;
    height: 24px;

    color: ${colors.GrayishGreen};
  }

  :hover {
    filter: brightness(0.9);
  }
`;

export const CircleLoading = styled(AiOutlineLoading)`
  animation: ${rotation} 1s infinite;
`;

export const FormItem = styled(OriginalFormItem)`
  margin-top: ${pxToRem(32)};

  .inputs-block {
    align-items: center;

    .select-sequence {
      margin-right: ${pxToRem(24)};
    }

    .select-receiver {
      margin-right: ${pxToRem(16)};
    }

    p {
      font-size: ${pxToRem(16)};

      > strong {
        font-family: 'SFProText-Semibold', sans-serif;

        > svg {
          margin-left: 8px;
        }
      }
    }
  }
`;

export const ModalContent = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  /* padding: ${pxToRem(8)} ${pxToRem(32)}; */

  h1 {
    color: ${colors.eightGrey};
    font-family: 'SFRounded-Semibold', sans-serif;
  }
`;
