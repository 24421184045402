import styled, { css } from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: ${pxToRem(20)};
  padding: ${pxToRem(8)} ${pxToRem(24)};
  width: ${({ width }) => (width ? pxToRem(width) : 'unset')};
  height: ${({ height }) => (height ? pxToRem(height) : pxToRem(40))};

  ${fonts.size2};
  letter-spacing: -0.4px;
  font-family: 'SFProText-Semibold', sans-serif;

  cursor: pointer;
  transition: all 0.3s ease;
  backface-visibility: hidden;
  background-color: ${({ color }) => color};
  color: ${({ fontLight }) => (fontLight ? colors.white : colors.black)};

  :not(:disabled):hover {
    filter: brightness(1.07);
  }

  :disabled {
    ${({ loading, bgDark }) => {
      if (loading) {
        return css`
          cursor: default;
          filter: brightness(1.1);
        `;
      }

      if (bgDark) {
        return css`
          cursor: not-allowed;
          filter: opacity(0.5);
          background-color: rgba(178, 178, 178);
        `;
      } else {
        return css`
          cursor: not-allowed;
          filter: opacity(0.25);
          background-color: #000;
        `;
      }
    }}
  }

  div {
    display: inherit;
    align-items: inherit;
    justify-content: inherit;

    a {
      color: ${colors.white};
    }
  }
`;

export const LoadingIcon = styled(LoadingOutlined).attrs({
  spin: true,
})`
  font-size: ${({ height }) =>
    height && height <= 24 ? pxToRem(16) : pxToRem(24)};

  color: #fff;
`;
