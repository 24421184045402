import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Breadcrumb } from '../../components';

import { colors } from '../../theme';
import { useIgnoreMainframe } from '../../context/IgnoreMainframe';

import imgHorse from '../../theme/assets/images/horse-intersection.svg';

import { Container } from './styles';

function Error({ mainframe }) {
  const { setIgnoreMainframe } = useIgnoreMainframe();

  const history = useHistory();

  useEffect(() => {
    (() => {
      !mainframe && setIgnoreMainframe(true);
    })();
  }, [setIgnoreMainframe, mainframe]);

  const handleButton = () => {
    if (mainframe) {
      window.location.reload();
    } else {
      history.replace('/', { fromError: true });
      setTimeout(() => {
        setIgnoreMainframe(false);
      }, 1000);
    }
  };

  return (
    <Container mainframe={mainframe}>
      <Helmet>
        <title>Error 404 - Central do Quartista</title>
      </Helmet>

      <Breadcrumb pages={[{ title: 'Error 404' }]} />

      <div className="content-wrapper">
        <h1>
          {mainframe
            ? 'Não foi possível carregar estes dados'
            : 'Ops! Esta página não foi\nencontrada, mas em breve voltaremos.'}
        </h1>
        <h2>Código do erro: 404</h2>

        <Button
          color={colors.lightGreen}
          width={131}
          fontLight
          onClick={handleButton}
        >
          {mainframe ? 'Atualizar' : 'Voltar'}
        </Button>
      </div>

      <img src={imgHorse} alt="Horse" />
    </Container>
  );
}

Error.propTypes = {
  mainframe: PropTypes.bool,
};

export default Error;
