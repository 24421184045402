import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { Menu as AntMenu, Form as AntForm } from 'antd';
import { AiOutlineLoading } from 'react-icons/ai';

import { colors, fonts } from '../../../theme';
import { pxToRem } from '../../../functions';
import Modal from '../../Modal';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const Container = styled.aside`
  display: flex;
  flex-direction: column;

  padding: ${pxToRem(20)};
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;  

  width: 100%;
  
  #logo {    
    margin: 0 auto 10%;

    width: 77%;    
  }

  #user-info {
    position: relative;

    width: 100%;
    height: ${pxToRem(40)};

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    :hover #name{
      background-color: rgba(0, 0, 0, 0.24);
    }

    :active #name{
      background-color: rgba(0, 0, 0, 0.44);
    }

    img {
      left: 0;
      bottom: 0;      
      position: absolute;

      object-fit: cover;
      object-position: top;

      border-radius: 100%;
      width: ${pxToRem(44)};
      height: ${pxToRem(44)};
    }

    > svg {
      margin-bottom: 2%;
      margin-left: ${pxToRem(14.5)};

      font-size: 20px;

      color: ${colors.white};
      animation: ${rotation} 1s infinite;

      ${media.greaterThan('1900px')`
        margin-bottom: 3%;

        font-size: 30px;
      `}

    }

    #name {
      display: flex;
      align-items: center;
      justify-content: center;

      left: 0;
      bottom: 5%;
      z-index: -1;
      position: absolute;

      width: 100%;
      height: 100%;
      border-radius: ${pxToRem(25)};

      transition: background 0.1s;
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.14);

      p {
        height: auto !important;

        display: flex;        
        justify-content: center;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        margin-left: ${pxToRem(30)};        

        width: 63%;
        ${fonts.size2}        
        font-family: 'SFRounded-Medium';
        height: ${pxToRem(28)};    
        
        color: ${colors.white};
      }
    }

    .caretdown-icon {
      position: absolute;
      right: ${pxToRem(14.5)};      

      ${fonts.size2};

      color: ${colors.white};

      ${media.greaterThan('1900px')`
        ${fonts.size3};
      `}
    }
  }

  ${media.between('huge', '1900px')`
    #logo {
      height: 47%;

      margin-bottom: ${pxToRem(18)};

      label {
        ${fonts.size1};
      }
    }

    #user-info {
      #name p {
        margin-left: ${pxToRem(20)};
      }      
    }
  `}

  ${media.greaterThan('1900px')`
    #logo {
      margin-bottom: ${pxToRem(22)};

      label {
        ${fonts.size2};
      }
    }

    #user-info {  
      height: ${pxToRem(56)};

      img {
        width: ${pxToRem(61)};
        height: ${pxToRem(61)};
      }

      #name p {
        height: ${pxToRem(38)};
        ${fonts.size4};
      }
    }
  `}

  ${media.lessThan('large')`    
    #logo {
      height: 38%;
    }
  `}
`;

export const SubMenu = styled(AntMenu)`
  background: transparent;
  border: 0;
`;

export const SubMenuItem = styled(AntMenu.Item)`
  line-height: ${pxToRem(30)} !important;
  height: ${pxToRem(30)} !important;

  margin: 0 !important;

  a,
  button,
  label {
    border: none;

    align-items: center;
    display: flex !important;

    background: transparent;

    svg {
      margin-right: ${pxToRem(4)};
    }
  }

  a,
  label,
  button,
  a:hover,
  label:hover {
    color: ${colors.darkGrey};
  }

  .logout,
  .logout:hover {
    color: ${colors.danger};
  }

  :hover {
    background: ${colors.lightGrey};
  }

  input {
    display: none;
  }
`;

export const Form = styled(AntForm)`
  position: relative;

  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Menu = styled.menu`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${pxToRem(6)};

  width: 100%;
  height: 44.75%;
  padding: 5%;

  ul {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(3, calc(100% / 3));

    /* Need to withdraw margin defined by lib ant */
    margin: 0;

    width: 100%;
    height: 100%;

    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 92%;
      height: 92%;
      padding: 5% 11%;

      margin: 6%;
      border-radius: ${pxToRem(4)};

      cursor: pointer;
      backdrop-filter: blur(5px);
      transition: background 0.1s;
      background-color: rgba(32, 32, 32, 0.5);

      :hover {
        background-color: rgba(32, 32, 32, 0.7);
      }

      :active {
        background-color: rgba(32, 32, 32);
      }

      #menu-item-wrapper {
        min-width: 100%;

        #icon-wrapper {
          width: 100%;
          height: ${pxToRem(24)};
          margin-bottom: ${pxToRem(8)};

          img {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }

        #title-wrapper {
          p {
            margin: 0;

            text-align: center;

            ${fonts.size1};
            color: ${colors.white};
          }
        }
      }
    }
  }

  li.disabled,
  li.disabled:hover,
  li.disabled:active {
    cursor: not-allowed;
    filter: opacity(0.4);
    background-color: rgba(32, 32, 32, 0.5);
  }

  ${media.greaterThan('huge')`
    margin-top: ${pxToRem(10)};

    ul li {
      #menu-item-wrapper {
        grid-gap: 4px;

        #icon-wrapper {
          height: ${pxToRem(32)};
        }
        
        #title-wrapper {
          p {
            ${fonts.size2};          
          }
        }                    
      }
    }
  `}

  ${media.greaterThan('1900px')`
    ul li {
      padding: 0 12%;

      #menu-item-wrapper {
        #icon-wrapper {          
          height: ${pxToRem(36)};
        }
        
        #title-wrapper p {
          ${fonts.size3};
        }  
      }
    }
  `}
`;

export const Favorites = styled.section`
  position: relative;
  margin-top: ${pxToRem(6)};
  padding-top: ${pxToRem(24)};

  width: 100%;
  height: 28%;

  div {
    display: flex;
    align-items: center;

    position: absolute;
    top: ${pxToRem(1)};

    .star-icon {
      ${fonts.size3};

      color: ${colors.darkYellow};

      ${media.greaterThan('1900px')`
        ${fonts.size4};
      `}
    }

    label {
      margin-left: ${pxToRem(4)};

      ${fonts.size1};

      color: ${colors.white};
    }
  }

  ul {
    height: 100%;

    overflow: hidden;

    li {
      height: ${pxToRem(20)};

      a {
        display: flex;
        align-items: center;

        width: 100%;
        height: 100%;
        ${fonts.size1};
        white-space: nowrap;
        padding: 0 ${pxToRem(10)};
        border-radius: ${pxToRem(6)};

        transition: background 0.1s;
        backdrop-filter: blur(10px);
        color: rgba(255, 255, 255, 0.75);
        background-color: rgba(0, 0, 0, 0.14);

        &:hover,
        &.active {
          background-color: rgba(0, 0, 0, 0.24);
        }
      }

      + li {
        margin-top: 1.978%;
      }
    }
  }

  ${media.greaterThan('huge')`
    margin-top: ${pxToRem(10)};
  `}

  ${media.greaterThan('1600px')`
    div label {
      ${fonts.size2};
    }

    ul li {
      height: ${pxToRem(23.42)};

      a{
        ${fonts.size2};
      }
    }
  `}

  ${media.greaterThan('1900px')`
    div label {
      ${fonts.size3};
    }

    ul li {
      height: ${pxToRem(28)};

      a{
        ${fonts.size3};
      }
    }
  `}
`;

export const Footer = styled.footer`
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  margin-top: ${pxToRem(15)};

  width: 100%;
  height: 7.12%;

  #links {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0;

    width: 80%;
    height: 28%;

    li a {
      ${fonts.size1};
      font-family: 'SFProText-Bold', sans-serif;
      color: ${colors.white};
      transition: text-decoration 0.25s ease;
      text-decoration: underline solid transparent;

      :hover {
        text-decoration: underline solid ${colors.white};
      }
    }

    #attendance {
      transform: translateX(4px);
    }
  }

  #phone {
    display: flex;
    align-items: center;

    ${fonts.size0};
    line-height: 1.56;
    letter-spacing: normal;
    transform: translateX(-5px);

    color: ${colors.white};
    transition: text-decoration 0.25s ease;
    text-decoration: underline solid transparent;

    :hover {
      text-decoration: underline solid ${colors.white};
    }

    svg {
      margin-right: ${pxToRem(2)};
    }
  }

  #copyright {
    width: 41%;

    line-height: 1.22;
    text-align: center;

    > a {
      ${fonts.size0};
      color: rgba(255, 255, 255, 0.5);
    }
  }

  ::before {
    position: absolute;
    top: ${pxToRem(-5.9)};

    width: 80%;
    height: ${pxToRem(1)};

    content: '';
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${media.greaterThan('huge')`
    #links li a{
      ${fonts.size2};

    }

    #attendance {
      transform: translateX(5px);
    }

    #phone {
      transform: translateX(-6px);
    }

    #phone, #copyright {
      ${fonts.size1};
    }
  `}

  ${media.greaterThan('1900px')`
    #links li a{
      ${fonts.size3};

    }

    #attendance {
      transform: translateX(5px);
    }

    #phone {
      transform: translateX(-7px);
    }

    #phone, #copyright {
      ${fonts.size2};
    }
  `}
`;

export const ModalComponent = styled(Modal)`
  form {
    height: 100%;
    padding: 20px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 1.5rem;
      color: ${colors.black};
      font-family: 'SFRounded-Thin', sans-serif;
    }

    button {
      border-radius: 1.25rem;

      background: ${colors.success};
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    border-color: ${colors.lightGreen};

    color: ${colors.lightGreen};
  }

  .ant-btn-primary:hover {
    color: ${colors.white};

    filter: brightness(1.07);
  }
`;

export const InputContainer = styled.div`
  position: relative;

  width: 60%;
  padding: 8px 16px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  margin-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 8px;
  }

  > input {
    border: none;

    flex: 1;

    background: transparent;
  }

  svg {
    margin-left: 1rem;
  }
`;

export const CircleLoading = styled(AiOutlineLoading)`
  bottom: 0;
  position: absolute;

  animation: ${rotation} 1s infinite;
`;
