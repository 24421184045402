import styled, { keyframes } from 'styled-components';
import { Radio } from 'antd';
import { fadeIn } from 'react-animations';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

const bounceFadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled(Radio.Group)`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
`;

export const RadioItem = styled(Radio)`
  display: flex;
  align-items: center;

  margin-bottom: 0 !important;

  animation: 0.3s ${bounceFadeInAnimation};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  span.ant-radio + span {
    padding-left: ${({ size }) => {
      if (size === 'small') return pxToRem(4);
      if (size === 'middle') return pxToRem(5.333);
      if (size === 'large') return pxToRem(8);
    }};
  }

  .ant-radio-inner {
    width: ${({ size }) => {
      if (size === 'small') return pxToRem(12);
      if (size === 'middle') return pxToRem(16);
      if (size === 'large') return pxToRem(24);
    }};
    height: ${({ size }) => {
      if (size === 'small') return pxToRem(12);
      if (size === 'middle') return pxToRem(16);
      if (size === 'large') return pxToRem(24);
    }};

    background-color: transparent;
    border-color: ${colors.eightGrey};

    ::after {
      top: ${({ size }) => {
        if (size === 'small') return `calc(50% - (${pxToRem(6)} / 2));`;
        if (size === 'middle') return `calc(50% - (${pxToRem(8)} / 2));`;
        if (size === 'large') return `calc(50% - (${pxToRem(12)} / 2));`;
      }};
      left: ${({ size }) => {
        if (size === 'small') return `calc(50% - (${pxToRem(6)} / 2));`;
        if (size === 'middle') return `calc(50% - (${pxToRem(8)} / 2));`;
        if (size === 'large') return `calc(50% - (${pxToRem(12)} / 2));`;
      }};

      width: ${({ size }) => {
        if (size === 'small') return pxToRem(6);
        if (size === 'middle') return pxToRem(8);
        if (size === 'large') return pxToRem(12);
      }};
      height: ${({ size }) => {
        if (size === 'small') return pxToRem(6);
        if (size === 'middle') return pxToRem(8);
        if (size === 'large') return pxToRem(12);
      }};

      background-color: ${colors.blue};
    }

    :hover {
      border-color: ${colors.blue};
    }
  }

  :disabled {
    cursor: not-allowed;
  }

  .ant-radio-disabled .ant-radio-inner {
    border-color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.blue};
  }
`;
