import React, { useState, useEffect, useCallback } from 'react';
import swal from 'sweetalert';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FiMoreVertical, FiCheck, FiX } from 'react-icons/fi';

import { colors } from '../../theme';
import { api } from '../../services/api';
import { convertCurrency, sort, parseDateIso } from '../../functions';

import {
  Input,
  Radio,
  Table,
  Select,
  Switch,
  Button,
  FormItem,
  PreLoader,
  Breadcrumb,
  TableHeader,
  MaskedInput,
  StyledPopoverSubmenu,
} from '../../components';

import {
  Form,
  Animal,
  Number,
  Drawer,
  Tooltip,
  Container,
  OutOfTime,
  DateInput,
  MoreButton,
  DrawerButton,
  PeriodWrapper,
  OptionsButton,
  DateInputWrapper,
  ActionButtonWrapper,
} from './styles';

const CoverageList = () => {
  const initialParams = {
    periodo: '',
    semestre: '',
    fora_prazo: '',
    propriedade: '',
    tamanho_pag: 50,
    tipo_cobertura: '',
    transferencia_embriao: '',
    nome_registro_garanhao: '',
    id_proprietario_garanhao: '',
  };

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [coverages, setCoverages] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [activeSort, setActiveSort] = useState('');
  const [preLoader, setPreLoader] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [params, setParams] = useState(initialParams);
  const [allCoverages, setAllCoverages] = useState([]);
  const [coverageDates, setCoverageDates] = useState({});
  const [totalCoverages, setTotalCoverages] = useState(0);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [editingDateId, setEditingDateId] = useState(null);
  const [invalidDateField, setInvalidDateField] = useState({});
  const [modalVisibility, setModalVisibility] = useState(false);
  const [searchedCoverages, setSearchedCoverages] = useState([]);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [currentCoverageDates, setCurrentCoverageDates] = useState({});
  const [currentPreRegisterNumber, setCurrentPreRegisterNumber] = useState('');

  const pageTitle = 'Coberturas comunicadas';

  const switchDrawer = () => setDrawerVisibility(!drawerVisibility);

  const fetchCoverageList = useCallback(async (params, page) => {
    setLoading(true);

    try {
      const { data } = await api.get('cobertura/listagem', {
        params: { ...params, pagina: page },
      });

      setTotalCoverages(data.data.length);

      return data.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setPreLoader(false);
    }
  }, []);

  useEffect(() => {
    const initialTableData = async () => {
      const fetchedCoverages = await fetchCoverageList(params, 1);

      setAllCoverages(fetchedCoverages);
    };

    initialTableData();
  }, [params, fetchCoverageList]);

  useEffect(() => {
    const listenSearch = () => {
      if (searchValue === '') {
        setCoverages(allCoverages);
        setTotalCoverages(allCoverages?.length);
      } else {
        setCoverages(searchedCoverages);
        setTotalCoverages(searchedCoverages?.length);
      }
    };

    listenSearch();
  }, [searchValue, allCoverages, searchedCoverages]);

  const handleSort = (objectKey, method) => {
    const [activeSort, sortedData] = sort(coverages, objectKey, method);

    setActiveSort(activeSort);
    setCoverages(sortedData);
  };

  const clearFilter = () => {
    setPage(1);
    setCoverages([]);
    setAllCoverages([]);

    setParams(initialParams);

    setFiltered(false);
  };

  const handleEditDate = (coverage) => {
    const {
      id_cobertura,
      primeira_data,
      segunda_data,
      terceira_data,
    } = coverage;

    const firstDate = primeira_data?.substring(0, 10) || '';
    const secondDate = segunda_data?.substring(0, 10) || '';
    const thirdDate = terceira_data?.substring(0, 10) || '';

    const dates = {
      firstDate,
      secondDate,
      thirdDate,
    };

    setEditingDateId(id_cobertura);
    setCoverageDates(dates);
    setCurrentCoverageDates(dates);
  };

  /**
   * Validate date to edit
   * @param {Date} currentDateIso current date to compare with the new date
   * @param {Date} newDateIso new date to compare with current the date
   */
  const isDateEditingInvalid = (currentDateIso, newDateIso) => {
    const currentDate = moment(currentDateIso);
    const newDate = moment(newDateIso);

    if (!currentDate.isValid() && !currentDate.isValid()) {
      throw new Error(
        'currentDate and newDate must be an ISO 8601 date format'
      );
    }

    const currentMonth = currentDate.month() + 1;
    const newMonth = newDate.month() + 1;

    // Check semester
    if (
      (currentMonth < 7 && newMonth >= 7) ||
      (currentMonth >= 7 && newMonth < 7) ||
      currentDate.year() !== newDate.year()
    ) {
      return 'A nova data precisa estar dentro do mesmo semestre que a data cadastrada anteriormente';
    }

    // Check date range
    if (
      newDate < moment(currentDateIso).subtract(15, 'days') ||
      newDate > moment(currentDateIso).add(15, 'days')
    ) {
      return 'A nova data precisa estar em um intervalo de 15 dias antes ou 15 dias depois que a data cadastrada anteriormente';
    }

    return false;
  };

  const handleSubmitDateEditing = () => {
    const {
      firstDate: currentFirstDate,
      secondDate: currentSecondDate,
      thirdDate: currentThirdDate,
    } = currentCoverageDates;

    const {
      firstDate: newFirstDate,
      secondDate: newSecondDate,
      thirdDate: newThirdDate,
    } = coverageDates;

    let errorMessage = '';
    let invalidFields = {};

    /**
     * Check if new dates is equal old dates
     */
    if (
      newFirstDate === currentFirstDate &&
      newSecondDate === currentSecondDate &&
      newThirdDate === currentThirdDate
    ) {
      setEditingDateId(null);

      return;
    }

    /**
     * Parse ISO
     */
    const currentFirstDateIso = parseDateIso(currentFirstDate);
    const currentSecondDateIso = currentSecondDate
      ? parseDateIso(currentSecondDate)
      : null;
    const currentThirdDateIso = currentThirdDate
      ? parseDateIso(currentThirdDate)
      : null;

    const newFirstDateIso = parseDateIso(newFirstDate);
    const newSecondDateIso = newSecondDate ? parseDateIso(newSecondDate) : null;
    const newThirdDateIso = newThirdDate ? parseDateIso(newThirdDate) : null;

    /**
     * Check date editing rules
     */
    const firstDateErrorMessage = isDateEditingInvalid(
      currentFirstDateIso,
      newFirstDateIso
    );
    const secondDateErrorMessage =
      currentSecondDateIso &&
      isDateEditingInvalid(currentSecondDateIso, newSecondDateIso);
    const thirdDateErrorMessage =
      currentThirdDateIso &&
      isDateEditingInvalid(currentThirdDateIso, newThirdDateIso);

    if (firstDateErrorMessage) {
      errorMessage = firstDateErrorMessage;
      invalidFields = { ...invalidFields, firstDate: true };
    }

    if (secondDateErrorMessage) {
      errorMessage = secondDateErrorMessage;
      invalidFields = { ...invalidFields, secondDate: true };
    }

    if (thirdDateErrorMessage) {
      errorMessage = thirdDateErrorMessage;
      invalidFields = { ...invalidFields, thirdDate: true };
    }

    /**
     * Check if the dates are within the same semester
     */
    const firstDateMonth = newFirstDateIso.getMonth() + 1;
    const secondDateMonth = newSecondDateIso?.getMonth() + 1 || null;
    const thirdDateMonth = newThirdDateIso?.getMonth() + 1 || null;

    if (
      secondDateMonth &&
      ((firstDateMonth < 7 && secondDateMonth >= 7) ||
        (firstDateMonth >= 7 && secondDateMonth < 7))
    ) {
      errorMessage = 'Todas as datas devem estar dentro do mesmo semestre';
      invalidFields = { ...invalidFields, secondDate: true };
    }

    if (
      thirdDateMonth &&
      ((firstDateMonth < 7 && thirdDateMonth >= 7) ||
        (firstDateMonth >= 7 && thirdDateMonth < 7))
    ) {
      errorMessage = 'Todas as datas devem estar dentro do mesmo semestre';
      invalidFields = { ...invalidFields, thirdDate: true };
    }

    /**
     * Check that one date is not greater than another
     */
    if (newSecondDateIso && newSecondDateIso <= newFirstDateIso) {
      errorMessage =
        'A data não pode ser menor ou igual que a data da cobertura anterior';
      invalidFields = { ...invalidFields, secondDate: true };
    }

    if (
      newThirdDateIso &&
      (newThirdDateIso <= newSecondDateIso ||
        newThirdDateIso <= newFirstDateIso)
    ) {
      errorMessage =
        'A data não pode ser menor ou igual que a data da cobertura anterior';
      invalidFields = { ...invalidFields, thirdDate: true };
    }

    /**
     * Check if it is a valid date
     */
    if (!newFirstDate || newFirstDate.match(/[0-9]/g).length !== 8) {
      errorMessage = 'Informe uma data válida';
      invalidFields = { ...invalidFields, firstDate: true };
    }

    if (
      newSecondDate &&
      newSecondDate.match(/[0-9]/g).length > 1 &&
      newSecondDate.match(/[0-9]/g).length !== 8
    ) {
      errorMessage = 'Informe uma data válida';
      invalidFields = { ...invalidFields, secondDate: true };
    }

    if (
      newThirdDate &&
      newThirdDate.match(/[0-9]/g).length > 1 &&
      newThirdDate.match(/[0-9]/g).length !== 8
    ) {
      errorMessage = 'Informe uma data válida';
      invalidFields = { ...invalidFields, thirdDate: true };
    }

    if (newThirdDate && !newSecondDate) {
      errorMessage =
        'Informe uma segunda data antes de informar a terceira data';
      invalidFields = { ...invalidFields, secondDate: true };
    }

    if (newSecondDate && !newFirstDate) {
      errorMessage =
        'Informe uma primeira data antes de informar a segunda data';
      invalidFields = { ...invalidFields, firstDate: true };
    }

    if (
      newFirstDateIso &&
      !(newFirstDateIso instanceof Date && !isNaN(newFirstDateIso))
    ) {
      errorMessage = 'Informe uma data válida';
      invalidFields = { ...invalidFields, firstDate: true };
    }

    if (
      newSecondDateIso &&
      !(newSecondDateIso instanceof Date && !isNaN(newSecondDateIso))
    ) {
      errorMessage = 'Informe uma data válida';
      invalidFields = { ...invalidFields, secondDate: true };
    }

    if (
      newThirdDateIso &&
      !(newThirdDateIso instanceof Date && !isNaN(newThirdDateIso))
    ) {
      errorMessage = 'Informe uma data válida';
      invalidFields = { ...invalidFields, thirdDate: true };
    }

    /**
     * Check if has error and invalid fields
     */
    if (errorMessage && Object.keys(invalidFields).length > 0) {
      swal('Data inválida', errorMessage, 'error');
      setInvalidDateField(invalidFields);
    } else {
      swal('Sucesso', 'Protocolo nº 123456', 'success');
      setEditingDateId(null);
      setInvalidDateField({});
    }
  };

  const escapeDateEditing = (e) => {
    e.key === 'Escape' && setEditingDateId(null);
    setInvalidDateField({});
  };

  const cancelDateEditing = () => {
    setEditingDateId(null);
    setInvalidDateField({});
  };

  const handleSubmitFilter = () => {
    setPage(1);
    setCoverages([]);
    setAllCoverages([]);

    const filteredParams = {
      pagina: page,
      tamanho_pag: '',
      id_proprietario_garanhao: '',
      periodo: filterData?.year ? filterData.year : '',
      propriedade: filterData?.mare ? filterData.mare : '',
      semestre: filterData?.semester ? filterData.semester : '',
      fora_prazo: filterData.outOfTime ? filterData.outOfTime : '',
      nome_registro_garanhao: filterData.stallion ? filterData.stallion : '',
      tipo_cobertura: filterData.coverageType ? filterData.coverageType : '',
      transferencia_embriao: filterData.embryoTranfer
        ? filterData.embryoTranfer
        : '',
    };

    setParams(filteredParams);

    setFiltered(true);

    switchDrawer();
  };

  const fetchMoreCoverages = async () => {
    const fetchedData = await fetchCoverageList(params, page + 1);

    setPage(page + 1);
    setAllCoverages([...allCoverages, ...fetchedData]);
  };

  const handleSearch = (value) => {
    setSearchValue(value);

    const searchedItems = allCoverages.filter((coverage) => {
      const preRegisterNumber = coverage?.num_preregistro?.toLowerCase();
      const nameFather = coverage?.nome_pai?.toLowerCase();
      const nameMother = coverage?.nome_mae?.toLowerCase();
      const valueToSearch = value?.toLowerCase();

      return (
        preRegisterNumber.indexOf(valueToSearch) !== -1 ||
        nameFather.indexOf(valueToSearch) !== -1 ||
        nameMother.toLowerCase().indexOf(valueToSearch) !== -1
      );
    });

    setSearchedCoverages(searchedItems);
  };

  const radioSemesterOptions = [
    { value: '1', label: '1º semestre' },
    { value: '2', label: '2º semestre' },
  ];

  const radioMareOptions = [
    { value: '', label: 'Todas' },
    { value: '1', label: 'Próprias' },
    { value: '0', label: 'De terceiros' },
  ];

  const selectCoverageTypeOptions = [
    { value: '1', label: 'Monta a Campo' },
    { value: '0', label: 'Monta Controlada' },
    {
      value: '2',
      label: 'Inseminação Artificial',
    },
    {
      value: '3',
      label: 'Sêmen Importado',
    },
  ];

  const radioEmbryoTransferOptions = [
    { value: '', label: 'Todas' },
    { value: '1', label: 'Sim' },
    { value: '0', label: 'Não' },
  ];

  const tableColumns = [
    { key: 'num_preregistro', title: 'NÚMERO', sort: true, size: 1.2 },
    { key: 'nome_pai', title: 'GARANHÃO', sort: true, size: 2.5 },
    { key: 'nome_mae', title: 'ÉGUA', sort: true, size: 2.5 },
    {
      key: 'dsc_tipo_cobertura',
      title: 'TIPO DE COBERTURA',
      sort: true,
      size: 2,
    },
    { key: 'primeira_data', title: '1ª DATA', sort: true, size: 1.2 },
    { key: 'segunda_data', title: '2ª DATA', sort: true, size: 1.2 },
    { key: 'terceira_data', title: '3ª DATA', sort: true, size: 1.2 },
    { key: 'prazo', title: 'FORA DO PRAZO', sort: true, size: 1.5 },
    { key: 'actions', title: '', size: 1 },
  ];

  const tableData = coverages.map((coverage) => ({
    num_preregistro: <Number>{coverage.num_preregistro}</Number>,

    nome_pai: (
      <Animal>
        <p className="name">{coverage.nome_pai}</p>
        <p className="registry">{coverage.registro_pai}</p>
      </Animal>
    ),

    nome_mae: (
      <Animal>
        <p className="name">{coverage.nome_mae}</p>
        <p className="registry">{coverage.registro_mae}</p>
      </Animal>
    ),

    dsc_tipo_cobertura: coverage.dsc_tipo_cobertura,

    primeira_data:
      editingDateId === coverage.id_cobertura ? (
        <DateInputWrapper>
          <DateInput
            name="date-1"
            value={coverageDates.firstDate}
            invalid={invalidDateField.firstDate}
            onKeyUp={escapeDateEditing}
            onPressEnter={handleSubmitDateEditing}
            onChange={(value) =>
              setCoverageDates({ ...coverageDates, firstDate: value })
            }
            autoFocus
          />
        </DateInputWrapper>
      ) : (
        coverage.primeira_data.substring(0, 10)
      ),

    segunda_data:
      editingDateId === coverage.id_cobertura ? (
        <DateInputWrapper>
          <DateInput
            name="date-2"
            value={coverageDates.secondDate}
            invalid={invalidDateField.secondDate}
            onKeyUp={escapeDateEditing}
            onPressEnter={handleSubmitDateEditing}
            onChange={(value) =>
              setCoverageDates({ ...coverageDates, secondDate: value })
            }
          />
        </DateInputWrapper>
      ) : coverage.segunda_data ? (
        coverage.segunda_data.substring(0, 10)
      ) : (
        '--'
      ),

    terceira_data:
      editingDateId === coverage.id_cobertura ? (
        <DateInputWrapper>
          <DateInput
            name="date-3"
            value={coverageDates.thirdDate}
            invalid={invalidDateField.thirdDate}
            onKeyUp={escapeDateEditing}
            onPressEnter={handleSubmitDateEditing}
            onChange={(value) =>
              setCoverageDates({ ...coverageDates, thirdDate: value })
            }
          />
        </DateInputWrapper>
      ) : coverage.terceira_data ? (
        coverage.terceira_data.substring(0, 10)
      ) : (
        '--'
      ),

    prazo: (
      <OutOfTime hasValue={!!coverage.prazo}>
        {!coverage.prazo ? '--' : convertCurrency(coverage.prazo)}
      </OutOfTime>
    ),

    actions:
      editingDateId === coverage.id_cobertura ? (
        <ActionButtonWrapper>
          <Tooltip
            title="Cancelar alterações"
            placement="topRight"
            align={{ offset: [6, 0] }}
          >
            <OptionsButton
              onClick={cancelDateEditing}
              backgroundColor={colors.danger}
            >
              <FiX />
            </OptionsButton>
          </Tooltip>

          <Tooltip
            title="Confirmar alterações"
            placement="topRight"
            align={{ offset: [6, 0] }}
          >
            <OptionsButton onClick={handleSubmitDateEditing}>
              <FiCheck />
            </OptionsButton>
          </Tooltip>
        </ActionButtonWrapper>
      ) : (
        <StyledPopoverSubmenu
          menuItems={[
            {
              title: 'Editar datas',
              onClick: () => handleEditDate(coverage),
            },
            { title: 'Enviar por e-mail', onClick: () => {} },
          ]}
          placement="left"
          align={{ offset: [10, 0] }}
        >
          <ActionButtonWrapper>
            <MoreButton>
              <FiMoreVertical />
            </MoreButton>
          </ActionButtonWrapper>
        </StyledPopoverSubmenu>
      ),
  }));

  return (
    <Container>
      <Breadcrumb pages={[{ title: pageTitle }]} />

      <Helmet>
        <title>{pageTitle} - Central do Quartista</title>
      </Helmet>
      <TableHeader
        title={pageTitle}
        count={totalCoverages}
        onFilterButtonClick={switchDrawer}
        onSearch={handleSearch}
        clearFilter={clearFilter}
        filtered={filtered}
        extraButtons={
          <Link to="comunicado-de-cobertura" style={{ marginLeft: 16 }}>
            <Button color={colors.blue} height={32} fontLight>
              Nova cobertura
            </Button>
          </Link>
        }
      />

      {preLoader ? (
        <PreLoader light />
      ) : (
        <Table
          light
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          count={totalCoverages}
          handleSort={handleSort}
          activeSort={activeSort}
          dataLength={allCoverages}
          fetchMoreData={fetchMoreCoverages}
        />
      )}

      <Drawer
        title="Filtro"
        placement="right"
        onClose={switchDrawer}
        destroyOnClose
        visible={drawerVisibility}
      >
        <Form onFinish={handleSubmitFilter}>
          <FormItem label="Período" name="period">
            <PeriodWrapper>
              <MaskedInput
                mask="1111"
                name="year"
                width={window.innerWidth / 10.58}
                placeholder="Ano"
                value={filterData.year}
                onChange={(value) =>
                  setFilterData({ ...filterData, year: value })
                }
                style={{ marginRight: 27 }}
              />

              <Radio
                name="semester"
                size={window.innerWidth >= 1900 ? 'large' : 'middle'}
                options={radioSemesterOptions}
                vertical
                value={filterData.semester}
                onChange={(value) =>
                  setFilterData({ ...filterData, semester: value })
                }
              />
            </PeriodWrapper>
          </FormItem>

          <FormItem label="Égua" name="mare" className="form-item2">
            <Radio
              name="mare"
              size={window.innerWidth >= 1900 ? 'large' : 'middle'}
              options={radioMareOptions}
              value={filterData.mare}
              onChange={(value) =>
                setFilterData({ ...filterData, mare: value })
              }
            />
          </FormItem>

          <FormItem label="Garanhão" name="stallion" className="form-item3">
            <Input
              name="stallion"
              placeholder="Nome ou registro"
              width="100%"
              value={filterData.stallion}
              onChange={(value) =>
                setFilterData({ ...filterData, stallion: value })
              }
            />
          </FormItem>

          <FormItem
            label="Tipo de cobertura"
            name="coverage-type"
            className="form-item4"
          >
            <Select
              name="coverage-type"
              placeholder="Selecione"
              width="100%"
              options={selectCoverageTypeOptions}
              value={filterData.coverageType}
              onChange={(value) =>
                setFilterData({ ...filterData, coverageType: value })
              }
            />
          </FormItem>

          <FormItem
            label="Transferência de embrião"
            name="embryo-transfer"
            className="form-item5"
          >
            <Radio
              name="embryo-transfer"
              size={window.innerWidth >= 1900 ? 'large' : 'middle'}
              options={radioEmbryoTransferOptions}
              value={filterData.embryoTranfer}
              onChange={(value) =>
                setFilterData({ ...filterData, embryoTranfer: value })
              }
            />
          </FormItem>

          <FormItem
            label="Fora do prazo"
            name="out-of-time"
            horizontal
            className="horizontal form-item6"
          >
            <Switch
              size="default"
              checked={filterData.outOfTime}
              onChange={(checked) =>
                setFilterData({ ...filterData, outOfTime: checked })
              }
            />
          </FormItem>

          <DrawerButton type="submit" color={colors.lightGreen} fontLight>
            Aplicar
          </DrawerButton>
        </Form>
      </Drawer>
    </Container>
  );
};

export default CoverageList;
