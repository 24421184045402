import { api } from './api';
import { removeTokenApiPagamentos } from '../services/api-pagamentos'

export const login = async () => {

  if (getToken()) {
    try {
      const { data } = await api.get('Pessoa/DadosPessoa');

      if (data.success) {
        return { token: getToken(), user: data.data[0] };
      } else {
        removeToken();
        removeUser();
        removeTokenApiPagamentos();
      }
    } catch (error) {
      console.error(error);
    }
  }

  return false;
};

export const logout = () => {
  removeToken();
  removeTokenApiPagamentos();

  window.location.replace(process.env.REACT_APP_LOGIN_PAGE);
};

export const setToken = (token) => {
  return localStorage.setItem('@CentralDoQuartista:token', token);
};

export const getToken = () => {
  return localStorage.getItem('@CentralDoQuartista:token');
};

export const removeToken = () => {
  localStorage.removeItem('@CentralDoQuartista:token');

};

export const removeUser = () => {
  return localStorage.removeItem('@CentralDoQuartista:id_pessoa');
};
