import styled from 'styled-components';
import { AutoComplete } from 'antd';

import { pxToRem } from '../../functions';

export const Container = styled(AutoComplete)`
  height: auto;

  .ant-select-selector {
    width: ${pxToRem(320)} !important;
    border-radius: 4px !important;

    input {
      color: rgba(0, 0, 0, 0.65);
      font-family: 'SFProText-Regular', sans-serif;
    }
  }
`;
