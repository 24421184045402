import styled, { keyframes } from 'styled-components';

import { zoomIn } from 'react-animations';

const bounceZoomInAnimation = keyframes`${zoomIn}`;

export const Container = styled.svg`
  animation: 0.8s ${bounceZoomInAnimation};
`;

export const ExclamationIcon = styled.path`
  fill: #ffcb00;
  stroke: #ffcb00;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;

  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 4s linear both;

  @keyframes dash {
    from {
      stroke-dashoffset: 2000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const Circle = styled.circle`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s linear both;

  @keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;
