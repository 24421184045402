import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { auth } from '../services';

import {
  Dashboard,
  RegistrationRequest,
  CoverageList,
  Auth,
  Error,
  Mobile,
  SingleReviews,
  AnimalProfile,
  OnlineServices,
  InspectCarriedOut,
  AnimalRelationship,
  Payments,
  CoverageCommunication,
  TrademarkRegistration,
  EditingRegistrationOrders,
  EmbryoTransferNotification,
  RegistrationRequestConsultation,
  AnimalChip
} from '../pages';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.login() ? (
        <Component {...props} />
      ) : (
        window.location.replace(process.env.REACT_APP_LOGIN_PAGE)
      )
    }
  />
);

export default function Routes() {
  return (
    <Switch>
      <Route path="/auth" component={Auth} />

      <Route
        path="/novo-comunicado"
        render={({location}) => (
          <Redirect
            to={{
              pathname: '/comunicado-de-cobertura',
              state: { 
                stallion: location.state.stallion, 
                frozenSemenImporters: location.state.frozenSemenImporters, 
              },
            }}
          />
        )}
      />
      <Route path="/mobile/:id/:path/:token" component={Mobile} />

      <PrivateRoute path="/" exact component={Dashboard} />

      <PrivateRoute path="/error" exact component={Error} />

      <PrivateRoute path="/servicos/:page?" exact component={OnlineServices} />

      <PrivateRoute
        path="/pedidos-de-registro"
        exact
        component={RegistrationRequest}
      />

      <PrivateRoute path="/resenhas-avulsas" exact component={SingleReviews} />

      <PrivateRoute
        path="/inspecoes-realizadas"
        exact
        component={InspectCarriedOut}
      />

      <PrivateRoute
        path="/consulta-de-pedido-de-registro"
        component={RegistrationRequestConsultation}
      />

      <PrivateRoute
        path="/detalhes-de-pedido-de-registro/:id"
        component={EditingRegistrationOrders}
      />

      <PrivateRoute
        path="/comunicado-de-cobertura"
        exact
        component={CoverageCommunication}
      />

      <PrivateRoute
        path="/listagem-de-coberturas"
        exact
        component={CoverageList}
      />

      <PrivateRoute
        path="/relacao-de-animais"
        exact
        component={AnimalRelationship}
      />

      <PrivateRoute path="/perfil-do-animal/:id?" component={AnimalProfile} />

      <PrivateRoute
        path="/cadastro-de-marca"
        component={TrademarkRegistration}
      />

      <PrivateRoute
        path="/pagamentos/:d?"
        exact
        component={Payments}
      />

      <PrivateRoute
        path="/listagem-chip"
        exact
        component={AnimalChip}
      />

      <Redirect from="*" to="/error" />
    </Switch>
  );
}
