import styled from 'styled-components';
import media from 'styled-media-query';

import { fonts } from '../../../theme';
import { pxToRem } from '../../../functions';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: ${pxToRem(59)} 0;

  > * {
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
  }

  h1 {
    ${fonts.size7}
    font-family: 'SFRounded-Thin', sans-serif;

    strong {
      font-family: 'SFRounded-Bold', sans-serif;
    }
  }

  p {
    margin: ${pxToRem(50)} 0;

    ${fonts.size2};
    line-height: 1.58;
    letter-spacing: -0.34px;
  }

  .checkbox-button-wrapper {
    display: inherit;
    align-items: inherit;
    flex-direction: inherit;

    button {
      margin-top: ${pxToRem(16)};
    }
  }

  ${media.greaterThan('1600px')`
    padding: ${pxToRem(69)} 0;

    h1 {
      ${fonts.size8};
    }

    p {
      ${fonts.size3};
    }
  `}

  ${media.greaterThan('1900px')`
    padding: ${pxToRem(82)} 0;
  `}
`;
