import React from 'react';

import { BreadcrumbProvider } from './Breadcrumb';
import { AuthProvider } from './Auth';
import { IgnoreMainframeProvider } from './IgnoreMainframe';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <IgnoreMainframeProvider>
      <BreadcrumbProvider>{children}</BreadcrumbProvider>
    </IgnoreMainframeProvider>
  </AuthProvider>
);

export default AppProvider;
