import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { Form as AntForm } from 'antd';
import { AiOutlineLoading } from 'react-icons/ai';
import { Modal as ModalComponent } from '../../../../components';

import { pxToRem } from '../../../../functions';
import { colors } from '../../../../theme';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const Modal = styled(ModalComponent)``;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  * label {
    color: ${colors.eightGrey};
  }

  .form-item1 {
    margin-top: ${pxToRem(30)};

    ${media.lessThan('1024px')`
      margin-top: ${pxToRem(10)};

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > label + label {
          margin-top: ${pxToRem(10)};
        }
      }
    `}
  }

  .embrio-transfer-radio-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .embryo-transfer-type {
    margin-top: ${pxToRem(30)};

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > div {
      margin-left: 75px;

      flex-direction: column;

      > label + label {
        margin-top: ${pxToRem(10)};
      }
    }

    ${media.lessThan('1024px')`
      margin-top: ${pxToRem(10)};
    `}
  }

  .receiving-mare {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    ${media.lessThan('1024px')`
      flex-direction: column;
      align-items: flex-start;
    `}
  }

  .ant-form-item-label > label {
    height: 0;
  }
`;

export const CircleLoading = styled(AiOutlineLoading)`
  align-self: center;

  font-size: 20px;
  color: ${colors.lightGreen};
  animation: ${rotation} 1s infinite;
`;
