import styled from 'styled-components';
import media from 'styled-media-query';

import { colors } from '../../../theme';
import { pxToRem } from '../../../functions';

export const Container = styled.section`
  height: 100%;
  overflow: hidden;

  h1 {
    font-size: 1.5rem;
    font-family: 'SFProText-Bold', sans-serif;
  }

  table {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
    border-collapse: collapse;
  }

  thead tr {
    height: 50px;
    padding-left: ${pxToRem(8)};

    background: ${colors.lightGrey};
  }

  tbody tr {
    grid-gap: 0;
    padding: 8px;

    display: flex;
    align-items: center;

    :nth-child(odd) {
      background-color: rgba(40, 49, 49, 0.06);
    }

    :nth-child(even) {
      background-color: rgba(40, 49, 49, 0.1);
    }

    :hover {
      background-color: rgba(40, 49, 49, 0.15);
    }

    > td {
      height: auto !important;

      + td {
        width: 285%;
      }
    }
  }

  th,
  td,
  p {
    font-size: ${pxToRem(14)};
    font-family: 'SFProText-Regular', sans-serif;
  }

  #body-wrapper {
    height: auto;
  }
`;

export const Stallion = styled.tr`
  display: flex;
  flex-direction: row;

  > div.type-wrapper {
    padding: ${pxToRem(8)} 0;

    display: flex;
    flex-direction: column;
  }

  p {
    padding-left: 8px;

    & + p {
      margin-top: ${pxToRem(4)};
    }
  }
`;

export const Text = styled.p`
  font-size: 11px;
  text-align: left;
  line-height: 1.18;
  white-space: nowrap;

  font-family: 'SFProText-Semibold', sans-serif;

  ${media.greaterThan('huge')`
    font-size: 12px;
  `}
`;
