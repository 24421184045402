/**
 * convert CPF or CNPJ numbers to string with separators
 * @param {string|number} value CPF or CNPJ number register
 */
export const addSeparators = (value) => {
  if (!value) {
    throw new Error('value is not be an empty string');
  }

  if (typeof value !== 'string' && typeof value !== 'number') {
    throw new Error('value must be an string or number type');
  }

  if (value.length === 11) {
    // CPF

    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (value.length === 14) {
    // CNPJ

    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  } else {
    throw new Error(
      'value must contain 11 to be a valid CPF or 14 characters to be a valid CNPJ'
    );
  }
};

/**
 * remove separators of CPF or CNPJ
 * @param {string} value CPF or CNPJ with separators
 */
export const removeSeparators = (value) => {
  if (!value) {
    throw new Error('value is not be an empty string');
  }

  if (typeof value !== 'string') {
    throw new Error('value must be an string type');
  }

  return value.replace(/[/.-]/g, '');
};
