import styled, { css } from 'styled-components';
import { pxToRem } from '../../functions';
import media from 'styled-media-query';
import { colors, fonts } from '../../theme';

export const Container = styled.section`
  ${({ loading }) =>
  loading &&
  css`
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    `}

  width: 100%;
  height: 100%;

  padding: ${pxToRem(20)} ${pxToRem(40)};

  ${media.greaterThan('1900px')`
    padding: ${pxToRem(28)} ${pxToRem(56)};
  `}

  ${media.greaterThan('1600px')`
    padding: ${pxToRem(23.5)} ${pxToRem(46)};
  `}
`;

export const MainTitle = styled.h1`
  font-size: 1.6rem;
  color: #fff;
  display: inline-block;
`;

export const MainTitleWrap = styled.div`

`

export const Wrap = styled.div`
 padding: 1rem;
`

export const Block = styled.div`   
  border-radius: ${pxToRem(6)};
  background: rgba(255, 255, 255, 0.1);
  
  margin-top: ${props => props.mt ? "1rem" : "0"};

  ${media.greaterThan('1600px')`    
    border-radius: ${pxToRem(7)};
  `}

  ${media.greaterThan('1900px')`
    border-radius: ${pxToRem(8)};
  `}
`;

export const Clear = styled.div`
  clear: both;
`


export const TitleBlock = styled.div`
  font-size: 13px !important;
  position: relative;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-bottom: 0.5px solid rgba(255,255,255,0.1);
  box-shadow: 0 -1px 0 0 rgba(0,0,0,0.1) inset;
  color: #ffffff;
  display: block;
`

export const ButtonApp = styled.span`
  button {
    &.ant-btn-primary {
      background: ${colors.deepGreen};
      border: 2px solid  ${colors.darkDeepGreen};
    }
    
    &:hover {
      background: ${colors.lightDeepGreen}; 
    }
    
    &.ant-btn-filter {
     background: ${colors.inputGreen};
     border: 0 solid  ${colors.darkDeepGreen};
     color: rgb(225 246 224 / 53%);    
    font-size: 14px;
    height: 39px;
    float: right;
    padding: 4px 30px;

     svg {
        margin-top: 0px;
        margin-bottom: -3px;
        margin-right: 6px;
     }
     
     img {
      width: 100%;
     }
     
      &:hover {
        background: ${colors.inputGreenLight}; 
      }
     
    }
    
  }
`

export const Divider = styled.div`
    border-top: 0.5px solid rgba(0,0,0,0.1);
    box-shadow: 0 1px 0 0 rgba(255,255,255,0.1) inset;
    color: #ffffff;
    width: 100%;
    height: 2px;
    margin-top: ${props => props.mTop ? props.mTop : '1'}rem;
    margin-bottom: ${props => props.mBottom ? props.mBottom : '1'}rem;
`
