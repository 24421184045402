import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom';

import './styles.css';

import { StyledMenu, StyledMenuItem } from './styles';

const StyledPopoverSubmenu = ({
  children,
  menuItems,
  onItemClick,
  disabled,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = () => setIsVisible(!isVisible);

  const handleNotification = (item) => {
    if (item?.conditions?.length > 0) {
      const conditionNotAllowed = item.conditions.filter(
        (filteredCondition) => filteredCondition?.liberado === false
      );

      if (conditionNotAllowed.length === 0) return;

      return onItemClick(
        conditionNotAllowed.map((condition) => (
          <p key={condition.id_condicao}>- {condition.mensagem}</p>
        ))
      );
    }
  };

  const styledMenuItemWithNavigationAndNotification = (item, i) => (
    <StyledMenuItem key={i} onClick={() => handleNotification(item)}>
      <NavLink
        to={{
          pathname: item.path,
          state: item.iframeUrl && {
            iframeUrl: item.iframeUrl,
            pageTitle: item.title,
          },
        }}
        onClick={handleVisibility}
      >
        {item.title}
      </NavLink>
    </StyledMenuItem>
  );

  const styledMenuItemWithoutNavigation = (item, i, hasNotification) => (
    <StyledMenuItem
      key={i}
      onClick={() => {
        item.onClick && item.onClick();
        hasNotification && handleNotification(item);
        handleVisibility();
      }}
    >
      {item.title}
    </StyledMenuItem>
  );

  const renderContent = () => {
    return (
      <StyledMenu selectable={false}>
        {menuItems.map((item, i) => {

          if (item.path) {

            if (item?.conditions?.length > 0) {

              const conditionNotAllowed = item.conditions.filter(
                (filteredCondition) => filteredCondition?.liberado === false
              );
      
              if (conditionNotAllowed.length === 0)
                return styledMenuItemWithNavigationAndNotification(item, i);

              else
                return styledMenuItemWithoutNavigation(item, i, true);
            }

            else
              return styledMenuItemWithNavigationAndNotification(item, i);
          } 

          else
              return styledMenuItemWithoutNavigation(item, i, false);
        })}
      </StyledMenu>
    );
  };

  return (
    <Popover
      content={menuItems && renderContent()}
      trigger="click"
      overlayClassName="styled-popover"
      {...props}
      visible={!disabled && isVisible}
      onVisibleChange={handleVisibility}
    >
      {children}
    </Popover>
  );
};

StyledPopoverSubmenu.propTypes = {
  children: PropTypes.node.isRequired,
  menuItems: PropTypes.array,
  onItemClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default StyledPopoverSubmenu;
