import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { notification, message } from 'antd';
import { FaPhoneAlt } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import { AiFillCaretDown, AiFillStar, AiOutlineLoading } from 'react-icons/ai';
import { FiEye, FiUnlock, FiEyeOff, FiLogOut, FiCamera } from 'react-icons/fi';

import { colors } from '../../../theme';

import { api } from '../../../services';
import { logout } from '../../../services/auth';
import { useAuth } from '../../../context/Auth';
import { convertToSlug } from '../../../functions';

import { PreLoader } from '../../.';
import StyledPopoverSubmenu from '../../StyledPopoverSubmenu';

import logoImg from '../../../theme/assets/images/logo.svg';
import defaultImage from '../../../theme/assets/images/avatar.png';

import userIcon from '../../../theme/assets/images/icons/user.svg';
import docsIcon from '../../../theme/assets/images/icons/docs.svg';
import gearIcon from '../../../theme/assets/images/icons/config.svg';
import horseIcon from '../../../theme/assets/images/icons/horse.svg';
import studBookIcon from '../../../theme/assets/images/icons/studbook.svg';

import notebookIcon from '../../../theme/assets/images/icons/pen.svg';

import { Button, Modal } from '../../';

import {
  Menu,
  Form,
  Header,
  Footer,
  SubMenu,
  Favorites,
  Container,
  SubMenuItem,
  CircleLoading,
  InputContainer,
} from './styles';

const favoritesItems = [
  { title: 'Comunicado de cobertura', path: '/comunicado-de-cobertura' },
  { title: 'Pedidos de registro', path: '/pedidos-de-registro' },
  { title: 'Coberturas comunicadas', path: '/listagem-de-coberturas' },
  { title: 'Relação de animais', path: '/relacao-de-animais' },
  { title: 'Consulta de animais', path: '/servicos/consulta-de-animais' },
];

const Sider = () => {
  const { userData } = useAuth();
  const imageData = userData.user?.foto;
  const [image, setImage] = useState(null);
  const [temporaryImage, setTemporaryImage] = useState(null);
  const [profileImage, setProfileImage] = useState(defaultImage);
  const [croppedImageConfirm, setCroppedImageConfirm] = useState(null);
  const [loadingToChangeImage, setLoadingToChangeImage] = useState(false);

  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleChangeVisibilityModal = () => {
    setNewPassword('');
    setCurrentPassword('');
    setNewPasswordVisible(false);
    setCurrentPasswordVisible(false);

    setModalIsOpen(!modalIsOpen);
  };

  const imagePreview = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);

  const convertToBase64 = (image) => {
    const img = new Image();
    img.src = image;
    setTemporaryImage(image);
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);

      const base64 = canvas
        .toDataURL('image/png')
        .replace(/^data:image.+;base64,/, '');
      setCroppedImageConfirm(base64);
    };
  };

  const handleChangeVisibilityPassword = (stateName) => {
    if (stateName === 'currentPasswordVisible') {
      setCurrentPasswordVisible(!currentPasswordVisible);
    } else if (stateName === 'newPasswordVisible') {
      setNewPasswordVisible(!newPasswordVisible);
    } else {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      message.warn('As senhas digitadas não conferem!');
      return;
    }

    if (newPassword.length < 6) {
      message.warn('A nova senha precisa ter no mínimo 6 caracteres!');
      return;
    }
    const {
      valida: passwordIsStrong,
      mensagem_bloqueio,
    } = await validateStrongPassword(newPassword);

    if (passwordIsStrong) changePassword(currentPassword, newPassword);
    else
      message.warn(
        'A nova senha não é válida, deve ' + mensagem_bloqueio + '.'
      );
  };

  const validateStrongPassword = useCallback(async (newPassword) => {
    const passwordData = { senha: newPassword };

    try {
      const { data } = await api.patch(
        'seguranca/validasenhaforte',
        passwordData
      );

      return data;
    } catch (e) {
      message.error(e);
    }
  }, []);

  const changePassword = useCallback(
    async (currentPassword, newPassword) => {
      setLoadingModal(true);

      const passwordData = { senha: currentPassword, senhaNova: newPassword, token: userData.token };

      console.log("passwordData", passwordData);
      console.log("userData", userData);

      try {
        const { data } = await api.patch(
          'seguranca/alterarsenha',
          passwordData
        );

        if (data.success) {
          message.success('Senha alterada com sucesso!');

          setModalIsOpen(!modalIsOpen);
        } else if (data.success === false) {
          message.warn(data.mensagem);
        }
      } catch (error) {
        message.error(error.response?.data.mensagem || "Ocorreu um erro ao tentar alterar senha, tente novamente ou entre em contato com o suporte ABQM!");
      } finally {
        setLoadingModal(false);
      }
    },
    [modalIsOpen, userData]
  );

  useEffect(() => {
    imageData && setProfileImage(imageData);
  }, [imageData]);

  useEffect(() => {
    async function fetchMenuData() {
      setLoading(false);

      try {
        const { data } = await api.get('dashboard/menu');

        const menuIcons = [
          userIcon,
          horseIcon,
          docsIcon,
          studBookIcon,
          notebookIcon,
          gearIcon,
        ];

        if (data.success) {
          const formattedData = data.data.map((menuItem) => ({
            icon: menuIcons[menuItem.ordem - 1],
            title: menuItem.sessao,
            submenu: menuItem.controllers?.map((submenuItem) => ({
              path:
                submenuItem.tipo_carregamento === '1' // 1 = Internal page
                  ? `/${submenuItem.link_carregamento}`
                  : `/servicos/${convertToSlug(submenuItem.descricao)}`,
              title: submenuItem.descricao,
              conditions: submenuItem.condicoes,
              iframeUrl:
                submenuItem.tipo_carregamento === '2' // 2 - iFrame page
                  ? `${submenuItem.link_carregamento}token=${userData.token}`
                  : null,
            })),
          }));

          setMenuData(formattedData);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchMenuData();
  }, [userData.token]);

  useEffect(() => {
    if (croppedImageConfirm) {
      setLoadingToChangeImage(true);

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      async function updateImage() {
        try {
          const { data } = await api.post(
            '/pessoa/enviarfotopessoa',
            { foto: String(croppedImageConfirm) },
            config
          );

          if (data.success) {
            setImage(null);
            setCroppedImageConfirm(null);
            setProfileImage(temporaryImage);

            message.success('A foto de perfil foi alterada com sucesso!');
          }
        } catch (error) {
          setImage(null);
          setCroppedImageConfirm(null);

          message.error(
            error.response
              ? error.response.data.mensagem
              : 'Houve um erro ao atualizar a foto de perfil. Se persistir entre em contato com a ABQM.'
          );
        } finally {
          setLoadingToChangeImage(false);
        }
      }
      updateImage();
    }
  }, [croppedImageConfirm, temporaryImage]);

  useEffect(() => {
    if (imagePreview) {
      convertToBase64(imagePreview);
    }
  }, [imagePreview]);

  const userMenu = () => {
    const handleLogout = (e) => {
      e.preventDefault();

      logout();
    };

    return (
      <>
        <SubMenu selectable={false}>
          <SubMenuItem>
            <button type="button" onClick={handleChangeVisibilityModal}>
              <FiUnlock /> Alterar Senha
            </button>
          </SubMenuItem>

          <SubMenuItem>
            <label htmlFor="upload-photo">
              <FiCamera />
              Alterar Foto
            </label>
            <input
              type="file"
              accept="image/*"
              id="upload-photo"
              name="upload-photo"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </SubMenuItem>

          <SubMenuItem>
            <Link to="/logout" className="logout" onClick={handleLogout}>
              <FiLogOut /> Sair
            </Link>
          </SubMenuItem>
        </SubMenu>

        <Modal
          height={300}
          scroll={false}
          isVisible={modalIsOpen}
          title="Alterar minha senha"
          toggleVisibility={handleChangeVisibilityModal}
          footer={[
            <Button
              key="back"
              fontLight
              color={colors.red}
              onClick={handleChangeVisibilityModal}
            >
              Cancelar
            </Button>,

            <Button
              fontLight
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleSubmit}
              color={colors.lightGreen}
            >
              Salvar
            </Button>,
          ]}
        >
          <Form>
            <InputContainer>
              <input
                name="currentPassword"
                value={currentPassword}
                placeholder="Digite a senha atual"
                type={currentPasswordVisible ? 'text' : 'password'}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />

              {!currentPasswordVisible ? (
                <FiEye
                  color={colors.fadedGrey}
                  onClick={() =>
                    handleChangeVisibilityPassword('currentPasswordVisible')
                  }
                />
              ) : (
                <FiEyeOff
                  color={colors.blue}
                  onClick={() =>
                    handleChangeVisibilityPassword('currentPasswordVisible')
                  }
                />
              )}
            </InputContainer>

            <InputContainer>
              <input
                name="new-password"
                placeholder="Digite a nova senha"
                type={newPasswordVisible ? 'text' : 'password'}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              {!newPasswordVisible ? (
                <FiEye
                  color={colors.fadedGrey}
                  onClick={() =>
                    handleChangeVisibilityPassword('newPasswordVisible')
                  }
                />
              ) : (
                <FiEyeOff
                  color={colors.blue}
                  onClick={() =>
                    handleChangeVisibilityPassword('newPasswordVisible')
                  }
                />
              )}
            </InputContainer>

            <InputContainer>
              <input
                name="confirm-password"
                placeholder="Confirme a nova senha"
                type={confirmPasswordVisible ? 'text' : 'password'}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              {!confirmPasswordVisible ? (
                <FiEye
                  color={colors.fadedGrey}
                  onClick={() =>
                    handleChangeVisibilityPassword('confirmPasswordVisible')
                  }
                />
              ) : (
                <FiEyeOff
                  color={colors.blue}
                  onClick={() =>
                    handleChangeVisibilityPassword('confirmPasswordVisible')
                  }
                />
              )}
            </InputContainer>

            {loadingModal && <CircleLoading />}
          </Form>
        </Modal>
      </>
    );
  };

  const openNotification = (message) => {
    message.length > 0 &&
      notification.error({
        message: 'Controle de Acesso',
        description: message,
        style: {
          width: 600,
          marginLeft: 335 - 600,
        },
      });
  };

  return (
    <Container>
      <Header>
        <Link to="/" id="logo">
          <img src={logoImg} alt="Logo" />
        </Link>

        <StyledPopoverSubmenu
          content={userMenu()}
          placement="bottomRight"
          align={{ offset: [0, -9] }}
        >
          <div id="user-info">
            {loadingToChangeImage ? (
              <AiOutlineLoading />
            ) : (
              <img src={profileImage} alt="Profile" />
            )}

            <div id="name">
              <p>{userData?.user?.nome_pessoa}</p>

              <AiFillCaretDown className="caretdown-icon" />
            </div>
          </div>
        </StyledPopoverSubmenu>
      </Header>

      {loading ? (
        <PreLoader />
      ) : (
        <Menu>
          <ul>
            {menuData.length > 0 &&
              menuData.map((item, i) => (
                <StyledPopoverSubmenu
                  placement={(() => {
                    if (i < 2) return 'rightTop';
                    if (i >= 2 && i < 4) return 'right';
                    if (i >= 4) return 'rightBottom';
                  })()}
                  menuItems={item.submenu}
                  onItemClick={(message) => openNotification(message)}
                  key={i}
                  align={{ offset: [-2, 0] }}
                  disabled={item.submenu.length === 0}
                >
                  <li className={item.submenu.length === 0 ? 'disabled' : ''}>
                    <div id="menu-item-wrapper">
                      <div id="icon-wrapper">
                        <img src={item.icon} alt="Menu Icon" />
                      </div>

                      <div id="title-wrapper">
                        <p>{item.title}</p>
                      </div>
                    </div>
                  </li>
                </StyledPopoverSubmenu>
              ))}
          </ul>
        </Menu>
      )}

      <Favorites>
        <div>
          <AiFillStar className="star-icon" /> <label>FAVORITOS</label>
        </div>

        <ul>
          {favoritesItems.map((favorite, i) => (
            <li key={i}>
              <NavLink
                to={{
                  pathname: favorite.path,
                  state: { pageTitle: favorite.title },
                }}
              >
                {favorite.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </Favorites>

      <Footer>
        <ul id="links">
          <li>
            <a href="#!">FAQ</a>
          </li>
          <li id="attendance">
            <a href="#!">Atendimento</a>
          </li>
          <li>
            <a href="#!">Ajuda</a>
          </li>
        </ul>

        <a href="tel:01138640800" id="phone">
          <FaPhoneAlt /> (11) 3864-0800
        </a>

        <div id="copyright">
          <a
            href="https://www.iclouds.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            powered by iClouds
          </a>
        </div>
      </Footer>
    </Container>
  );
};

export default Sider;
