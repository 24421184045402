import React from 'react';
import PropTypes from 'prop-types';

import { StyledCustomScrollBar } from '../.';

import { colors } from '../../theme';

import { Container } from './styles';

const Modal = ({
  children,
  title,
  toggleVisibility,
  closable,
  isVisible,
  height,
  width,
  scroll,
  description,
  circleLoading,
  ...props
}) => {
  return (
    <Container
      title={title}
      visible={isVisible}
      onOk={toggleVisibility}
      onCancel={closable && toggleVisibility}
      closable={false}
      width={+width}
      height={+height}
      destroyOnClose
      circleLoading={circleLoading}
      {...props}
    >
      {description && <h2>{description}</h2>}
      {scroll ? (
        <StyledCustomScrollBar color={colors.eightGrey}>
          {children}
        </StyledCustomScrollBar>
      ) : (
        children
      )}
    </Container>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scroll: PropTypes.bool,
};

Modal.defaultProps = {
  scroll: true,
  closable: true,
};

export default Modal;
