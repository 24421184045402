export default {
  dark: '#283131',
  black: '#333333',
  lightBlack: '#46514c',

  blue: '#3b91b1',
  darkBlue: '#363d41',
  lightBlue: '#50e3c2',

  grey: '#9e9e9e',
  darkGrey: '#171414',
  mediumGrey: '#7a7b7f',
  eightGrey: '#666666',
  fadedGrey: '#b8b7b7',
  lightGrey: '#ececec',
  buttonGray: '#d8d8d8',
  observatoryGrey: '#f9f9f9',
  buttonDashboardWhite: '#fcfcfc',

  red: '#ff4d4f',

  inputGreenLight: '#4e693b',
  inputGreen: '#415532',
  darkDeepGreen: '#415532',
  lightDeepGreen: '#4da71d',
  otherGreen: '#4fad67',
  deepGreen: '#438b1d',
  darkGreen: '#719104',
  green: '#329a58',
  lightGreen: '#87c500',
  extraLightGreen: '#ced9cc',
  GrayishGreen: '#96a394',


  white: '#ffffff',
  darkWhite: '#efefef',
  greyWhite: '#cecdcd',

  lightYellow: '#e1f6e0',
  yellow: '#efe187',
  darkYellow: '#ffcb00',

  orange: '#dd8800',
  mediumOrange: '#f5a623',
  redOrange: '#f46c24',

  danger: '#d04f5f',
  warning: '#f2d000',
  success: 'hsl(141,71%,48%)',

  transparent: 'transparent',
};
