import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  border-radius: ${pxToRem(6)};

  grid-area: m1;

  background: rgba(255, 255, 255, 0.1);

  ${media.greaterThan('1600px')`
  border-radius: ${pxToRem(7)};
`}

  ${media.greaterThan('1900px')`
  border-radius: ${pxToRem(8)};
`}

${(props) =>
  props.doubleChart &&
  css`
    grid-area: m2;
  `}
`;

export const GraphAndCharacteristics = styled.div`
  /* 100% - TextHeader height */
  height: calc(100% - ${pxToRem(33)});
  padding: ${pxToRem(6)} ${pxToRem(16)};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${media.greaterThan('1600px')`
    height: calc(100% - ${pxToRem(38)});
    padding: ${pxToRem(6)} ${pxToRem(18)};
  `}

  ${media.greaterThan('1900px')`
    height: calc(100% - ${pxToRem(43)});
    padding: ${pxToRem(8)} ${pxToRem(22)};
  `}
`;

export const Graph = styled.div`
  height: 100%;
  width: 25%;

  ${(props) =>
    props.doubleChart &&
    css`
      width: 38%;
    `}
`;

export const Characteristics = styled.ul`
  width: 71%;

  li {
    min-width: 100%;
    border-bottom: ${pxToRem(1)} dashed rgba(0, 0, 0, 0.12);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      margin: ${pxToRem(8)} 0 ${pxToRem(4)};

      ${fonts.size2};
      color: ${colors.white};

      ${media.greaterThan('1600px')`
        margin: ${pxToRem(10)} 0 ${pxToRem(5)};
        ${fonts.size3};
    `}

      ${media.greaterThan('1900px')`
        margin: ${pxToRem(12)} 0 ${pxToRem(6)};
        ${fonts.size4};
    `}
    }
  }

  ${(props) =>
    props.doubleChart &&
    css`
      width: 55%;
    `}
`;

export const CharacteristicsText = styled.p`
  position: relative;
  margin: ${pxToRem(8)} 0 ${pxToRem(4)};

  ${fonts.size1};
  color: ${colors.white};
  text-transform: initial;

  ::before {
    position: absolute;
    top: ${pxToRem(2)};
    left: ${pxToRem(-16)};

    border-radius: 50%;
    width: ${pxToRem(10)};
    height: ${pxToRem(10)};

    content: '';
    background-color: ${({ color }) => color};
  }

  ${media.greaterThan('1600px')`
    margin: ${pxToRem(10)} 0 ${pxToRem(5)};
    ${fonts.size3};

    ::before {
      top: ${pxToRem(2)};
      left: ${pxToRem(-18)};

      width: ${pxToRem(12)};
      height: ${pxToRem(12)};
    }
  `}

  ${media.greaterThan('1900px')`
    margin: ${pxToRem(12)} 0 ${pxToRem(6)};
    ${fonts.size4};

    ::before {
      top: ${pxToRem(3)};
      left: ${pxToRem(-22)};

      width: ${pxToRem(14)};
      height: ${pxToRem(14)};
    }
  `}
`;
