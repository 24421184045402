import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Table, PreLoader, Breadcrumb, TableHeader } from '../../components';

import { sort } from '../../functions';
import { api } from '../../services/api';
import { useAuth } from '../../context/Auth';

import { Name, Registry, Container } from './styles';

function SingleReviews() {
  const { userData } = useAuth();

  const initialParams = {
    id_inspetor: userData?.user?.id_pessoa,
    max_results: 50,
  };

  const [loading, setLoading] = useState(true);
  const [preLoader, setPreLoader] = useState(true);
  const [activeSort, setActiveSort] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [listingTotal, setListingTotal] = useState(0);
  const [searchedRecords, setSearchedRecords] = useState([]);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [singleReviews, setSingleReviews] = useState([]);

  const [page, setPage] = useState(1);
  const [params, setParams] = useState(initialParams);
  const [allSingleReviews, setAllSingleReviews] = useState([]);

  const pageTitle = 'Resenhas avulsas';

  const switchDrawer = () => setDrawerVisibility(!drawerVisibility);

  const fetchRegistrationRequest = useCallback(async (params, page) => {
    setLoading(true);

    try {
      const { data } = await api.get('ResenhaAvulsa/ListaResenhasInspetor', {
        params: { ...params, pagina: page },
      });

      if (data.success) {
        setListingTotal(data.data.total);
        return data.data.resenhas;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setPreLoader(false);
    }
  }, []);

  useEffect(() => {
    setPreLoader(true);
    if (userData?.user?.id_pessoa) {
      const initialTableData = async () => {
        const fetchedData = await fetchRegistrationRequest(params, 1);

        setSingleReviews(fetchedData);
        setAllSingleReviews(fetchedData);
      };

      initialTableData();
    } else {
      setParams(initialParams);
    }
  }, [fetchRegistrationRequest, params]); //eslint-disable-line

  useEffect(() => {
    userData?.user?.id_pessoa &&
      setParams({ ...initialParams, id_inspetor: userData.user.id_pessoa });
  }, [userData]); //eslint-disable-line

  const handleSort = (objectKey, method) => {
    const [activeSort, sortedData] = sort(singleReviews, objectKey, method);

    setActiveSort(activeSort);
    setSingleReviews(sortedData);
  };

  const fetchMoreData = async () => {
    const fetchedData = await fetchRegistrationRequest(params, page + 1);

    setPage(page + 1);

    setSingleReviews([...singleReviews, ...fetchedData]);
    setAllSingleReviews([...allSingleReviews, ...fetchedData]);
  };

  useEffect(() => {
    const listenSearch = async () => {
      if (searchValue === '') {
        setSingleReviews(allSingleReviews);
      } else {
        setSingleReviews(searchedRecords);
      }
    };

    listenSearch();
  }, [searchValue, allSingleReviews, searchedRecords]);

  const handleSearch = (value) => {
    setSearchValue(value);

    const searchedItems = allSingleReviews.filter((record) => {
      const nome = record?.nome_animal?.toLowerCase();
      const solicitante = record?.nome_pessoa?.toLowerCase();
      const registro = record?.registro_abqm?.toLowerCase();
      const protocolo = record?.protocolo?.toLowerCase();
      const inclusao = record?.dt_inclusao?.toLowerCase();
      const status = record?.ds_status_atividade?.toLowerCase();
      const valueToSearch = value?.toLowerCase();

      return (
        nome.indexOf(valueToSearch) !== -1 ||
        solicitante.indexOf(valueToSearch) !== -1 ||
        registro.indexOf(valueToSearch) !== -1 ||
        protocolo.indexOf(valueToSearch) !== -1 ||
        inclusao.indexOf(valueToSearch) !== -1 ||
        status.indexOf(valueToSearch) !== -1
      );
    });

    setSearchedRecords(searchedItems);
  };

  const clearFilter = () => {
    setPage(1);
    setParams(initialParams);
    setSingleReviews([]);
  };

  const tableColumns = [
    { key: 'tipo', title: 'TIPO', sort: false, size: 3 },
    { key: 'protocolo', title: 'PROTOCOLO', sort: true, size: 1.3 },
    { key: 'nome', title: 'NOME DO ANIMAL', sort: true, size: 2 },
    { key: 'solicitante', title: 'SOLICITANTE', sort: true, size: 3 },
    { key: 'status', title: 'STATUS', sort: true, size: 1.3 },
    { key: 'inclusao', title: 'INCLUSÃO', sort: true, size: 1.3 },
  ];

  const tableData = singleReviews.map((registrationRequest) => ({
    tipo: <Link>{registrationRequest.resenha_desc}</Link>,

    protocolo: <Link>{registrationRequest.protocolo}</Link>,

    nome: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        <td>
          <Name>{registrationRequest?.nome_animal}</Name>
          <Registry>{registrationRequest?.registro_abqm}</Registry>
        </td>
      </Link>
    ),

    solicitante: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.nome_pessoa}
      </Link>
    ),

    status: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.ds_status_atividade}
      </Link>
    ),

    inclusao: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.dt_inclusao
          ? registrationRequest.dt_inclusao
          : '--'}
      </Link>
    ),
  }));

  return (
    <Container>
      <Breadcrumb
        pages={[
          {
            title: pageTitle,
            path: '/resenhas-avulsas',
          },
        ]}
      />

      <Helmet>
        <title>{pageTitle} - Central do Quartista</title>
      </Helmet>

      <TableHeader
        title={pageTitle}
        count={listingTotal}
        onSearch={handleSearch}
        clearFilter={clearFilter}
        dataLength={singleReviews}
        onFilterButtonClick={switchDrawer}
      />

      {preLoader ? (
        <PreLoader light />
      ) : (
        <Table
          light
          loading={loading}
          count={listingTotal}
          dataSource={tableData}
          columns={tableColumns}
          handleSort={handleSort}
          activeSort={activeSort}
          fetchMoreData={fetchMoreData}
          dataLength={allSingleReviews}
        />
      )}
    </Container>
  );
}

export default SingleReviews;
