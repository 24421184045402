import React from 'react';

import { Container, Circle, ExclamationIcon } from './styles';

const Exclamation = () => (
  <Container
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="89"
    viewBox="0 0 89 89"
  >
    <g id="Ícone" transform="translate(-1054 -208)">
      <g
        id="Elipse_74"
        fill="#fff"
        stroke="rgba(255,203,0,0.2)"
        stroke-width="4px"
        data-name="Elipse 74"
        transform="translate(1054 208)"
      >
        <Circle cx="44.5" cy="44.5" r="44.5" stroke="none" />
        <Circle cx="44.5" cy="44.5" r="42.5" fill="none" />
      </g>
      <g
        id="Grupo_663"
        data-name="Grupo 663"
        transform="translate(-.833 -2.934)"
      >
        <ExclamationIcon
          id="Caminho_252"
          d="M236.663 84.338a3.045 3.045 0 0 0-3 3.089v30.889a3 3 0 1 0 6 0V87.427a3.045 3.045 0 0 0-3-3.089z"
          class="cls-2"
          data-name="Caminho 252"
          transform="translate(863.17 147.596)"
        />
        <ExclamationIcon
          id="Caminho_253"
          d="M236.663 383a3 3 0 1 0 3 3 3 3 0 0 0-3-3z"
          class="cls-2"
          data-name="Caminho 253"
          transform="translate(863.17 -109.185)"
        />
      </g>
    </g>
  </Container>
);

export default Exclamation;
