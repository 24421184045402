import React, { useState, useCallback, useEffect, useMemo } from 'react';
import swal from 'sweetalert';
import { Input as AntInput } from 'antd';
import { FiFileText } from 'react-icons/fi';
import { toDate, format } from 'date-fns';
import { isMobile } from 'react-device-detect';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import {
  Table,
  Modal,
  Loader,
  Button,
  FormItem,
  PageTitle,
} from '../../../components';

import { MobileList } from '../components';

import { Form, Type, Text, Container, CircleLoading } from './styles';

export default function ProcessDocument({
  token,
  userData,
  preRegister,
  modalIsOpen,
  toggleModal,
}) {
  const { TextArea } = AntInput;
  const currentDate = format(toDate(Date.now()), 'dd/MM/yyyy');

  const [ip, setIp] = useState('');
  const [decision, setDecision] = useState('');
  const [loading, setLoading] = useState(true);
  const [inspector, setInspector] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [processDocuments, setProcessDocuments] = useState([]);

  const fetchProcessDocuments = useCallback(async () => {
    try {
      const { data } = await api.get('pedidoregistro/documentosprocesso', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_preregistro: preRegister,
        },
      });

      if (data.success) {
        setProcessDocuments(data.data);
      }
    } catch (error) {
      swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
    } finally {
      setLoading(false);
    }
  }, [preRegister, token]);

  const handleSubmit = useCallback(async () => {
    setModalLoading(true);

    try {
      const { data } = await api.post(
        'parecerInspetor/salvaparecerinspetor',
        {
          ip_usuario: ip,
          parecer: decision,
          num_preregistro: preRegister,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        swal('Sucesso', 'Parecer enviado!', 'success');
        toggleModal();
        setDecision('');
      }
    } catch (error) {
      swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
    } finally {
      setModalLoading(false);
    }
  }, [token, preRegister, ip, decision, toggleModal]);

  useEffect(() => {
    fetchProcessDocuments();
  }, [fetchProcessDocuments]);

  useEffect(() => {
    if (userData) {
      setInspector(userData.user?.nome_pessoa);
    }
  }, [setInspector, userData]);

  const tableColumns = useMemo(() => [
    { key: 'tipo', title: 'Tipo', size: 7 },
    { key: 'Protocolo', title: 'Protocolo', size: 1.3 },
    { key: 'entrada', title: 'Entrada', size: 1.3 },
    { key: 'pagamento', title: 'Pagamento', size: 1.3 },
    { key: 'status', title: 'Status', size: 1.3 },
    { key: 'valor', title: 'Valor', size: 1.3 },
    { key: 'link', title: '', size: 0.6 },
  ], []);

  const tableData = useMemo(() => processDocuments.map((document) => ({
    tipo: (
      <Type>
        <div className="type-wrapper">
          {document.tipo_lancamento && (
            <Text style={{ fontWeight: 'bold' }}>{document.tipo_lancamento}</Text>
          )}

          <Text>{`${document?.nome_garanhao} X ${document?.nome_egua}`}</Text>

          {document.dt_cobertura_inicio && (
            <Text>Inicio da Cobertura: {document.dt_cobertura_inicio}</Text>
          )}

          {document.dt_cobertura_fim && (
            <Text>Fim da Cobertura: {document.dt_cobertura_fim}</Text>
          )}

          <Text>
            Documento válido: {document.documento_valido ? 'Sim' : 'Não'}
          </Text>

          <Text>
            Solicitante: {document.solicitante ? document.solicitante : '--'}
          </Text>
        </div>
      </Type>
    ),

    protocol: document?.protocolo + '/' + document?.AnoProtocolo,

    entry: document.dt_entrada,

    payment: document?.dt_pagamento ? document?.dt_pagamento : '--/--/--',

    status: document.status,

    value: (
      <td>
        <Text>{`R$ ${document.valor}`}</Text>
      </td>
    ),

    link: (
      <td>
        {document.link && (
          <a href={document.link} target="_blank" rel="noopener noreferrer">
            <FiFileText />
          </a>
        )}
      </td>
    ),
  })), [processDocuments]);

  function getIp(callback) {
    function response(s) {
      callback(window.userip);

      s.onload = s.onerror = null;
      document.body.removeChild(s);
    }

    function trigger() {
      window.userip = false;

      var s = document.createElement('script');
      s.async = true;
      s.onload = function () {
        response(s);
      };
      s.onerror = function () {
        response(s);
      };

      s.src = 'https://l2.io/ip.js?var=userip';
      document.body.appendChild(s);
    }

    if (/^(interactive|complete)$/i.test(document.readyState)) {
      trigger();
    } else {
      document.addEventListener('DOMContentLoaded', trigger);
    }
  }

  getIp(function (ip) {
    setIp(ip);
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {!isMobile ? (
            <>
              <PageTitle>Documentos do processo</PageTitle>

              <Table light dataSource={tableData} columns={tableColumns} />
            </>
          ) : (
            <MobileList documents={processDocuments} />
          )}

          <Modal
            centered
            closable
            height={341}
            scroll={false}
            isVisible={modalIsOpen}
            toggleVisibility={toggleModal}
            title="Novo parecer do inspetor"
            width={window.innerWidth >= 1600 ? 585 : 500}
            footer={
              <>
                <Button
                  fontLight
                  color={colors.red}
                  onClick={toggleModal}
                  disabled={modalLoading}
                >
                  Cancelar
                </Button>

                <Button
                  fontLight
                  disabled={modalLoading}
                  color={colors.lightGreen}
                  onClick={() => handleSubmit()}
                >
                  Salvar
                </Button>
              </>
            }
          >
            <Form>
              {modalLoading ? (
                <CircleLoading />
              ) : (
                <>
                  <div className="header-modal">
                    {inspector && (
                      <FormItem name="inspector" label="Inspetor:" horizontal>
                        {inspector}
                      </FormItem>
                    )}

                    <FormItem name="date" label="Data:" horizontal>
                      {currentDate}
                    </FormItem>

                    <FormItem
                      name="pre-register"
                      label="Pré-Rregistro:"
                      horizontal
                    >
                      {preRegister}
                    </FormItem>
                  </div>

                  <FormItem
                    name="decision"
                    className="form-item1"
                    label="Deixe aqui o seu parecer técnico"
                  >
                    <TextArea
                      rows={4}
                      width={457}
                      name="decision"
                      value={decision}
                      className="text-area"
                      onChange={(e) => setDecision(e.target.value)}
                    />
                  </FormItem>
                </>
              )}
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
