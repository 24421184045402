import styled from 'styled-components';
import { Form as AntForm, Drawer as AntDrawer } from 'antd';

import { Button } from '../../components';

import { fonts } from '../../theme';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';

export const Container = styled.section`
  width: 100%;
  height: 100%;

  padding: ${pxToRem(20)} ${pxToRem(40)};

  background-color: rgba(255, 255, 255, 0.86);

  a{
    :first-child{
      padding-left: 10px;
    }
  }

th{
  :first-child{
    p{
      margin-right: 35px;
    }
  }
}
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-content-wrapper {
    width: 25% !important;
  }

  .ant-drawer-body {
    padding: ${pxToRem(22)} ${pxToRem(26)};
    overflow: hidden;
  }

  ${media.greaterThan('1900px')`
    .ant-drawer-title {
      ${fonts.size6};
    }
  `}
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .form-item2 {
    margin-top: 10.92%;
  }

  .form-item3 {
    margin-top: 13.65%;
  }

  .form-item4 {
    margin-top: 12.96%;
  }

  .form-item5 {
    margin-top: 5.46%;
  }

  .form-item6 {
    margin-top: 15.01%;
  }

  ${media.greaterThan('1900px')`  
    *,
    label {
      ${fonts.size5}
    }

    label {
      margin-bottom: ${pxToRem(6)};
    }

    .horizontal {
      label {
        margin-bottom: 0;
      }
    }

    .ant-switch {
      width: ${pxToRem(60)};
      height: ${pxToRem(30)};

      :after {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
      }
    }
  `}
`;

export const DrawerButton = styled(Button)`
  align-self: center;
  width: ${pxToRem(131)};
  margin-top: 10.57%;
`;
