import styled, { keyframes, css } from 'styled-components';
import { Checkbox } from 'antd';
import { zoomIn } from 'react-animations';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

const bounceZoomInAnimation = keyframes`${zoomIn}`;

export const Container = styled(Checkbox)`
  border-radius: ${pxToRem(4)};

  .ant-checkbox-inner,
  .ant-form-item input[type='checkbox'] {
    ${({ checkSize }) =>
      checkSize === 'large'
        ? css`
            width: ${pxToRem(24)};
            height: ${pxToRem(24)};

            ::after {
              transform: rotate(45deg) scale(1.4) translate(-25%, -50%);
            }
          `
        : null}

    background-color: transparent;
    border-color: ${({ light, checkColor }) => {
      if (checkColor) return checkColor;
      else return light ? colors.buttonGray : colors.eightGrey;
    }};

    :hover {
      border-color: ${colors.blue};
    }
  }

  :disabled {
    cursor: not-allowed;
  }

  .ant-checkbox-checked {
    border-radius: ${pxToRem(4)};
    background-color: ${colors.blue};

    .ant-checkbox-inner {
      border-color: ${colors.blue};
      animation: 0.3s ${bounceZoomInAnimation};
    }
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: rgba(0, 0, 0, 0.25) !important;
  }

  span {
    color: ${({ light }) =>
      light ? 'rgba(255, 255, 255, 0.85)' : 'rgba(0, 0, 0, 0.65)'};
  }
`;
