import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as FatherBackground } from '../../../theme/assets/images/genealogy-father.svg';
import { ReactComponent as MotherBackground } from '../../../theme/assets/images/genealogy-mother.svg';
import { ReactComponent as GrandfatherBackground } from '../../../theme/assets/images/genealogy-grandfather.svg';
import { ReactComponent as GrandmotherBackground } from '../../../theme/assets/images/genealogy-grandmother.svg';

import {
  Container,
  OwnerInfo,
  Genealogy,
  GenealogyLineConector,
} from './styles';

function Information({ data }) {
  return (
    <Container>
      <OwnerInfo>
        <div className="name-wrapper left">
          <p>Proprietário</p>
          <strong>{data?.nome_proprietario}</strong>
        </div>

        <div className="name-wrapper right">
          <p>Criador</p>
          <strong>{data?.nome_criador}</strong>
        </div>
      </OwnerInfo>

      <Genealogy>
        <div id="left">
          <div className="parent-wrapper">
            <div id="father" title={data?.nome_pai}>
              <FatherBackground />
              <strong>{data?.nome_pai}</strong>
              <p>{data?.registro_pai}</p>
            </div>

            <GenealogyLineConector />
          </div>

          <div className="parent-wrapper">
            <div id="mother" title={data?.nome_mae}>
              <MotherBackground />
              <strong>{data?.nome_mae}</strong>
              <p>{data?.registro_mae}</p>
            </div>

            <GenealogyLineConector />
          </div>
        </div>

        <div id="right">
          <div id="paternal">
            <div className="grandfather" title={data?.nome_avo_paterno}>
              <GrandfatherBackground />
              <p className="name">{data?.nome_avo_paterno}</p>
              <p className="register">{data?.registro_avo_paterno}</p>
            </div>

            <div className="grandmother" title={data?.nome_avo_paterna}>
              <GrandmotherBackground />
              <p className="name">{data?.nome_avo_paterna}</p>
              <p className="register">{data?.registro_avo_paterna}</p>
            </div>
          </div>

          <div id="maternal">
            <div className="grandfather" title={data?.nome_avo_materno}>
              <GrandfatherBackground />
              <p className="name">{data?.nome_avo_materno}</p>
              <p className="register">{data?.registro_avo_materno}</p>
            </div>

            <div className="grandmother" title={data?.nome_avo_materna}>
              <GrandmotherBackground />
              <p className="name">{data?.nome_avo_materna}</p>
              <p className="register">{data?.registro_avo_materna}</p>
            </div>
          </div>
        </div>
      </Genealogy>
    </Container>
  );
}

export default Information;
