import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

import {
  Table,
  TableHeader,
} from '../../../components';
import { sort } from '../../../functions';

import {
  ButtonTab,
  RegistryCentered
} from './styles';

function WrongInspections({
  inspectorId,
  params,
  filtered,
  totalInspectionsWrong,
  wrongInspections,
  allWrongInspections,
  pageWrong,
  loading,
  setSelectedTab,
  setSearchValue,
  setTotalInspectionsWrong,
  setWrongInspections,
  setAllWrongInspections,
  setPageWrong,
  setLoading,
  handleSearch,
  clearFilter,
  switchDrawer,
  fetchMoreDataWrong,
  handleSwitchScreen
}) {

  const [activeSortWrong, setActiveSortWrong] = useState('');
  
  const handleSort = (objectKey, method) => {

    const [activeSort, sortedData] = sort(wrongInspections, objectKey, method);

    setActiveSortWrong(activeSort);
    setWrongInspections(sortedData);
  };

  const WrongTableColumns = [
    { key: 'pedido', title: 'PEDIDO', sort: false, size: .77 },
    { key: 'criador', title: 'CRIADOR', sort: false, size: 2 },
    { key: 'erro', title: 'ERRO', sort: true, size: 2 },
    { key: 'pontos', title: 'PONTOS', sort: true, size: .5 },
    { key: 'dt_inclusao', title: 'INCLUSÃO', sort: true, size: 1 },
    { key: 'dt_inspecao', title: 'INSPEÇÃO', sort: true, size: 1 },
    { key: 'produto', title: 'PRODUTO', sort: true, size: 1 },
    { key: 'status', title: 'STATUS', sort: true, size: 1 },
  ];

  const WrongTableData = wrongInspections.map((registrationRequest) => ({
    pedido: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.num_preregistro
          ? registrationRequest.num_preregistro.substr(0, 10)
          : '--'}
      </Link>
    ),
    criador: (
      (
        <Link
          to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
        >
          {registrationRequest.criador}
        </Link>
      )
    ),
    erro: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.dsc_erros_inspecao}
      </Link>
    ),
    pontos: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
        className="center-table-text"
      >
          <td>
            {/* <Name>{registrationRequest?.pontos}</Name> */}
            <RegistryCentered>{registrationRequest?.pontos}</RegistryCentered>
          </td>
      </Link>
    ),
    inclusao: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.dt_inclusao.substr(0, 10)}
      </Link>
    ),
    inspecao: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest?.dt_inspecao.substr(0, 10)}
      </Link>
    ),
    produto: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.nome_produto}
      </Link>
    ),
    status: (
      <Link
        to={`/detalhes-de-pedido-de-registro/${registrationRequest.num_preregistro}`}
      >
        {registrationRequest.perdoado}
      </Link>
    ),
  }));

  const SwitchTabButtons = useCallback(() => (
    <ButtonTab 
      color="rgba(178, 178, 178, 0.47)" 
      onClick={() => handleSwitchScreen()}
    >
      <>
        Realizadas
        &nbsp;
        <FiChevronLeft size={18}/>
      </>
    </ButtonTab>
  ), [handleSwitchScreen]);

  return (
      <>
        <TableHeader
          title={'Inspeções com erro'}
          filtered={filtered}
          count={totalInspectionsWrong}
          onSearch={handleSearch}
          clearFilter={clearFilter}
          dataLength={wrongInspections}
          onFilterButtonClick={switchDrawer}
          extraButtons={SwitchTabButtons()}
        />

        <Table
          light
          loading={loading}
          count={totalInspectionsWrong}
          dataSource={WrongTableData}
          columns={WrongTableColumns}
          handleSort={handleSort}
          activeSort={activeSortWrong}
          fetchMoreData={fetchMoreDataWrong}
          dataLength={allWrongInspections}
        />
      </>
  );
}

export default WrongInspections;
