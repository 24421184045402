import React from 'react';
import PropTypes from 'prop-types';
import { FiMoreVertical } from 'react-icons/fi';

import StyledPopoverSubmenu from '../StyledPopoverSubmenu';
import { Container } from './styles';

const TextHeader = ({ title, hasMenu, menuItems }) => {
  return (
    <Container>
      <p>{title}</p>

      {hasMenu && (
        <StyledPopoverSubmenu
          menuItems={menuItems}
          placement="leftTop"
          align={{ offset: [0, -5] }}
        >
          <FiMoreVertical className="more-icon" />
        </StyledPopoverSubmenu>
      )}
    </Container>
  );
};

TextHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hasMenu: PropTypes.bool,
  menuItems: PropTypes.array,
};

export default TextHeader;
