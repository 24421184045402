import React, { useState, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { colors } from '../../../theme';
import { api } from '../../../services/api';
import { Loader, PageTitle, StyledCustomScrollBar } from '../../../components';

import { Container } from './styles';

export default function ActivityRecord({ preRegister, token }) {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRecords = useCallback(async () => {
    try {
      const { data } = await api.get('pedidoregistro/registroatividade', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          num_preregistro: preRegister,
        },
      });

      if (data.success) {
        setRecords(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [preRegister, token]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {!isMobile && <PageTitle>Registro de atividades</PageTitle>}

          <div className="table-info">
            <StyledCustomScrollBar color={colors.black}>
            {records.length === 0 && (
                <p className="bold text-not-found">
                  Processo não possui registro de atividades !
                </p>)}
              {records.map((record, index) => (
                <table key={index}>
                  <tr>
                    <td>Data:</td>
                    <td>{record?.data}</td>
                  </tr>

                  <tr>
                    <td>Usuário:</td>
                    <td>{record?.nome_usuario}</td>
                  </tr>

                  <tr>
                    <td>Detalhe:</td>
                    <td>{record?.descricao}</td>
                  </tr>
                </table>
              ))}
            </StyledCustomScrollBar>
          </div>
        </Container>
      )}
    </>
  );
}
