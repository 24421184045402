import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

import { apiPortal } from '../../services/api';

import { formatDate } from '../../functions';
import { TextHeader, StyledCustomScrollBar, Button } from '../';
import { colors } from '../../theme';

import { Container, News, CustomModal, ModalContent } from './styles';

const ListItem = ({ data }) => {
  const { dataInfo, title, url } = data;
  const [dataModal, setDataModal] = useState([]);
  const content = ReactHtmlParser(dataModal.text);
  const [isVisible, setIsVisible] = useState(false);

  const switchModal = (e) => {
    e.preventDefault();

    setIsVisible(!isVisible);
  };

  useEffect(() => {
    async function fetchNews() {
      const { data } = await apiPortal.get(`/contents/${url}`, {
        params: {
          title: title,
        },
      });

      setDataModal(data);
    }
    fetchNews();
  }, [title, url]);

  return (
    <>
      <li>
        <strong>{dataInfo.day}</strong>

        <small>{dataInfo.month}</small>

        <p onClick={switchModal}>{title}</p>
      </li>

      <CustomModal
        centered
        isVisible={isVisible}
        toggleVisibility={switchModal}
        footer={
          <Button onClick={switchModal} color={colors.lightGreen} fontLight>
            OK
          </Button>
        }
      >
        <ModalContent>
          <h1>{dataModal.title}</h1>
          <p>{dataModal.resume}</p>

          {content}
        </ModalContent>
      </CustomModal>
    </>
  );
};

const Newsletter = ({ data }) => {
  const formattedDate = data.map((dataItem) => {
    const { day, month } = formatDate(dataItem.date, 'newsletter');

    const dataInfo = { day, month };

    return { ...dataItem, dataInfo };
  });

  return (
    <Container>
      <TextHeader title="Últimas notícias" />

      <News>
        <StyledCustomScrollBar>
          {formattedDate.map((dataItem) => (
            <ListItem key={dataItem.id} data={dataItem} />
          ))}
        </StyledCustomScrollBar>
      </News>
    </Container>
  );
};

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
};

Newsletter.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Newsletter;
