import styled, { keyframes } from 'styled-components';
import { Form as AntForm } from 'antd';
import media from 'styled-media-query';
import { AiOutlineLoading } from 'react-icons/ai';

import { Modal } from '../../../../components';
import { pxToRem } from '../../../../functions';
import { colors, fonts } from '../../../../theme';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  height: ${({ specialStyle }) => (specialStyle ? '100%' : 'auto')};
  padding: ${({ specialStyle }) =>
    specialStyle ? '0 0 0 39px' : '20px 20px 0'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ specialStyle }) =>
    specialStyle ? 'flex-start' : 'center'};

  > * {
    color: ${colors.eightGrey};
  }

  label {
    margin-top: ${pxToRem(20)};
  }

  h1 {
    margin-top: ${pxToRem(7)};

    ${fonts.size6}
    line-height: 1.21;
    text-align: center;
    font-family: 'SFRounded-Regular', sans-serif;
  }
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  #reason {
    margin-top: ${pxToRem(10)};
  }

  .form-item1 {
    margin-top: ${pxToRem(30)};

    ${media.greaterThan('1600px')`
      label {
        margin-bottom: 0;
      }
    `}

    ${media.lessThan('1024px')`
      margin-top: ${pxToRem(10)};

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    `}
  }
`;

export const CircleLoading = styled(AiOutlineLoading)`
  margin-top: 52px;

  align-self: center;

  font-size: 20px;
  color: ${colors.lightGreen};
  animation: ${rotation} 1s infinite;
`;
