import styled, { css } from 'styled-components';

import { colors } from '../../theme';

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  div[aria-label='animation'] {
    transform: scaleX(-1) translate(3px, -24px);

    path {
      fill: ${({ light }) =>
        light ? colors.extraLightGreen : colors.lightBlack};
    }
  }

  p {
    position: absolute;

    margin: 0;

    ${({ size }) => {
      if (size === 'small')
        return css`
          top: 60px;
          font-size: 9px;
        `;
      if (size === 'middle')
        return css`
          top: 140px;
          font-size: 18px;
        `;
      if (size === 'large')
        return css`
          top: 220px;
          font-size: 27px;
        `;
    }};

    line-height: 1.22;

    color: ${({ light }) =>
      light ? colors.extraLightGreen : colors.lightBlack};
    font-family: 'SFProText-Regular', sans-serif;
  }

  ${({ sizeScreen }) => {
    if (sizeScreen === 'huge')
      return css`
        p {
          top: 60%;
        }
      `;

    if (sizeScreen === 'large')
      return css`
        p {
          top: 55%;
        }
      `;

    if (sizeScreen === 'middle')
      return css`
        width: 100%;
        height: 100%;

        p {
          top: 55%;
        }
      `;
  }}
`;
