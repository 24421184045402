import { createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';
import 'antd/dist/antd.css';

import backgroundImg from '../theme/assets/images/background.jpg';
import blurredBackgroundImg from '../theme/assets/images/blurred-background.jpg';

import { colors } from '.';
import { isBackdropFilterSupported, pxToRem } from '../functions';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  body, #root {
    width: 100%;
    min-height: 100vh;
    
    ${media.lessThan('medium')`
      overflow-x: hidden;
    `}

    ${media.lessThan('1024px')`
      background-image: none;
      background-color: ${colors.white};
    `}
  }

  body {
    -webkit-font-smoothing: antialiased;


    background-size: cover;
    background-color: #0a633c;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(
       ${isBackdropFilterSupported() ? backgroundImg : blurredBackgroundImg}
     );
    
    line-height: normal;
    font-family: 'SFProText-Regular', sans-serif;    
  }

  a {
    color: ${colors.darkGrey};
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  p, h1, span, ul{
    margin-bottom: 0;
  }

  /* Sweet Alert */
  .swal-modal {
    max-width: ${pxToRem(478)};
    max-height: ${pxToRem(300)};
  }

  .swal-footer {
    margin-top: ${pxToRem(-10)};
  }

  .swal-title {
    margin-top: ${pxToRem(-16)};
    font-family: 'SFRounded-Bold', sans-serif;
  }

  .swal-text {
    text-align: center;
    font-family: 'SFRounded-Regular', sans-serif;
  }

  .swal-button {    
    width: ${pxToRem(81)};
    height: ${pxToRem(40)};
    border-radius: ${pxToRem(20)};    
    background-color: ${colors.lightGreen};
    transition: all 0.3s ease;

    :not(:disabled) {
      :hover {
        background-color: ${colors.lightGreen};
        filter: brightness(1.05);
      }
    }
  }

  .swal-icon--error  {
    border-color: ${colors.red};  

    .swal-icon--error__line {
      background-color: ${colors.red};
    }
  }  
`;
