import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const FormValidator = ({
  name,
  message,
  required,
  initialValue,
  children,
  rules,
  ...props
}) => (
  <Container
    name={name}
    htmlFor={name}
    rules={rules || [{ required, message }]}
    initialValue={initialValue}
    {...props}
  >
    {children}
  </Container>
);

FormValidator.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  rules: PropTypes.array,
  children: PropTypes.node.isRequired,
};

FormValidator.defaultProps = {
  required: true,
  message: '',
};

export default FormValidator;
