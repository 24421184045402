import styled, { css, createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';
import {ButtonApp} from '../../components/Global/styles';

import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';


export const MetricasGraphic = styled.div`
    width: 100%;
    text-align: center;
    max-width: 70px;
    margin: auto;
    
    img {
      width: 80%;
     }
`;

export const Overview = styled.div`

  color: ${colors.white};  
`;

export const Metricas = styled.div`
  
  h3 {
    color: ${props => colors[props.colors] };

    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    font-family: 'SFRounded-Regular';
  }
  span {
    font-size: 1.1rem;
    color: #fff;
  }
 
`

export const MetricasBlock = styled(Metricas)`
 padding-left: 1.3rem;
 
 ${media.lessThan('1599px')`
      text-align: center;
      margin-top: 1rem;
       padding-left: 0;
  `}
 
`

export const TotalOfDebt = styled.div` ;
padding-left: 1.5rem;
 h3 {
    font-size: 1.4rem;
    color: rgb(206 205 205 / 72%);
    font-weight: bold;
    margin-bottom: 0;
    font-family: 'SFRounded-Regular';
     ${media.lessThan('1700px')`
      font-size: 1.2rem;      
     `}
     
     ${media.lessThan('1600px')`
      font-size: 1rem;
     `}
  }
  > span {
    font-size: 1.7rem;
    color: #fff;
    
     ${media.lessThan('1700px')`
      font-size: 1.4rem;
      `}
      ${media.lessThan('1600px')`
      font-size: 1.3rem;
      `}
  }
  
  ${media.lessThan('1599px')`
      text-align: center;
      margin-top: 10px
  `}

`


export const GraphAndCharacteristics = styled.div`
  height: 100px;
`;

export const Graph = styled.div`
  height: 100%;
  width: 100%;

  ${(props) =>
  props.doubleChart &&
  css`
      width: 38%;
    `}
`;

export const Characteristics = styled.ul`
padding-left: 0.5rem;
  
  p {
    font-size: 0.7rem !important;
  }

  li {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      margin: ${pxToRem(5)} 0 ${pxToRem(4)};

      font-size: 0.7rem !important;
      color: ${colors.white};
    }
  }

  ${(props) =>
  props.doubleChart &&
  css`
      width: 55%;
    `}
`;

export const CharacteristicsText = styled.p`
  position: relative;
  margin: 0 0 4px 1rem;

  color: ${colors.greyWhite};
  text-transform: initial;

  ::before {
    position: absolute;    
    left: ${pxToRem(-16)};
    top: 3px;

    border-radius: 50%;
    width: ${pxToRem(10)};
    height: ${pxToRem(10)};

    content: '';
    background-color: ${({ color }) => color};
  } 
`;

export const TotalRecords = styled.small`
 float: right;
 color: ${colors.greyWhite}
`

export const TableBody = styled.div`
  p, td {
  color: rgb(206 205 205 / 76%) !important;
  }
  
  tr {
    border-bottom: 1px solid rgb(255 255 255 / 12%);
  }
  
  .ant-table {
    background: transparent;
  }
  
  table {
    /*margin-left: -60px;
    position: relative;*/

  }
  
  td.ant-table-cell.ant-table-selection-column, th.ant-table-cell.ant-table-selection-column {
    /*position: absolute;
    right: 0;
    border-bottom: 0 !important;
    z-index: 2;*/
  }
  
  td.ant-table-cell.ant-table-selection-column {
    padding: 6px 8px 9px !important;  
  }  
 
  .ant-table-thead > tr > th {
    background: transparent;
    border-bottom: 0;
    opacity: 0.5;
    font-size: 0.7rem !important;
    color: ${colors.greyWhite} !important;
  }
  
  .ant-table-tbody > tr > td {
    font-family: 'SFRounded-Regular';
    border-bottom: 1px solid #f0f0f012 !important;
        padding: 16px 8px;
   }
   
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
      text-transform: uppercase;
   }
   .ant-checkbox-wrapper {
    font-size: 12px;
    span {
      padding: 0;
    }
   }
   .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
  }   
  tr.ant-table-row:hover {
   -webkit-transition: background 0.3s;
    transition: background 0.3s;
  } 
  tr.ant-table-row:hover {
  
  }
  td.ant-table-cell.ant-table-selection-column, th.ant-table-cell.ant-table-selection-column {

  },
  .ant-table-tbody > tr.ant-table-row-selected > td {
   background: transparent;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #49971c;
    border-color: #49971c !important;
    
    &:after {
      transform: rotate(45deg) scale(1.3) translate(-40%, -56%) !important;
    }  
  } 

  
  .ant-checkbox-inner {   
    background-color: transparent;
    border: 2px solid #5c7b51 !important;
    top: 4px;
    width: 20px;
    height: 20px;
  }
  
  .ant-checkbox-input, .ant-checkbox-checked::after {  
      top: 4px;
  }
  
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #49971c;
  }
  
  .ant-table-thead, .ant-table-footer {
  
    .ant-table-selection {  
       top: -5px;
    } 
  }
  
  td:nth-child(3) {
    width: 60%;
  }
  
  .ant-pagination-item-active {
    background: rgb(255 255 255 / 4%);
    border-color: #3e5d33;
  }
  
  .ant-pagination-item-active a {
    color: rgb(255 255 255 / 42%);
  }
  
  .ant-pagination-item a {
    color: rgb(247 247 247 / 50%);
  }
  
  .ant-pagination-prev button, .ant-pagination-next button {
    color: rgb(247 247 247 / 33%);
  }
  
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${colors.lightGreen};
    border-color: ${colors.lightGreen};
  }
  
  .ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: ${colors.lightGreen} !important;
  }
  
  td.ant-table-column-sort, .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: transparent;
  }  
  
  .ant-select-selector {  
    background: transparent !important;
    border: 1px solid rgb(247 247 247 / 33%) !important;
    color: rgb(247 247 247 / 50%);
  }
  
  .ant-pagination-item-ellipsis, span.anticon.anticon-double-right.ant-pagination-item-link-icon, span.ant-select-arrow {
    color: rgb(247 247 247 / 50%) !important;
  }
  
  i.ant-spin-dot-item {
    background-color: ${colors.lightDeepGreen}
  }
  
  .ant-spin-container::after {
    background: #32511f;
    
  }
  
  .ant-spin-blur::after {
    opacity: 0.6;
  }
  
  .ant-spin.ant-spin-spinning {
    max-height: 100%;
  }
    
`

export const SelectedTotal = styled.div`

  color: ${colors.greyWhite} !important;
  border-top: 0.5px solid rgba(0,0,0,0.1);
  box-shadow: 0 1px 0 0 rgba(255,255,255,0.1) inset;
  color: #ffffff;
  padding: 1rem 0.7rem 0;
  font-size: 1.4rem;
  span:first-child {  
  
  }  
  span:last-child {
     
  }
`

export const PaymentButton = styled(ButtonApp)`
    font-family: 'SFRounded-Regular';
    button {    
      font-size: 1rem !important;
      font-weight: bold;
      height: 43px !important;
      padding: 6.4px 35px !important;
      letter-spacing: 1px !important;    
    }
    
    button[disabled], button[disabled]:hover, button[disabled]:focus, button[disabled]:active     {
      color: rgb(255 255 255 / 31%);
      background: #438b1d70;
      border-color: rgb(67 139 29 / 44%);
    }    
    .anticon {
      float: right;
      margin-left: 10px;
      margin-top: 4px;
      font-size: 1rem;
    }
    
    ${media.lessThan('1240px')`
       margin-bottom: 150px;
    `}
`

export const Tabs = styled.div`
 margin-bottom: 1rem;
 margin-top: -10px;
 margin-left: 38%;
 
 button {
  color: ${colors.lightYellow};
  opacity: 0.5;
  font-size: 1.3rem;
  height: 40px;
  padding: 0;
      
    &.active {
      opacity: 1;
      border-bottom: 2px solid ${colors.otherGreen}
    }
    
    &:hover, &:focus {
       color: ${colors.lightYellow};
         opacity: 1;
    }
 }
 
 .ant-badge {
    margin-right: 2.5rem;
 }
 
 .ant-badge-count {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgb(225 246 224 / 68%);
    color: #1f4006;
    min-width: 15px;
    height: 19px;
    padding: 0 6px;
    font-size: 13px;
    line-height: 18px;
    top: 20px;
    right: -20px;
}
  ${media.lessThan('990px')`
     margin-top: 2rem;
     margin-bottom: 1rem;
  `}
`

export const Filters = styled.div`

 float: right; 
 
  
  ${media.lessThan('1170px')`
     margin-bottom: 2rem;
  `}
 
 #searchFormPayments {
    display: inline-block;
 }
 
 label {
    color: ${colors.lightYellow};
    opacity: 0.5;
    font-size: 15px;
 }
 
 #searchFormPayments_search {
    width: 200px;
    background: ${colors.inputGreen};
    border: 0;
    border-radius: 30px !important;
    font-size: 17px;
    color: ${colors.greyWhite};
 }

 #filtersType {
    margin-left: 10px;
    float: left !important;
 }
`

  export const GlobalStyle = createGlobalStyle`
  
    body.open-payment main, body.open-payment header, body.open-payment  aside {
      filter: blur(8px);
    }
    
    .col-charts {
      margin-top: 25px;
      
      ${media.lessThan('992px')`
        margin-top: 0;
    `}
      
    }
    
    .payment-drawer {
    
      .ant-drawer-mask {
        background-color: rgb(15 39 12 / 72%);
      }
      
      .ant-drawer-content {
        background-color: #415532cf;
        color: rgb(255 255 255 / 71%);
      }
        
      .ant-drawer-header {
        color: rgb(255 255 255 / 71%);
        background: rgb(65 85 50 / 81%);
        border-bottom: 0;
      }
        
      .ant-drawer-title, .ant-drawer-close {
        color: rgb(255 255 255 / 71%);
      }
    }  
    
    #PaymentForm {
       zoom: 1.1;
       
       form, .rccs {
          transition: all 0.5s ease;
        } 
        
        .rccs__card {
          height: 160px;
        }
       
       &.process {
       form, .rccs {
          opacity: 0.4
        }       
       }
    }
    
    .form-card {
    
      margin: 1rem 0;
      
      .ant-row {
      
        ${media.greaterThan('992px')`
          width: 100%;
          margin: auto !important;
        `}          
      }
      
      .ant-form-item-control-input-content {
          margin-bottom: 10px;
      }
      
      .ant-form-item {
        margin-bottom: 0 !important
      }
      
      
      .ant-input {
        padding: 10px 20px;
        background: ${colors.inputGreen};
        border: 0;
        border-radius: 30px !important;
        font-size: 16px;
        color: ${colors.greyWhite};
      }

      .ant-form-item-label {
        color: ${colors.greyWhite};
        font-size: 12px;
        font-style: italic;
        opacity: 0.6;
        margin-bottom: 0;
        margin-left: 20px;
        font-family: 'SFRounded-Regular';
      }
      
      button {     
        
         ${media.greaterThan('992px')`
            width: 66%;
            margin: auto !important;
          `} 
        
        &.ant-btn-primary {
          margin-top: 7px;
          border-radius: 40px;
          text-align: center;
          line-height: 50px;
          height: 51px;
          
          &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
            color: rgb(255 255 255 / 31%);
            background: #438b1d70;
            border-color: rgb(67 139 29 / 44%);
           } 
           
           span {
             padding: 0 !important;
            }   
            
          .ant-btn-loading-icon {
              margin-right: 15px;
          }
          
        }
        
        &.ant-btn-back {
          height: 38px;
          border-radius: 40px;
          line-height: 0;
          overflow: hidden;
               
                  
          .img-icon {
            padding: 5px 20px;
           margin-right: 0 !important;
          }
        } 
      }
    }
  
    .table-payments .ant-table-tbody > tr.ant-table-placeholder:hover > td {
     background: transparent !important;
    }

    .table-payments .ant-table-footer {
      padding-left: 0px; !important;
      background: transparent !important;
     }

    .table-payments .ant-table-footer > label {
      margin-left: 0px; !important;
    }

    .table-payments .ant-table-footer > label > span > strong {
      color: white; !important;
      padding-left: 10px; !important;
      line-height: 0;
    }
  
    .btn-payment {
    
      opacity: 1;
    
      &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
        color: #fff !important;
        opacity: 0.5;
         background: ${colors.deepGreen};
      }
    
      .ant-btn-loading-icon {
        padding: 0 !important;
      }
      
      &.download {
        background: ${colors.blue} !important;
      }  
    }
  
    .card-selection {
      height: 30px !important;
      width: 70% !important;
      text-align: center !important;
      margin-bottom: 20px !important;
      background: #526a41;
      color: #fff;
      
      &:hover {
        color: #fff
      }
      
      span {
        padding: 4px !important;
      }
      
      &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
        background: #526a41 !important;
        color: #fff !important;
        opacity: 0.5 !important;
      }
    }
  
    button {
     &.process-payment {
      height: 45px !important;
      line-height: 44px !important;
      margin-top: -10px !important;
      }  
    }
    
    .btn-select-cards-wrap {
     margin-bottom: 10px;
    }
  
    .list-cards {  
      border: 1px solid ${colors.inputGreen};   
      padding: 10px;
      float: left;
      width: 80%;
      color:${colors.inputGreen};
      background: white;
      border-radius: 5px;
      transition: all 0.3s ease;
      
      &:hover, &:focus, &:active, &.active {
      background: ${colors.inputGreen};
      color: #fff !important;
      }
    }
    
    .delete-card {
      background: transparent;
      padding: 8px;
      display: inline-block;
      margin: 0 0 0 7px;
      border-radius: 5px;
      color: red;
      border: 1px solid red;
    }  
    
    .ant-drawer-body {
      overflow-x: hidden;
    }
    
    .tabelaData {
      margin-top: -27px;
      
      .ant-badge {
        .ant-btn {
          font-size: 1rem !important;
        }
      }
      
    }
    
    .tabs {
      .button {
        font-size: 1rem !important;
      }
    }
    
    .credito-block {
      padding: 23px 16px 23px;
      
      ${media.lessThan('1600px')`
      
     `}
    }

    .select-input {
      .ant-select-selector {
        
        background: #415532 !important;
        border: 0 !important;
        border-radius: 30px !important;
        height: 40px !important;
        color: #fff !important;
        padding: 5px 20px !important;

        .ant-select-selection-placeholder {
          font-size: 16px !important;
          color: #cecdcd !important;
          opacity: 1 !important;
        }
      }

      .ant-select-arrow {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
    
    .liquida-facil-active {
      .ant-pagination {
        opacity: 0;
      }
    }
    
    .btn-abqm {
      background: ${colors.lightDeepGreen};
      border: 0 !important;
      color: #fff;

      &:hover, &:focus, &:active {
        background: ${colors.darkGreen};
        color: #fff;
      }
    }

    .btn-abqm[disabled], .btn-abqm[disabled]:hover, .btn-abqm[disabled]:focus, .btn-abqm[disabled]:active {
      background: #438b1d !important;
      opacity: 0.5;
      color: #fff;
    }

    .nome-competidor {
      background: rgba(255, 255, 255, 0.03);
      line-height: 35px;
    }

    .nome-competidor .ant-table-cell:nth-child(3) * {
      visibility: visible !important;
    }

    .ant-table-tbody tr.nome-competidor > td {
      border-bottom: 0 !important;
    }

    .nome-competidor .ant-table-cell * {
      visibility: hidden;
    }

    .nome-competidor .ant-table-cell:nth-child(3) {
      display: block;
      position: absolute;
      left: 0;
      border-bottom: 2px solid #456635 !important;
      width: 100%;
      color: #fff !important;
    }
    
`

export const PaymentOption = styled.div`

    border-top: 0.5px solid rgba(0,0,0,0.1);
    box-shadow: 0 1px 0 0 rgba(255,255,255,0.1) inset;
    color: #ffffff;
    padding: 1rem 0.7rem 0;
    margin-top: 2rem;

  h3 {
    text-align: center;
    color: rgb(255 255 255 / 71%);
    font-size: 1.1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  button {
    
    margin-bottom: 3px;
    border: 0;
    text-align: left;
    padding: 0;
    height: 66px;
    width: 80%;
    margin: 0 auto 4px;
    display: block;
    
    &.ant-btn-primary {
      background: ${colors.deepGreen};
      
       span.img-icon {
          background: ${colors.deepGreen};
          background: rgb(0 0 0 / 15%);
       }      
    }
    
    &.ant-btn-back {   
     background: #526a41;
     color: ${colors.greyWhite};
      
       span.img-icon {
          background: rgb(0 0 0 / 15%);
       }  
    }
   
   span.img-icon {     
      display: inline-block;     
      margin-right: 20px;
      float: left;
   }
   
   span {
    height: 100%;
      padding: 19px;
   }
   
   img {
     width: 25px;     
   }
   
    &:hover, &:focus, &:active {
      background: ${colors.lightDeepGreen}; 
    }    
  }
`

export const PaymentSumary = styled.div`
  h2 {
      font-size: 2rem;
      margin-bottom: 0;
  }
  
  h3 {
    img {
      width: 14px;
      margin-top: -4px;
      margin-right: 5px;
    }
  }
  
  h2, h3 {
    color: #fff;
    text-align: center;
  }
`