import React from 'react';
import PropTypes from 'prop-types';

import { percentage } from '../../functions';

import { Pendencies, Debts, CardError } from '../';

import { Container } from './styles';

export default function StudBookPendencies({
  dataPendencies,
  dataDebts,
  onUpdate,
}) {
  const {
    processos_pessoais: personal,
    processos_terceiros: thirdParty,
    processos_studbook: allProcesses,
  } = dataPendencies;

  const percentagePersonal = percentage(personal, allProcesses);
  const percentageThirdParties = percentage(thirdParty, allProcesses);

  return (
    <Container>
      {Object.keys(dataPendencies).length > 0 || !dataPendencies.error ? (
        <>
          <Pendencies
            title="Minhas pendências"
            description="Processos parados que dependem da minha interação para a continuidade"
            value={personal}
            data={dataPendencies}
            graphValue={percentagePersonal}
          />
          <Pendencies
            title="Pendências de terceiros"
            description="Atividades que dependem da interação de outras pessoas"
            value={thirdParty}
            data={dataPendencies}
            graphValue={percentageThirdParties}
          />
        </>
      ) : (
        <>
          <CardError
            message={dataPendencies.error.message}
            code={dataPendencies.error.code}
            onUpdate={onUpdate}
          />
          <CardError
            message={dataPendencies.error.message}
            code={dataPendencies.error.code}
            onUpdate={onUpdate}
          />
        </>
      )}

      {Object.keys(dataDebts).length > 0 || !dataDebts.error ? (
        <Debts title="Meus débitos" value={dataDebts.debito} />
      ) : (
        <CardError
          message={dataDebts.error.message}
          code={dataDebts.error.code}
          onUpdate={onUpdate}
        />
      )}
    </Container>
  );
}

StudBookPendencies.defaultProps = {
  dataDebts: { debito: 0 },
};

StudBookPendencies.propTypes = {
  dataPendencies: PropTypes.shape({
    processos_pessoais: PropTypes.number,
    processos_studbook: PropTypes.number,
    processos_terceiros: PropTypes.number,
  }),
  dataDebts: PropTypes.shape({
    debito: PropTypes.number,
  }),
  onUpdate: PropTypes.func.isRequired,
};
