import React, { useState, useCallback, useEffect } from 'react';
import swal from 'sweetalert';
import debounce from 'lodash/debounce';
import { api } from '../../../../services/api';
import { colors } from '../../../../theme';
import {
  Modal,
  Radio,
  Button,
  Checkbox,
  FormItem,
  AutoComplete,
  FormValidator,
  ExclamationSvg,
} from '../../../../components';

import { ConfirmChangeRequesterModal } from '..';
import { MaskInput, Form, ModalContent, CircleLoading } from './styles';

export default function ChangeRequesterModal({
  token,
  isOpen,
  apiCall,
  setModalIsOpen,
  detailsRequest,
}) {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [newRequester, setNewRequester] = useState('');
  const [typeRequester, setTypeRequester] = useState('pf');
  const [isAutoComplete, setIsAutoComplete] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [maskTypeAndPlaceholder, setMaskTypeAndPlaceholder] = useState({
    mask: '999.999.999-99',
    placeholder: '000.000.000-00',
  });

  const [options, setOptions] = useState([]);
  const [notFoundPerson, setNotFoundPerson] = useState('');

  const onSearch = async (searchText) => {
    if (!searchText) {
      setOptions([]);
      return;
    }
    else if (searchText.length < 8 && typeRequester === '') {
      return;
    }
    else {
      handleSearch(searchText);
    }
  };

  const handleSearch = useCallback(async (searchText) => {
    try {
      const { data } = await api.post(
        'PedidoRegistro/AutoCompleteBuscaPessoaSolicitante',
        {
          conteudo: searchText,
        }
      );
      const people = data.result.Data;

      if (people.length > 0) {
        const options = people.map((person) => ({
          key: person.id_pessoa,
          value: person.nome_pessoa,
          label: person.nome_pessoa,
        }));

        setOptions(options);
        setNotFoundPerson('');
      } else {
        setOptions(people);
        setNotFoundPerson('Nenhum resultado encontrado.');
      }
    } catch (error) {
      console.log(error);
      swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
    }
  }, []);

  const onSelect = useCallback((data, option) => {
    setNewRequester({ id_pessoa: option.key, nome_pessoa: option.value });
  }, []);

  const switchModal = useCallback(() => {
    setInputValue('');
    setChecked(false);
    setNewRequester('');
    setConfirmed(false);
    setTypeRequester('pf');
    setModalIsOpen(!isOpen);
    setConfirmationModal(false);
  }, [isOpen, setModalIsOpen]);

  const handleConfirm = () => {
    setConfirmed(true);
  };

  useEffect(() => {
    if (typeRequester === 'pf') {
      return (
        setMaskTypeAndPlaceholder({
          mask: '999.999.999-99',
          placeholder: '000.000.000-00',
        }),
        setIsAutoComplete(false)
      );
    } else if (typeRequester === 'pj') {
      return (
        setMaskTypeAndPlaceholder({
          mask: '99.999.999/9999-99',
          placeholder: '00.000.000/0000-00',
        }),
        setIsAutoComplete(false)
      );
    } else {
      return (
        setInputValue(''),
        setIsAutoComplete(true),
        setMaskTypeAndPlaceholder({
          mask: '',
          placeholder: 'Pesquisar...',
        })
      );
    }
  }, [typeRequester]);

  const handleSubmit = useCallback(async () => {
    const conteudo = inputValue.replace(/[.-/-]/g, '');
    setLoading(true);

    try {
      const { data } = await api.post(
        'PedidoRegistro/AutoCompleteBuscaPessoaSolicitante',
        {
          conteudo,
          tipo_pessoa: typeRequester,
        }
      );

      if (data.result.Data[0]) {
        setNewRequester(data.result.Data[0]);
        setConfirmationModal(true);
      } else {
        swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
      }
    } catch (error) {
      console.log(error);
      swal('Algo de errado', 'Se o erro persistir contate-nos!', 'error');
    } finally {
      setLoading(false);
    }
  }, [inputValue, typeRequester]);

  const radioRequesterOptions = [
    { value: 'pf', label: 'CPF' },
    { value: 'pj', label: 'CNPJ' },
    { value: '', label: 'Copropriedade/Condomínio' },
  ];

  return (
    <>
      <Modal
        centered
        scroll={false}
        isVisible={isOpen}
        circleLoading={loading}
        height={confirmed ? 280 : 340}
        title={confirmed && 'Alterar solicitante'}
        width={window.innerWidth >= 1600 ? 525 : 420}
        toggleVisibility={switchModal}
        footer={
          <>
            {confirmed ? (
              <>
                <Button onClick={switchModal} color={colors.red} fontLight>
                  Cancelar
                </Button>

                {!typeRequester ? (
                  <Button
                    fontLight
                    color={colors.lightGreen}
                    disabled={!newRequester}
                    onClick={() => setConfirmationModal(true)}
                  >
                    Continuar
                  </Button>
                ) : (
                  <Button
                    fontLight
                    onClick={handleSubmit}
                    color={colors.lightGreen}
                    disabled={!inputValue}
                  >
                    Continuar
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button color={colors.red} fontLight onClick={switchModal}>
                  Cancelar
                </Button>
                <Button
                  fontLight
                  color={colors.lightGreen}
                  disabled={!checked}
                  onClick={handleConfirm}
                >
                  Continuar
                </Button>
              </>
            )}
          </>
        }
      >
        <>
          {confirmed ? (
            <>
              {loading ? (
                <CircleLoading />
              ) : (
                <Form>
                  <FormItem
                    label="Tipo do solicitante"
                    name="requester"
                    className="form-item1"
                  >
                    <Radio
                      name="requester"
                      size={window.innerWidth >= 1600 ? 'large' : 'middle'}
                      options={radioRequesterOptions}
                      value={typeRequester}
                      onChange={(value) => setTypeRequester(value)}
                    />
                  </FormItem>

                  <span className="requester-span">
                    Informe do novo solicitante
                  </span>

                  {isAutoComplete ? (
                    <FormValidator>
                      <AutoComplete
                        withFilter={false}
                        options={options}
                        onSelect={onSelect}
                        notFoundContent={notFoundPerson}
                        onSearch={debounce(onSearch, 500)}
                        placeholder="Pesquisar por nome..."
                      />
                    </FormValidator>
                  ) : (
                    <FormItem name="requester-data" className="form-item2">
                      <MaskInput
                        name="requester-data"
                        width={window.innerWidth <= 420 ? '100%' : 320}
                        maskChar={null}
                        value={inputValue}
                        mask={maskTypeAndPlaceholder.mask}
                        placeholder={maskTypeAndPlaceholder.placeholder}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </FormItem>
                  )}
                </Form>
              )}
            </>
          ) : (
            <ModalContent>
              <ExclamationSvg />

              <h1>
                Você tem certeza que deseja alterar o solicitante desse pedido
                de registro?
              </h1>

              <Checkbox
                checkColor={colors.buttonGray}
                onChange={(checkedValue) => setChecked(checkedValue)}
                checked={checked}
              >
                Sim, tenho certeza!
              </Checkbox>
            </ModalContent>
          )}
        </>
      </Modal>

      <ConfirmChangeRequesterModal
        token={token}
        apiCall={apiCall}
        switchModal={switchModal}
        newRequester={newRequester}
        detailsRequest={detailsRequest}
        confirmationModal={confirmationModal}
      />
    </>
  );
}
