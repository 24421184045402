import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { FiSearch } from 'react-icons/fi';

import { Container } from './styles';

const SearchButtonAnimals = ({
  icon,
  title,
  onTop,
  disabled,
  description,
  ...props
}) => {
  return (
    <Tooltip
      title={!disabled && title}
      placement={onTop && 'bottom'}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
    >
      <Container
        size="large"
        type="primary"
        shape="round"
        icon={icon}
        disabled={disabled}
        description={description}
        {...props}
      >
        {description}
      </Container>
    </Tooltip>
  );
};

SearchButtonAnimals.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  onTop: PropTypes.bool,
};

SearchButtonAnimals.defaultProps = {
  icon: <FiSearch size={18} />,
};

export default SearchButtonAnimals;
