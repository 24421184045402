import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { AiOutlineLoading } from 'react-icons/ai';

import { pxToRem } from '../../../../functions';
import { colors, fonts } from '../../../../theme';

const rotation = keyframes`
 to {
    transform: rotate(1turn);
  }
`;

export const ModalContent = styled.div`
  height: ${({ specialStyle }) => (specialStyle ? '100%' : 'auto')};
  padding: ${({ specialStyle }) =>
    specialStyle ? '0 0 0 39px' : '20px 20px 0'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ specialStyle }) =>
    specialStyle ? 'flex-start' : 'center'};

  > * {
    color: ${colors.eightGrey};
  }

  label {
    margin-top: ${pxToRem(20)};
  }

  h1,
  h3 {
    font-family: 'SFRounded-Regular', sans-serif;
  }

  h1 {
    margin-top: ${pxToRem(7)};

    ${fonts.size7}
    line-height: 1.21;
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
    margin-top: ${pxToRem(16)};

    ${fonts.size7}
    line-height: 1.21;

    font-family: 'SFRounded-Bold', sans-serif;
  }

  h3 {
    margin-bottom: 0;
    margin-top: ${pxToRem(4)};

    ${fonts.size4};
    line-height: 1.19;
  }

  div[aria-label='animation'] {
    margin-top: ${pxToRem(-14)} !important;
  }

  ${media.lessThan('1024px')`
    h1 {
      ${fonts.size4}
    }

    h2 {
      ${fonts.size4}
    }

    h3 {
      ${fonts.size3};
    }
  `}
`;

export const CircleLoading = styled(AiOutlineLoading)`
  align-self: center;

  font-size: 20px;
  color: ${colors.lightGreen};
  animation: ${rotation} 1s infinite;
`;
