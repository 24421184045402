import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';

export const Container = styled.div`
  height: 15%;
  margin-top: ${pxToRem(12)};

  display: grid;
  grid-gap: ${pxToRem(32)};
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);

  ${media.greaterThan('1600px')`
    margin-top: ${pxToRem(14)}

    grid-gap: ${pxToRem(37.5)};
  `}

  ${media.greaterThan('1900px')`
    margin-top: ${pxToRem(16.5)};

    grid-gap: ${pxToRem(44.5)};
  `}
`;
