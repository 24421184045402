import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { FiSearch } from 'react-icons/fi';

import { Container } from './styles';

const SearchButton = ({ title, disabled, icon, onTop, ...props }) => (
  <Tooltip
    title={!disabled && title}
    placement={onTop && 'bottom'}
    mouseEnterDelay={0}
    mouseLeaveDelay={0}
  >
    <Container
      size="middle"
      type="dashed"
      shape="circle"
      icon={icon}
      disabled={disabled}
      {...props}
    />
  </Tooltip>
);

SearchButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  onTop: PropTypes.bool,
};

SearchButton.defaultProps = {
  icon: <FiSearch size={18} />,
};

export default SearchButton;
