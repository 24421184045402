import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../../functions';

export const Container = styled.section`
  height: 100%;
  overflow: hidden;

  .bold {
    font-family: 'SFProText-Bold', sans-serif;
  }

  .text-not-found {
    margin-top: 10px;
  }

  h1 {
    font-size: 1.5rem;
    font-family: 'SFProText-Bold', sans-serif;
  }

  table {
    position: relative;

    width: 100%;
    height: auto;
    margin: 1.5rem 0;
    border-collapse: collapse;

    ::after {
      position: absolute;
      bottom: -12px;

      content: '';
      width: 100%;
      height: 1px;

      background: #ccc;
    }

    :last-child::after {
      background: transparent;
    }

    tr {
      padding: 0 ${pxToRem(8)};

      display: grid;
      grid-template-columns: 0.8fr 2fr;

      :nth-child(odd) {
        background-color: rgba(40, 49, 49, 0.06);
      }

      :nth-child(even) {
        background-color: rgba(40, 49, 49, 0.1);
      }

      :hover {
        background-color: rgba(40, 49, 49, 0.15);
      }
    }

    td,
    th {
      padding: 8px 0;

      text-align: left;
    }
  }

  .table-info {
    height: 100%;

    padding-bottom: ${pxToRem(28)};
  }

  ${media.lessThan('1024px')`
    table {
      margin: ${pxToRem(10)} 0;
    }

    .table-info {
      padding-bottom: ${pxToRem(38)};
  `}
`;
