import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import media from 'styled-media-query';

import { colors } from '../../theme';
import { pxToRem } from '../../functions';

const bounceFadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  align-items: ${({ horizontal }) => (horizontal ? 'center' : 'unset')};

  animation: 0.3s ${bounceFadeInAnimation};

  > label {
    margin-bottom: ${({ horizontal }) => (horizontal ? 0 : pxToRem(4))};
    margin-right: ${({ horizontal }) => (horizontal ? pxToRem(24) : 0)};

    line-height: 1.14;
    letter-spacing: -0.4px;

    color: ${colors.eightGrey};

    filter: ${({ disabled }) => disabled && 'opacity(0.5)'};
  }

  > div {
    display: flex;
    align-items: flex-start;

    input {
      transform: translateX(-2px);
    }
  }

  .ant-switch-handle {
    ${media.greaterThan('1900px')`
      width: ${pxToRem(26)};
      height: ${pxToRem(26)};

      ::before {
        border-radius: 50%;
      }
    `}
  }

  .ant-switch-checked .ant-switch-handle {
    ${media.greaterThan('1900px')`
      left: calc(100% - 26px - 2px);
    `}
  }

  .ant-btn-dashed-disabled,
  .ant-btn-dashed.disabled,
  .ant-btn-dashed[disabled],
  .ant-btn-dashed-disabled:hover,
  .ant-btn-dashed.disabled:hover,
  .ant-btn-dashed[disabled]:hover,
  .ant-btn-dashed-disabled:focus,
  .ant-btn-dashed.disabled:focus,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed-disabled:active,
  .ant-btn-dashed.disabled:active,
  .ant-btn-dashed[disabled]:active,
  .ant-btn-dashed-disabled.active,
  .ant-btn-dashed.disabled.active,
  .ant-btn-dashed[disabled].active {
    background-color: transparent;
  }

  .ant-picker-focused {
    border-color: ${colors.blue};
  }
`;
