import { parseDateIso } from '../.';

const comparator = (a, b, objectKey) => {
  let x = a[objectKey];
  let y = b[objectKey];

  // Date verify
  if (typeof x === 'string' && typeof y === 'string') {
    const dateStringRegex = /\d{2}\/\d{2}\/\d{4}/;
    const dateStringX = x.match(dateStringRegex);
    const dateStringY = y.match(dateStringRegex);

    if (dateStringX && dateStringY) {
      x = parseDateIso(dateStringX[0]);
      y = parseDateIso(dateStringY[0]);
    }
  }

  if (x === y) {
    return 0;
  } else {
    return x > y ? 1 : -1;
  }
};

export default (data, objectKey, method) => {
  let activeSort, sortedData;

  if (method === 'asc') {
    activeSort = objectKey;
    sortedData = data.sort((a, b) => comparator(a, b, objectKey));
  } else if (method === 'desc') {
    activeSort = '';
    sortedData = data.reverse((a, b) => comparator(a, b, objectKey));
  } else {
    throw new Error('Method must be equal "asc" or "desc"');
  }

  return [activeSort, sortedData];
};
