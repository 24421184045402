import React, { useState } from 'react';
import { message } from 'antd';
import { Helmet } from 'react-helmet';

import { api } from '../../services/api';

import {
  Loader,
  PageTitle,
  Breadcrumb,
  StyledCustomScrollBar,
} from '../../components';

import colors from '../../theme/colors';

import { Container, Header, Content } from './styles';

function RegistrationRequestConsultation() {
  const pageTitle = 'Consulta de pedido de registro';

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [pendencies, setPendencies] = useState();
  const [requestNumber, setRequestNumber] = useState('');
  const [requestExibition, setRequestExibition] = useState('');

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.get('studbook/consultapedidoregistro', {
        params: {
          num_preregistro: requestNumber,
        },
      });

      if (data.success) {
        setData(data?.data);
        setPendencies(data?.pendencias);
      }
    } catch (error) {
      message.error('Número do pedido não encontrado!');
      setData();
    } finally {
      setLoading(false);
      setRequestExibition(requestNumber);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  return (
    <Container>
      <Breadcrumb
        pages={[
          {
            title: pageTitle,
            path: '/pedido-de-registro',
          },
        ]}
      />

      <Helmet>
        <title>{pageTitle} - Central do Quartista</title>
      </Helmet>

      <Header>
        <PageTitle>{pageTitle}</PageTitle>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <label>Número do pré registro</label>

            <input
              placeholder="0000000"
              value={requestNumber}
              onChange={(event) => setRequestNumber(event.target.value)}
              type="number"
            />
          </fieldset>

          <button type="submit">Buscar</button>

          {data && (
            <h2>
              número do pedido: <strong>{requestExibition}</strong>
            </h2>
          )}
        </form>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        data && (
          <Content>
            <table>
              <StyledCustomScrollBar color={colors.eightGrey}>
                <tr>
                  <td>Solicitante:</td>
                  <td>{data?.solicitante}</td>
                </tr>

                <tr>
                  <td>Proprietário:</td>
                  <td>{data?.proprietario}</td>
                </tr>

                <tr>
                  <td>Criador:</td>
                  <td>{data?.criador}</td>
                </tr>

                <tr>
                  <td>Inspetor:</td>
                  <td>{data?.inspetor}</td>
                </tr>

                <tr>
                  <td>Grau de sangue: </td>
                  <td>{data?.grau_sangue}</td>
                </tr>

                <tr>
                  <td>Pelagem do Produto: </td>
                  <td>{data?.pelagem_produto}</td>
                </tr>

                <tr>
                  <td>Sexo do Produto: </td>
                  <td>{data?.sexo_produto}</td>
                </tr>

                <tr>
                  <td>Opções de nome: </td>
                  <td>{data?.opcoes_nome}</td>
                </tr>

                <tr>
                  <td>Data do Lançamento: </td>
                  <td>{data?.dt_lancamento}</td>
                </tr>

                <tr>
                  <td>Data da Cobertura: </td>
                  <td>{data?.dt_cobertura}</td>
                </tr>

                <tr>
                  <td>Data de Nascimento: </td>
                  <td>{data?.dt_nascimento}</td>
                </tr>

                <tr>
                  <td>Data de Inspeção: </td>
                  <td>{data?.dt_inspecao}</td>
                </tr>

                <tr>
                  <td>Filiação: </td>
                  <td>{data?.filiacao}</td>
                </tr>

                <tr>
                  <td>Pelagem da Égua Comum: </td>
                  <td>{data?.pelagem_egua_comum}</td>
                </tr>

                <tr>
                  <td>Trasferência de Embrião: </td>
                  <td>{data?.te}</td>
                </tr>

                <tr>
                  <td>Produto ao Pé: </td>
                  <td>{data?.prd_ao_pe}</td>
                </tr>

                <tr>
                  <td>Opções de nome: </td>
                  <td>{data?.opcoes_nome}</td>
                </tr>

                <tr>
                  <td>Caracteristicas Indesejáveis: </td>
                  <td>{data?.caracteristicas_indesejaveis}</td>
                </tr>

                <tr>
                  <td>Tipo da Cobertura: </td>
                  <td>{data?.tp_cobertura}</td>
                </tr>

                <tr>
                  <td>Registrado em: </td>
                  <td>{data?.dt_registro}</td>
                </tr>

                <tr>
                  <td>Pedido de Registro fora do prazo: </td>
                  <td>{data?.pedido_fora_prazo}</td>
                </tr>

                <tr>
                  <td>Cobertura fora do prazo: </td>
                  <td>{data?.cobertura_fora_prazo}</td>
                </tr>

                <tr>
                  <td>Emitido em: </td>
                  <td>{data?.dt_emissao}</td>
                </tr>

                <tr>
                  <td>Data de envio: </td>
                  <td>{data?.dt_envio}</td>
                </tr>

                <tr>
                  <td>Situação: </td>
                  <td>{data?.situacao}</td>
                </tr>

                {pendencies && (
                  <>
                    <h2>Pendências</h2>
                    {pendencies.map((pendencie, index) => (
                      <tr key={index}>
                        <td>{pendencie?.descricao}</td>
                        <td>{pendencie?.detalhes}</td>
                      </tr>
                    ))}
                  </>
                )}
              </StyledCustomScrollBar>
            </table>
          </Content>
        )
      )}
    </Container>
  );
}

export default RegistrationRequestConsultation;
