import React, { useEffect } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';

import { setToken } from '../../services/auth';

import { authApiPagamentos } from '../../services/api-pagamentos'

function Auth() {
  const { token, d } = queryString.parse(window.location.search);

  useEffect(() => {
    const handleToken = () => {
      if (token) {
        setToken(token);
        authApiPagamentos(token, d)
      }
    };

    handleToken();
  }, [token]);

  return <Redirect to="/" />;
}

export default Auth;
