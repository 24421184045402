import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const MaskedInput = ({ name, width, onChange, size, invalid, ...props }) => (
  <Container
    id={name}
    size={size}
    name={name}
    width={width}
    invalid={invalid}
    autoComplete="off"
    onChange={(e) => onChange(e.target.value)}
    {...props}
  />
);

MaskedInput.propTypes = {
  size: PropTypes.oneOf(['large', 'small', 'middle']),
  name: PropTypes.string,
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  invalid: PropTypes.bool,
};

MaskedInput.defaultProps = {
  size: 'large',
  onChange: () => {},
};

export default MaskedInput;
