import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import horseAnimated from '../../theme/assets/animations/horse.json';

import { Container } from './styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: horseAnimated,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

/**
 * if this component is inside mainframe, it's necessary to set negative margin in styles
 * @param {boolean} removeMargin
 */
const PreLoader = ({ removeMargin, profileAnimal }) => (
  <Container removeMargin={removeMargin} profileAnimal>
    <Lottie options={defaultOptions} height={315} width={315} />
    <div className="loader-text">
      <h1>Aguarde!</h1>
      <p>Estamos carregando suas informações</p>
    </div> 
  </Container>
);

PreLoader.propTypes = {
  removeMargin: PropTypes.bool,
};

export default PreLoader;
