import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../context/Auth';
import { api, apiPortal } from '../../services/api';
import { verifyKindOfPerson } from '../../functions';
import { useIgnoreMainframe } from '../../context/IgnoreMainframe';

import {
  Loader,
  CardError,
  MyAnimals,
  PreLoader,
  Breadcrumb,
  Newsletter,
  JoinUsButton,
  ServiceAndCalled,
  PendingInspections,
  StudBookPendencies,
} from '../../components';

import {
  Container,
  Salutation,
  ContentCenter,
  SalutationAndButtonJoinUs,
} from './styles';

export default function Dashboard() {
  const [debts, setDebts] = useState({});
  const [called, setCalled] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [pendencies, setPendencies] = useState({});
  const [newsletter, setNewsletter] = useState([]);
  const [doubleChart, setDoubleChart] = useState(false);
  const [pendingInspections, setPendingInspections] = useState([]);

  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { userData } = useAuth();
  const { setIgnoreMainframe } = useIgnoreMainframe();

  const history = useHistory();
  const location = useLocation();

  const fetchData = useCallback(async () => {
    if (history.action === 'POP' && !location.state?.fromError && !hasError) {
      setIgnoreMainframe(true);
      setPreLoading(false);
    } else {
      setLoading(false);
    }

    try {
      const [
        pendencies,
        debts,
        animals,
        called,
        pendingInspections,
        newsletter,
      ] = await Promise.allSettled([
        api.get('dashboard/pendenciasstudbook'),
        api.get('dashboard/pendenciasdebitos'),
        api.get('dashboard/animais'),
        api.get('dashboard/chamados'),
        api.get('dashboard/inspecoespendentes'),
        apiPortal.get('contents', {
          params: {
            pagesize: 20,
            index_id: 35,
          },
        }),
      ]);

      if (pendencies.status === 'fulfilled') {
        setPendencies(pendencies.value.data.data);
      } else {
        setHasError(false);
        setPendencies({
          error: {
            code: pendencies.reason.response.status,
            message: pendencies.reason.response.data.mensagem,
          },
        });
      }

      if (debts.status === 'fulfilled') {
        setDebts(debts.value.data.data);
      } else {
        setHasError(false);
        setDebts({
          error: {
            code: debts.reason.response.status,
            message: debts.reason.response.data.mensagem,
          },
        });
      }

      if (animals.status === 'fulfilled') {
        setAnimals(animals.value.data.data);
      } else {
        setHasError(false);
        setAnimals({
          error: {
            code: animals.reason.response.status,
            message: animals.reason.response.data.mensagem,
          },
        });
      }

      if (called.status === 'fulfilled') {
        setCalled(called.value.data.data);
      } else {
        setHasError(false);
        setCalled({
          error: {
            code: called.reason.response.status,
            message: called.reason.response.data.mensagem,
          },
        });
      }

      if (pendingInspections.status === 'fulfilled') {
        setPendingInspections(pendingInspections.value.data.data);
      } else {
        setHasError(false);
        setPendingInspections({
          error: {
            code: pendingInspections.reason.response.status,
            message: pendingInspections.reason.response.data.mensagem,
          },
        });
      }

      if (newsletter.status === 'fulfilled') {
        setNewsletter(newsletter.value.data.rows);
      } else {
        setHasError(false);
        setNewsletter({
          error: {
            code: newsletter.reason.response.status,
            message: newsletter.reason.response.data.mensagem,
          },
        });
      }

      if (userData) {
        const inspector = verifyKindOfPerson(userData, 'Inspetor');

        if (
          inspector &&
          animals.value.data.success &&
          animals.value.data.data[0].qtd > 0
        ) {
          setDoubleChart(true);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIgnoreMainframe(false);
      setPreLoading(false);
      setLoading(false);
    }
  }, [history, location, setIgnoreMainframe, userData, hasError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderComponent = useCallback(() => {
    if (doubleChart) {
      return (
        <>
          {(animals.length > 0 && animals[0].qtd > 0) || !animals.error ? (
            <MyAnimals data={animals} doubleChart={doubleChart} />
          ) : (
            <CardError
              large
              style={{ gridArea: 'm1' }}
              title="Meus animais"
              message={animals.error.message}
              code={animals.error.code}
              onUpdate={fetchData}
            />
          )}

          {pendingInspections.lenght > 0 || !pendingInspections.error ? (
            <PendingInspections
              doubleChart={doubleChart}
              data={pendingInspections}
            />
          ) : (
            <CardError
              large
              style={{ gridArea: 'm2' }}
              title="Inspeções"
              message={pendingInspections.error.message}
              code={pendingInspections.error.code}
              onUpdate={fetchData}
            />
          )}
        </>
      );
    } else if (animals.length > 0 && animals[0].qtd > 0) {
      return <MyAnimals data={animals} />;
    } else if (pendingInspections.length > 0) {
      return <PendingInspections data={pendingInspections} />;
    } else if (animals.error) {
      return (
        <CardError
          large
          style={{ gridArea: 'm1' }}
          title="Meus animais"
          message={animals.error.message}
          code={animals.error.code}
          onUpdate={fetchData}
        />
      );
    } else if (pendingInspections.error) {
      return (
        <CardError
          large
          style={{ gridArea: 'm1' }}
          title="Inspeções"
          message={pendingInspections.error.message}
          code={pendingInspections.error.code}
          onUpdate={fetchData}
        />
      );
    } else {
      return <CardError large style={{ gridArea: 'm1' }} />;
    }
  }, [animals, doubleChart, pendingInspections, fetchData]);

  if (preLoading) {
    return <PreLoader removeMargin />;
  } else if (loading) {
    return (

      <Container loading={loading}>
        <Loader light sizeScreen="huge" />
      </Container>
    );
  } else {
    return (
      <Container>
        <Breadcrumb />

        <Helmet>
          <title>Dashboard - Central do Quartista</title>
        </Helmet>

        <SalutationAndButtonJoinUs>
          <Salutation>Bem vindo à Central do Quartista!</Salutation>
          <JoinUsButton />
        </SalutationAndButtonJoinUs>

        <StudBookPendencies
          dataPendencies={pendencies}
          dataDebts={debts}
          onUpdate={fetchData}
        />

        <ContentCenter doubleChart={doubleChart}>
          {renderComponent()}

          {/* <MyScore /> */}

          {newsletter.length > 0 || !newsletter.error ? (
            <Newsletter data={newsletter} />
          ) : (
            <CardError
              large
              style={{ gridArea: 'l1' }}
              title="Últimas notícias"
              message={newsletter.error.message}
              code={newsletter.error.code}
              onUpdate={fetchData}
            />
          )}

          {called.length > 0 || !called.error ? (
            <ServiceAndCalled data={called} />
          ) : (
            <CardError
              style={{ gridArea: 'm5' }}
              large
              title="Atendimento e chamado"
              message={called.error.message}
              code={called.error.code}
              onUpdate={fetchData}
            />
          )}
        </ContentCenter>
      </Container>
    );
  }
}
