import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgressbar as Progressbar,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { colors } from '../../theme';

const CircularProgressbar = ({ value, color, strokeWidth }) => {
  return (
    <Progressbar
      value={value}
      text={`${value}%`}
      strokeWidth={strokeWidth ? strokeWidth : 16}
      styles={buildStyles({
        textColor: colors.white,
        pathColor: color,
        /**
         * 1a: 0.1 of opacity
         * 4d: 0.3 of opacity
         */
        trailColor: color === '#ffffff' ? `${color}1a` : `${color}4d`,
      })}
    />
  );
};

CircularProgressbar.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default CircularProgressbar;
