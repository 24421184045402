import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const FormItem = ({
  name,
  label,
  disabled,
  className,
  children,
  horizontal,
  ...props
}) => (
  <Container
    disabled={disabled}
    className={className}
    horizontal={horizontal}
    {...props}
  >
    {label && <label htmlFor={name}>{label}</label>}
    {children}
  </Container>
);

FormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FormItem.defaultProps = {
  disabled: false,
};

export default FormItem;
