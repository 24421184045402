import styled from 'styled-components';
import media from 'styled-media-query';
import {
  Form as AntForm,
  Drawer as AntDrawer,
  Tooltip as TooltipContainer,
} from 'antd';

import { Button, MaskedInput } from '../../components';

import { colors, fonts } from '../../theme';
import { pxToRem } from '../../functions';

export const Container = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(40)};

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.86);
`;

// Some styles is on components/Table/styles.js

export const Number = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Animal = styled.td`
  p.name {
    font-size: 11px;
    text-align: left;
    line-height: 1.18;
    white-space: nowrap;
    text-decoration: none !important;

    font-family: 'SFProText-Semibold', sans-serif;
  }

  p.registry {
    margin-top: ${pxToRem(2)};

    font-size: 9px;
    text-align: left;
    line-height: 1.22;
    text-decoration: none !important;
  }

  ${media.greaterThan('huge')`
    p.name {
      font-size: 12px;
    }
  `}
`;

export const OutOfTime = styled.td`
  text-align: end;
  padding-right: 5px;

  font-family: ${({ hasValue }) =>
      hasValue ? 'SFProText-Bold' : 'SFProText-Regular'},
    sans-serif;

  color: ${({ hasValue }) =>
    hasValue ? colors.danger : colors.GrayishGreen} !important;
`;

export const DateInputWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;
`;

export const DateInput = styled(MaskedInput).attrs({
  size: 'small',
  mask: '11/11/1111',
  width: 80,
})`
  margin-left: ${pxToRem(-4)};
`;

export const ActionButtonWrapper = styled.td`
  align-items: center !important;
  flex-direction: row !important;
  justify-content: flex-end !important;

  padding-right: 5px;
`;

export const Tooltip = styled(TooltipContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-content-wrapper {
    width: 25% !important;
  }

  .ant-drawer-body {
    padding: ${pxToRem(22)} ${pxToRem(26)};
    overflow: hidden;
  }

  ${media.greaterThan('1900px')`
    .ant-drawer-title {
      ${fonts.size6};
    }
  `}
`;

export const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;

  .form-item2 {
    margin-top: 10.92%;
  }

  .form-item3 {
    margin-top: 13.65%;
  }

  .form-item4 {
    margin-top: 12.96%;
  }

  .form-item5 {
    margin-top: 5.46%;
  }

  .form-item6 {
    margin-top: 15.01%;
  }

  ${media.greaterThan('1900px')`  
    *,
    label {
      ${fonts.size5}
    }

    label {
      margin-bottom: ${pxToRem(6)};
    }

    .horizontal {
      label {
        margin-bottom: 0;
      }
    }

    .ant-switch {
      width: ${pxToRem(60)};
      height: ${pxToRem(30)};

      :after {
        width: ${pxToRem(25)};
        height: ${pxToRem(25)};
      }
    }
  `}
`;

export const PeriodWrapper = styled.div`
  display: flex;
  align-items: center !important;

  height: ${pxToRem(40)};

  .ant-select {
    margin-right: ${pxToRem(27)};
  }
`;

export const DrawerButton = styled(Button)`
  align-self: center;
  width: ${pxToRem(131)};
  margin-top: 10.57%;
`;

export const MoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  text-align: center;

  background: unset;
  transition: all 0.15s ease;

  svg {
    width: 24px;
    height: 24px;

    color: ${colors.GrayishGreen};
  }

  :hover {
    filter: brightness(0.9);
  }
`;

export const OptionsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  text-align: center;
  border-radius: 50%;
  padding: ${pxToRem(4)};

  color: ${colors.white};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? colors.red : colors.lightGreen};
  transition: all 0.3s ease;

  & + button {
    margin-left: ${pxToRem(8)};
  }

  svg {
    ${fonts.size4};
  }

  :hover {
    filter: brightness(1.05);
  }
`;
