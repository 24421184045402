import React from 'react';

import { colorsChart } from '../../theme';
import { TextHeader, DonutChart } from '../';

import {
  Container,
  Graph,
  GraphAndCharacteristics,
  Characteristics,
  CharacteristicsText,
} from './styles';

export default function PendingInspections({ doubleChart, data }) {
  const mappedValue = data.map((item, index) => {
    return { id: index, ...item, color: colorsChart[index] };
  });

  return (
    <Container doubleChart={doubleChart}>
      <TextHeader title="Inspeções" />

      <GraphAndCharacteristics>
        <Graph doubleChart={doubleChart}>
          <DonutChart data={mappedValue} />
        </Graph>

        <Characteristics doubleChart={doubleChart}>
          {mappedValue.map((item) => (
            <li key={item.id}>
              <CharacteristicsText color={item.color}>
                {item.titulo}
              </CharacteristicsText>
              <span>{item.qtd}</span>
            </li>
          ))}
        </Characteristics>
      </GraphAndCharacteristics>
    </Container>
  );
}
