import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const PageTitle = ({ children, fontLight }) => (
  <Container fontLight={fontLight}>{children}</Container>
);

PageTitle.propTypes = {
  fontLight: PropTypes.bool,
};

export default PageTitle;
