import React, { createContext, useState, useContext } from 'react';

const BreadcrumbContext = createContext([]);

const BreadcrumbProvider = ({ children }) => {
  const [breadcrumb, setBreadcrumb] = useState([]);

  return (
    <BreadcrumbContext.Provider value={{ breadcrumb, setBreadcrumb }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

function useBreadcrumb() {
  const context = useContext(BreadcrumbContext);

  if (!context)
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');

  return context;
}

export { BreadcrumbProvider, useBreadcrumb };
