import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Input = ({ name, onChange, ...props }) => (
  <Container
    size="large"
    name={name}
    id={name}
    onChange={(e) => onChange(e.target.value)}
    {...props}
  />
);

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default Input;
