import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '../../functions';
import { fonts } from '../../theme';

export const Container = styled.div`
  border-radius: ${pxToRem(6)};
  background: rgba(255, 255, 255, 0.1);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ large }) =>
    large
      ? css`
          padding: ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(16)};
        `
      : css`
          padding: ${pxToRem(12)} ${pxToRem(16)};
        `};

  height: calc(100% - ${pxToRem(33)});

  h4,
  h5,
  button {
    margin: 0;

    color: rgba(255, 255, 255, 0.5);
  }

  h4 {
    ${({ large }) => (large ? fonts.size4 : fonts.size3)};
  }

  h5 {
    margin-top: ${({ large }) => (large ? pxToRem(2) : pxToRem(5))};

    ${({ large }) => (large ? fonts.size3 : fonts.size2)};
  }

  button {
    align-self: flex-end;

    ${fonts.size1};
    line-height: 1;

    border: none;
    background-color: transparent;
    transition: color 0.15s ease;

    svg {
      ${fonts.size4};
    }

    :hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  ${media.greaterThan('1600px')`
    height: calc(100% - ${pxToRem(38)});
  `}

  ${media.greaterThan('1900px')`
    height: calc(100% - ${pxToRem(43)});

    button {
      ${fonts.size2};

      svg {
        ${fonts.size5};
      }
    }  
  `}
`;
