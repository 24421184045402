import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../.';
import { pxToRem } from '../../functions';
import { colors, fonts } from '../../theme';

export const Container = styled.div`
  border-radius: ${pxToRem(6)};
  padding-bottom: ${pxToRem(18)};

  grid-area: l1;

  background: rgba(255, 255, 255, 0.1);

  ${media.greaterThan('1600px')`
    border-radius: ${pxToRem(7)};
  `}

  ${media.greaterThan('1900px')`
    border-radius: ${pxToRem(8)};
  `}
`;

export const News = styled.ul`
  padding-left: ${pxToRem(18)};
  height: calc(100% - ${pxToRem(18)});

  overflow: hidden;

  display: flex;
  align-items: left;
  flex-direction: column;

  color: ${colors.white};

  li {
    margin-top: ${pxToRem(10)};

    display: flex;
    align-items: center;

    strong {
      margin-right: ${pxToRem(4)};

      ${fonts.size3};
    }

    small {
      margin-right: ${pxToRem(4)};

      opacity: 0.5;
      ${fonts.size1};
      line-height: 1.22;
    }

    p {
      line-height: 1;
      margin-right: ${pxToRem(4)};

      opacity: 0.75;
      ${fonts.size2};
      cursor: pointer;
      color: ${colors.white};
      transition: opacity 0.15s ease;

      :hover {
        opacity: 1;
      }
    }
  }

  ${media.greaterThan('1600px')`
    padding-left: ${pxToRem(22)};
    height: calc(100% - ${pxToRem(26)});

    li {
      margin-top: ${pxToRem(12)};

      strong {
        margin-right: ${pxToRem(5)};

        ${fonts.size4};
      }

      small {
        margin-right: ${pxToRem(5)};

        ${fonts.size1};
      }

      p {
        margin-right: ${pxToRem(5)};

        ${fonts.size3};
      }
    }
  `}

  ${media.greaterThan('1900px')`
    padding-left: ${pxToRem(24)};
    height: calc(100% - ${pxToRem(28)});

    li {
      margin-top: ${pxToRem(14)};

      strong {
        margin-right: ${pxToRem(6)};

        ${fonts.size5};
      }

      small {
        margin-right: ${pxToRem(6)};

        ${fonts.size2};
      }

      p {
        margin-right: ${pxToRem(6)};

        ${fonts.size4};
      }
    }
  `}
`;

export const CustomModal = styled(Modal)`
  width: ${pxToRem(909)} !important;

  .ant-modal-body {
    height: ${pxToRem(500)} !important;
  }

  ${media.greaterThan('huge')`
    .ant-modal-body {
        height: ${pxToRem(560)} !important;
      }
  `}

  ${media.greaterThan('1600px')`
    width: ${pxToRem(1064)} !important;

    .ant-modal-body {
      height: ${pxToRem(600)} !important;
    }
  `}

  ${media.greaterThan('1900px')`
    width: ${pxToRem(1264)} !important;

    .ant-modal-body {
      height: ${pxToRem(700)} !important;
    }
  `}

  .ant-modal-footer {
    /* fa: 0.98 opacity */
    background-color: ${colors.white}fa;
  }
`;

export const ModalContent = styled.div`
  padding: ${pxToRem(48)} 18%;

  color: ${colors.eightGrey};

  h1 {
    margin-bottom: ${pxToRem(24)};

    ${fonts.size9}

    color: inherit;
    line-height: 1.33;
    font-family: 'SFRounded-Bold', sans-serif;
  }

  p {
    color: inherit;
    ${fonts.size4};
    line-height: 1.6;
  }

  img {
    margin: ${pxToRem(24)} auto;
    width: 100%;
  }

  button {
    margin-top: ${pxToRem(38)};
  }
`;
