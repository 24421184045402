import styled from 'styled-components';
import { Button } from 'antd';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const Container = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${pxToRem(4)};
  margin-left: ${pxToRem(8)};

  color: ${colors.grey};
  border-color: ${colors.grey};
  background-color: transparent;

  :hover,
  :active,
  :focus {
    color: ${colors.blue};
    border-color: ${colors.blue};
    background-color: transparent;
  }

  :disabled {
    svg {
      filter: opacity(0.5);
    }

    border-color: rgba(0, 0, 0, 0.15);
  }
`;
