import styled, {css} from 'styled-components';
import { Button } from 'antd';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const Container = styled(Button)`
  /* margin-top: ${pxToRem(4)}; */
  margin-left: ${pxToRem(16)};
  border-color: ${colors.transparent};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.white};
  background-color: ${colors.lightGreen};

  :hover,
  :active,
  :focus {
    border-color: ${colors.transparent};

    color: ${colors.white};
    background-color: ${colors.lightGreen};
  }

  ${props => props.description !== '' && css`
    > svg {
      margin-right: ${pxToRem(8)};
    }
  `}

  > p {
    text-align: start;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(16)};
  }

  :disabled {
    svg {
    }
    filter: opacity(0.5);

    border-color: ${colors.transparent};

    color: ${colors.white};
    background-color: ${colors.lightGreen};
  }
`;
