import styled from 'styled-components';
import media from 'styled-media-query';

import { fonts, colors } from '../../../theme';
import { pxToRem } from '../../../functions';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: ${pxToRem(59)} 0;

  > * {
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
  }

  h1 {
    ${fonts.size7}
    font-family: 'SFRounded-Thin', sans-serif;

    span {
      font-family: 'SFRounded-Thin', sans-serif;
    }

    strong {
      font-family: 'SFRounded-Bold', sans-serif;
    }

    &#castrated-animal {
      line-height: 1.5;
    }
  }

  p {
    margin: ${pxToRem(50)} 0;

    ${fonts.size2};
    line-height: 1.58;
    letter-spacing: -0.34px;
  }

  ${media.greaterThan('1600px')`
    padding: ${pxToRem(69)} 0;

    h1 {
      ${fonts.size8};
    }

    p {
      ${fonts.size3};
    }
  `}

  ${media.greaterThan('1900px')`
    padding: ${pxToRem(82)} 0;
  `}
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: ${pxToRem(20)} ${pxToRem(20)} 0;

  > * {
    color: ${colors.eightGrey};
  }

  label {
    margin-top: ${pxToRem(20)};
  }

  h1,
  h3 {
    font-family: 'SFRounded-Regular', sans-serif;
  }

  h1 {
    margin-top: ${pxToRem(7)};

    ${fonts.size7}
    line-height: 1.21;
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
    margin-top: ${pxToRem(16)};

    ${fonts.size7}
    line-height: 1.21;

    font-family: 'SFRounded-Bold', sans-serif;
  }

  h3 {
    margin-bottom: 0;
    margin-top: ${pxToRem(4)};

    ${fonts.size4};
    line-height: 1.19;
  }

  div[aria-label='animation'] {
    margin-top: ${pxToRem(-14)} !important;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  button + button {
    margin-left: ${pxToRem(16)};
  }
`;
