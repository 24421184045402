import React from 'react';
import { Helmet } from 'react-helmet';

import { Breadcrumb } from '../../components';
import { Error } from '../.';
import data from './data';

import { Iframe } from './styles';

function OnlineServices({ match }) {
  const { page } = match.params;

  if (data[page]) {
    return (
      <>
        <Helmet>
          <title>{data[page].name} - Central do Quartista</title>
        </Helmet>
        <Breadcrumb pages={[{ title: data[page].name }]} />

        <Iframe url={data[page].link} />
      </>
    );
  } else {
    return <Error mainframe />;
  }
}

export default OnlineServices;
