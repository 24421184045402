import styled from 'styled-components';
import { Input } from 'antd';

import { pxToRem } from '../../functions';
import { colors } from '../../theme';

export const Container = styled(Input)`
  height: auto;
  width: ${({ width }) => (width ? pxToRem(width) : pxToRem(320))};

  border-radius: 4px !important;

  color: rgba(0, 0, 0, 0.65);
  font-family: 'SFProText-Regular', sans-serif;

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  :not(:disabled) {
    :hover,
    :active,
    :focus {
      border-color: ${colors.blue};
    }
  }

  :disabled::placeholder {
    color: rgba(0, 0, 0, 0.1);
  }
`;
