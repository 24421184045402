import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '../.';

import { Container, Head, Body, BodyWrapper } from './styles';

function Table({
  light,
  count,
  columns,
  loading,
  dataSource,
  activeSort,
  handleSort,
  dataLength,
  fetchMoreData,
}) {
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsLoaded, setTotalItemsLoaded] = useState(0);
  useEffect(() => {
    if (dataLength && count) {
      setTotalItems(count);
      setTotalItemsLoaded(dataLength.length);
    }
  }, [dataLength, count]);

  const columnsSize =
    columns?.map((column) => (column.size ? +column.size : 1)).join('fr ') +
    'fr';

  return (
    <Container columnsSize={columnsSize}>
      <Head light={light}>
        <tr>
          {columns?.map((column) => (
            <th key={column.key} className={column.key}>
              <p>{column.title}</p>
              {column.sort &&
                (activeSort !== column.key ? (
                  <button
                    onClick={() => handleSort && handleSort(column.key, 'asc')}
                  >
                    <FiChevronDown size={14} />
                  </button>
                ) : (
                  <button
                    onClick={() => handleSort && handleSort(column.key, 'desc')}
                  >
                    <FiChevronUp size={14} />
                  </button>
                ))}
            </th>
          ))}
        </tr>
      </Head>

      <BodyWrapper light={light} id="body-wrapper">
        <InfiniteScroll
          dataLength={dataLength || 10}
          next={fetchMoreData}
          hasMore
          scrollableTarget="body-wrapper"
        >
          <Body light={light}>
            {dataSource.map((data, dataIndex) => (
              <tr key={dataIndex}>
                {Object.keys(data).map((column, columnIndex) =>
                  typeof data[column] === 'string' ||
                  typeof data[column] === 'number' ? (
                    <td key={columnIndex} className="default">
                      {data[column]}
                    </td>
                  ) : (
                    <React.Fragment key={columnIndex}>
                      {data[column]}
                    </React.Fragment>
                  )
                )}
              </tr>
            ))}

            {loading && <Loader light={!light} size="small" />}
          </Body>
        </InfiniteScroll>
      </BodyWrapper>
    </Container>
  );
}

Table.propTypes = {
  light: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      sort: PropTypes.bool,
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  dataSource: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
  ),
  loading: PropTypes.bool,
  handleSort: PropTypes.func,
  activeSort: PropTypes.string,
  dataLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchMoreData: PropTypes.func,
};

export default Table;
